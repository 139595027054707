import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { ChatPoolingService } from './chat-pooling.service';

@Injectable({
  providedIn: 'root'
})
export class PoolingService {

  public subscriptions: Subscription[] = [];

  constructor(
    private chatPoolingService: ChatPoolingService
  ) {
    this.chatPoolingService.subscribe();
  }
}
