<div *ngIf="details.motivo" class="detail-info">
  <h6>Anomalia</h6>
  <span>{{details.motivo}}</span>
</div>

<section class="travel-info-section">
  <div class="detail-info">
    <h6>Cliente</h6>
    <span>{{details.cliente}}</span>
  </div>

  <div class="detail-info">
    <h6>Peso da Carga</h6>
    <span>{{details.pesoEntrega}}</span>
  </div>

  <div class="detail-info">
    <h6>Data agendada para entrega</h6>
    <span>{{details.dataPrevisaoEntrega}}</span>
  </div>

  <div class="detail-info">
    <h6>Tempo em diária</h6>
    <span>{{details.tempoEmDiaria || '0 dias'}}</span>
  </div>
</section>

<hr />

<section class="driver-info-section">
  <h5 class="section-title">Motorista</h5>

  <div class="driver-info-section-data-wrapper">
    <div class="detail-info">
      <h6>CPF</h6>
      <span>{{details.motorista.cpf}}</span>
    </div>

    <div class="detail-info">
      <h6>Nome</h6>
      <span>{{details.motorista.nome}}</span>
    </div>

    <div class="detail-info">
      <h6>Telefone</h6>
      <span class="phone-wrapper">
        <span class="phone-number">{{details.motorista.telefone}}</span>
      </span>
    </div>
  </div>
</section>

<hr />

<section *ngIf="googleMapPoints" class="location-info-section">
  <div class="detail-info">
    <h6>Local</h6>
    <span>Em {{details.localAnomalia.endereco}}</span>
  </div>

  <app-google-map logistic="distribuicao" [rastro]="googleMapPoints" [currentPoint]="googleMapPoints[0]"></app-google-map>
</section>
