
<app-modal-drawer [modalId]="modalId">
    <div class="modal-drawer-anomalies">
        <span class="modal-drawer-anomalies__title">Anomalias relacionadas</span>

        <ng-container *ngFor="let anomaly of anomalies">
            <app-panel>
                <app-panel-header 
                    [isDot]="true"
                    [title]="anomaly.name ? anomaly.name : anomaly.nome"
                    [link]="'/anomaly/' + anomaly.id"
                    (redirectUrl)="onRedirectionUrl($event)"
                >
                    <div style="display: flex; align-items: center;">
                        <app-tag-chips 
                            [title]="anomaly.priority | priority" 
                            [modifier]="anomaly.priority === 0
                                ? 'danger'
                                : anomaly.priority === 1
                                    ? 'warning'
                                    : 'primary'" 
                            icon="arrow-curved"
                        ></app-tag-chips>
                        
                        <app-tag-chips [title]="anomaly.status | anomalystatus" modifier="default"></app-tag-chips>

                        <span style="display: flex; align-items: center; margin-left: 4px;">
                            <app-icon icon="detail-hamburguer" height="24px" width="24px"></app-icon>
                            
                            <span
                                *ngIf="logistic === 'distribuicao'" 
                                class="modal-drawer-anomalies__small-text"
                            >
                                Distribuição
                            </span>

                            <span 
                                *ngIf="logistic === 'primaria'" 
                                class="modal-drawer-anomalies__small-text"
                            >
                                Primária
                            </span>

                            <span 
                                *ngIf="logistic === 'primaria'" 
                                class="modal-drawer-anomalies__small-text"
                            >
                                Agro
                            </span>

                            <span 
                                *ngIf="logistic === 'primaria'" 
                                class="modal-drawer-anomalies__small-text"
                            >
                                Commodities
                            </span>
                        </span>
                    </div>

                    <span class="modal-drawer-anomalies__calendar">
                        <app-icon icon="calendar" height="16px" width="16px"></app-icon>
                        <span class="modal-drawer-anomalies__small-text">{{anomaly.creationAt ? anomaly.creationAt : anomaly.dtCreated | datetimeformat:false}}</span>
                    </span>
                </app-panel-header>
            </app-panel>
        </ng-container>
    </div>
</app-modal-drawer>
