import { Component, Input, OnInit } from '@angular/core';
import { UserFilterScreenMonitoring } from 'src/app/models/user-filter/screen/monitoring.model';
import { UserFilter } from 'src/app/models/user-filter/user-filter.model';
import { ModalService } from '../../../modal.service';
import { ModalWindowMonitoringFilterService } from '../monitoring-filter.service';

@Component({
  selector: 'app-modal-window-monitoring-filter-delete-filter',
  templateUrl: './delete-filter.component.html',
  styleUrls: ['./delete-filter.component.scss']
})
export class ModalWindowMonitoringFilterDeleteFilterComponent implements OnInit {

  @Input() public modalId: string;

  @Input() public logistic: string;

  @Input() public savedFilterId: string;

  public savedFilter: UserFilter<UserFilterScreenMonitoring>;

  constructor(
    private modalService: ModalService,
    private modalWindowMonitoringFilterService: ModalWindowMonitoringFilterService
  ) { }

  public ngOnInit(): void {
    this.savedFilter = this.modalWindowMonitoringFilterService.getSavedFilterById(this.savedFilterId);
  }

  public handleDeleteFilter(): void {
    this.modalWindowMonitoringFilterService.deleteSavedFilter(this.savedFilter, this.logistic);
    this.modalService.unsetModal(this.modalId);
  }

  public handleBack(): void {
    this.modalService.unsetModal(this.modalId);
  }
}
