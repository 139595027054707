<div class="modal-delete">
    <div class="modal-delete__title">
        <div>
            <h1>{{ 'FILTER.DELETE_FILTER' | translate }}</h1>
            <p>{{ 'FILTER.ARE_YOU_SURE_DELETE_FILTER' | translate }}
                <b>"{{nameFilter}}"</b>?<br />{{'FILTER.THIS_ACTION_CANT_BE_UNDONE' | translate}}
            </p>
        </div>

        <button class="close-button" (click)="close()">
            <mat-icon matSuffix>close</mat-icon>
        </button>
    </div>
    <div class="modal-delete__footer">

        <app-button (clicked)="close()" text="{{'FILTER.CANCEL' | translate}}" [isOutlined]="true"
            [isMarginRight]="true" [isMarginLeft]="true"></app-button>

        <app-button text="{{'FILTER.DELETE_FILTER' | translate}}" (clicked)="deleteFilter()"></app-button>
    </div>
</div>