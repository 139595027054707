
<dialog class="modal-window {{centeredModifier}}">
    <div class="modal-window__content modal-window__content--is-animation-fade-in {{extraClassContent}}"
         (click)="$event.stopImmediatePropagation()">
        <div class="modal-window__content__header">
            <div class="modal-window__content__header__title">
                <span>{{titleModal || title}}</span>

                <div *ngIf="isCloseable" class="modal-window__content__header__icon" (click)="closeModal($event)">
                    <app-icon
                        [icon]="'close'"
                        [width]="24"
                        [height]="24"
                        title="Fechar"
                    ></app-icon>
                </div>
            </div>

            <span *ngIf="subTitle" class="modal-window__content__header__subtitle">{{subTitle}}</span>
        </div>

        <ng-content></ng-content>
    </div>
</dialog>