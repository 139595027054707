import { StepDetailDistributionFinishTrackingFinished } from './tracking-finished.model';

export class StepDetailDistributionFinishTracking {
    #finishedRealizedDate: Date;

    #finishedExpectedDate: Date;

    #entryCDDate: Date;

    #totalRealizedTime: number;

    #totalExpectedTime: number;

    #timeRemaining: number;

    #deliveryOutsideRadius: boolean;

    #motoristReason: string;

    #attachments: string[];

    #finished: StepDetailDistributionFinishTrackingFinished;

    constructor({
        dataHoraTerminoEsperada,
        dataHoraTerminoRealizada,
        dataHoraEntradaRaioCD,
        tempoTotalPrevisto,
        tempoTotalRealizado,
        tempoPendente,
        entregueForaRaio,
        motivoMotorista,
        anexos,
        finalizada
    }) {
        this.#finishedRealizedDate = !dataHoraTerminoRealizada ? null : new Date(dataHoraTerminoRealizada);
        this.#finishedExpectedDate = !dataHoraTerminoEsperada ? null : new Date(dataHoraTerminoEsperada);
        this.#entryCDDate = !dataHoraEntradaRaioCD ? null : new Date(dataHoraEntradaRaioCD);
        this.#totalExpectedTime = tempoTotalPrevisto;
        this.#totalRealizedTime = tempoTotalRealizado;
        this.#timeRemaining = tempoPendente;
        this.#deliveryOutsideRadius = entregueForaRaio;
        this.#motoristReason = motivoMotorista;
        this.#attachments = anexos;
        this.#finished = !finalizada
            ? null
            : new StepDetailDistributionFinishTrackingFinished(finalizada);
    }

    get finishedRealizedDate(): Date {
        return this.#finishedRealizedDate;
    }

    get finishedExpectedDate(): Date {
        return this.#finishedExpectedDate;
    }

    get entryCDDate(): Date {
        return this.#entryCDDate;
    }

    get totalRealizedTime(): number {
        return this.#totalRealizedTime;
    }

    get totalExpectedTime(): number {
        return this.#totalExpectedTime;
    }

    get timeRemaining(): number {
        return this.#timeRemaining;
    }

    get deliveryOutsideRadius(): boolean {
        return this.#deliveryOutsideRadius;
    }

    get motoristReason(): string {
        return this.#motoristReason;
    }

    get attachments(): string[] {
        return this.#attachments;
    }

    get finished(): StepDetailDistributionFinishTrackingFinished {
        return this.#finished;
    }
}
