export enum TypeOperationCommoditiesEnum {
    TRANSFERENCIA = 1,
    COMPRA_FIXA = 2
}

export enum TypeOperationAgroEnum {
    OVOS_CARRINHOS = 2,
    PINTOS_E_PERUZINHOS = 3,
    RACAO = 4,
    FRANGO = 5
}

export class TypeOperation {
    static readonly RACAO = 'Ração';
    static readonly FRANGO = 'Frango';
    static readonly PERUS = 'Perus';
    static readonly SUINOS = 'Suínos';
    static readonly PINTOS_E_PERUZINHOS = 'PintosEPeru';
    static readonly TRANSFERENCIA = 'Transferência';
    static readonly COMPRA_FIXA = 'Compra Fixa';
    static readonly ANIMAIS_ABATE = 'AnimaisAbate';
    static readonly OVOS_CARRINHOS = 'ovos';
    static readonly LEITAO = 'leitao';
}