<div
  *ngIf="opened"
  (scroll)="$event.stopImmediatePropagation();"
  [@modal]="options.animation || 'slideUp'"
  (@modal.done)="animationDone($event)"
>
  <div class="modal__backdrop"></div>

  <div
    class="modal__container"
    (click)="backdropClick($event)"
    [class.--noOverflow]="options?.noOverflow"
    [class.--v-top]="options?.verticalAlign === 'top'"
  >
    <div
      class="modal__container__content"
      (window:keydown.escape)="keyboardPress($event)"
      (click)="$event.stopPropagation();"
      (mousedown)="setClickInside(true)"
      (mouseup)="setClickInside(false)"
    >
      <ng-template #modalContainer></ng-template>
    </div>
  </div>
</div>
