import {
  Component,
  ViewChild,
  ViewContainerRef,
  ComponentFactoryResolver,
  ComponentFactory,
  Type,
  ComponentRef,
  OnDestroy, Injector, Inject,
  ChangeDetectorRef, ApplicationRef, Renderer2, ElementRef, AfterViewInit, HostListener
} from '@angular/core';
import {animate, animation, group, query, style, transition, trigger, useAnimation} from '@angular/animations';
import {MODAL_COMPONENT, MODAL_COMPONENT_INJECTOR, MODAL_REF} from '../modal-tokens';
import {ModalOptions} from '../modal-options';
import {ModalRef} from '../modal-ref.model';
import {Subscription} from 'rxjs';
import {IModal} from '../modal.interface';
import {ShouldModalClose} from '../should-modal-close';

const slideUpYAnimation = animation([
  style({transform: 'translateY({{ startOffset }})'}),
  animate('{{ time }}', style({transform: 'translateY({{ endOffset }})'}))
]);

const fadeAnimation = animation([
  style({opacity: '{{ start }}'}),
  animate('{{ time }}', style({opacity: '{{ end }}'}))
]);

const fadeInAnimation = animation([
  useAnimation(fadeAnimation, {params: {start: 0, end: 1}})
]);

const fadeOutAnimation = animation([
  useAnimation(fadeAnimation, {params: {start: 1, end: 0}})
]);


const timing = '200ms cubic-bezier(.27,.9,.57,1)';

@Component({
  templateUrl: 'modal-container.component.html',
  styleUrls: ['modal-container.component.scss'],
  animations: [
    trigger('modal', [
      transition('void => slideUp', [
        group([
          query('.modal__backdrop', [
            useAnimation(fadeInAnimation, {params: {time: timing}}),
          ]),
          query('.modal__container__content', [
            group([
              useAnimation(fadeInAnimation, {params: {time: timing}}),
              useAnimation(slideUpYAnimation, {params: {time: timing, startOffset: '50px', endOffset: '0px'}})
            ])
          ])
        ])
      ]),
      transition('slideUp => void', [
        group([
          query('.modal__backdrop', [
            useAnimation(fadeOutAnimation, {params: {time: timing}}),
          ]),
          query('.modal__container__content', [
            group([
              useAnimation(fadeOutAnimation, {params: {time: timing}}),
              useAnimation(slideUpYAnimation, {params: {time: timing, startOffset: '0px', endOffset: '50px'}})
            ])
          ])
        ])
      ]),
      transition('void => fade', [
        useAnimation(fadeInAnimation, {params: {time: timing}}),
      ]),
      transition('fade => void', [
        useAnimation(fadeOutAnimation, {params: {time: timing}}),
      ]),
    ]),
  ]
})
export class ModalContainerComponent implements OnDestroy, AfterViewInit {
  private static modalStack: ModalContainerComponent[] = [];

  private contentComponent: ComponentRef<IModal<any, any>>;
  private subscribe: { [s: string]: Subscription } = {};

  shouldModalClose = new ShouldModalClose();
  opened: boolean;
  options: ModalOptions = {};
  clickInside = false;

  @ViewChild('modalContainer', {read: ViewContainerRef, static: false}) private modalContainer: ViewContainerRef;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private changeDetector: ChangeDetectorRef,
    private appRef: ApplicationRef,
    private renderer2: Renderer2,
    private el: ElementRef<HTMLElement>,
    @Inject(MODAL_REF) private modalRef: ModalRef,
    @Inject(MODAL_COMPONENT) private component: Type<any>,
    @Inject(MODAL_COMPONENT_INJECTOR) private injector: Injector
  ) {

    this.subscribe.options = this.modalRef.options$.subscribe(options => {
      this.options = options;
    });
  }

  show(): void {
    this.opened = true;
    this.changeDetector.detectChanges();

    if (this.contentComponent) {
      return;
    }

    const injector = Injector.create({
      providers: [
        // { provide: PerfectScrollbarComponent, useValue: this.scrollbarComponent },
        // { provide: PerfectScrollbarDirective, useValue: this.scrollbarComponent.directiveRef },
        {provide: ShouldModalClose, useValue: this.shouldModalClose}
      ],
      parent: this.injector
    });

    const factory: ComponentFactory<any> = this.componentFactoryResolver.resolveComponentFactory(this.component);
    this.contentComponent = this.modalContainer.createComponent(factory, 0, injector);

    this.shouldModalClose.intercept(this, async () => {
      return this.contentInstance.shouldModalClose
        ? this.contentInstance.shouldModalClose()
        : true;
    });

    this.changeDetector.detectChanges();

    ModalContainerComponent.modalStack.unshift(this);
    // global?.history?.pushState(null, 'modal_opened' + Date.now());
  }

  get contentInstance(): any {
    return this.contentComponent.instance;
  }

  async hide(): Promise<any> {
    if (!this.opened) {
      return;
    }

    if (!(await this.shouldModalClose.should())) {
      return;
    }

    this.opened = false;
    this.changeDetector.detectChanges();
  }

  ngOnDestroy(): void {
    if (this.contentComponent) {
      this.contentComponent.destroy();
    }
  }

  ngAfterViewInit(): void {
  }

  backdropClick(e: MouseEvent): void {
    e.stopPropagation();
    if (this.options.backdropDismiss && !this.clickInside) {
      this.hide();
    }
    this.clickInside = false;
  }

  setClickInside(value): void {
    this.clickInside = value;
  }

  animationDone(event: any): void {
    if (event.phaseName === 'done' && event.toState === 'void') {
      this.close();
    }
  }

  @HostListener('window:popstate', ['$event'])
  keyboardPress(e: KeyboardEvent | PopStateEvent): void {
    e?.stopPropagation();
    if (this.options.keyboardDismiss && ModalContainerComponent.modalStack[0] === this) {
      this.hide();
    }
  }

  close(): void {
    this.modalRef.dismiss();
    this.modalRef._destroy();

    ModalContainerComponent.modalStack.splice(ModalContainerComponent.modalStack.indexOf(this), 1);
  }
}
