<section class="chat-link {{isMobile ? 'chat-link--mobile' : ''}}" (click)="handleChat()">
    <button *ngIf="floatingButton && loadNumber && plate" class="chat-link__button">
        Iniciar conversa
    </button>

    <ng-container *ngIf="!loadNumber && !isMobile">
        <svg width="26" height="26" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.5 0.5H4.5C3.43913 0.5 2.42172 0.921427 1.67157 1.67157C0.921427 2.42172 0.5 3.43913 0.5 4.5V9.5C0.5 10.5609 0.921427 11.5783 1.67157 12.3284C2.42172 13.0786 3.43913 13.5 4.5 13.5H5.5V17.5L10 13.5H14.5C15.5609 13.5 16.5783 13.0786 17.3284 12.3284C18.0786 11.5783 18.5 10.5609 18.5 9.5V4.5C18.5 3.43913 18.0786 2.42172 17.3284 1.67157C16.5783 0.921427 15.5609 0.5 14.5 0.5V0.5Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M11.5 16.5V18C11.5 18.3283 11.5647 18.6534 11.6903 18.9567C11.8159 19.26 12.0001 19.5356 12.2322 19.7678C12.4644 19.9999 12.74 20.1841 13.0433 20.3097C13.3466 20.4353 13.6717 20.5 14 20.5H16.5L19.5 23.5V20.5H21C21.3283 20.5 21.6534 20.4353 21.9567 20.3097C22.26 20.1841 22.5356 19.9999 22.7678 19.7678C22.9999 19.5356 23.1841 19.26 23.3097 18.9567C23.4353 18.6534 23.5 18.3283 23.5 18V15C23.5 14.337 23.2366 13.7011 22.7678 13.2322C22.2989 12.7634 21.663 12.5 21 12.5H20.5" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

        <app-chat-counter-unread [versionNumericBadge]="true"></app-chat-counter-unread>
    </ng-container>
    
    <ng-container *ngIf="loadNumber && !isMobile">
        <svg width="26" height="26" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.5 0.5H4.5C3.43913 0.5 2.42172 0.921427 1.67157 1.67157C0.921427 2.42172 0.5 3.43913 0.5 4.5V9.5C0.5 10.5609 0.921427 11.5783 1.67157 12.3284C2.42172 13.0786 3.43913 13.5 4.5 13.5H5.5V17.5L10 13.5H14.5C15.5609 13.5 16.5783 13.0786 17.3284 12.3284C18.0786 11.5783 18.5 10.5609 18.5 9.5V4.5C18.5 3.43913 18.0786 2.42172 17.3284 1.67157C16.5783 0.921427 15.5609 0.5 14.5 0.5V0.5Z" stroke="#5B5C60" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M11.5 16.5V18C11.5 18.3283 11.5647 18.6534 11.6903 18.9567C11.8159 19.26 12.0001 19.5356 12.2322 19.7678C12.4644 19.9999 12.74 20.1841 13.0433 20.3097C13.3466 20.4353 13.6717 20.5 14 20.5H16.5L19.5 23.5V20.5H21C21.3283 20.5 21.6534 20.4353 21.9567 20.3097C22.26 20.1841 22.5356 19.9999 22.7678 19.7678C22.9999 19.5356 23.1841 19.26 23.3097 18.9567C23.4353 18.6534 23.5 18.3283 23.5 18V15C23.5 14.337 23.2366 13.7011 22.7678 13.2322C22.2989 12.7634 21.663 12.5 21 12.5H20.5" stroke="#5B5C60" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

        <app-chat-counter-unread *ngIf="totalUnreadMessage > 0" [versionWithoutNumericBadge]="true"></app-chat-counter-unread>
    </ng-container>

    <ng-container *ngIf="isMobile">
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 0.5H5C3.93913 0.5 2.92172 0.921427 2.17157 1.67157C1.42143 2.42172 1 3.43913 1 4.5V9.5C1 10.5609 1.42143 11.5783 2.17157 12.3284C2.92172 13.0786 3.93913 13.5 5 13.5H6V17.5L10.5 13.5H15C16.0609 13.5 17.0783 13.0786 17.8284 12.3284C18.5786 11.5783 19 10.5609 19 9.5V4.5C19 3.43913 18.5786 2.42172 17.8284 1.67157C17.0783 0.921427 16.0609 0.5 15 0.5V0.5Z" stroke="#5B5C60" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12 16.5V18C12 18.3283 12.0647 18.6534 12.1903 18.9567C12.3159 19.26 12.5001 19.5356 12.7322 19.7678C12.9644 19.9999 13.24 20.1841 13.5433 20.3097C13.8466 20.4353 14.1717 20.5 14.5 20.5H17L20 23.5V20.5H21.5C21.8283 20.5 22.1534 20.4353 22.4567 20.3097C22.76 20.1841 23.0356 19.9999 23.2678 19.7678C23.4999 19.5356 23.6841 19.26 23.8097 18.9567C23.9353 18.6534 24 18.3283 24 18V15C24 14.337 23.7366 13.7011 23.2678 13.2322C22.7989 12.7634 22.163 12.5 21.5 12.5H21" stroke="#5B5C60" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

        <span>Chat</span>
    </ng-container>
</section>
