import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChatBusinessService } from './chat-business.service';
import { ChatLoadConversationHistoric } from './chat-load-conversation-historic.model';
import { ChatLoad } from './chat-load.model';
import { ChatMessageTypeFile } from './chat-message-type-file.model';
import { ChatMessageTypeMessage } from './chat-message-type-message.model';
import { ChatMessage } from './chat-message.model';
import { ChatRecipients } from './chat-recipients.model';
import { PerfilPermissaoService } from 'src/app/core/services/perfil-permissao.service';
import { MessageService } from 'src/app/core/services';
import { BaseService } from 'src/app/core/services/base.service';

@Injectable({
    providedIn: 'root'
})
export class ChatHttpService extends BaseService<any> {

    constructor(
        _http: HttpClient,
        perfilPermissaoService: PerfilPermissaoService,
        protected messageService: MessageService,
        public chatBusinessService: ChatBusinessService
    ) {
        super(_http, 'chats', messageService, perfilPermissaoService);
    }

    /**
     * Busca o histórico de mensagens
     *
     * @param logistic strig
     * @param loadNumber string
     * @param placa string
     * @param origem string
     *
     * @returns ChatMessage
     */
    getMessages(
        logistic: string,
        loadNumber: string,
        placa: string,
        origem: string,
        userName: string,
        showLoading: boolean = true
    ): Observable<ChatMessage> {
        let params = null;

        if (!showLoading) {
            params = {
                loading: false
            };
        }

        return this.http
            .get(`${this.url}/detalhe/${logistic}/numero-viagem/${loadNumber}/placa/${placa}/origem/${origem}/${userName}`, { params })
            .pipe(map((response: any) => {
                if (!response) {
                    console.error(`[Chat.Http.Service.getMessages] Não houve resposta com as mensagens da viagem: ${loadNumber}/${placa}`);
                    return null;
                }

                try {
                    return new ChatMessage(response);
                } catch (e: any) {
                    console.error(
                        '[Chat.Http.Service.getMessages] Erro ao compilar os dados do endpoint da listagem de mensagens',
                        e.message
                    );
                    console.error('[Chat.Http.Service.getMessages] Retorno', response);
                    return null;
                }
            }));
    }

    /**
     * Envia uma mensagem
     *
     * @param talk ChatMessageTypeMessage
     * @returns any
     */
    sendMessage(talk: ChatMessageTypeMessage, files: any = null): Observable<any> {
        return this.http
            .post(`${this.url}/enviar`, { mensagem: talk.serialize(), arquivos: files })
            .pipe(map((response: any) => {
                if (!response) {
                    console.error(`[Chat.Http.Service.sendMessage] Falha ao enviar uma mensagem`, talk.serialize());
                    return null;
                }

                return response;
            }));
    }

    /**
     * Envia multíplas mensagens
     */
    sendMultipleMessage(
      talk: ChatMessageTypeMessage,
      recipients: ChatRecipients[],
      filesToShare: ChatMessageTypeFile[] = [],
      filesToUpload: Array<Record<string, string>>,
    ): Observable<any> {
        return this.http
            .post(
                `${this.url}/enviar-multiplas-mensagens`,
                {
                    mensagem: talk.serialize(),
                    arquivos: [...filesToShare.map(_ => _.serialize()), ...filesToUpload],
                    destinatarios: recipients.map(_ => _.serialize())
                }
            )
            .pipe(map((response: any) => {
                if (!response) {
                    console.error(`[Chat.Http.Service.sendMessage] Falha ao enviar uma mensagem`, talk.serialize());
                    return null;
                }

                return response;
            }));
    }

    /**
     * Busca o resumo de conversações do usuário logado
     *
     * @param login string
     *
     * @returns ChatLoadConversationHistoric[]
     */
    getResume(login: string): Observable<ChatLoadConversationHistoric[]> {
        const subscriptions = this.chatBusinessService.getSubscriptions();

        const params = {
            login,
            viagens: !subscriptions ? null : subscriptions
        };

        return this.http
            .post(`${this.url}/resumo`, params)
            .pipe(map((response: any) => {
                if (!response) {
                    return null;
                }

                try {
                    return ChatLoadConversationHistoric.from(response);
                } catch (e: any) {
                    console.error('[Chat.Http.Service.getResume] Erro ao compilar os dados do endpoint de resumo', e.message);
                    console.error('[Chat.Http.Service.getResume] Retorno', response);
                    return null;
                }
            }));
    }

    /**
     * Faz a busca por placa ou número da viagem
     *
     * @param text string
     * @returns ChatLoad[]
     */
    getLoadsInRoute(logistic: string, text: string): Observable<ChatLoad[]> {
        return this.http
            .get(`${this.url}/nova/viagem/${logistic}/${text}`)
            .pipe(map((response: any) => {
                if (!response) {
                    console.error(`[Chat.Http.Service.getLoadsInRoute] Não houve resposta com o texto pesquisado: ${text}`);
                    return null;
                }

                try {
                    return ChatLoad.from(response);
                } catch (e: any) {
                    console.error('[Chat.Http.Service.getLoadsInRoute] Erro ao compilar os dados do endpoint de resumo', e.message);
                    console.error('[Chat.Http.Service.getLoadsInRoute] Retorno', response);
                    return null;
                }
            }));
    }

    /**
     * Método para buscar todas as novas mensagens não lidas pelo motorista
     *
     * @param logistic string
     * @param loadNumber string
     * @param plate string
     *
     * @returns ChatMessageTypeMessage[]
     */
    getNewMessagesDriver(loadNumber: string, plate: string): Observable<ChatMessageTypeMessage[]> {
        let lastMessageReaded = null;

        try {
            lastMessageReaded = localStorage.getItem(`chats-lastmessagereaded-driver-${loadNumber}-${plate}`);
            lastMessageReaded = new Date(lastMessageReaded);
        } catch { }

        const params = {
            origem: 'motorista',
            numeroViagem: loadNumber,
            placa: plate,
            ultimaDataMensagemLida: lastMessageReaded ? lastMessageReaded.toISOString() : null
        };

        return this.http
            .post(`${this.url}/novas-mensagens`, params)
            .pipe(map((response: any) => {
                if (!response) {
                    console.error('[Chat.Http.Service.getNewMessagesDriver] Não foi possível trazer novas mensagens do motorista');
                    return null;
                }

                if (response.length === 0) {
                    return null;
                }

                const messages = ChatMessageTypeMessage.from(response);

                const firstMessage: ChatMessageTypeMessage = messages[0];

                if (lastMessageReaded && firstMessage.receivedAt.toISOString() === lastMessageReaded.toISOString()) {
                    return null;
                }

                try {
                    localStorage.setItem(
                        `chats-lastmessagereaded-driver-${loadNumber}-${plate}`,
                        firstMessage.receivedAt.toISOString()
                    );
                } catch { }

                return messages;
            }));
    }

    /**
     * Método para buscar novas mensagens baseado nas últimas interações do usuário logado BRF
     *
     * @param logistic string
     * @param userName string
     *
     * @returns ChatMessageTypeMessage[]
     */
    getNewMessagesMonitor(logistic: string, userName: string): Observable<ChatMessage> {
        const lastMessageReaded = this.chatBusinessService.getLastMessageReaded(logistic);

        const subscriptions = this.chatBusinessService.getSubscriptions();

        const params = {
            logistica: logistic,
            origem: 'monitor',
            usuario: userName,
            ultimaDataMensagemLida: lastMessageReaded
                ? lastMessageReaded.toISOString()
                : null,
            loading: 'false',
            viagens: !subscriptions ? [] : subscriptions
        };

        return this.http
            .post(`${this.url}/novas-mensagens`, params)
            .pipe(map((response: any) => {
                if (!response) {
                    console.error('[Chat.Http.Service.getNewMessagesMonitor] Não foi possível trazer novas mensagens do monitor');
                    return null;
                }

                if (response.length === 0) {
                    return null;
                }

                let firstMessage: any = response[0];

                if (firstMessage.tipo === 'arquivo') {
                    firstMessage = new ChatMessageTypeFile(firstMessage);
                } else if (firstMessage.tipo === 'mensagem') {
                    firstMessage = new ChatMessageTypeMessage(firstMessage);
                } else {
                    console.error('[Chat.Http.Service.getNewMessagesMonitor] Não foi possível definir a primeira mensagem', firstMessage);
                    return null;
                }

                this.chatBusinessService.setMessageUnreadIds(response, logistic);

                if (lastMessageReaded && firstMessage.receivedAt.toISOString() === lastMessageReaded.toISOString()) {
                    return null;
                }

                this.chatBusinessService.setLastMessageReaded(logistic, firstMessage.receivedAt);

                return new ChatMessage({ statusViagem: firstMessage.status, mensagens: response });
            }));
    }

    /**
     * Método para buscar as viagens e placas que o usuário interagiu
     *
     * @param logistic string
     * @param userName string
     *
     * @returns any
     */
    getSubscriptions(logistic: string, userName: string): Observable<any> {
        return this.http
            .get(`${this.url}/inscricoes/${logistic}/usuario/${userName}`)
            .pipe(map((response: any) => {
                if (!response) {
                    console.error('[Chat.Http.Service.getSubscriptions] Não foi possível buscar as inscrições');
                    return null;
                }

                return response;
            }));
    }
}
