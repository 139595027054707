<form [formGroup]="filterForm" class="modal-filter-subzones">
    <div class="modal-filter-subzones__row">
        <div class="modal-filter-subzones__row__item">
            <span class="modal-filter-subzones__row__item__label"> Descrição </span>
            <app-input [autofocus]="true" formControlName="descricao" placeholder="Digite a descrição da unidade">
            </app-input>
        </div>

        <div class="modal-filter-subzones__row__item">
            <span class="modal-filter-subzones__row__item__label"> Código </span>
            <app-input formControlName="codigos" placeholder="Digite o código da unidade" separator=";"></app-input>
        </div>
    </div>

    <div class="modal-filter-subzones__row">
        <div class="modal-filter-subzones__row__item">
            <span class="modal-filter-subzones__row__item__label"> Ativo </span>

            <app-select id="status" formControlName="status" placeholder="Selecione o status ativo"
                [multipleSelect]="false" [options]="optionsYesOrNot">
            </app-select>
        </div>

        <div class=" modal-filter-subzones__row__item">
            <span class="modal-filter-subzones__row__item__label"> Tipo de Unidade </span>
            <app-select id="tipoUnidades" formControlName="tipoUnidades" placeholder="Selecione o tipo de unidade"
                [options]="typesSubzones"></app-select>
        </div>
    </div>

    <div class="modal-filter-subzones__row">
        <div class="modal-filter-subzones__row__item">
            <span class="modal-filter-subzones__row__item__label"> Estado </span>

            <app-select id="estados" formControlName="estados" placeholder="Selecione o estado" [options]="states"
                (onSelected)="selectedState($event)" selectedText="estados selecionados">
            </app-select>
        </div>

        <div class="modal-filter-subzones__row__item">
            <span class="modal-filter-subzones__row__item__label">
                {{isAnotherState ? 'Estado' : 'Cidade'}}
            </span>

            <app-select *ngIf="!isAnotherState" id="cidades" formControlName="cidades" placeholder="Selecione a cidade"
                [options]="cities" [invalid]="!validForm && filterForm.get('cidades')?.value?.length == 0" selectedText="cidades selecionadas">
            </app-select>

            <app-input *ngIf="isAnotherState" formControlName="outroEstado" placeholder="Digite o nome do estado">
            </app-input>
        </div>
    </div>

    <div class="modal-filter-subzones__row"
        *ngIf="isAnotherState">
        <div class="modal-filter-subzones__row__item">
            <span class="modal-filter-subzones__row__item__label"> Cidade </span>
            <app-input formControlName="outraCidade" placeholder="Digite o nome da cidade" [invalid]="!validForm">
            </app-input>
        </div>
    </div>

    <div class="modal-filter-subzones__actions">
        <app-link *ngIf="!!filterCount || isAnotherState" (handleClick)="onClearFilter()" [isBolder]="true">
            Limpar todos os filtros
        </app-link>
        <app-button [isDisabled]="filterCount == 0" (clicked)="savedFilter ? onCancel() : onCreateFilter()"
            [text]="savedFilter ? 'Cancelar' : 'Criar filtro'" [isOutlined]="true" [isMarginRight]="true"
            [isMarginLeft]="true"></app-button>
        <app-button *ngIf="(savedFilters$ | async)?.length" [isDisabled]="filterCount == 0"
            [text]="savedFilter ? 'Salvar' : 'Aplicar filtro'" (clicked)="savedFilter ? onSaveFilter() : onSubmit()">
        </app-button>
    </div>
</form>