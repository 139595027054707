import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/core/services/auth.service';
import { MenuService } from 'src/app/core/services/menu.service';
import { PerfilPermissaoService } from 'src/app/core/services/perfil-permissao.service';
import { MessageService } from 'src/app/core/services';
import { SessionStorageService } from 'src/app/core/services/session-storage.service';
import { DiariaService } from 'src/app/core/services/diaria.service';
import { BreadCrumbItem } from 'src/app/core/models/bread-crumb-item.model';
import { DiariaStatus } from 'src/app/enums/diarias/diaria-status.enum';
import { BaseComponent } from 'src/app/components/shared/base.component';
import { DiariaDetail } from 'src/app/models/diarias/diaria-detail.interface';
import { DiariaApprovalRequest } from 'src/app/models/diarias/diaria-approval.interface';
import { createManualApprovalFormGroup } from 'src/app/pages/daily/utils/create-manual-approval-form-group';

interface ApprovalFormType {
  isVisible: boolean;
  canEdit: boolean;
  isEditing: boolean;
  editRequireNewApproval: boolean;
  isWaitingApproval: boolean;
  formGroup: FormGroup | undefined;
}

@Component({
  selector: 'app-detalhes',
  templateUrl: './detalhes.component.html',
  styleUrls: ['./detalhes.component.scss']
})
export class DetalhesComponent extends BaseComponent implements OnInit {

  private readonly statusesAllowedToEditApproval: Array<string> = [DiariaStatus.REPROVED];
  private readonly statusesAllowedToEditWithApprovalRequired: Array<string> = [DiariaStatus.REPROVED];
  private readonly statusesWithApprovalForm: Array<string> = [
    DiariaStatus.PENDING,
    DiariaStatus.WAITING_APPROVAL,
    DiariaStatus.WITHIN_DELIVERY_PLANNING,
  ];

  canEdit = false;
  diariaId: string;
  diariaDetails: DiariaDetail | undefined = undefined;
  private backToUrl = '/diarias';
  @ViewChild('approvalFormSuccessDialog') approvalFormSuccessDialogTemplate: TemplateRef<any>;
  @ViewChild('timeExceededAnalysisDialog') timeExceededAnalysisDialogTemplate: TemplateRef<any>;

  approvalForm: ApprovalFormType = {
    isVisible: false,
    canEdit: false,
    isEditing: false,
    editRequireNewApproval: false,
    isWaitingApproval: false,
    formGroup: undefined,
  };

  constructor(
    protected authService: AuthService,
    protected menuService: MenuService,
    protected sessionStorageService: SessionStorageService,
    protected perfilPermissaoService: PerfilPermissaoService,
    protected router: Router,
    private location: Location,
    private routerAct: ActivatedRoute,
    private messageService: MessageService,
    private matDialog: MatDialog,
    private diariaService: DiariaService,
  ) {
    super(authService, router, menuService, sessionStorageService, perfilPermissaoService, 'diarias', '#F9FAFB');

    const userRole = this.perfilPermissaoService.getCurrentLogisticaRole().codigo;
    const rolesWithEditPermission = ['GestaoDiariasEdicao', 'MonitorDistribuicao', 'AdminDistribuicao', 'GestorDistribuicao'];
    this.canEdit = rolesWithEditPermission.includes(userRole);
  }

  ngOnInit(): void {
    this.checkAuth();
    this.saveBackToUrl();
    this.routerAct.params.subscribe(params => {
      this.diariaId = params.id;
      this.loadDiariaDetails();
    });
  }

  private saveBackToUrl(): void {
    const locationState: { backTo?: string } = this.location.getState();
    if (locationState?.backTo) {
      this.backToUrl = locationState.backTo;
    }
  }

  navigateBack(): void {
    void this.router.navigateByUrl(this.backToUrl);
  }

  toggleIsEditingApproval(): void {
    if (this.canEdit) {
      const isEditing = !this.approvalForm.isEditing;
      this.approvalForm = {
        ...this.approvalForm,
        isEditing,
        isVisible: isEditing,
        formGroup: isEditing ? this.buildApprovalFormGroup(this.diariaDetails) : undefined,
      };
    }
  }

  private loadDiariaDetails(): void {
    this.diariaService.getDiariaById(this.diariaId).subscribe((diaria) => {
      this.diariaDetails = diaria;
      this.setBreadcrumbs(diaria);
      this.initializeApprovalForm(diaria);
    });
  }

  private setBreadcrumbs(diaria: DiariaDetail): void {
    this.menuService.setBreadCrumb([
      new BreadCrumbItem('MENU.HOME'),
      new BreadCrumbItem('Diárias', '/diarias'),
      new BreadCrumbItem(`Placa ${diaria.placa} - Viagem ${diaria.numeroViagem}`, `/diarias/${this.diariaId}`),
    ]);
  }

  private initializeApprovalForm(details: DiariaDetail): void {
    const isVisible = this.statusesWithApprovalForm.includes(details.status);
    const canEdit = this.statusesAllowedToEditApproval.includes(details.status);
    const editRequireNewApproval = this.statusesAllowedToEditWithApprovalRequired.includes(details.status);
    const isWaitingApproval = details.status === DiariaStatus.WAITING_APPROVAL;
    const formGroup = isVisible ? this.buildApprovalFormGroup(isWaitingApproval ? details : undefined) : undefined;

    if (isWaitingApproval || !this.canEdit) {
      formGroup.disable();
    }
    this.approvalForm = { isVisible, canEdit, editRequireNewApproval, isEditing: false, isWaitingApproval, formGroup };
  }

  private buildApprovalFormGroup(initialValues?: DiariaDetail): FormGroup {
    return createManualApprovalFormGroup({ initialValues });
  }

  openTimeExceededAnalysisDialog(): void {
    if (this.canEdit) {
      this.matDialog.open(this.timeExceededAnalysisDialogTemplate, { width: '500px' });
    }
  }

  async submitTimeExceededAnalysisApproval({ isApproved }: { isApproved: boolean }): Promise<void> {
    try {
      const params = { isApproved, id: this.diariaId, idUsuarioLogado: this.user.preferred_username };
      const response = await this.diariaService.postTimeExceededApprovalRequest(params);
      if (!response.success) {
        return this.messageService.error(`Ocorreu um erro ao enviar a ${isApproved ? 'aprovação' : 'reprovação'}`);
      }
      this.loadDiariaDetails();
    } catch (err) {
    }
  }

  async submitApprovalRequest(): Promise<void> {
    try {
      const approvalRequest = this.buildApprovalRequest();
      const responsibleAreaOptions = await this.diariaService.getAllResponsibleAreas();
      this.approvalForm.formGroup.disable({ emitEvent: false });
      const response = await this.diariaService.postApprovalRequest(approvalRequest).toPromise();
      if (!response.success) {
        return this.messageService.error('Ocorreu um erro ao enviar a aprovação');
      }
      const responsibleArea = responsibleAreaOptions.find(({ key }) => key === approvalRequest.areaResponsavel);
      this.openApprovalRequestSuccessDialog(responsibleArea?.descricao || approvalRequest.areaResponsavel);
    } finally {
      this.approvalForm.formGroup.enable({ emitEvent: false });
    }
  }

  private openApprovalRequestSuccessDialog(responsibleArea: string): void {
    const config = { width: '500px', data: { responsibleArea } };
    const dialogRef = this.matDialog.open(this.approvalFormSuccessDialogTemplate, config);
    dialogRef.afterClosed().subscribe(() => {
      if (!this.approvalForm.isEditing) {
        return this.navigateBack();
      }
      this.loadDiariaDetails();
    });
  }

  private buildApprovalRequest(): DiariaApprovalRequest {
    return {
      idDiaria: [this.diariaId],
      areaResponsavel: this.approvalForm.formGroup.get('areaResponsavel').value,
      motivo: this.approvalForm.formGroup.get('motivo').value,
      subMotivo: this.approvalForm.formGroup.get('subMotivo').value,
      clienteResponsavel: this.approvalForm.formGroup.get('clienteResponsavel').value,
      justificativa: this.approvalForm.formGroup.get('justificativa').value,
      idUsuarioLogado: this.user.preferred_username,
    };
  }
}
