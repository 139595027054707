import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IndicatorModule} from '../indicator/indicator.module';
import {TabComponent} from './tab/tab.component';
import {TabsComponent} from './tabs.component';

@NgModule({
  declarations: [
    TabsComponent,
    TabComponent
  ],
  exports: [
    TabsComponent,
    TabComponent,
  ],
  imports: [
    CommonModule,
    IndicatorModule,
  ]
})
export class TabsModule { }
