import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MyFilter } from '../models/my-filter.model';
import { DiariaService } from './diaria.service';
import { PerfilPermissaoService } from './perfil-permissao.service';

@Injectable({
    providedIn: 'root',
})
export class GDFilterService {

    private static dateFilter: { start: '' | Date, end: '' | Date } = { start: '', end: '' };

    private _customFilter: BehaviorSubject<object | null> = new BehaviorSubject<object | null>(
        null
    );

    private myFiltersSubject: BehaviorSubject<MyFilter[]> = new BehaviorSubject<MyFilter[]>(null);

    private _myFilterSelected: BehaviorSubject<MyFilter | null> =
        new BehaviorSubject<MyFilter | null>(null);

    get dateFilter(): { start: '' | Date, end: '' | Date } {
        return GDFilterService.dateFilter;
    }

    set dateFilter(value: { start: '' | Date, end: '' | Date }) {
        GDFilterService.dateFilter = value;
    }

    get customFilter(): object | null {
        return this._customFilter.getValue();
    }

    set customFilter(data: object | null) {
        this._customFilter.next(data);
    }

    get customFilterObservable(): Observable<object | null> {
        return this._customFilter;
    }

    get myFilters(): MyFilter[] | null {
        return this.myFiltersSubject.getValue();
    }

    set myFilters(data: MyFilter[]) {
        this.myFiltersSubject.next(data);
    }

    get myFiltersObservable(): Observable<MyFilter[]> {
        return this.myFiltersSubject;
    }

    get myFilterSelected(): MyFilter {
        return this._myFilterSelected.getValue();
    }

    set myFilterSelected(data: MyFilter) {
        this._myFilterSelected.next(data);
    }

    get myFilterSelectedObservable(): Observable<MyFilter | null> {
        return this._myFilterSelected;
    }


    constructor(private diariaService: DiariaService, private perfilService: PerfilPermissaoService) { }


    /**
     * Cria o filtro do usuário
     *
     * @param nameFilter Nome do Filtro
     * @param filter Objeto do filtro
     * @returns Promise<void> resultado de requisicao Http da criacao
     */
    async createFilter(nameFilter: string, filter: string, idUsuario: string): Promise<void> {
        const request: Promise<void> = this.diariaService.createFilter({
            idUsuario,
            definicaoFiltro: filter,
            nomeFiltro: nameFilter,
            filtroPadrao: true,
            tela: `governanca-diaria-${this.perfilService.getCurrentLogisticaRole()?.logistica}`,
        });

        return await request;
    }

    async updateFilter(id: string, nameFilter: string, filter: string, idUsuario: string): Promise<void> {
        const request: Promise<void> = this.diariaService.updateFilter({
            idUsuario,
            id,
            definicaoFiltro: filter,
            nomeFiltro: nameFilter,
            filtroPadrao: true,
            tela: `governanca-diaria-${this.perfilService.getCurrentLogisticaRole()?.logistica}`,
        });

        return await request;
    }

    async deleteFilter(idFiltro: string, idUsuario: string): Promise<any> {
        const request: Promise<void> = this.diariaService.deleteFilter(
            idFiltro, idUsuario
        );


        return await request;
    }
}
