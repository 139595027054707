import { Anomaly } from 'src/app/models/anomaly.model';
import { StepDetailDistributionFinishTracking } from './tracking.model';

export class StepDetailDistributionFinish {
    #anomalies: Anomaly[];
    #showFinishLoadButton: boolean;
    #tracking: StepDetailDistributionFinishTracking;

    constructor({ anomalias, tracking, mostrarBotaoFinalizarViagem }) {
        this.#anomalies = Anomaly.from(anomalias);
        this.#showFinishLoadButton = mostrarBotaoFinalizarViagem;
        this.#tracking = new StepDetailDistributionFinishTracking(tracking);
    }

    get anomalies(): Anomaly[] {
        return this.#anomalies;
    }

    get showFinishLoadButton(): boolean {
        return this.#showFinishLoadButton;
    }

    get tracking(): StepDetailDistributionFinishTracking {
        return this.#tracking;
    }
}
