import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { TOption } from 'src/app/components/select/select.component';
import { CityFilter } from 'src/app/core/models/filters/subzone-filter';
import { TypesSubzoneOption } from 'src/app/core/models/subzone.model';
import { MessageService } from 'src/app/core/services';
import { FilterJsonService } from 'src/app/core/services/filter-json.service';
import { UserFilterScreenMonitoring } from 'src/app/models/user-filter/screen/monitoring.model';
import { UserFilter } from 'src/app/models/user-filter/user-filter.model';
import { ModalEnum, ModalService, TModal } from '../../../modal.service';
import { MonitoringFilter } from '../monitoring-filter';
import { ModalWindowMonitoringFilterService, TCurrentFilter } from '../monitoring-filter.service';

@Component({
  selector: 'app-modal-window-filter-subozones-agro',
  templateUrl: './modal-filter-subzones-agro.component.html',
  styleUrls: ['./modal-filter-subzones-agro.component.scss']
})
export class ModalFilterSubzonesAgroComponent extends MonitoringFilter implements OnInit {

  @Input() public modalId: string;

  @Input() public myFiltersModalId: string;

  @Input() public savedFilterId: string;

  @Input() public isFirstFilter = false;

  @Output() filterCountChange = new EventEmitter<number>();

  public filterForm: FormGroup = null;

  public uuidModalMonitoringSaveFilter: string;

  public currentFilter: TCurrentFilter;

  public savedFilter: UserFilter<UserFilterScreenMonitoring>;

  public savedFilters$ = this.modalWindowMonitoringFilterService.savedFilters$;

  public filterCount = 0;

  public optionsYesOrNot: TOption[] = [
    { key: 'sim', value: 'Sim', display: 'Sim', checked: true },
    { key: 'não', value: 'Não', display: 'Não', checked: false }
  ];

  public typesSubzones: TOption[] = [];

  public cities: TOption[] = [];

  public states: TOption[] = [];

  public isAnotherState = false;

  public validForm = true;

  private statesMap = new Map();

  constructor(
    private formBuilder: FormBuilder,
    private filterServiceJson: FilterJsonService,
    private modalService: ModalService,
    private cdref: ChangeDetectorRef,
    private messageService: MessageService,
    modalWindowMonitoringFilterService: ModalWindowMonitoringFilterService,
  ) {
    super(modalWindowMonitoringFilterService);
  }

  public ngOnInit(): void {
    const filter = this.getFilter(this.savedFilterId);

    this.typesSubzones = TypesSubzoneOption.getTypes();
    this.getStates(filter?.states || null, filter?.cities || null).then(_ => {
      if (filter?.states) {
        this.getCities(filter?.states);
      }
    });
    filter?.unitTypes?.map(typeIndex => this.typesSubzones[typeIndex - 1].checked = true);
    this.isAnotherState = !!filter?.anotherCity;

    this.filterForm = this.formBuilder.group({
      descricao: new FormControl(filter?.description || ''),
      codigos: new FormControl(filter?.unitCode || ''),
      estados: new FormControl(filter?.states || []),
      cidades: new FormControl(filter?.cities || []),
      outroEstado: new FormControl(filter?.anotherState || ''),
      outraCidade: new FormControl(filter?.anotherCity || ''),
      status: new FormControl(filter?.status != null ? [{
        key: filter?.status ? 'sim' : 'não',
        value: filter?.status ? 'Sim' : 'Não',
        display: filter?.status ? 'Sim' : 'Não',
        checked: true
      }] : []),
      tipoUnidades: new FormControl(this.typesSubzones.filter(type => type.checked) || []),
    });

    const count = Object.values(this.filterForm.getRawValue()).
      filter((value: any) => {
        const isArray = Array.isArray(value);
        if (!isArray && !!value) {
          return true;
        }
        if (isArray && value.filter(item => !!item).length) {
          return true;
        }
      }).length;
    this.filterCount = count;
    this.filterCountChange.emit(this.filterCount);
    this.filterForm.valueChanges.subscribe(values => {
      if (values) {
        const count = Object.keys(this.filterForm.value)
          .filter(key => {
            const isArray = Array.isArray(this.filterForm.value[key]);
            if (!isArray && !!this.filterForm.value[key]) {
              return true;
            }
            if (isArray && this.filterForm.value[key].filter(item => !!item).length) {
              return true;
            }
          }).length;
        this.filterCount = count;
        this.filterCountChange.emit(count);
        this.cdref.detectChanges();
      }
    });
    this.cdref.detectChanges();
  }

  public selectedState = (event: any) => {
    if (event.length == 1 && event[0].key == 'unknown') {
      this.isAnotherState = true;
      this.filterForm.get('cidades').setValue([]);
      this.filterForm.get('estados').setValue([]);
      return;
    }
    this.filterForm.get('outroEstado').setValue('');
    this.filterForm.get('outraCidade').setValue('');
    this.isAnotherState = false;
    this.getCities(event)
  };

  public onCancel = () => this.modalService.unsetModal(this.modalId);

  public onSubmit(): void {
    if (!this.isValidForm()) {
      this.messageService.error('Campo cidade deve ser selecionado ou preenchido');
      return;
    }
    const currentFilter: TCurrentFilter = {
      mode: 'filtered',
      filter: this.getFakeUserFilter('monitoramento-agro-subzones', this.getParameters()),
    };

    this.modalWindowMonitoringFilterService.setCurrentFilter(currentFilter);
    this.modalService.unsetModal(this.modalId);
  }

  public onSaveFilter() {
    if (!this.isValidForm()) {
      this.messageService.error('Campo cidade deve ser selecionado ou preenchido');
      return;
    }
    this.savedFilter.parameters = this.getParameters(false);
    this.savedFilter.default = true;

    const currentFilter: TCurrentFilter = {
      mode: 'saved',
      filter: this.savedFilter
    };

    const defaultSavedFilter = this.modalWindowMonitoringFilterService.getDefaultSavedFilter();

    if (defaultSavedFilter && defaultSavedFilter.id !== this.savedFilter.id) {
      defaultSavedFilter.default = false;
      this.modalWindowMonitoringFilterService.updateSavedFilter(defaultSavedFilter);
    }

    this.modalWindowMonitoringFilterService.updateSavedFilter(this.savedFilter, true, 'agro-subzones');
    this.modalWindowMonitoringFilterService.setCurrentFilter(currentFilter, 'agro-subzones');

    this.modalService.unsetModal(this.modalId);
    this.modalService.unsetModal(this.myFiltersModalId);
  }

  isValidForm() {
    this.validForm = true;
    if (this.isAnotherState &&
      this.filterForm.get('outroEstado').value.trim('') != '' &&
      this.filterForm.get('outraCidade').value.trim('') == '') {
      this.validForm = false;
      return this.validForm;
    }
    if (this.isAnotherState &&
      this.filterForm.get('outroEstado').value.trim('') != '' &&
      this.filterForm.get('outraCidade').value.trim('') != '') {
      this.validForm = true;
      return this.validForm;
    }
    if (this.filterForm.get('estados').value?.length > 0 &&
      this.filterForm.get('cidades').value?.length == 0) {
      this.validForm = false;
      return this.validForm;
    }
    return this.validForm;
  }

  public onCreateFilter() {
    if (!this.isValidForm()) {
      this.messageService.error('Campo cidade deve ser selecionado ou preenchido');
      return;
    }
    const modal: TModal = {
      name: ModalEnum.WINDOW_MONITORING_FILTER_SAVE,
      data: {
        uuidModalMonitoringFilter: this.modalId,
        filtersResult: this.getFakeUserFilter(
          'monitoramento-agro-subzones',
          this.getParameters()
        ).parameters.getSubzoneFilterAgro(),
        logistic: 'agro-subzones'
      }
    };
    this.uuidModalMonitoringSaveFilter = this.modalService.setModal(modal);
  }

  public onClearFilter(): void {
    this.isAnotherState = false;
    this.filterForm.reset();
    this.cdref.detectChanges();
  }

  private async getStates(filterStates: TOption[], filterCities: TOption[]) {
    var result = await this.filterServiceJson.getEstatosCidades().toPromise();
    result.map(item => this.statesMap.set(`${item.nome}/${item.sigla}`, item.cidades.map(city => {
      return {
        key: city,
        value: city,
        display: city,
        state: item.nome,
        uf: item.sigla,
        checked: !!(filterCities?.filter(filter => filter.value == city).length || false)
      }
    })));
    this.statesMap.forEach((value: string[], key: string) => {
      this.states.push({
        key: key.split('/')[1],
        value: key.split('/')[0],
        display: key.split('/')[0],
        checked: !!(filterStates?.filter(option => option.key == key.split('/')[1]).length || false)
      })
    });
    if (filterStates?.length == 1 && filterStates[0]?.key == 'unknown') {
      this.states.unshift(filterStates[0]);
      return;
    }
    this.states.unshift({ key: 'unknown', value: 'Outro estado', display: 'Outro estado', checked: false })
  }

  private async getCities(statesFilter: TOption[]) {
    if (statesFilter.length == 1 && statesFilter[0].key == 'unknown') return;
    this.cities = [];
    if (statesFilter.length == 0) {
      this.filterForm.get('cidades').setValue('')
      return;
    };
    statesFilter.map(stateFilter => {
      if (stateFilter.key == 'unknown') return;
      this.cities = this.cities.concat(this.statesMap.get(`${stateFilter.value}/${stateFilter.key}`));
    })
    if (this.cities.length > 0) {
      this.filterForm.get('outraCidade').setValue('');
      this.filterForm.get('outroEstado').setValue('');
    }
    const citiesChecked = this.cities.filter((city) => city.checked);
    this.filterForm.get('cidades').setValue(citiesChecked);
  }  

  private getParameters(isStringify = true): any {
    var params: any = {};
    Object.keys(this.filterForm.getRawValue()).map((property) => {
      if (Array.isArray(this.filterForm.getRawValue()[property]) && property != 'codigos') {
        if (property == 'cidades') {
          var citiesFilter: CityFilter[] = [];
          this.filterForm.getRawValue()[property].map((city: any) => {
            citiesFilter.push(new CityFilter(city.value, city.state));
            params['cidadesEstados'] = citiesFilter;
          });
          params[property] = this.filterForm.getRawValue()[property]
          return;
        }
        if (property == 'tipoUnidades') {
          params[property] = this.filterForm.getRawValue()[property].map((typeUnit: TOption) => typeUnit.key);
          return;
        }
        if (property == 'status') {
          if (this.filterForm.getRawValue()[property].length > 0) {
            params[property] = this.filterForm.getRawValue()[property][0].key == 'sim'
          } else {
            params[property] = null
          }
          return;
        }
        params[property] = this.filterForm.getRawValue()[property];
      } else {
        params[property] = this.filterForm.getRawValue()[property]
      }
    });
    return isStringify ? JSON.stringify(params) : new UserFilterScreenMonitoring(params);
  }


}
