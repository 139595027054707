<mat-form-field [appearance]="appearance" style="width: 100%">
  <mat-label>{{ label }}</mat-label>

  <mat-select
    [multiple]="multiple"
    [disabled]="disabled"
    [value]="multiple ? values : values[0]"
    (blur)="propagateTouched()"
    (selectionChange)="handleSelectChange($event.value)"
    (openedChange)="$event ? inputElement.focus() : inputElement.value = ''; handleSearchInputChange(inputElement.value)"
  >
    <input
      matInput
      #inputElement
      class="select-search-input"
      [placeholder]="searchPlaceholder ?? ('FILTER.DEFAULT_SEARCH_PLACEHOLDER' | translate)"
      (input)="handleSearchInputChange(inputElement.value)"
    />

    <mat-option *ngFor="let option of (searchedOptions ?? options)" [value]="option.value">
      {{ option.label }}
    </mat-option>
  </mat-select>

  <mat-icon
    *ngIf="values.length > 0"
    matSuffix
    aria-label="Clear"
    (click)="$event.preventDefault(); $event.stopPropagation(); handleSelectChange([])"
    class="clear-icon"
  >
    close
  </mat-icon>

  <mat-icon matSuffix>arrow_drop_down</mat-icon>
</mat-form-field>
