<form [formGroup]="filterForm" class="modal-filter-subzones">
    <div class="modal-filter-subzones__row">
        <div class="modal-filter-subzones__row__item">
            <span class="modal-filter-subzones__row__item__label"> Categoria </span>
            <app-input [autofocus]="true" formControlName="categoria" placeholder="Digite a categoria">
            </app-input>
        </div>

        <div class="modal-filter-subzones__row__item">
            <span class="modal-filter-subzones__row__item__label"> Nome </span>
            <app-input formControlName="nome" placeholder="Digite o Nome"></app-input>
        </div>
    </div>

    <div class="modal-filter-subzones__row">
        <div class=" modal-filter-subzones__row__item">
            <span class="modal-filter-subzones__row__item__label"> Código </span>
            <app-input formControlName="codigo" placeholder="Digite o código"></app-input>
        </div>

        <div class="modal-filter-subzones__row__item">
            <span class="modal-filter-subzones__row__item__label"> Tipo de saída </span>
            <app-select id="tipoSaida" formControlName="tipoSaida" placeholder="Selecione o(s) tipo(s) de saída"
                [options]="tipoSaidaOptions" selectedText="tipos de saídas selecionados">
            </app-select>
        </div>
    </div>

    <div class="modal-filter-subzones__row">
        <div class="modal-filter-subzones__row__item">
            <span class="modal-filter-subzones__row__item__label"> Tipo de unidade </span>
            <app-select id="tipoUnidade" formControlName="tipoUnidade"
                placeholder="Selecione o(s) tipo(s) de unidade(s)" selectedText="tipos de unidades selecionadas"
                [multipleSelect]="true" [options]="tipoParadaOptions">
            </app-select>
        </div>
        <div class="modal-filter-subzones__row__item">            
        </div>
    </div>

    <div class="modal-filter-subzones__actions">
        <app-link *ngIf="!!filterCount" (handleClick)="onClearFilter()" [isBolder]="true">
            Limpar todos os filtros
        </app-link>
        <app-button [isDisabled]="filterCount == 0" (clicked)="savedFilter ? onCancel() : onCreateFilter()"
            [text]="savedFilter ? 'Cancelar' : 'Criar filtro'" [isOutlined]="true" [isMarginRight]="true"
            [isMarginLeft]="true"></app-button>
        <app-button *ngIf="(savedFilters$ | async)?.length" [isDisabled]="filterCount == 0"
            [text]="savedFilter ? 'Salvar' : 'Aplicar filtro'" (clicked)="savedFilter ? onSaveFilter() : onSubmit()">
        </app-button>
    </div>
</form>