import {
  AfterViewInit,
  Component,
  ElementRef,
  forwardRef,
  Input,
  Output,
  ViewChild,
  EventEmitter
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import { heightFadeAnimation } from 'src/app/core/animations/height-fade/height-fade.animation';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  animations: [heightFadeAnimation],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => InputComponent)
    }
  ]
})
export class InputComponent implements ControlValueAccessor, AfterViewInit {

  @ViewChild('inputElement', { static: true, read: ElementRef }) inputElement: ElementRef<HTMLInputElement>;

  @Input() placeholder = 'Texto';

  @Input() value = '';

  @Input() isDisabled = false;

  @Input() separator = '';

  @Input() autofocus: boolean;

  @Input() mask: string;

  @Input() type = 'text';

  @Input() messageError = '';

  @Input() readonly = false;

  @Input() invalid = false;

  @Input() maxlength: number = null;

  @Input() removeAllSpaces = false;

  @Output() onChangeEvent = new EventEmitter<string>();

  private onTouched = () => {};

  private onChanged = (_: string) => {
    this.onChangeEvent.emit(_);
  };

  ngAfterViewInit(): void {
    if (this.autofocus) {
      setTimeout(() => {
        this.inputElement.nativeElement.focus();
      });
    }
  }

  onkeypress(event: KeyboardEvent) {
    if (!this.removeAllSpaces) return true;
    if (event.key == ' ') return false;
  }

  handleValue(value: string): void {
    if (this.removeAllSpaces) {
      value = value.split(' ').join(this.separator);
    }

    let newValue: any = value;

    if (newValue !== '' && this.separator) {
      newValue = value.split(this.separator);
    }

    this.onTouched();
    this.onChanged(newValue);
    this.value = value;
  }

  writeValue(value: any): void {
    if (value && Array.isArray(value)) {
      const separator = this.separator || ',';
      const newValue = value.join(separator);
      this.value = newValue;
      this.onTouched();
      this.onChanged(newValue);
      return;
    }

    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
