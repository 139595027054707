import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'moneyformat'
})
export class MoneyFormatPipe implements PipeTransform {
  transform(value: number, remove = ''): string {
    if (value === null || value === undefined) {
      return;
    }

    const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      // @ts-ignore
      currency: 'BRL'
    });

    return formatter.format(value);
  }
}
