import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpContext,
  HttpContextToken,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MessageService } from '../services';
import { AuthService } from '../services/auth.service';

const SUPPRESS_ERROR_INTERCEPTOR_MESSAGE = new HttpContextToken(() => false);
export const suppressErrorInterceptorMessageContent = new HttpContext().set(SUPPRESS_ERROR_INTERCEPTOR_MESSAGE, false);

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private messageService: MessageService,
    private router: Router,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {

      if (err.status === 200) {
        return;
      }

      if ((!err.status && err.stack) ||
        err.url.includes('AutenticacaoTransportadora') ||
        err.url.includes('CadastroTransportadora')) {
        console.log('Another errors Transportadora:', err);
        const error = err.error.message || err.error || err.statusText;
        return throwError(error);
      }

      switch (err.status) {

        case 400:
        case 404:
        case 500: // Bad Request
          if (!request.context.has(SUPPRESS_ERROR_INTERCEPTOR_MESSAGE)) {
            this.messageService.error(err.message);
          }

          if (err.message) {
            console.error(`${err.status} - Bad Request:` + err.message);
          }

          if (err.error && err.error.errors) {
            err.error.errors.forEach((element: any) => {
              this.messageService.error(element);
            });
          }
          break;

        case 401: // Não autorizado
          this.messageService.error('Não autorizado!');

          if (err.message) {
            console.error('401 - Não autorizado: ' + err.message);
          }
          this.router.navigate(['/login']);
          break;

        case 403: // Permission denied
          this.messageService.error('Permissão negada!');

          if (err.message) {
            console.error('403 - Permissão negada: ' + err.message);
          }
          break;

        case 409: // Report not found
          this.messageService.error(err.error, null, 5000, null, true);
          break;
        case 410: // Token not found
        case 417: // Token Invalid
        case 423: // Token inactived
        case 424: // Token expired

          this.messageService.error('Não autorizado!');

          let statusDescription = '';

          if (err.status === 410) {
            statusDescription = 'Token not found';
          } else if (err.status === 417) {
            statusDescription = 'Token Invalid';
          } else if (err.status === 423) {
            statusDescription = 'Token inactived';
          } else if (err.status === 424) {
            statusDescription = 'Token expired';
          }

          if (err.message) {
            console.error(`${err.status} - ${err.statusDescription}: ${err.message}`);
          }

          if (err.error && err.error.errors) {
            err.error.errors.forEach((element: any) => {
              this.messageService.error(element);
              this.authService.logout();
              this.authService.setLoginScreen(true);
              this.router.navigateByUrl('/login');
            });
          }
          break;

        default:
          this.messageService.error('Desculpe, ocorreu um erro!');

          if (err.message) {
            console.error('Erro não tratado: ' + err.message);
          }
          break;
      }

      console.log('Another errors:', err);
      const error = err || err.message || err.error.message || err.statusText;
      return throwError(error);
    }));
  }
}
