export class IconsPrimary {    
    static readonly ICON_CD = "../../../../assets/icons/primary/pins/pin_cd.svg";
    static readonly ICON_CLIENT = "../../../../assets/icons/primary/pins/pin_client.svg";
    static readonly ICON_END = "../../../../assets/icons/primary/pins/pin_end.svg";
    static readonly ICON_FISCAL = "../../../../assets/icons/primary/pins/pin_fiscal.svg";
    static readonly ICON_HOME = "../../../../assets/icons/primary/pins/pin_home.svg";
    static readonly ICON_MEAL = "../../../../assets/icons/primary/pins/pin_meal.svg";
    static readonly ICON_MAINTENANCE = "../../../../assets/icons/primary/pins/pin_maintenance.svg";
    static readonly ICON_NF = "../../../../assets/icons/primary/pins/pin_nf.svg";
    static readonly ICON_PORT = "../../../../assets/icons/primary/pins/pin_port.svg";
    static readonly ICON_PRF = "../../../../assets/icons/primary/pins/pin_prf.svg";
    static readonly ICON_PROVIDER = "../../../../assets/icons/primary/pins/pin_provider.svg";
    static readonly ICON_REST = "../../../../assets/icons/primary/pins/pin_rest.svg";
    static readonly ICON_STOP = "../../../../assets/icons/primary/pins/pin_stop.svg";
    static readonly ICON_SUPPLY = "../../../../assets/icons/primary/pins/pin_supply.svg";
    static readonly ICON_UP = "../../../../assets/icons/primary/pins/pin_up.svg";
    static readonly ICON_VEHICLE = "../../../../assets/icons/primary/pins/pin_vehicle.svg";
    static readonly ICON_WAREHOUSE = "../../../../assets/icons/primary/pins/pin_warehouse.svg";
    static readonly ICON_WC = "../../../../assets/icons/primary/pins/pin_wc.svg";
    static readonly ICON_CURRENT_POINT = "../../../../assets/icons/maps-pointer/pin_current_.svg";
}