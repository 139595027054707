
<div class="modal-window-paradaRaio">
    <span *ngIf="!modalId && paradas[0]" (click)="onModalOpen()" class="modal-window-paradaRaio__visualization">
        <span class="modal-window-paradaRaio__visualization"> 
            {{paradas[0].dataHoraInicio | datetimeformat:false}} -  {{paradas[0].dataHoraFim | datetimeformat:false}} 
        </span>

        <strong *ngIf="paradas && paradas.length > 1" class="modal-window-paradaRaio__link"> + {{paradas.length - 1}} </strong>
    </span>
    
    <div *ngIf="modalId && paradas">
        <app-modal-window [modalId]="modalId" title="Paradas">
   
            <div class="modal-window-paradaRaio__content">
                <span 
                    *ngFor="let parada of paradas" 
                    class="modal-window-paradaRaio__text" > 
                    {{parada.dataHoraInicio | datetimeformat:false}} -  {{parada.dataHoraFim | datetimeformat:false}}
                </span>
            </div>

            <span class="modal-window-paradaRaio__total">
                Total de paradas: {{paradas.length}}   
            </span>
        </app-modal-window>
    </div>
</div>
