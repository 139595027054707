<ul *ngIf="user != null && user.roles != null && user.roles.length > 0">
  <div class="menu">
    <li *ngFor="let item of list" [ngClass]="{'actived': item.selected}" (click)="goToUrl(item, $event)">
      <a [ngClass]="{'disabled' : item.disabled, 'actived': item.selected}" (click)="item.toggle = !item.toggle;">
        <div fxLayout="row" fxLayoutAlign="center" >
            <span openTab [url]="item.route" class="redirect"> </span>
          <img class="icon" mat-icon-button matPrefix
            [src]="'../../../../assets/icons/' + (item.selected ? 'primary/' : '') + item.icon + '.svg'">
          <span [ngClass]="{'actived': item.selected}">{{item.title  | translate}}</span>
          <img  *ngIf="item.children?.length" width="16px"
            height="9.6px" class="toggle" mat-icon-button matSuffix
            [src]="'../../../../assets/icons/toggle-' + (item.toggle ? 'up' : 'down') + '.svg'">
        </div>
      </a>

      <ul *ngIf="item.children?.length && item.toggle" @height-fade>
        <li class="children" (click)="goToUrl(child, $event)" *ngFor="let child of item.children" [ngClass]="{'actived': child.selected}">
          {{child.title | translate}}
          <span openTab [url]="child.route" class="redirect-child"> </span>
        </li>
      </ul>
    </li>
    <!-- <li>
    <a (click)="logout()">
      <div fxLayout="row" fxLayoutAlign="center">
        <img class="icon" mat-icon-button matPrefix src="../../../../assets/icons/logout.svg">
        <span>Sair</span>
      </div>
    </a>
  </li> -->
  </div>
</ul>
