import { Directive, Input, HostListener } from '@angular/core';
@Directive({
  selector: '[openTab]'
})
export class OpenTabDirective {
  @Input() url: string;
  @HostListener('contextmenu', ['$event'])
  public onRightClick(event): void {    
    event.preventDefault();
    if (!this.url) {
      return;
    }
    window.open(this.url, '_blank', 'noopener noreferrer');
  }
}
