export enum MonitoramentoStatusViagemEnum
{
    InicioViagem = 0,
    Cliente = 1,
    Armazem = 2,
    TerminoViagem = 3,
    UnidadeProdutiva = 4,
    Porto = 5,
    EmTransito = 6,
    ParadaProgramada = 7,
    CentroDeDistribuicao = 8,
    Fornecedor = 9,
    TrocaNotaFiscal = 10,
    ViagemCancelada = 11,
}

export enum MonitoramentoStatusAtendimentoEnum
{
    NaoAtende = 0,
    Atende = 1,
    Risco = 2
}

export enum MonitoramentoTipoOperacaoEnum
{
    Vd = 0,
    ContainerVazio = 1,
    Transferencia =2,
    ContainerCheio=3,
    Inbound =4,
    Projeto = 5,
}

export enum TipoProjetoEnum
{
    AltaProdutividade = 0,
    FrotaDedicada = 1,
    EngateDesengateCNTR = 2,
    HotSeat = 3,
    PowerMobil = 4,
    EngateDesengateHotSeat = 5,
    BateVolta = 6,
    Rodotrem = 7,
}

export enum  MonitoramentoStatusEspelhamentoEnum
{
    Espelhado = 0,
    NaoEspelhado = 1
}

export enum TipoEquipamentoEnum
{
    Carreta = 0,
    Container = 1,
    Truck = 2
}

export enum MercadoEnum
{
    MI = 0,
    ME = 1,
    Inbound = 2,
    MP = 3,
    Ingredients = 4
}

export enum TipoFrotaEnum
{
    FrotaFixaBRF = 0,
    FrotaComun = 1  
}