import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ChatMessageTypeFile } from './chat-message-type-file.model';
import { ChatMessageTypeMessage } from './chat-message-type-message.model';

@Injectable({
    providedIn: 'root'
})
export class ChatObservableService {

    #messageSent = new Subject<ChatMessageTypeMessage
        | ChatMessageTypeMessage[]
        | ChatMessageTypeFile
        | ChatMessageTypeFile[]>();

    #showMessages = new Subject<any>();

    #showNewMessage = new Subject<any>();

    #markAllRead = new Subject<any>();

    /**
     * Envia uma ou mais mensagens
     *
     * @param message ChatMessageTypeMessage | ChatMessageTypeMessage[] | ChatMessageTypeFile | ChatMessageTypeFile[]
     */
    public sendMessage(message: ChatMessageTypeMessage
        | ChatMessageTypeMessage[]
        | ChatMessageTypeFile
        | ChatMessageTypeFile[]
    ): void {
        this.#messageSent.next(message);
    }

    /**
     * Escuta uma ou mais mensagens
     *
     * @returns ChatMessageTypeMessage | ChatMessageTypeMessage[] | ChatMessageTypeFile | ChatMessageTypeFile[]
     */
    public getMessage(): Observable<ChatMessageTypeMessage
        | ChatMessageTypeMessage[]
        | ChatMessageTypeFile
        | ChatMessageTypeFile[]> {
        return this.#messageSent.asObservable();
    }

    /**
     * Desinscreve de receber mensagens
     */
    public unsetMessage(): void {
        this.#messageSent.complete();
    }

    /**
     * Mostra a tela de detalhamento com o histórico de mensagens
     *
     * @param loadNumber string
     * @param plate string
     * @param status string
     */
    public showDetail(loadNumber: string, plate: string, status: string): void {
        this.#showMessages.next({ loadNumber, plate, status });
    }

    /**
     * Escuta o detalhamento de histórico de mensagens
     * @returns any
     */
    public getDetail(): Observable<any> {
        return this.#showMessages.asObservable();
    }

    /**
     * Desinscreve de mostrar a tela de detalhamento
     */
    public unsetGetDetail(): void {
        this.#showMessages.complete();
    }

    /**
     * Mostra uma nova mensagem filtrada
     *
     * @param textFilter string
     * @param visible boolean
     */
    public showNewMessage(textFilter: string = '', visible: boolean = true): void {
        this.#showNewMessage.next({ textFilter, visible });
    }

    /**
     * Escuta uma nova mensagem mostrada
     * @returns any
     */
    public getShowNewMessage(): Observable<any> {
        return this.#showNewMessage.asObservable();
    }

    /**
     * Desinscreve uma nova mensagem mostrada
     */
    public unsetShowNewMessage(): void {
        this.#showNewMessage.complete();
    }

    /**
     * Seta todas as mensagens como lidas a partir de uma listagem de identificadores
     *
     * @param chatIdsUnreaded string[]
     */
    public setMarkAllRead(chatIdsUnreaded: string[]): void {
        this.#markAllRead.next({ chatIdsUnreaded });
    }

    /**
     * Escuta as mensagens lidas
     *
     * @returns any
     */
    public getMarkAllRead(): Observable<any> {
        return this.#markAllRead.asObservable();
    }

    /**
     * Desinscreve a marcação de mensagens lidas
     */
    public unsetMarkAllRead(): void {
        this.#markAllRead.complete();
    }
}
