import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { SelectOption } from 'src/app/models/common/angular-material-ui/select-option.interface';

@Component({
  selector: 'app-select-form-field',
  templateUrl: './select-form-field.component.html',
  styleUrls: ['./select-form-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectFormFieldComponent),
      multi: true
    }
  ],
})
export class SelectFormFieldComponent implements ControlValueAccessor {

  @Input() multiple = true;
  @Input() label: string;
  @Input() searchPlaceholder: string;
  @Input() options: SelectOption[] = [];
  @Input() appearance: MatFormFieldAppearance = 'outline';

  disabled = false;
  values: string[] = [];
  searchedOptions: SelectOption[] | undefined = undefined;
  propagateTouched: () => void;
  private propagateChange: (value: string | string[]) => void;

  handleSearchInputChange(searchValue: string): void {
    const lowerCaseSearchValue = searchValue.trim().toLowerCase();
    this.searchedOptions = !lowerCaseSearchValue
      ? undefined
      : this.options.filter(option => option.label.toLowerCase().includes(lowerCaseSearchValue));
  }

  writeValue(value: string | string[]): void {
    this.values = Array.isArray(value) ? value : value !== '' ? [value] : [];
  }

  handleSelectChange(value: string | string[]): void {
    this.writeValue(value);
    this.propagateChange(value);
  }

  registerOnChange(fn: (value: string | string[]) => void): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.propagateTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
