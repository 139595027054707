import { ChatMessageOrigin } from './chat-message-origin.model';

export class ChatMessageTypeFile {

    #id: string;

    #loadNumber: string;

    #plate: string;

    #attachment: string;

    #mimeType: string;

    #name: string;

    #createdAt: Date;

    #receivedAt: Date;

    #origin: ChatMessageOrigin;

    #type: string;

    constructor({
        anexo,
        dataEnvio,
        origem,
        id = null,
        numeroViagem = null,
        placa = null,
        dataRecebido = null,
        tipo = null
    }) {
        this.#id = id;
        this.#loadNumber = numeroViagem;
        this.#plate = placa;
        this.#attachment = anexo.nomeArquivo;
        this.#mimeType = anexo.mime || anexo.tipoMime;
        this.#name = anexo.nomeArquivoOriginal;
        this.#createdAt = dataEnvio !== null ? new Date(dataEnvio) : null;
        this.#receivedAt = dataRecebido !== null ? new Date(dataRecebido) : null;
        this.#origin = origem !== null ? new ChatMessageOrigin(origem) : null;
        this.#type = tipo;
    }

    get id(): string {
        return this.#id;
    }

    get loadNumber(): string {
        return this.#loadNumber;
    }

    get plate(): string {
        return this.#plate;
    }

    get attachment(): string {
        return this.#attachment;
    }

    get createdAt(): Date {
        return this.#createdAt;
    }

    get receivedAt(): Date {
        return this.#receivedAt;
    }

    get type(): string {
        return this.#type;
    }

    get mimeType(): string {
        return this.#mimeType;
    }

    get name(): string {
        return this.#name;
    }

    get origin(): ChatMessageOrigin {
        return this.#origin;
    }

    get isImage(): boolean {
        return this.mimeType && this.mimeType.match('image') !== null;
    }

    serialize() {
        return {
            mime: this.#mimeType,
            nomeArquivo: this.#attachment,
            nomeArquivoOriginal: this.#name
        }
    }
}
