import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule} from '@angular/router';
import {IndicatorComponent} from './indicator.component';
import {IndicatorDirective} from './indicator.directive';

@NgModule({
  declarations: [
    IndicatorComponent,
    IndicatorDirective
  ],
  exports: [
    IndicatorComponent,
    IndicatorDirective
  ],
  imports: [
    CommonModule,
  ]
})
export class IndicatorModule { }
