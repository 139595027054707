import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'priority'
})
export class PriorityPipe implements PipeTransform {
  transform(priority: number): string {
    switch (priority) {
      case 0:
        return 'Alta prioridade';
      case 1:
        return 'Média prioridade';
      case 2:
        return 'Baixa prioridade';
      default:
        console.error('Prioridade inexistente');
        return;
    }
  }
}
