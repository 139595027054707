import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MyFilter } from 'src/app/core/models/my-filter.model';
import { DiariaService } from 'src/app/core/services/diaria.service';
import { GDFilterService } from 'src/app/core/services/gd-filter.service';
import { Diarias } from 'src/app/models/diarias.model';

export class DialogDataSaveFilter {
  screen: string;
  objectFilter: object;
}

@Component({
  selector: 'app-dialog-salvar-filtro',
  templateUrl: './dialog-salvar-filtro.component.html',
  styleUrls: ['./dialog-salvar-filtro.component.scss'],
})
export class DialogSalvarFiltroComponent implements OnInit {
  public haveValue = false;

  public form: FormGroup = new FormGroup({
    nomeFiltro: new FormControl(''),
  });

  constructor(
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataSaveFilter,
    private gdFilterService: GDFilterService,
    private diariaService: DiariaService,
  ) { }

  close(): void {
    this.dialog.closeAll();
  }

  async create(): Promise<void> {
    await this.gdFilterService.createFilter(
      this.form.controls.nomeFiltro.value,
      JSON.stringify(this.data.objectFilter),
      JSON.parse(localStorage.getItem('currentUser')).email
    );
    void this.loadFilters();
  }

  ngOnInit(): void {
    this.form.valueChanges.subscribe((data) => this.haveValue = !!data.nomeFiltro);
  }

  async loadFilters(): Promise<void> {
    const myFiltersResponse = await this.diariaService.getMyFilters(JSON.parse(localStorage.getItem('currentUser')).email);
    const myFilters: MyFilter[] = myFiltersResponse.map((element: any) => new MyFilter(element));
    this.gdFilterService.myFilters = myFilters;

    const [defaultFilter] = myFilters.filter((element: MyFilter) => element.filtroPadrao);
    this.gdFilterService.myFilterSelected = defaultFilter ?? null;

    const filters = defaultFilter ? JSON.parse(defaultFilter.definicaoFiltro) : undefined;
    const diarias = await this.diariaService.getAllDiarias(filters);
    this.diariaService.listaDiarias = diarias.map((element: any) => new Diarias(element));
    this.diariaService.listaDiariasCompleta = diarias.map((element: any) => new Diarias(element));
    this.dialog.closeAll();
  }
}
