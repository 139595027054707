import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DailyComponent } from './daily.component';
import { DetalhesComponent } from './detalhes/detalhes.component';
import { SinalizarDiariaComponent } from './sinalizar-diaria/sinalizar-diaria.component';

const routes: Routes = [
  {
    path: '',
    children: [
      { path: '', component: DailyComponent },
      { path: 'detalhes/:id', component: DetalhesComponent },
      { path: 'sinalizar', component: SinalizarDiariaComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DailyRoutingModule { }
