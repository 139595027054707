import { ComponentRef } from '@angular/core';

import { Subject } from 'rxjs';

import { ModalContainerComponent } from './modal-container/modal-container.component';
import {ModalOptions} from './modal-options';
import {BehaviorSubject, Observable} from 'rxjs';

export class ModalRef<R = any, D = any> {

  private promiseInitialized: boolean;
  private result$ = new Subject<R>();
  private dismiss$ = new Subject();
  private data$ = new Subject<D>();
  private resolve;
  private reject;
  private promise: any;
  public options$: Observable<ModalOptions>;
  private optionsSubject: BehaviorSubject<ModalOptions>;
  container: ComponentRef<ModalContainerComponent>;

  constructor(private options: ModalOptions) {
    this.optionsSubject = new BehaviorSubject<ModalOptions>(this.options);
    this.options$ = this.optionsSubject.asObservable();

    this.promise = new Promise<R>((resolve, reject) => {
      this.reject = reject;
      this.resolve = resolve;
    });
  }

  /**
   * Close the modal and emit output param to result observables then remove modal from tree
   * param output
   */
  close(output?: R): ModalRef<R> {
    this.container.instance.hide();
    this.result$.next(output);
    this.result$.complete();

    if (this.promiseInitialized) {
      this.resolve(output);
    }

    this.container.changeDetectorRef.detectChanges();
    return this;
  }

  /**
   * Close and remove modal from tree
   */
  dismiss(): ModalRef<R> {
    this.container.instance.hide();
    this.dismiss$.next();

    if (this.promiseInitialized) {
      this.reject();
    }

    this.container.changeDetectorRef.detectChanges();
    return this;
  }

  show(): ModalRef<R> {
    this.container.instance.show();
    return this;
  }

  async showPromise(): Promise<R> {
    this.show();
    return this.toPromise().catch(() => undefined);
  }

  hide(): void {
    this.container.instance.hide();
  }

  /**
   * Listen for close result
   * param cb
   */
  onResult(cb: (data: R) => void): ModalRef<R> {
    this.result$.subscribe(cb);
    return this;
  }

  /**
   * Listen for dismiss dialog
   * param cb
   */
  onDismiss(cb: () => void): ModalRef<R> {
    this.dismiss$.subscribe(cb);
    return this;
  }

  /**
   * Listen for data sent by modal
   * param cb
   */
  onData<E>(cb: (data?: D | E) => void): ModalRef<R> {
    this.data$.subscribe(cb);
    return this;
  }

  sentData(data: D): void {
    this.data$.next(data);
  }

  updateOptions(options: ModalOptions): void {

    this.optionsSubject.next({
      ...this.getOptions(),
      ...options,
      openFrom: {
        ...(this.options.openFrom || {}),
        ...(options.openFrom || {}),
      }
    } as ModalOptions);
  }

  toPromise<_R = any>(): Promise<_R> {
    this.promiseInitialized = true;
    return this.promise;
  }

  _destroy(): void {
    this.container.destroy();
    this.result$.complete();
    this.dismiss$.complete();
    this.data$.complete();
  }

  // scrollUp(offset, speed) {
  //   const directive = this.container.instance.scrollbarComponent.directiveRef
  //
  //   if ((directive.ps().lastScrollTop as any) > offset) {
  //     directive.scrollToTop(offset, speed)
  //   }
  // }

  getOptions(): ModalOptions {
    return this.optionsSubject.getValue();
  }
}
