import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, Subject } from 'rxjs';
import { BreadCrumbItem } from '../models/bread-crumb-item.model';

@Injectable({ providedIn: 'root' })
export class ContainerScrollingService {

    public onScrolled: Subject<any>;
    constructor() {
        this.onScrolled = new Subject();
    }

    public emitEvent(obj: any){
        this.onScrolled.next(obj);
    }

    public eventObservable(){
        return this.onScrolled.asObservable();
    }
}
