<mat-toolbar>
  <button *ngIf="showMenu" (click)="change()" mat-icon-button aria-label="icon-button with menu icon">
    <mat-icon>menu</mat-icon>
  </button>

 <div fxLayout="row" class="header"  fxLayoutAlign="left center">
  <img class="header-logo icon" mat-icon-button matPrefix src="../../../../assets/images/BRF_onelog.svg">
  <div class="header-profile">
    <app-user-profile [showMenu]="showMenu" matSuffix></app-user-profile>
  </div>
 </div>

</mat-toolbar>
