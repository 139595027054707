import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss']
})
export class PanelComponent implements OnInit {

  @Input() public isShadow: boolean = true;

  @Input() public isMarginBottom: boolean = true;

  public shadowStyled = '';

  public marginBottonStyled = '';

  ngOnInit(): void {
    if (this.isShadow) {
      this.shadowStyled = 'panel--is-shadow';
    }

    if (this.isMarginBottom) {
      this.marginBottonStyled = 'panel--is-margin-bottom';
    }
  }
}
