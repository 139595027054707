export class Diarias {

    areaResponsavel: string;
    cliente: {
        codigo: string;
        nome: string;
    };
    codigoCliente: string;
    nomeCliente: string;
    dataPrevisaoEntrega: Date;
    dataSolicitacao: Date;
    id: string;
    justificativa: string;
    localAnomalia: {
        endereco: string;
        latitude: number;
        longitude: number;
    };
    enderecoAnomalia: string;
    latitudeAnomalia: string;
    longitudeAnomalia: string;

    motivo: string;
    motorista: {
        codigo: string;
        cpf: string;
        nome: string;
        telefone: string;
    };
    codigoMotorista: string;
    cpfMotorista: string;
    nomeMotorista: string;
    telefoneMotorista: string;
    numeroEntrega: string;
    numeroViagem: string;
    pesoEntrega: number;
    placa: string;
    status: string;
    statusAnomalia: string;
    statusColor: string;
    subMotivo: string;
    tempoEmDiaria: string;

    constructor(json: DiariasInterface) {
        this.areaResponsavel = json.areaResponsavel;
        this.cliente = json.cliente;
        this.dataPrevisaoEntrega = new Date(json.dataPrevisaoEntrega);
        this.dataSolicitacao = new Date(json.dataSolicitacao);
        this.id = json.id;
        this.justificativa = json.justificativa;
        this.localAnomalia = json.localAnomalia;
        this.motivo = json.motivo;
        this.motorista = json.motorista;
        this.numeroEntrega = json.numeroEntrega;
        this.numeroViagem = json.numeroViagem;
        this.pesoEntrega = json.pesoEntrega;
        this.placa = json.placa;
        this.status = json.status;
        this.statusAnomalia = json.statusAnomalia;
        this.statusColor = json.statusCor;
        this.subMotivo = json.subMotivo;
        this.tempoEmDiaria = json.tempoEmDiaria;

        this.codigoCliente = json.cliente.codigo;
        this.nomeCliente = json.cliente.nome;
        this.enderecoAnomalia = json.localAnomalia.endereco;
        // this.latitudeAnomalia = json.localAnomalia.latitude.toString();
        // this.longitudeAnomalia = json.localAnomalia.longitude.toString();
        this.codigoMotorista = json.motorista.codigo;
        this.cpfMotorista = json.motorista.cpf;
        this.nomeMotorista = json.motorista.nome;
        this.telefoneMotorista = json.motorista.telefone;
    }
}

export interface DiariasInterface {
    areaResponsavel: string;
    cliente: {
        codigo: string;
        nome: string;
    };
    dataPrevisaoEntrega: string;
    dataSolicitacao: string;
    id: string;
    justificativa: string;
    localAnomalia: {
        endereco: string;
        latitude: number;
        longitude: number;
    };
    motivo: string;
    motorista: {
        codigo: string;
        cpf: string;
        nome: string;
        telefone: string;
    };
    numeroEntrega: string;
    numeroViagem: string;
    pesoEntrega: number;
    placa: string;
    status: string;
    statusAnomalia: string;
    statusCor: string;
    subMotivo: string;
    tempoEmDiaria: string;
}
