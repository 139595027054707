import { Shipping } from 'src/app/models/shipping.model';

export class StepDetailDistributionClientTracking {
    constructor(
        public operationType: string,
        public calendar: Date,
        public statusDelivery: string,
        public arrivalExpected: Date,
        public arrivalFinished: Date,
        public exitExpected: Date,
        public exitFinished: Date,
        public distanceExpected: number,
        public distanceExecuted: number,
        public waitingTime: number,
        public waitingTimeUnloading: number,
        public waitingTimeTotal: number,
        public waitingTimeTotalExpected: number,
        public coordenateVehicleLatitude: number,
        public coordenateVehicleLongitude: number,
        public observationMotorist: string,
        public shippings: Shipping[],
        public loadsRelated: string[],
        public pedidos: string[],
        public pesoPrevisto: number,
        public pesoRealizado: number,
        public sequenciaPrevista: number,
        public sequenciaRealizada: number,
        public valorPrevisto: number,
        public valorRealizado: number,
        public dispersao: number,
        public inicioDescarga: Date,
        public fimEntrega: Date,
        public devolucoes: Date[],
        public paradaDentroDoRaio:any[],
        public raioKm? : number
    ) { }
}
