
<div class="modal-window-loads-related">
    <span *ngIf="!modalId" (click)="onModalOpen()" class="modal-window-loads-related__visualization">
        {{loadNumber}}
        <strong>+{{loadNumberRelated.length}}</strong>
    </span>

    <div *ngIf="modalId">
        <app-modal-window [modalId]="modalId" title="Viagen(s) relacionada(s)">
            <div class="modal-window-loads-related__content">
                <span 
                    *ngFor="let loadNumber of loadNumberRelated" 
                    class="modal-window-loads-related__link"
                    (click)="onRedirect(loadNumber)"
                >
                    {{loadNumber}}
                </span>

                <span class="modal-window-loads-related__total">
                    Total de viagens relacionadas: {{loadNumberRelated.length}}
                </span>
            </div>    
        </app-modal-window>
    </div>
</div>
