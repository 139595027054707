import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatLinkComponent } from './link.component';
import { ChatCounterUnreadModule } from '../counter-unread/counter-unread.module';

@NgModule({
  declarations: [
    ChatLinkComponent
  ],
  imports: [
    CommonModule,
    ChatCounterUnreadModule
  ],
  exports: [
    ChatLinkComponent
  ]
})
export class ChatLinkModule { }
