import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable()
export class ErrorService {

    static checkIsValidForm(form: FormGroup): boolean {
        const controls = Object.keys(form.controls).filter(key => form.controls[key]);

        if(controls.every(key => form.controls[key].valid))
            return true;
        
        controls.forEach(key =>{
            const formControl = form.controls[key];
            if(formControl.disabled || formControl.valid)
                return;

            if(!formControl.value == null && formControl.invalid){
                formControl.markAsTouched();
                return;
            }
            
            if((typeof formControl.value !== 'object' || formControl.value instanceof Array) &&
            formControl.invalid){
                formControl.markAsTouched();
 
            }else if(typeof formControl.value === 'object' && !(formControl.value instanceof Array) && !(formControl.value instanceof Date)){
                this.checkIsValidForm(formControl as FormGroup);
            }
        });
    }
}