import { Pipe, PipeTransform } from '@angular/core';
import { AnomalyStatusEnum } from '../enums/anomaly-status.enum';

@Pipe({
  name: 'anomalystatus'
})
export class AnomalyStatusPipe implements PipeTransform {
  transform(status: number): string {
    if (status === null || status === undefined) {
      return;
    }

    switch (status) {
      case AnomalyStatusEnum.Pending:
        return 'Pendente';
      case AnomalyStatusEnum.InProgress:
        return 'Em andamento';
      case AnomalyStatusEnum.StandBy:
        return 'Stand-by';
      case AnomalyStatusEnum.Finished:
        return 'Finalizado';
      default:
        console.error(`Status ${status} da anomalia inexistente`);
        return;
    }
  }
}
