export class ChatMessageResponsible {
    #login: string;

    #name: string;

    constructor({ login, nome }) {
        this.#login = login;
        this.#name = nome;
    }

    get login(): string {
        return this.#login;
    }

    get name(): string {
        return this.#name;
    }

    serialize(): any {
        return {
            login: this.#login,
            nome: this.#name
        };
    }
}
