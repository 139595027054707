
<div [ngClass]="{'modal-window-monitoring-filter': logistic != 'agro-subzones', 'modal-window-filter-subozones': logistic == 'agro-subzones'}" *ngIf="logistic && modalId">
    <app-modal-window [modalId]="modalId" [title]="title" [subTitle]="subTitle">
        <ng-container [ngSwitch]="logistic">
            <app-modal-window-monitoring-filter-agro
                *ngSwitchCase="'agro'"
                [modalId]="modalId"
                [savedFilterId]="savedFilterId"
                [myFiltersModalId]="myFiltersModalId"
                (filterCountChange)="setSubtitle($event)"
            ></app-modal-window-monitoring-filter-agro>

            <app-modal-window-monitoring-filter-commodities
                *ngSwitchCase="'commodities'"
                [modalId]="modalId"
                [savedFilterId]="savedFilterId"
                [myFiltersModalId]="myFiltersModalId"
                (filterCountChange)="setSubtitle($event)"
            ></app-modal-window-monitoring-filter-commodities>

            <app-modal-window-filter-subozones-agro
                *ngSwitchCase="'agro-subzones'"
                [modalId]="modalId"
                [savedFilterId]="savedFilterId"
                [myFiltersModalId]="myFiltersModalId"
                (filterCountChange)="setSubtitle($event)"
            ></app-modal-window-filter-subozones-agro>

            <app-modal-window-filter-subzones-primary
                *ngSwitchCase="'primaria-subzones'"
                [modalId]="modalId"
                [savedFilterId]="savedFilterId"
                [myFiltersModalId]="myFiltersModalId"
                (filterCountChange)="setSubtitle($event)"
            ></app-modal-window-filter-subzones-primary>
        </ng-container>
    </app-modal-window>
</div>
