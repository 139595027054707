<div class="bread-crumb" *ngIf="list && list.length > 0">
    <div  class="bread-crumb-items" fxLayout="row" fxLayoutGap="4px" fxLayoutGap.gt-sm="8px" fxLayoutAlign="start center">
        <div fxLayout="row" fxLayoutGap="4px" fxLayoutGap.gt-sm="8px" *ngFor="let item of list; let i = index">
            <span>
                <a (click)="actionItem(item)" [ngClass]="{'current': i == list.length -1, 'action': item.route}">
                    <span *ngIf="item.translate">{{item.title | translate}}</span>
                    <span *ngIf="!item.translate">{{item.title}}</span>
                </a>
            </span>
            <span class="bar" *ngIf="i < list.length -1">{{item.symbol}}</span>
        </div>
    </div>
</div>
