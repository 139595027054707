import { ChatMessageTypeMessage } from './chat-message-type-message.model';
import { ChatMessageTypeTopic } from './chat-message-type-topic.model';
import { ChatMessageTypeFile } from './chat-message-type-file.model';

export type TMessage = {
    message?: ChatMessageTypeMessage;
    topic?: ChatMessageTypeTopic;
    file?: ChatMessageTypeFile;
};

export class ChatMessage {
    #loadStatus: string;

    #messages: TMessage[];

    constructor({ statusViagem, mensagens }) {
        this.#loadStatus = statusViagem;

        // @ts-ignore
        this.#messages = mensagens.map((message: any) => {
            switch (message.tipo) {
                case 'mensagem':
                    return { message: new ChatMessageTypeMessage(message) };

                case 'arquivo':
                    return { file: new ChatMessageTypeFile(message) };

                case 'topico':
                    return { topic: new ChatMessageTypeTopic(message) };
            }
        });
    }

    get loadStatus(): string {
        return this.#loadStatus;
    }

    get messages(): TMessage[] {
        return this.#messages;
    }
}
