import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'datetimeformat'
})
export class DateTimeFormatPipe implements PipeTransform {
  transform(dateTime: any, dashed: boolean = true, nullDefault = ''): string {
    if (!dateTime) {
      return nullDefault;
    }
    

    if (typeof dateTime !== 'string' && typeof dateTime !== 'object') {
      console.error(`Tipo de data inválida`);
      return "";
    }

    if (typeof dateTime === 'object' && !(dateTime instanceof Date)) {
      console.error(`Instância da classe de data inválida`);
      return "";
    }

    if (typeof dateTime === 'string') {
      if (dateTime === '0001-01-01T00:00:00') {
        return nullDefault;
      }

      dateTime = new Date(dateTime);
    }

    const time = dateTime.toLocaleTimeString();

    const newHourPart = time.split(":");

    const dashedFormatter = dashed === true ? '-' : '';

    return `${dateTime.toLocaleDateString()} ${dashedFormatter} ${newHourPart[0]}:${newHourPart[1]}`;
  }
}
