export enum PointV2Size {
    ExtraSmaller = 8,
    Smaller = 16,
    Medium = 24,
    Bigger = 32
}

export class PointV2 {
    #title: string;

    #titleUrl: string;

    #subtitle: string;

    #date: Date;

    #dateFinished: Date;

    #time: number;

    #moreDetails: string;

    #status: string;

    #statusColor: string;

    #size: PointV2Size;

    #color: string;   

    #current: boolean;

    #anomaly: string;

    #anomalies: string;

    #anomalyTotal: number;

    #anomalyUrl: string;

    #showing: boolean;

    #type: string;

    #isTransfer: boolean;

    constructor({
        title,
        titleUrl,
        subtitle,
        date,
        dateFinished,
        time,
        moreDetails,
        status,
        statusColor,
        size,
        color,        
        current,
        anomaly,
        anomalies,
        anomalyTotal,
        anomalyUrl,
        showing,
        type,
        isTransfer
    }) {
        this.#title = title;
        this.#titleUrl = titleUrl;
        this.#subtitle = subtitle;
        this.#date = date;
        this.#dateFinished = dateFinished;
        this.#time = time;
        this.#moreDetails = moreDetails;
        this.#status = status;
        this.#size = size;
        this.#color = color;
        this.#statusColor = statusColor;
        this.#current = current;
        this.#anomaly = anomaly;
        this.#anomalies = anomalies;
        this.#anomalyTotal = anomalyTotal;
        this.#anomalyUrl = anomalyUrl;
        this.#showing = showing;
        this.#type = type;
        this.#isTransfer = isTransfer;
    }

    get title(): string {
        return this.#title;
    }

    get titleUrl(): string {
        return this.#titleUrl;
    }

    get subtitle(): string {
        return this.#subtitle;
    }

    get date(): Date {
        return this.#date;
    }    

    get dateFinished(): Date {
        return this.#dateFinished;
    }

    get time(): number {
        return this.#time;
    }

    get moreDetails(): string {
        return this.#moreDetails;
    }

    get status(): string {
        return this.#status;
    }

    get size(): PointV2Size {
        return this.#size;
    }

    get color(): string {
        return this.#color;
    }

    get statusColor(): string {
        return this.#statusColor;
    }

    get current(): boolean {
        return this.#current;
    }

    get anomaly(): string {
        return this.#anomaly;
    }

    get anomalies(): string {
        return this.#anomalies;
    }

    get anomalyTotal(): number {
        return this.#anomalyTotal;
    }

    get anomalyUrl(): string {
        return this.#anomalyUrl;
    }

    get showing(): boolean {
        return this.#showing;
    }

    get type(): string {
        return this.#type;
    }

    get isTransfer(): boolean {
        return this.#isTransfer;
    }

    static from(points: any[]): PointV2[] {
        return points.map((point) => new PointV2(point));
    }
}
