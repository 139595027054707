import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ModalModule } from 'src/app/core/services/modal';
import { IconModule } from 'src/app/components/shared/icon/icon-module';

import { ModalFinishLoadComponent } from './modal-finish-load.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { ButtonModule } from 'src/app/components/button/button.module';
import { SelectModule } from 'src/app/components/select/select.module';
import { SelectSimpleModule } from 'src/app/components/select-simple/select-simple.module';

@NgModule({
  declarations: [ModalFinishLoadComponent],
  imports: [
    CommonModule,
    IconModule,
    ModalModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ReactiveFormsModule,    
    ButtonModule,
    SelectModule
  ],
})
export class ModalFinishLoadModule { }