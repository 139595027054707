export class MyFilter {
    definicaoFiltro: string;
    filtroPadrao: boolean;
    id: string;
    idUsuario: string;
    nomeFiltro: string;
    tela: string;

    constructor(json: MyFilterInterface) {
        this.definicaoFiltro = json.definicaoFiltro;
        this.filtroPadrao = json.filtroPadrao;
        this.id = json.id;
        this.idUsuario = json.idUsuario;
        this.nomeFiltro = json.nomeFiltro;
        this.tela = json.tela;
    }
}

export interface MyFilterInterface {
    definicaoFiltro: string;
    filtroPadrao: boolean;
    id: string;
    idUsuario: string;
    nomeFiltro: string;
    tela: string;
}
