import { ChatMessageOrigin } from './chat-message-origin.model';
import { ChatMessageResponsible } from './chat-message-responsible.model';
import { ChatMessageTypeMessageReply } from './chat-message-type-message-reply.model';

export class ChatMessageTypeMessage {
    #attachment: string;

    #createdAt: Date;

    #readedAt: Date;

    #receivedAt: Date;

    #id: string;

    #readed: boolean;

    #loadNumber: string;

    #origin: ChatMessageOrigin;

    #plate: string;

    #reply: ChatMessageTypeMessageReply;

    #message: string;

    #type: string;

    #topicId: string;

    #responsible: ChatMessageResponsible;

    #loadStatus: string;

    constructor({
        anexo = null,
        dataEnvio = null,
        dataLido = null,
        dataRecebido = null,
        id = null,
        mensagemLida = false,
        numeroViagem,
        origem,
        placa,
        respostaDe = null,
        texto,
        tipo,
        topicoId = null,
        usuario = null,
        statusViagem = null
    }) {
        this.#attachment = anexo;
        this.#createdAt = dataEnvio !== null ? new Date(dataEnvio) : null;
        this.#readedAt = dataLido !== null ? new Date(dataLido) : null;
        this.#receivedAt = dataRecebido !== null ? new Date(dataRecebido) : null;
        this.#id = id;
        this.#readed = mensagemLida;
        this.#loadNumber = numeroViagem;
        this.#origin = origem !== null ? new ChatMessageOrigin(origem) : null;
        this.#plate = placa;
        this.#reply = respostaDe !== null ? new ChatMessageTypeMessageReply(respostaDe) : null;
        this.#message = texto;
        this.#type = tipo;
        this.#topicId = topicoId;
        this.#responsible = usuario !== null ? new ChatMessageResponsible(usuario) : null;
        this.#loadStatus = statusViagem;
    }

    get attachment(): string {
        return this.#attachment;
    }

    get createdAt(): Date {
        return this.#createdAt;
    }

    set createdAt(date: Date) {
        this.#createdAt = date;
    }

    get readedAt(): Date {
        return this.#readedAt;
    }

    get receivedAt(): Date {
        return this.#receivedAt;
    }

    get id(): string {
        return this.#id;
    }

    get readed(): boolean {
        return this.#readed;
    }

    get loadNumber(): string {
        return this.#loadNumber;
    }

    get origin(): ChatMessageOrigin {
        return this.#origin;
    }

    get plate(): string {
        return this.#plate;
    }

    get reply(): ChatMessageTypeMessageReply {
        return this.#reply;
    }

    get message(): string {
        return this.#message;
    }

    set message(message: string) {
        this.#message = message;
    }

    get type(): string {
        return this.#type;
    }

    get topicId(): string {
        return this.#topicId;
    }

    get responsible(): ChatMessageResponsible {
        return this.#responsible;
    }

    get loadStatus(): string {
        return this.#loadStatus;
    }

    static from(messages: any[]): ChatMessageTypeMessage[] {
        return messages.map((message: any) => new ChatMessageTypeMessage(message));
    }

    serialize(): any {
        return {
            texto: this.#message,
            tipo: this.#type,
            numeroViagem: this.#loadNumber,
            placa: this.#plate,
            origem: !this.#origin ? null : this.#origin.serialize(),
            usuario: !this.#responsible ? null : this.#responsible.serialize(),
            dataEnvio: !this.#createdAt ? new Date().toISOString() : this.#createdAt.toISOString(),
            statusViagem: this.loadStatus
        };
    }
}
