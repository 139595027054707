import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Guid } from 'guid-typescript';
import { AuthService } from 'src/app/core/services/auth.service';
import { PerfilPermissaoService } from 'src/app/core/services/perfil-permissao.service';
import { UserFilterScreenMonitoring } from 'src/app/models/user-filter/screen/monitoring.model';
import { UserFilter } from 'src/app/models/user-filter/user-filter.model';
import { ModalService } from '../../../modal.service';
import { ModalWindowMonitoringFilterService, TCurrentFilter } from '../monitoring-filter.service';
import { MessageService } from '../../../../../core/services';
import { LogisticTypeEnum } from 'src/app/core/models/enums/logistic-type.enum';

@Component({
  selector: 'app-modal-window-monitoring-filter-save-filter',
  templateUrl: './save-filter.component.html',
  styleUrls: ['./save-filter.component.scss']
})
export class ModalWindowMonitoringFilterSaveFilterComponent implements OnInit {

  @Input() modalId: string;

  @Input() uuidModalMonitoringFilter: string;

  @Input() filtersResult: any;

  @Input() logistic: string;
 
  public saveFormGroup: FormGroup = null;

  public submitted: boolean = false;

  public messageError: string = '';

  myFilters = this.modalWindowMonitoringFilterService.getSavedFilters();

  constructor(
    private formBuilder: FormBuilder,
    private modalService: ModalService,
    private modalWindowMonitoringFilterService: ModalWindowMonitoringFilterService,
    private authService: AuthService,
    private perfilPermissaoService: PerfilPermissaoService,
    private messageService: MessageService,
  ) { }

  ngOnInit(): void {
    this.saveFormGroup = this.formBuilder.group({
      name: new FormControl('', [Validators.required, Validators.maxLength(39)]),
    });
    this.onChangesForm();
  }
  
  onChangesForm() {
    this.saveFormGroup.valueChanges.subscribe(_ => {
      if (this.saveFormGroup.valid) {
        this.messageError = '';
      }
    });
  }

  handleSaveFilter(): void {

    this.submitted = true;

    if (this.saveFormGroup.invalid) {
      if (this.saveFormGroup.get('name').hasError('required')) {
        this.messageError = 'Nome do filtro deve ser informado';
      }
      if (this.saveFormGroup.get('name').hasError('maxlength')) {
        this.messageError = 'Nome do filtro deve ser menor que 40 caracteres';
      }
      return;
    }

    const name = this.saveFormGroup.controls.name.value.toUpperCase().trim();

    if (!!this.myFilters.find( f => f.name.toUpperCase().trim() === name)) {
      return this.messageService.error(`Filtro já cadastrado para esse usuário`);
    }

    let savedFilter = this.modalWindowMonitoringFilterService.getSavedFilterByName(name);
    
    let logistic = this.logistic || LogisticTypeEnum.COMMODITIES;  
    
    if (!savedFilter) {
      this.authService.userData.subscribe((user) => {
        const role = this.perfilPermissaoService.getCurrentLogisticaRole();

        savedFilter = new UserFilter<UserFilterScreenMonitoring>({
          id: `monitoramento-${this.logistic || role.logistica};${Guid.create().toString()}`,
          idUsuario: user.preferred_username,
          tela: this.logistic ? `monitoramento-${this.logistic}` : `monitoramento-${role.logistica}`,
          definicaoFiltro: JSON.stringify(this.filtersResult),
          filtroPadrao: true,
          nomeFiltro: name
        });
      });      

      this.modalWindowMonitoringFilterService.createSavedFilter(savedFilter, true, logistic);
    } else {
      this.modalWindowMonitoringFilterService.updateSavedFilter(savedFilter, true, logistic);
    }

    this.submitted = false;

    const currentFilter: TCurrentFilter = {
      mode: 'saved',
      filter: savedFilter
    };

    logistic = this.logistic || LogisticTypeEnum.AGRONOMY;

    this.modalWindowMonitoringFilterService.setCurrentFilter(currentFilter, logistic);

    this.modalService.unsetModal(this.modalId);
    this.modalService.unsetModal(this.uuidModalMonitoringFilter);
    this.messageService.success('Filtro salvo com sucesso');
  }

  handleBack(): void {
    this.modalService.unsetModal(this.modalId);
  }
}
