import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ModalWindowMonitoringFilterService } from './monitoring-filter.service';

@Component({
  selector: 'app-modal-window-monitoring-filter',
  templateUrl: './monitoring-filter.component.html',
  styleUrls: ['./monitoring-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalWindowMonitoringFilterComponent implements OnInit {

  @Input() public modalId: string;

  @Input() public myFiltersModalId: string;

  @Input() public logistic: string;

  @Input() public savedFilterId: string;

  public title = 'Filtros';

  subTitle = 'Nenhum filtro aplicado';

  constructor(
    private modalWindowMonitoringFilterService: ModalWindowMonitoringFilterService,
    private cdref: ChangeDetectorRef
  ) { }

  public ngOnInit(): void {
    if (!this.logistic) {
      console.error('Não foi enviado a logística');
      return;
    }

    if (this.savedFilterId) {
      const savedFilter = this.modalWindowMonitoringFilterService.getSavedFilterById(this.savedFilterId);
      this.title = `Meus filtros (${savedFilter.name})`;
      this.cdref.detectChanges();
    }
  }

  setSubtitle(count: number): void {
    if (!count) {
      this.subTitle = 'Nenhum filtro aplicado';
    } else {
      this.subTitle = count === 1 ? `${count} filtro aplicado` :  `${count} filtros aplicado`;
    }

    this.cdref.detectChanges();
  }
}
