import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'kilogramformat'
})
export class KilogramFormatPipe implements PipeTransform {
  transform(value: number): string {
    if (value === null || value === undefined) {
      return;
    }

    const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'unit',
      // @ts-ignore
      unit: 'kilogram'
    });

    return formatter.format(value);
  }
}
