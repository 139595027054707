import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Shipping } from 'src/app/models/shipping.model';
import { StepDetailDistributionClientCharge } from 'src/app/models/step-detail/distribution/client/charge.model';
import { StepDetailDistributionClient } from 'src/app/models/step-detail/distribution/client/client.model';
import { StepDetailDistributionClientCommercial } from 'src/app/models/step-detail/distribution/client/commercial.model';
import { StepDetailDistributionClientInfo } from 'src/app/models/step-detail/distribution/client/info.model';
import { StepDetailDistributionClientTracking } from 'src/app/models/step-detail/distribution/client/tracking.model';
import { RastroViagem } from '../models/rastro-viagem.model';
import { BaseService } from './base.service';
import { MessageService } from './message.service';
import { Product } from 'src/app/models/product.model';
import { Anomaly } from 'src/app/models/anomaly.model';
import { Address } from 'src/app/models/address.model';
import { StepDetailDistributionFinish } from 'src/app/models/step-detail/distribution/finish/finish.model';
import { StepDetailCommonProgrammed } from 'src/app/models/step-detail/common/programmed/programmed.model';
import { StepDetailDistributionStart } from 'src/app/models/step-detail/distribution/start/start.model';
import { StepDetailPrimaryInTransitUnloading } from 'src/app/models/step-detail/primary/in-transit-unloading.model';
import { PerfilPermissaoService } from './perfil-permissao.service';

export type TLoadStep = {
    numeroViagem: string;
    logistica: string;
    codigoParada?: string;
    tipoEtapaViagemEnum: string;
    idParada: string;
};

@Injectable()
export class ViagemService extends BaseService<any> {

    #detailStepUrl = 'integration/etapaviagem/detalheetapaviagem';

    constructor(_http: HttpClient, messageService: MessageService, perfilPermissaoService: PerfilPermissaoService) {
        super(_http, '', messageService, perfilPermissaoService);
    }

    getDetalheEtapaViagem(infoViagem: TLoadStep): Observable<any> {
        return this.http.get<any>(`${this.url}${this.#detailStepUrl}`, { params: this.mountParams(infoViagem) })
            .pipe(map((response: any) => response as any));
    }

    getViagemPossuiParadasPendentes(idParada: string): Observable<any> {
        return this.http.get<any>(`${this.url}integration/etapaviagem/possuiparadaspendentes/${idParada}`);
    }

    postFinalizarEtapa(etapaViagem){
        return this.http.post<any>(`${this.url}integration/etapaviagem/finalizaretapa`,etapaViagem)
        .pipe(map((response: any) => response as any));
    }

    getRastroViagem (logistica :string , numeroViagem:string):  Observable<RastroViagem[] >  {

        let params = new HttpParams();
        if (logistica) {
            params = params.append('logistica', logistica);
        }
        if (numeroViagem) {
            params = params.append('numeroViagem', numeroViagem);
        }
        return this.http.get<RastroViagem[]>(`${this.url}integration/etapaviagem/timelineetapaviagem`, { params: params })
        .pipe(map((response: RastroViagem[]) => response as RastroViagem[]));

    }

    getDetailStepDistributionClient(loadData: TLoadStep): Observable<StepDetailDistributionClient> {
        return this.http
            .get<StepDetailDistributionClient>(`${this.url}${this.#detailStepUrl}`, { params: this.mountParams(loadData) })
            .pipe(map((response: any) => {
                if (!response) {
                    console.error('Não houve resposta da etapa viagem - cliente');
                    return null;
                }

                try {
                    /** Anomalias */
                    response.anomalias = response.anomalias.map((anomalia: any) => new Anomaly(anomalia));

                    /** Cliente */
                    response.cliente = new StepDetailDistributionClientInfo(
                        response.cliente.codigo,
                        response.cliente.cnpj,
                        response.cliente.nome,
                        new Address(response.cliente.endereco),
                        response.cliente.horarioAtendimentoInicio ? new Date(response.cliente.horarioAtendimentoInicio) : null,
                        response.cliente.horarioAtendimentoFim ? new Date(response.cliente.horarioAtendimentoFim) : null,
                        response.cliente.contatoNome,
                        response.cliente.contatoTelefone
                    );

                    /** Comercial */
                    response.comercial = new StepDetailDistributionClientCommercial(
                        response.comercial.vendedorNome,
                        response.comercial.vendedorTelefone,
                        response.comercial.supervisorNome,
                        response.comercial.supervisorTelefone,
                        response.comercial.gerenteNome,
                        response.comercial.gerenteTelefone
                    );

                    /** Remessas Tracking */
                    response.tracking.remessas = response.tracking.remessas.map((remessa: any) => new Shipping(remessa));

                    /** Tracking */
                    response.tracking = new StepDetailDistributionClientTracking(
                        response.tracking.tipoOperacao,
                        response.tracking.agenda,
                        response.tracking.statusEntrega,
                        response.tracking.chegadaEsperada,
                        response.tracking.chegadaRealizada,
                        response.tracking.saidaEsperada,
                        response.tracking.saidaRealizada,
                        response.tracking.distanciaEsperada,
                        response.tracking.distanciaRealizada,
                        response.tracking.tempoEspera,
                        response.tracking.tempoDescarga,
                        response.tracking.tempoTotal,
                        response.tracking.tempoTotalPrevisto,
                        response.tracking.veiculoLatitude,
                        response.tracking.veiculoLongitude,
                        response.tracking.observacaoMotorista,
                        response.tracking.remessas,
                        response.tracking.viagensRelacionadas,
                        response.tracking.pedidos,
                        response.tracking.pesoPrevisto,
                        response.tracking.pesoRealizado,
                        response.tracking.sequenciaPrevista,
                        response.tracking.sequenciaRealizada,
                        response.tracking.valorPrevisto,
                        response.tracking.valorRealizado,
                        response.tracking.dispersao,
                        response.tracking.inicioDescarga,
                        response.tracking.fimEntrega,
                        response.tracking.devolucoes,
                        response.tracking.paradaDentroDoRaio,
                        response.tracking.raioKm,
                    );

                    /** Carga */
                    response.carga = response.cargas.map((carga: any) => {

                        /** Produtos */
                        carga.produtos = carga.produtos.map((produto: any) => new Product(produto));

                        /** Remessas Carga */
                        carga.remessas = carga.remessas.map((remessa: any) => new Shipping(remessa));

                        return new StepDetailDistributionClientCharge(
                            carga.numeroViagem,
                            carga.notaFiscal,
                            carga.notaFiscalSerie,
                            carga.emissaoData,
                            carga.remessas,
                            carga.pedidos,
                            carga.centroOrigem,
                            carga.pesoBruto,
                            carga.pesoLiquido,
                            carga.notaFiscalValor,
                            carga.valorItensNotaFiscal,
                            carga.volume,
                            carga.condicaoPagamento,
                            carga.produtos
                        );
                    });

                    const detailStepClient = new StepDetailDistributionClient(
                        response.anomalias,
                        response.cliente,
                        response.comercial,
                        response.tracking,
                        response.carga
                    );

                    return detailStepClient;

                } catch (e: any) {
                    console.error('Erro ao compilar os dados do endpoint de detalhe etapa viagem - cliente', e.message);
                    console.error('Retorno', response);
                    return null;
                }
            }));
    }

    getDetailStepDistributionStart(loadData: TLoadStep): Observable<StepDetailDistributionStart> {
        return this.http
            .get<StepDetailDistributionStart>(`${this.url}${this.#detailStepUrl}`, { params: this.mountParams(loadData) })
            .pipe(map((response: any) => {
                if (!response) {
                    console.error('Não houve resposta da etapa viagem - início da viagem');
                    return null;
                }

                try {
                    return new StepDetailDistributionStart(response);
                } catch (e: any) {
                    console.error('Erro ao compilar os dados do endpoint de detalhe etapa viagem - início da viagem', e.message);
                    console.error('Retorno', response);
                    return null;
                }
            }));
    }

    getDetailStepDistributionFinish(loadData: TLoadStep): Observable<StepDetailDistributionFinish> {
        return this.http
            .get<StepDetailDistributionFinish>(`${this.url}${this.#detailStepUrl}`, { params: this.mountParams(loadData) })
            .pipe(map((response: any) => {
                if (!response) {
                    console.error('Não houve resposta da etapa viagem - término da viagem');
                    return null;
                }

                try {
                    return new StepDetailDistributionFinish(response);
                } catch (e: any) {
                    console.error('Erro ao compilar os dados do endpoint de detalhe etapa viagem - término da viagem', e.message);
                    console.error('Retorno', response);
                    return null;
                }
            }));
    }

    /**
     * Busca a etapa da viagem EM TRÂNSITO DESCARGA
     *
     * @logistic PRIMÁRIA
     * @param loadData
     * @returns StepDetailPrimaryInTransitUnloading
     */
    getDetailStepPrimaryInTransitUnloading(loadData: TLoadStep): Observable<StepDetailPrimaryInTransitUnloading> {
        return this.http
            .get<StepDetailPrimaryInTransitUnloading>(`${this.url}${this.#detailStepUrl}`, { params: this.mountParams(loadData) })
            .pipe(map((response: any) => {
                if (!response) {
                    console.error('Não houve resposta da etapa viagem - em trânsito descarga');
                    return null;
                }

                try {
                    return new StepDetailPrimaryInTransitUnloading(response);
                } catch (e: any) {
                    console.error('Erro ao compilar os dados do endpoint de detalhe etapa viagem - em trânsito descarga', e.message);
                    console.error('Retorno', response);
                    return null;
                }
            }));
    }

    getDetailStepCommonProgrammed(loadData: TLoadStep): Observable<StepDetailCommonProgrammed> {
        return this.http
            .get<StepDetailCommonProgrammed>(`${this.url}${this.#detailStepUrl}`, { params: this.mountParams(loadData) })
            .pipe(map((response: any) => {
                if (!response) {
                    console.error('Não houve resposta da etapa viagem - parada programada (justificada)');
                    return null;
                }

                try {
                    return new StepDetailCommonProgrammed(response);
                } catch (e: any) {
                    const error = 'Erro ao compilar os dados do endpoint de detalhe etapa viagem - parada programada (justificada)';
                    console.error(error, e.message);
                    console.error('Retorno', response);
                    return null;
                }
            }));
    }


  getDetailStepsAgronomy(loadData: TLoadStep): Observable<any> {
    return this.http
      .get<any>(`${this.url}${this.#detailStepUrl}`, { params: this.mountParams(loadData) })
      .pipe(map((response: any) => {
        if (!response) {
          console.error('Não houve resposta da etapa viagem - início da viagem');
          return null;
        }

        try {
          return response;
        } catch (e: any) {
          console.error('Erro ao compilar os dados do endpoint de detalhe etapa viagem - início da viagem', e.message);
          console.error('Retorno', response);
          return null;
        }
      }));
  }

  getDetailsStepsCommodities(loadData: TLoadStep): Observable<any> {
    return this.http
      .get<any>(`${this.url}${this.#detailStepUrl}`, { params: this.mountParams(loadData) })
      .pipe(map((response: any) => {
        if (!response) {
          console.error('Não houve resposta da etapa viagem - início da viagem');
          return null;
        }

        try {
          return response;
        } catch (e: any) {
          console.error('Erro ao compilar os dados do endpoint de detalhe etapa viagem - início da viagem', e.message);
          console.error('Retorno', response);
          return null;
        }
      }));
  }

    private mountParams(loadData: TLoadStep): HttpParams {
        let params = new HttpParams();

        if (loadData?.numeroViagem) {
            params = params.append('numeroViagem', loadData.numeroViagem);
        }

        if (loadData?.logistica) {
            params = params.append('logistica', loadData.logistica);
        }

        if (loadData?.codigoParada) {
            params = params.append('codigoParada', loadData.codigoParada);
        }

        if (loadData?.tipoEtapaViagemEnum && Number(loadData.tipoEtapaViagemEnum) >= 0) {
            params = params.append('tipoEtapaViagemEnum', loadData.tipoEtapaViagemEnum.toString());
        }

        if (loadData?.idParada) {
            params = params.append('idParada', loadData.idParada);
        }

        return params;
    }
}
