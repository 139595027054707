import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { ChatBusinessService } from '../chat-business.service';
import { ChatMessageTypeMessage } from '../chat-message-type-message.model';
import { ChatObservableService } from '../chat-observable.service';

@Component({
  selector: 'app-chat-counter-unread',
  templateUrl: './counter-unread.component.html',
  styleUrls: ['./counter-unread.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatCounterUnreadComponent implements AfterViewInit {

  @Input() public loadNumber: string = null;

  @Input() public plate: string = null;

  @Input() public isMobile = false;

  @Input() public versionNumericBadge = false;

  @Input() public versionWithoutNumericBadge = false;

  public totalUnreadMessage = 0;

  public currentUser: any;

  public loadNumberUri: string;

  public plateUri: string;

  private loads: string[] = [];

  constructor(
    private cdref: ChangeDetectorRef,
    private chatBusinessService: ChatBusinessService,
    private chatObservableService: ChatObservableService
  ) { }

  public ngAfterViewInit(): void {
    this.normalizeVersion();

    this.initializeTotalUnreadMessageFromStorage();

    this.cdref.detectChanges();

    this.subscribeSubjectNewMessage();

    this.subscribeSubjectMarkAllRead();
  }

  private subscribeSubjectNewMessage(): void {
    this.chatObservableService.getMessage().subscribe((newMessageOrMessageList: ChatMessageTypeMessage | ChatMessageTypeMessage[]) => {
      if (newMessageOrMessageList && Array.isArray(newMessageOrMessageList) && newMessageOrMessageList.length > 0) {

        let loadShowing = null;

        if (this.isMobile === false) {
          loadShowing = this.chatBusinessService.getLoadViewing();
        }

        newMessageOrMessageList = newMessageOrMessageList
          .filter((message: ChatMessageTypeMessage) => !this.chatBusinessService.isOwnerMessage(message)
            && !this.loads.includes(message.id)
            && loadShowing?.loadNumber !== message.loadNumber
            && loadShowing?.plate !== message.plate
          );

        this.totalUnreadMessage += newMessageOrMessageList.length;

        this.loads = [...this.loads, ...newMessageOrMessageList.map((message: ChatMessageTypeMessage) => message.id)];

        this.cdref.detectChanges();

      } else if (newMessageOrMessageList) {
        const message: any = newMessageOrMessageList;

        this.totalUnreadMessage += !this.chatBusinessService.isOwnerMessage(message) && !message.readed ? 1 : 0;

        this.cdref.detectChanges();
      }
    });
  }

  private subscribeSubjectMarkAllRead(): void {
    this.chatObservableService.getMarkAllRead().subscribe(({ chatIdsUnreaded }) => {
      const logistic = this.chatBusinessService.getLogistic(false);

      if (!chatIdsUnreaded) {
        this.chatBusinessService.unsetMessageUnreadIds(logistic, this.loads);
        this.loads = [];
        this.totalUnreadMessage = 0;

        this.cdref.detectChanges();
        return;
      }

      this.loads = this.loads.filter((loadId: string) => chatIdsUnreaded.includes(loadId));

      const newTotalUnread = this.totalUnreadMessage - chatIdsUnreaded.length;

      this.totalUnreadMessage = newTotalUnread < 0 ? 0 : newTotalUnread;

      this.chatBusinessService.unsetMessageUnreadIds(logistic, chatIdsUnreaded);

      this.cdref.detectChanges();
    });
  }

  private normalizeVersion(): void {
    if (this.versionNumericBadge && this.versionWithoutNumericBadge) {
      this.versionWithoutNumericBadge = false;
    } else if (!this.versionNumericBadge && !this.versionWithoutNumericBadge) {
      this.versionNumericBadge = true;
    }
  }

  private initializeTotalUnreadMessageFromStorage(): void {
    const logistic = this.chatBusinessService.getLogistic(this.isMobile);

    const messageUnreadIdsList = this.chatBusinessService.getMessageUnreadIds(logistic);

    if (!messageUnreadIdsList) {
      return;
    }

    this.loads = messageUnreadIdsList.map((message: ChatMessageTypeMessage) => message.id);

    this.totalUnreadMessage = messageUnreadIdsList.length;
  }
}
