import { Injectable } from '@angular/core';
import { ChatMessageTypeMessage } from './chat-message-type-message.model';
import { ChatSubscriptions } from './chat-subscriptions.model';

@Injectable({
    providedIn: 'root'
})
export class ChatBusinessService {

    #currentUser: any = null;

    #logistic: string;

    /**
     * Busca o usuário logado
     * @returns any
     */
    public getCurrentUser(): any {
        if (this.#currentUser) {
            return this.#currentUser;
        }

        const currentUser = localStorage.getItem('currentUser');

        this.#currentUser = !currentUser ? null : JSON.parse(currentUser);

        return this.#currentUser;
    }

    /**
     * Retorna a logística atual
     * @returns any
     */
    public getLogistic(isMobile: boolean): any {
        if (this.#logistic) {
            return this.#logistic;
        }

        let logistic = null;

        try {
            switch (isMobile) {
                case true:
                    logistic = localStorage.getItem('currentRota');
                    logistic = !logistic ? null : JSON.parse(logistic)[0].logistica;
                    break;
                case false:
                    logistic = localStorage.getItem('CURRENT_ROLE');
                    logistic = !logistic ? null : JSON.parse(logistic).logistica;
                    break;
            }
        } catch(e) {
            console.error('[Chat.Business.Service]', e);
            logistic = null;
        }

        if (!logistic) {
            console.error('[Chat.Business.Service] Não foi possível identificar a logística');
            return null;
        }

        this.#logistic = logistic;

        return this.#logistic;
    }

    /**
     * Valida se o usuário logado é o dono da mensagem
     *
     * @param message ChatMessageTypeMessage
     * @returns boolean
     */
    public isOwnerMessage(message: ChatMessageTypeMessage): boolean {
        if (!message) {
            return false;
        }

        const currentUser = this.getCurrentUser();

        if (!currentUser) {
            return false;
        }

        return message && message.responsible && message.responsible.login === currentUser.preferred_username;
    }

    /**
     * Busca a última mensagem lida
     *
     * @param logistic string
     *
     * @return Date
     */
    public getLastMessageReaded(logistic: string): Date {
        try {
            const lastMessage = localStorage.getItem(`chats-lastmessagereaded-monitor-${logistic}`);

            return new Date(lastMessage);
        } catch (e) {
            console.error('[Chat.Business.Service.getLastMessageReaded]', e);
            return null;
        }
    }

    /**
     * Define uma nova última mensagem lida
     *
     * @param logistic string
     * @param date Date
     */
    public setLastMessageReaded(logistic: string, date: Date): void {
        try {
            this.setHistoryLastMessageReaded(logistic);

            localStorage.setItem(`chats-lastmessagereaded-monitor-${logistic}`, date.toISOString());
        } catch (e) {
            console.error('[Chat.Business.Service.setLastMessageReaded]', e);
        }
    }

    /**
     * Apaga a última mensagem lida
     *
     * @param logistic string
     */
    public unsetLastMessageReaded(logistic: string): void {
        try {
            localStorage.removeItem(`chats-lastmessagereaded-monitor-${logistic}`);
        } catch (e) {
            console.error('[Chat.Business.Service.unsetLastMessageReaded]', e);
        }
    }

    /**
     * Define o histórico da última mensagem lida
     *
     * @param logistic string
     */
     public setHistoryLastMessageReaded(logistic: string): void {
        try {
            const getLastMessageReaded = this.getLastMessageReaded(logistic);

            localStorage.setItem(`chats-lastmessagereaded-history-monitor-${logistic}`, getLastMessageReaded.toISOString());
        } catch (e) {
            console.error('[Chat.Business.Service.setHistoryLastMessageReaded]', e);
        }
    }

    /**
     * Busca o último histórico mensagem lida
     *
     * @param logistic string
     *
     * @return Date
     */
     public getHistoryLastMessageReaded(logistic: string): Date {
        try {
            const lastMessage = localStorage.getItem(`chats-lastmessagereaded-history-monitor-${logistic}`);

            return new Date(lastMessage);
        } catch (e) {
            console.error('[Chat.Business.Service.getLastMessageReaded]', e);
            return null;
        }
    }

    /**
     * Seta uma listagem de mensagens não lidas
     *
     * @param response any
     * @param logistic string
     */
    public setMessageUnreadIds(response: any, logistic: string): void {
        try {
            localStorage.setItem(`chats-messageunreadedids-monitor-${logistic}`, JSON.stringify(response));
        } catch (e) {
            console.error('[Chat.Business.Service.setMessageUnreadIds]', e);
        }
    }

    /**
     * Busca os ids de mensagens não lidas
     *
     * @param logistic string
     * @returns any[]
     */
    public getMessageUnreadIds(logistic: string): any[] {
        try {
            const messageUnreads: any = localStorage.getItem(`chats-messageunreadedids-monitor-${logistic}`);

            return !messageUnreads ? null : JSON.parse(messageUnreads);
        } catch (e) {
            console.error('[Chat.Business.Service.getMessageUnreadIds]', e);
            return [];
        }
    }

    /**
     * Apaga um ou mais elementos das mensagens não lidas
     *
     * @param logistic string
     */
    public unsetMessageUnreadIds(logistic: string, ids: string[] = null): void {
        try {
            if (ids === null) {
                localStorage.removeItem(`chats-messageunreadedids-monitor-${logistic}`);
            } else {
                let messageUnreadIdsList = this.getMessageUnreadIds(logistic);

                if (!messageUnreadIdsList) {
                    return;
                }

                messageUnreadIdsList = messageUnreadIdsList.filter((messageUnread: any) => !ids.includes(messageUnread.id));

                localStorage.removeItem(`chats-messageunreadedids-monitor-${logistic}`);

                if (messageUnreadIdsList.length > 0) {
                    this.setMessageUnreadIds(messageUnreadIdsList, logistic);
                }
            }
        } catch (e) {
            console.error('[Chat.Business.Service.unsetMessageUnreadIds]', e);
        }
    }

    /**
     * Seta novas inscrições de viagens e placas
     *
     * @param result any
     */
    public setSubscriptions(loads: any[]): void {
        try {
            const logistic = this.getLogistic(false);
            localStorage.setItem(`chats-subscriptions-${logistic}`, JSON.stringify(loads));
        } catch (e) {
            console.error('[Chat.Business.Service.setSubscriptions]', e);
        }
    }

    /**
     * Busca as inscrições em viagens e placas
     *
     * @return any[]
     */
    public getSubscriptions(): any[] {
        try {
            const logistic = this.getLogistic(false);
            const subscriptions: any = localStorage.getItem(`chats-subscriptions-${logistic}`);

            return !subscriptions ? null : JSON.parse(subscriptions);
        } catch (e) {
            console.error('[Chat.Business.Service.getSubscriptions]', e);
            return null;
        }
    }

    /**
     * Apaga as inscrições de viagens e placas
     */
    public unsetSubscriptions(): void {
        try {
            const logistic = this.getLogistic(false);
            localStorage.removeItem(`chats-subscriptions-${logistic}`);
        } catch (e) {
            console.error('[Chat.Business.Service.unsetSubscriptions]', e);
        }
    }

    /**
     * Valida se possui inscrições em placas e viagens
     *
     * @returns boolean
     */
    public hasSubcriptions(): boolean {
        try {
            const logistic = this.getLogistic(false);
            const subscriptions: any = localStorage.getItem(`chats-subscriptions-${logistic}`);

            return !!subscriptions;
        } catch (e) {
            console.error('[Chat.Business.Service.hasSubcriptions]', e);
            return null;
        }
    }

    public setLoadViewing(loadNumber: string, plate: string): void {
        try {
            if (loadNumber === null || plate === null) {
                return;
            }

            localStorage.setItem('chats-loadviewing', JSON.stringify({ loadNumber, plate }));
        } catch (e) {
            console.error('[Chat.Business.Service.setLoadViewing]', e);
        }
    }

    public getLoadViewing(): any {
        try {
            const loadViewing = localStorage.getItem('chats-loadviewing');

            return !loadViewing ? null : JSON.parse(loadViewing);
        } catch (e) {
            console.error('[Chat.Business.Service.setLoadViewing]', e);
        }
    }

    public unsetLoadViewing(): void {
        try {
            localStorage.removeItem('chats-loadviewing');
        } catch (e) {
            console.error('[Chat.Business.Service.unsetLoadViewing]', e);
        }
    }
}
