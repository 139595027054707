<section
    *ngIf="totalUnreadMessage > 0"
    class="chat-counter-unread
        {{isMobile ? 'chat-counter-unread--is-mobile' : 'chat-counter-unread--is-web'}}
        {{versionWithoutNumericBadge ? 'chat-counter-unread--empty' : ''}}
    "
>
    <ng-container *ngIf="versionNumericBadge">
        <span class="chat-counter-unread__unread">
            {{totalUnreadMessage >= 9 ? '9+' : totalUnreadMessage}}
        </span>
    </ng-container>
</section>
