import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'timeformat'
})
export class TimeFormatPipe implements PipeTransform {
    transform(value: any, unit: string = 'seconds', showSeconds: boolean = false, nullDefault = ''): string {
        if (!value && value !== 0) {
            return nullDefault;
        }

        if (value instanceof Date) {
            const options: Intl.DateTimeFormatOptions = {
                hour: '2-digit',
                minute: '2-digit'
            };

            if (showSeconds) {
                options.second = '2-digit';
            }

            const formatter = new Intl.DateTimeFormat('pt-BR', options);

            return formatter.format(value);
        }

        if (unit === 'hour') {
            return this.unitHour(value);
        }

        if (unit === 'minute') {
            return this.unitMinute(value);
        }

        /**
         * Considerado a quantidade em segundos
         * HH:MM:ss
         */
        let seconds = parseInt(value, 10);

        const hours = Math.floor(seconds / 3600);

        const minutes = Math.floor((seconds - (hours * 3600)) / 60);

        seconds = seconds - (hours * 3600) - (minutes * 60);

        const hourFormatted = this.str0(hours);

        const minutesFormatted = this.str0(minutes);

        const secondsFormatted = this.str0(seconds);

        return showSeconds
            ? `${hourFormatted}:${minutesFormatted}:${secondsFormatted}`
            : `${hourFormatted}:${minutesFormatted}`;
    }

    private str0(value: number): string {
        const valueStr = value.toString();

        if (valueStr.length == 2) {
            return valueStr;
        }

        if (valueStr.length >= 2) {
            return valueStr.slice(0, 2);
        }

        return valueStr.length === 0
            ? '00'
            : '0' + valueStr;
    }

    private unitHour(value: number): string {
        const horas = Math.floor(value);
        const min100 = value - horas;
        const minutos = Math.trunc(min100 * 60);

        const hourFormatted = this.str0(horas);
        const minutesFormatted = this.str0(minutos);

        return `${hourFormatted}:${minutesFormatted}`;
    }

    private unitMinute(value: any): string {
        /**
         * Considerado a quantidade em minutos
         * HH:MM:ss
        */
        let total = parseInt(value, 10);

        const hours = Math.floor(total / 60);
        const minutes = Math.floor(total % 60);

        const hourFormatted = this.str0(hours);
        const minutesFormatted = this.str0(minutes);

        return `${hourFormatted}:${minutesFormatted}`;
    }
}
