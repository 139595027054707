import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {OverlayModule} from '@angular/cdk/overlay';
import {AwesomeTooltipComponent} from './tooltip.component';
import {AwesomeTooltipDirective} from './tooltip.directive';

@NgModule({
  declarations: [
    AwesomeTooltipComponent,
    AwesomeTooltipDirective,
  ],
  exports: [
    AwesomeTooltipComponent,
    AwesomeTooltipDirective,
  ],
  imports: [
    CommonModule,
    OverlayModule,
  ],
  entryComponents: [AwesomeTooltipComponent],
})
export class AwesomeTooltipModule { }
