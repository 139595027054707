<div class="modal-my-filters">
  <div class="modal-my-filters__content">
    <h1>{{ "FILTER.MY_FILTERS" | translate }}</h1>
    <h2>{{ "FILTER.SELECT_ONE_OF_FILTERS_DOWN" | translate }}</h2>

    <div class="modal-my-filters__filters">
      <form [formGroup]="form">
        <mat-radio-group formControlName="options">
          <div *ngFor="let f of listFilters; let i = index" class="modal-my-filters__filters__item">
            <mat-radio-button [value]="i">{{ f.nomeFiltro }}</mat-radio-button>
            <div class="modal-my-filters__filters__item__buttons">
              <app-button text="{{'FILTER.EDIT_FILTER' | translate}}" (clicked)="editFilter(i)"></app-button>
              <app-button text="{{'FILTER.DELETE_FILTER' | translate}}" (clicked)="deleteFilter(i)"></app-button>
            </div>
          </div>
        </mat-radio-group>
      </form>
    </div>
  </div>

  <div class="modal-my-filters__footer">
    <!-- <app-button (onClick)="applyFilter()">
      <p class="modal-my-filters__footer__text-button">
        {{ "filter.apply_filter" | translate }}
      </p>
    </app-button> -->

    <app-button text="{{'FILTER.APPLY_FILTER' | translate}}" (clicked)="applyFilter()"></app-button>
  </div>
</div>