import { UserFilterScreenMonitoring } from "src/app/models/user-filter/screen/monitoring.model";
import { UserFilter } from "src/app/models/user-filter/user-filter.model";
import { ModalWindowMonitoringFilterService, TCurrentFilter } from "./monitoring-filter.service";

export abstract class MonitoringFilter {

    protected savedFilterId: string;

    protected savedFilter: UserFilter<UserFilterScreenMonitoring>;

    protected currentFilter: TCurrentFilter;

    constructor(
        protected modalWindowMonitoringFilterService: ModalWindowMonitoringFilterService
    ) { }

    protected getFilter(savedFilterId: string): UserFilterScreenMonitoring {
        if (savedFilterId) {
            this.savedFilter = this.modalWindowMonitoringFilterService.getSavedFilterById(savedFilterId);
            return this.savedFilter.parameters;
        }
        this.currentFilter = this.modalWindowMonitoringFilterService.getCurrentFilter();
        return this.currentFilter?.mode === 'filtered'
            ? this.currentFilter?.filter?.parameters
            : this.savedFilter?.parameters;
    }


    protected getFakeUserFilter(screen: string, parameters: any): UserFilter<UserFilterScreenMonitoring> {
        return new UserFilter<UserFilterScreenMonitoring>({
            id: null,
            idUsuario: null,
            nomeFiltro: null,
            tela: screen,
            definicaoFiltro: parameters,
            filtroPadrao: false
        });
    }
}