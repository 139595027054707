import { TypeOperation } from 'src/app/core/models/enums/type-operation.enum';
import { Anomaly } from '../anomaly.model';

export class SynopticMapPoint<T> {
    #id: string;

    #loadNumber: string;

    #order: number;

    #sequence: number;

    #description: string;

    #completed: boolean;

    #type: string;

    #classification: number;

    #name: string;

    #unloadingNoteDate: Date;

    #unloadingNoteDateFinished: Date;

    #expectedUnloadingNoteDate: Date;

    #expectedUnloadingNoteDateFinished: Date;

    #expectedUnloadingNoteDateETA: Date;

    #expectedArrivalDate: Date;

    #acomplishedArrivalDate: Date;

    #customerCode: string;

    #code: string;

    #typePoint: string;

    #anomalies: Anomaly[] = [];

    #threeCurrent = false;

    #threeFinalizedForced = false;

    #threeFinalizedSuccess = false;

    #typeOperation: string;

    #status: string;

    #deliveryType: boolean;

    #weightForessen: number;

    #weightAccomplished: number;

    #totalTimeForessen: number;

    #totalTimeAcomplished: number;

    #isTransfer: boolean;

    constructor({
        id,
        ordem,
        sequencia,
        descricao,
        concluido,
        tipoParada,
        classificacao,
        nome,
        dataHora,
        dataHoraFinalizacao,
        dataHoraInicio,
        dataHoraFim,
        dataHoraPrevisaoChegada,
        dataHoraRealizadoChegada,
        dataPrevisaoChegadaETA,
        codigoCliente,
        codigoParada,
        tipoPonto,
        anomalias,
        numeroViagem,
        tipoOperacao,
        status,
        deliveryType,
        pesoPrevistoEntrega,
        pesoRealizadoEntrega,
        tempoTotalPrevistoEntrega,
        tempoTotalRealizadoEntrega,
        tempoTotalRealizado,
        isBaldeado
    }) {
        this.#id = id;
        this.#order = ordem;
        this.#sequence = sequencia;
        this.#description = descricao;
        this.#completed = concluido;
        this.#type = tipoParada;
        this.#classification = classificacao;
        this.#name = nome;
        this.#unloadingNoteDate = dataHora;
        this.#unloadingNoteDateFinished = dataHoraFinalizacao;
        this.#expectedUnloadingNoteDate = dataHoraInicio;
        this.#expectedUnloadingNoteDateFinished = dataHoraFim;
        this.#expectedUnloadingNoteDateETA = dataPrevisaoChegadaETA;
        this.#acomplishedArrivalDate = dataHoraRealizadoChegada;
        this.#expectedArrivalDate = dataHoraPrevisaoChegada;
        this.#customerCode = codigoCliente;
        this.#code = codigoParada;
        this.#typePoint = tipoPonto;
        this.#anomalies = anomalias;
        this.#loadNumber = numeroViagem;
        this.#typeOperation = tipoOperacao;
        this.#status = status;
        this.#deliveryType = deliveryType
        this.#weightForessen = pesoPrevistoEntrega;
        this.#weightAccomplished = pesoRealizadoEntrega;
        this.#totalTimeForessen = tempoTotalPrevistoEntrega;
        this.#totalTimeAcomplished = tempoTotalRealizadoEntrega || tempoTotalRealizado;
        this.#isTransfer = isBaldeado
    }

    get id(): string {
        return this.#id;
    }

    set id(id: string) {
        this.#id = id;
    }

    get loadNumber(): string {
        return this.#loadNumber;
    }

    get order(): number {
        return this.#order;
    }

    set order(order: number) {
        this.#order = order;
    }

    get sequence(): number {
        return this.#sequence;
    }

    set sequence(sequence: number) {
        this.#sequence = sequence;
    }

    get description(): string {
        return this.#description;
    }

    get completed(): boolean {
        return this.#completed;
    }

    set completed(completed: boolean) {
        this.#completed = completed;
    }

    get type(): string {
        return this.#type;
    }

    set type(type: string) {
        this.#type = type;
    }

    get classification(): number {
        return this.#classification;
    }

    get name(): string {
        return this.#name;
    }

    get unloadingNoteDate(): Date {
        return this.#unloadingNoteDate;
    }

    set unloadingNoteDate(date: Date) {
        this.#unloadingNoteDate = date;
    }

    get unloadingNoteDateFinished(): Date {
        return this.#unloadingNoteDateFinished;
    }

    set unloadingNoteDateFinished(date: Date) {
        this.#unloadingNoteDateFinished = date;
    }

    get expectedUnloadingNoteDate(): Date {
        return this.#expectedUnloadingNoteDate;
    }

    get expectedUnloadingNoteDateFinished(): Date {
        return this.#expectedUnloadingNoteDateFinished;
    }

    get expectedUnloadingNoteDateETA(): Date {
        return this.#expectedUnloadingNoteDateETA;
    }

    get expectedArrivalDate(): Date {
        return this.#expectedArrivalDate;
    }

    get acomplishedArrivalDate(): Date {
        return this.#acomplishedArrivalDate;
    }

    get customerCode(): string {
        return this.#customerCode;
    }

    get code(): string {
        return this.#code;
    }

    get typePoint(): string {
        return this.#typePoint;
    }

    get anomalies(): Anomaly[] {
        return this.#anomalies;
    }

    set anomalies(anomalies: Anomaly[]) {
        this.#anomalies = anomalies;
    }

    get title(): string {
        switch (this.type) {
            case 'inicio_rota':
                return 'Início da viagem';
            case 'fim_rota':
                return 'Término da viagem';
            case 'in_transit':
                return 'Em trânsito';
            case 'origem':
                return 'Coleta';
            case 'entrega':
                return 'Entrega';
            case 'retorno_incubatório':
                return 'Incubatório';
            case 'retorno_fabrica':
                return 'Fábrica';
            case 'FRIGORIFICO':
            case 'retorno_frigorífico':
                return 'Frigorífico';
            case 'transito_carregamento':
                return 'Em trânsito carregamento'
            case 'carregamento':
                return 'Carregamento'
            case 'transito_descarga':
                return 'Em trânsito descarga'
            case 'descarga':
                return 'Descarga'
        }
    }

    get typeOperation() {
        return this.#typeOperation;
    }

    get status() {
        return this.#status;
    }

    get collect() {
        switch (this.#typeOperation) {
            case TypeOperation.PINTOS_E_PERUZINHOS:
                return 'no incubatório';
            case TypeOperation.OVOS_CARRINHOS:
                if (this.#deliveryType) {
                    return 'no incubatório';
                }
                return 'no integrado';
            default:
                return 'em integrado';
        }
    }

    get origin() {
        switch (this.#typeOperation) {
            case TypeOperation.OVOS_CARRINHOS:
                return 'Incubatório';
            default:
                return 'Frigorífico';
        }
    }

    get deliveryType() {
        return this.#deliveryType;
    }

    get threeCurrent(): boolean {
        return this.#threeCurrent;
    }

    get threeFinalizedForced(): boolean {
        return this.#threeFinalizedForced;
    }

    get threeFinalizedSuccess(): boolean {
        return this.#threeFinalizedSuccess;
    }

    set threeCurrent(current: boolean) {
        this.#threeCurrent = current;
    }

    set threeFinalizedForced(finalizedForced: boolean) {
        this.#threeFinalizedForced = finalizedForced;
    }

    set threeFinalizedSuccess(finalizedSuccess: boolean) {
        this.#threeFinalizedSuccess = finalizedSuccess;
    }

    get weightAccomplished() {
        return this.#weightAccomplished;
    }

    get weightForessen() {
        return this.#weightForessen;
    }

    get totalTimeAccomplished() {
        return this.#totalTimeAcomplished;
    }

    get totalTimeForessen() {
        return this.#totalTimeForessen;
    }

    get isTransfer() {
        return this.#isTransfer;
    }

    static from<T>(points: any[]): SynopticMapPoint<T>[] {
        return points.map((point) => new SynopticMapPoint<T>(point));
    }
}
