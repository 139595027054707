import { Component, Input, OnInit } from '@angular/core';
import { ModalEnum, ModalService, TModal } from '../../modal.service';

@Component({
  selector: 'app-modal-window-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class ModalWindowOrdersComponent implements OnInit {

  @Input() modalId: string;

  @Input() orders: string[];

  @Input() orderIndex = 0;


  constructor(private modalService: ModalService) { }

  public ngOnInit(): void {
    if (!this.orders) {
      console.error('Não foram enviados pedidos');
      return;
    }
  }

  public onModalOpen(): void {
    if (this.orders.length <= 1) {
      return;
    }

    const modal: TModal = {
      name: ModalEnum.WINDOW_ORDERS,
      data: { orders: this.orders }
    };

    this.modalService.setModal(modal);
  }
}
