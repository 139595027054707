<div *ngIf="diariaDetails" class="page-header">
  <a (click)="navigateBack()">
    <i class="navigate-back-icon fa-solid fa-chevron-left"></i>
  </a>
  Placa {{ diariaDetails?.placa }} / Viagem {{ diariaDetails?.numeroViagem }}
</div>

<mat-card *ngIf="diariaDetails" class="page-card">
  <header>
    <h2>Sinalização de diária</h2>

    <span class="diaria-detail-chip priority-chip">
      <mat-icon>trending_up</mat-icon>
      <span class="priority-chip-text">Alta Prioridade</span>
    </span>

    <span class="diaria-detail-chip" [attr.data-status]="diariaDetails?.status">{{ diariaDetails?.status }}</span>
    <span *ngIf="diariaDetails?.statusGeral" class="diaria-detail-chip">{{ diariaDetails?.statusGeral }}</span>
    <span class="diaria-detail-chip logistic-chip">
      <mat-icon>subject</mat-icon>
      Distribuição
    </span>
    <span *ngIf="approvalForm.canEdit && !approvalForm.isEditing" class="diaria-detail-chip edit-chip"
      (click)="toggleIsEditingApproval()">
      <mat-icon class="material-symbols-outlined">edit</mat-icon>
      Editar
    </span>
    <span *ngIf="diariaDetails?.dataUltimaModificacao" class="diaria-detail-chip last-status-change-date">
      <mat-icon class="material-symbols-outlined">schedule</mat-icon>
      {{ diariaDetails?.dataUltimaModificacao }}
    </span>
  </header>

  <app-diaria-details-info *ngIf="diariaDetails" [details]="diariaDetails"></app-diaria-details-info>

  <ng-container *ngIf="diariaDetails?.tratativas.length">
    <h2>Tratativa</h2>
    <section>
      <app-diaria-treatment *ngFor="let treatment of diariaDetails.tratativas" [treatment]="treatment"
        [showAnalyzeButton]="treatment.showAnalisada"
        (analyzeButtonClick)="openTimeExceededAnalysisDialog()"></app-diaria-treatment>
    </section>
  </ng-container>

  <ng-container *ngIf="approvalForm.isVisible">
    <h2>{{
      approvalForm.isWaitingApproval
      ? 'Aguardando aprovação'
      : approvalForm.isEditing
      ? 'Editar diária'
      : 'Envio para aprovação'
      }}</h2>
    <form [formGroup]="approvalForm.formGroup" (ngSubmit)="submitApprovalRequest()" class="approval-form">
      <app-approval-request-form [formGroup]="approvalForm.formGroup"
        [responsibleClients]="approvalForm.isWaitingApproval ? [diariaDetails.clienteResponavel] : diariaDetails.clientesResponaveis"></app-approval-request-form>
      <div class="approval-form-buttons">
        <button *ngIf="approvalForm.isEditing" (click)="toggleIsEditingApproval()" class="cancel mat-flat-button">
          Cancelar
        </button>
        <button *ngIf="!approvalForm.isWaitingApproval" type="submit" class="submit mat-raised-button"
          [disabled]="approvalForm.formGroup.invalid || !canEdit">
          {{ approvalForm.isEditing && !approvalForm.editRequireNewApproval ?
          'Salvar alterações' :
          'Enviar para aprovação'
          }}
        </button>
      </div>
    </form>
  </ng-container>
</mat-card>

<ng-template #approvalFormSuccessDialog let-data>
  <h2 mat-dialog-title class="dialog-title">
    {{ approvalForm.isEditing && !approvalForm.editRequireNewApproval ?
    'Diária editada' :
    'A diária foi' + (approvalForm.isEditing && approvalForm.editRequireNewApproval ? ' editada e ' : ' ') + 'enviada para aprovação'
    }}
  </h2>
  <mat-dialog-content class="dialog-content">
    {{ approvalForm.isEditing && !approvalForm.editRequireNewApproval ?
    'A diária foi editada com sucesso.' :
    'A solicitação foi enviada para aprovação para a área ' + data.responsibleArea.toLowerCase() + '.'
    }}
  </mat-dialog-content>
  <mat-dialog-actions class="dialog-actions">
    <button mat-dialog-close class="mat-flat-button dialog-button-flat">
      OK
    </button>
  </mat-dialog-actions>
</ng-template>

<ng-template #timeExceededAnalysisDialog>
  <h2 mat-dialog-title class="dialog-title">Tempo de aprovação excedido</h2>
  <mat-dialog-content class="dialog-content">
    O tempo de aprovação para essa viagem foi excedido, é necessária a ação da torre de monitoramento para aprovação ou
    reprovação da diária.
  </mat-dialog-content>
  <mat-dialog-actions class="dialog-actions">
    <button mat-dialog-close class="mat-flat-button dialog-button-flat"
      (click)="submitTimeExceededAnalysisApproval({ isApproved: false })">
      REPROVAR
    </button>
    <button mat-dialog-close class="mat-flat-button dialog-button-filled"
      (click)="submitTimeExceededAnalysisApproval({ isApproved: true })">
      APROVAR
    </button>
  </mat-dialog-actions>
</ng-template>
