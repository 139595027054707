import { Coordinate } from "./coordinate.model";

export class PointMarker {
    public coordinate: Coordinate
    public icon: string;
    public label: string;

    constructor(
        coordinate,
        {
            icon = "../../../../assets/icons/maps-pointer/pin_current_.svg",
            label = 'Ponto de referência'
        },
    ) {
        this.coordinate = coordinate;
        this.icon = icon;
        this.label = label;
    }
}