import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { MyFilter } from 'src/app/core/models/my-filter.model';
import { DiariaService } from 'src/app/core/services/diaria.service';
import { GDFilterService } from 'src/app/core/services/gd-filter.service';
import { Diarias } from 'src/app/models/diarias.model';
import { DialogFiltroComponent } from '../dialog-filtro/dialog-filtro.component';
import { DialogDeletarFiltroComponent } from '../dialog-deletar-filtro/dialog-deletar-filtro.component';
import { PerfilPermissaoService } from 'src/app/core/services/perfil-permissao.service';

@Component({
  selector: 'app-dialog-meus-filtros',
  templateUrl: './dialog-meus-filtros.component.html',
  styleUrls: ['./dialog-meus-filtros.component.scss'],
})
export class DialogMeusFiltrosComponent implements OnInit, OnDestroy {
  private subscriptionLoading: Subscription;

  public listFilters: MyFilter[] = [];
  public form: FormGroup = new FormGroup({
    options: new FormControl(0),
  });

  constructor(
    private filterService: GDFilterService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<DialogMeusFiltrosComponent>,
    private diariaService: DiariaService,
    private perfilService: PerfilPermissaoService
  ) { }

  ngOnInit(): void {
    this.subscriptionLoading =
      this.filterService.myFiltersObservable.subscribe(
        (data: MyFilter[]) => {
          this.listFilters = data;

          this.form.controls.options.setValue(
            this.listFilters.findIndex((f) => f.id === this.filterService.myFilterSelected.id)
          );
        }
      );
  }

  ngOnDestroy(): void {
    this.subscriptionLoading?.unsubscribe();
  }

  async applyFilter(): Promise<void> {
    this.filterService.customFilter = null;
    this.filterService.myFilterSelected = this.listFilters[this.form.controls.options.value];
    this.diariaService.loadingDiarias = true;
    const filters = JSON.parse(this.filterService.myFilterSelected.definicaoFiltro);
    const data = await this.diariaService.getAllDiarias(filters);
    const diariasList = data.map((element: any) => new Diarias(element));
    this.diariaService.listaDiarias = diariasList;
    this.diariaService.listaDiariasCompleta = diariasList;
    this.dialog.closeAll();
    this.diariaService.loadingDiarias = false;
  }

  editFilter(i: number): void {
    this.dialogRef.close();
    this.filterService.myFilterSelected =
      this.listFilters[i];
    this.dialog.open(DialogFiltroComponent, {
      width: '800px',
      height: 'auto',
      data: {
        useMyFilter: true,
        filter: JSON.parse(this.listFilters[i].definicaoFiltro),
        typeAction: 'edit',
      }
    });
  }

  deleteFilter(i: number): void {
    this.dialogRef.close();
    this.dialog.open(DialogDeletarFiltroComponent, {
      width: '800px',
      height: 'auto',
      data: {
        name: this.listFilters[i].nomeFiltro,
        id: this.listFilters[i].id,
        screen: `governanca-diaria-${this.perfilService.getCurrentLogisticaRole()?.logistica}`,
      }
    });
  }
}
