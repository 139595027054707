import { AnomalyStatusEnum } from '../enums/anomaly-status.enum';
import { PriorityEnum } from '../enums/priority.enum';
import { Filter } from '../filter.model';


export class AnomalyFilter extends Filter {

    public dtCreatedStart: Date;
    public dtCreatedEnd: Date;
    public unidades: string[];
    public logistica: string;
    public status: AnomalyStatusEnum[];
    public placa: string;
    public prioridade: PriorityEnum[];
    public statusList: Array<any> ;
    public prioridadeList: Array<any>;
    public showCards: boolean = true;

    constructor() {
        super();
    }
}