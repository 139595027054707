import { TOption } from "src/app/components/select/select.component";
import { LogisticTypeEnum } from "../enums/logistic-type.enum";

export class RoutePrimaryFilter {
    id: string;
    numeroViagem: string[];
    placa: string[];
    dataEntrega: { startDate: Date, endDate: Date };
    dataColeta: { startDate: Date, endDate: Date };
    dataEntregaInicio: Date;
    dataColetaInicio: Date;
    dataEntregaFim: Date;
    dataColetaFim: Date;
    ocr: string[];
    ufOrigem: TOption[];
    ufDestino: TOption[];
    remessa: string[];
    transportadora: TOption[];
    regional: TOption[];
    mercado: TOption[];
    tipoProjeto: TOption[];
    tipoOperacao: TOption[];
    tipoEquipamento: TOption[];
    statusEspelhamento: TOption[];
    statusAtendimento: TOption[];
    statusViagem: TOption[];
    tipoFrota: TOption[];
    destino: TOption[];
    origem: TOption[];
    ultimaDataMensagemLida: Date;
    logistica: string;

    constructor({
        id = '',
        numeroViagem = [],
        numeroViagemString = '',
        placa = { startDate: null, endDate: null },
        placaString = '',
        transportadora = [],
        transportadoras = [],
        dataEntrega = null,
        dataColeta = null,
        dataEntregaInicio = null,
        dataColetaInicio = null,
        dataEntregaFim = null,
        dataColetaFim = null,
        regional = [],
        mercado = [],
        tipoProjeto = [],
        tipoOperacao = [],
        tipoEquipamento = [],
        statusEspelhamento = [],
        statusAtendimento = [],
        statusViagem = [],
        tipoFrota = [],
        destino = [],
        destinos = [],
        origem = [],
        origens = [],
        ocr = [],
        ufOrigem = [],
        ufDestino = [],
        remessa = [],
        remessaString = '',
        ocrString = ''
    }) {
        this.id = id;
        this.numeroViagem = this.setArrayString(numeroViagem, numeroViagemString);
        this.placa = this.setArrayString(placa, placaString);
        this.transportadora = transportadora || transportadoras;
        this.dataEntrega = dataEntrega;
        if (this.dataEntrega) {
            this.dataEntregaInicio = this.dataEntrega.startDate;
            this.dataEntregaFim = this.dataEntrega.endDate;
        } else {
            this.dataEntregaInicio = dataEntregaInicio;
            this.dataEntregaFim = dataEntregaFim;
        }
        this.dataColeta = dataColeta;
        if (this.dataColeta) {
            this.dataColetaInicio = this.dataColeta.startDate;
            this.dataColetaFim = this.dataColeta.endDate;
        } else {
            this.dataColetaInicio = dataColetaInicio;
            this.dataColetaFim = dataColetaFim;
        }
        this.regional = regional;
        this.mercado = mercado;
        this.tipoProjeto = tipoProjeto;
        this.tipoOperacao = tipoOperacao;
        this.tipoEquipamento = tipoEquipamento;
        this.statusEspelhamento = statusEspelhamento;
        this.statusAtendimento = statusAtendimento;
        this.statusViagem = statusViagem;
        this.tipoFrota = tipoFrota;
        this.destino = destino || destinos;
        this.origem = origem || origens;
        this.ocr = this.setArrayString(ocr, ocrString);
        this.ufOrigem = ufOrigem;
        this.ufDestino = ufDestino;
        this.remessa = this.setArrayString(remessa, remessaString);
        this.logistica = LogisticTypeEnum.PRIMARY;
    }

    setArrayString(valueArray: any, valueString: any) {
        if (Array.isArray(valueArray) && valueArray?.length > 0) {
            return valueArray;
        }
        if (typeof valueArray === 'string') {
            return valueArray.split(";");
        }
        if (valueString) {
            return valueString?.split(";")
        }
        return [];
    }


    toJson() {
        const obj = {}
        Object.entries(this).map((value) => {
            if (typeof value[1] === 'string' && value[1].trim() !== '' || !Array.isArray(value[1]) && !!value[1]) {
                obj[value[0]] = value[1];
            }
            if (Array.isArray(value[1]) && value[1].length > 0) {
                if ((typeof value[1][0] === 'string' && value[1][0].trim() !== '') || typeof value[1][0] === 'number') {
                    obj[value[0]] = value[1];
                } else {
                    obj[value[0]] = value[1]
                        .filter((option: TOption) => option.checked)
                        .map((option: TOption) => option.value);
                }
            }
        });
        return obj;
    }

    countFilters() {
        let count = 0;
        Object.entries(this).map(item => {            
            if (item[0] !== 'id') {
                if ((item[0] === 'dataEntregaInicio' || item[0] === 'dataColetaInicio') && !!item[1]) {                    
                    count++;
                }                
                if (Array.isArray(item[1]) && item[1].length > 0) {                    
                    if (typeof item[1][0] === 'object' && item[1][0]?.value) {
                        count = count + item[1].length;
                    } 
                    if ((typeof item[1][0] === 'string' && item[1][0].trim() !== '') || typeof item[1][0] === 'number') {                        
                        count = count + item[1].length;
                    }                    
                }
            }
        })
        return count;
    }
}