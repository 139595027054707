<div class="step">
  <mat-icon class="step-icon">radio_button_checked</mat-icon>
  <span class="step-line"></span>
</div>

<div class="treatment-content-wrapper">
  <div class="treatment-data">
    <h4 class="treatment-title">{{ treatment.titulo }}</h4>
    <h6
      class="treatment-description"
      [ngClass]="{ 'treatment-with-analysis-button': showAnalyzeButton }"
    >
      {{ treatment.descricao }}
    </h6>

    <ng-container *ngIf="treatment.justificativa">
      <div class="treatment-justification-wrapper">
        <h5 class="treatment-justification-title">Justificativa</h5>
        <span *ngIf="treatment.criadoPor" class="treatment-justification-created-by">{{ treatment.criadoPor }}</span>
      </div>
      <span>{{ treatment.justificativa}}</span>
    </ng-container>
  </div>

  <div class="treatment-analyze-button-wrapper">
    <button *ngIf="showAnalyzeButton" class="treatment-analyze-button" (click)="handleAnalyzeButtonClick()">
      <mat-icon class="material-symbols-outlined">find_in_page</mat-icon>
      <span class="treatment-analyze-button-text">Analisar</span>
    </button>
  </div>

  <div class="treatment-total-time-wrapper">
    <h4 class="treatment-total-time">Tempo do total de diárias</h4>
    <span>{{ treatment.tempoEmDiaria }}</span>
  </div>
</div>
