import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';

@Component({
  selector: 'app-return',
  templateUrl: './return.component.html',
  styleUrls: ['./return.component.scss']
})
export class ReturnComponent implements OnInit {

  constructor(public authService: AuthService, private router: Router, private localStorageService: LocalStorageService) { }

  ngOnInit(): void {
    this.authService.checkAuth().subscribe((auth) => {
      if (auth) {
        this.authService.userData.subscribe((user) => {
          this.authService.setLoginScreen(false);
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.router.navigateByUrl('/logistic');
        });
        return;
      }

      this.authService.setLoginScreen(true);
      this.router.navigateByUrl('/login');
      this.localStorageService.removeItem('currentUser');
    });
  }
}
