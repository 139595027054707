import { ISerialize } from '../serialize.interface';
import { UserFilterScreenMonitoring } from './screen/monitoring.model';

export class UserFilter<T extends ISerialize> implements ISerialize {
    #id: string;

    #login: string;

    #name: string;

    #screen: string;

    #parameters: T;

    #default: boolean;

    constructor({
        id,
        idUsuario,
        nomeFiltro,
        tela,
        definicaoFiltro,
        filtroPadrao
    }) {
        this.#id = id;
        this.#login = idUsuario;
        this.#name = nomeFiltro;
        this.#screen = tela;
        this.#default = filtroPadrao;
        switch (this.#screen) {
            case 'monitoramento-primaria':
            case 'monitoramento-distribuicao':
            case 'monitoramento-commodities':
            case 'monitoramento-agro':
            case 'monitoramento-agro-subzones':
            case 'monitoramento-primaria-subzones':
              // @ts-ignore
              this.#parameters = new UserFilterScreenMonitoring(JSON.parse(definicaoFiltro));
              break;
            default:
                console.error(`[UserFilter] Tela ${this.#screen} não implementada`);
                break;
        }
    }

    get id(): string {
        return this.#id;
    }

    get login(): string {
        return this.#login;
    }

    get name(): string {
        return this.#name;
    }

    get screen(): string {
        return this.#screen;
    }

    get parameters(): T {
        return this.#parameters;
    }

    set parameters(params: T) {
        this.#parameters = params;
    }

    get default(): boolean {
        return this.#default;
    }

    set default(newDefault: boolean) {
        this.#default = newDefault;
    }

    static from<T extends ISerialize>(filters: any[]): UserFilter<T>[] {
        return filters.map(filter => new UserFilter<T>(filter));
    }

    serialize(): any {
        return {
            id: this.#id,
            idUsuario: this.#login,
            nomeFiltro: this.#name,
            tela: this.#screen,
            definicaoFiltro: this.#parameters.serialize(),
            filtroPadrao: this.#default
        };
    }
}
