<form [formGroup]="filterForm" class="monitoring-filter-agro">
  <div class="monitoring-filter-agro__row">
    <div class="monitoring-filter-agro__row__item">
      <span class="monitoring-filter-agro__row__item__label"> Número da viagem </span>
      <app-input [autofocus]="true" formControlName="loadNumber" placeholder="Digite o(s) número(s) da(s) viagem(ns)"
        separator=";" [removeAllSpaces]="true"></app-input>
    </div>

    <div class="monitoring-filter-agro__row__item">
      <span class="monitoring-filter-agro__row__item__label"> Placa </span>
      <app-input formControlName="plate" placeholder="Digite a(s) placa(s) do(s) veículo(s)" separator=";"
        [removeAllSpaces]="true"></app-input>
    </div>
  </div>

  <div class="monitoring-filter-agro__row">
    <div class="monitoring-filter-agro__row__item">
      <span class="monitoring-filter-agro__row__item__label"> Transportadora </span>

      <app-select id="carriers" formControlName="carriers" placeholder="Selecione a transportadora"
        selectedText="transportadoras selecionadas" placeholderSearchOptions="Pesquise pela transportadora"
        placeholderTotalSelected="Transportadora(s) selecionadas" [options]="options.transportadora"></app-select>
    </div>

    <div class="monitoring-filter-agro__row__item">
      <span class="monitoring-filter-agro__row__item__label"> Data da viagem </span>
      <app-date-range-picker formControlName="loadDate" startDate="2021-08-17" endDate="2021-08-17">
      </app-date-range-picker>
    </div>
  </div>

  <div class="monitoring-filter-agro__row">
    <div class="monitoring-filter-agro__row__item">
      <span class="monitoring-filter-agro__row__item__label"> Unidade </span>

      <app-select id="units" formControlName="units" placeholder="Selecione a unidade"
        selectedText="unidades selecionadas" placeholderSearchOptions="Pesquise pela unidade"
        placeholderTotalSelected="Unidade(s) selecionada(s)" [options]="options.unidadecomcodigoagro"></app-select>
    </div>

    <div class="monitoring-filter-agro__row__item">
      <span class="monitoring-filter-agro__row__item__label"> Origem </span>

      <app-select id="origins" formControlName="origins" placeholder="Selecione origem"
        selectedText="origens selecionadas" placeholderSearchOptions="Pesquise pela origem"
        placeholderTotalSelected="Origens selecionadas" [options]="options.unidadecomcodigoorigemagro"></app-select>
    </div>
  </div>

  <div class="monitoring-filter-agro__row">
    <div class="monitoring-filter-agro__row__item">
      <span class="monitoring-filter-agro__row__item__label"> Destino </span>

      <app-select id="destinations" formControlName="destinations" placeholder="Selecione o destino"
        selectedText="destinos selecionados" placeholderSearchOptions="Pesquise pelos destinos"
        placeholderTotalSelected="Destinos selecionados" [options]="options.unidadecomcodigodestinoagro"></app-select>
    </div>

    <div class="monitoring-filter-agro__row__item">
      <span class="monitoring-filter-agro__row__item__label"> Tipo de operação </span>

      <app-select id="operations" formControlName="operations" placeholder="Selecione o tipo de operação"
        selectedText="tipos de operação selecionados" placeholderSearchOptions="Pesquise pelos tipos de operação"
        placeholderTotalSelected="Tipos de operação selecionados" [options]="options.tipooperacaoagro"></app-select>
    </div>
  </div>

  <div class="monitoring-filter-agro__actions">
    <app-link *ngIf="!!filterCount" (handleClick)="onClearFilter()" [isBolder]="true">
      Limpar todos os filtros
    </app-link>
    <app-button [isDisabled]="!filterCount" (clicked)="savedFilter ? onCancel() : onCreateFilter()"
      [text]="savedFilter ? 'Cancelar' : 'Criar filtro'" [isOutlined]="true" [isMarginRight]="true"
      [isMarginLeft]="true"></app-button>
    <app-button *ngIf="(savedFilters$ | async)?.length" [isDisabled]="!filterCount"
      [text]="savedFilter ? 'Salvar' : 'Aplicar filtro'" (clicked)="savedFilter ? onSaveFilter() : onSubmit()">
    </app-button>
  </div>
</form>