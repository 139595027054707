export function TypePointIconUtil(type: string): string {
    switch (type.toLocaleLowerCase()) {
        case 'armazem':
          return 'warehouse';
        case 'wc':
          return 'wc';
        case 'posto_fiscal':
          return 'fiscal';
        case 'viagem_cancelada':
          return 'canceled';
        case 'origem':
        case 'cd':
        case 'carregamento':
          return 'cd';
        case 'cliente':
          return 'client';
        case 'fim_rota':
          return 'end';
        case 'residencia':
          return 'home';
        case 'viagem_com_agenda_imcompativel':
          return 'imcompatible-schedule';
        case 'viagem_sem_rastreio':
          return 'lat-long';
        case 'manutencao':
          return 'maintenance';
        case 'refeicao':
          return 'meal';
        case 'viagem_movimento':
          return 'movement';
        case 'troca_nf':
          return 'nf';
        case 'nf':
          return 'nf';
        case 'fornecedor':
        case 'descarga':
          return 'provider';
        case 'prf':
          return 'prf';
        case 'porto':
          return 'port';
        case 'repouso':
          return 'rest';
        case 'inicio_rota':
          return 'start';
        case 'viagem_parada':
          return 'stop';
        case 'abastecimento':
          return 'supply';
        case 'up':
          return 'up';
        case 'deslocamento':
        case 'in_transit':
        case 'transito_carregamento':
        case 'transito_descarga':
          return 'vehicle';
        case 'entrega':
        case 'coleta':
          return 'delivery';
        case 'frigorifico':
        case 'retorno_frigorífico':
        case 'retorno_fabrica':
          return 'factory';
        case 'retorno_incubatório':
          return 'fullfilment';
        case 'higienização':
          return 'wash';
        case 'monitoramento_continuo':
          return 'vehicle';
    }
}
