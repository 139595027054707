import { Injectable } from '@angular/core';
import { initializeApp } from 'firebase/app';
import { MessagePayload, getMessaging, getToken, onMessage } from 'firebase/messaging';
import { GDFilterService } from '../core/services/gd-filter.service';
import { DiariaService } from '../core/services/diaria.service';
import { MessageService } from '../core/services';
import { Diarias } from '../models/diarias.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class FirebaseService {
    private apiUrl = environment.base_api_distribution_diaria;

    constructor(
        protected http: HttpClient,

        private gdFilterService: GDFilterService,
        private gdService: DiariaService,
        private messageService: MessageService,
    ) { }

    init(): void {
        const config: any = this.http.get("http:localhost:4201/firebase");

        const app = initializeApp({
            apiKey: config.firebaseApiKey,
            authDomain: config.firebaseAuthDomain,
            databaseURL: config.firebaseDatabaseURL,
            projectId: config.firebaseProjectId,
            storageBucket: config.firebaseStorageBucket,
            messagingSenderId: config.firebasemessagingSenderId,
            appId: config.firebaseAppId,
            measurementId: config.firebasemeasurementId,
        });

        Notification.requestPermission().then((permission) => {
            if (permission !== 'granted') {
                return;
            }

            const messaging = getMessaging(app);
            getToken(messaging, { vapidKey: JSON.parse(process.env.CONFIG).vapidKey }).then((value: string) => {
                this.sendTokenToServer(value).catch((err) => console.error(err));

                onMessage(messaging, (payload: MessagePayload) => {
                    // msg, devoluca
                    const obj = {
                        gd: () => {
                            const objFilter = this.gdFilterService.customFilter
                                ? this.gdFilterService.customFilter
                                : this.gdFilterService.myFilterSelected
                                    ? JSON.parse(this.gdFilterService.myFilterSelected.definicaoFiltro)
                                    : null;

                            this.gdService.loadingDiarias = true;

                            this.gdService.getAllDiarias(objFilter).then((result) => {
                                this.gdService.listaDiarias = result.map((element: any) => new Diarias(element));
                                this.gdService.listaDiariasCompleta = result.map((element: any) => new Diarias(element));
                                this.gdService.loadingDiarias = false;
                            }, (err) => {
                                console.error(err);
                            });
                        },
                    };

                    const notificationType = payload.data.typeNotification != null
                        ? payload.data.typeNotification as 'success' | 'error' | 'caution'
                        : 'success';
                    this.messageService.message(notificationType, payload.notification.body, null, 5000, null, false, true, null);

                    obj[payload.data.key ?? 'gd']();
                });

            }, (err) => {
                console.error(err);
            });
        });
    }

    sendTokenToServer(firebaseToken: string): Promise<{ success: boolean }> {
        const url = `${this.apiUrl}/firebase/`;
        return this.http.post<{ success: boolean }>(url, {
            idUsuario: JSON.parse(localStorage.getItem('currentUser')).email,
            firebaseToken,
        }).toPromise();
    }
}
