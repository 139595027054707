import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';

@Component({
  selector: 'app-input-form-field',
  templateUrl: './input-form-field.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputFormFieldComponent),
      multi: true
    }
  ],
})
export class InputFormFieldComponent implements ControlValueAccessor {

  @Input() type = 'text';
  @Input() label: string;
  @Input() placeholder: string;
  @Input() textListSeparator: string | undefined = undefined;
  @Input() appearance: MatFormFieldAppearance = 'outline';

  value = '';
  disabled = false;
  propagateTouched: () => void;
  private propagateChange: (value: string | string[]) => void;

  writeValue(value: string | string[]): void {
    this.value = Array.isArray(value) ? value.join(this.textListSeparator) : value;
  }

  handleInputChange(value: string): void {
    this.writeValue(value);
    const arrayValue = value ? value.split(this.textListSeparator) : [];
    const propagatedValue = this.textListSeparator ? arrayValue : value;
    this.propagateChange(propagatedValue);
  }

  formatPastedValue(event: ClipboardEvent, inputElement: HTMLInputElement): void {
    if (!this.textListSeparator) {
      return;
    }

    event.preventDefault();
    const { value, selectionStart, selectionEnd } = inputElement;
    const valueBeforeSelection = value.slice(0, selectionStart);
    const valueAfterSelection = value.slice(selectionEnd);
    const pastedValue = event.clipboardData.getData('text/plain');
    const formattedPastedValue = pastedValue.split(/\s+/).join(this.textListSeparator);
    const newValue = `${valueBeforeSelection}${formattedPastedValue}${valueAfterSelection}`;

    this.handleInputChange(newValue);
    const restoreCursorPositionAfterValueUpdate = () => inputElement.selectionEnd = `${valueBeforeSelection}${formattedPastedValue}`.length;
    setTimeout(restoreCursorPositionAfterValueUpdate);
  }

  registerOnChange(fn: (value: string | string[]) => void): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.propagateTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
