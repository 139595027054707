import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BreadCrumbItem } from 'src/app/core/models/bread-crumb-item.model';

@Component({
  selector: 'app-bread-crumb',
  templateUrl: './bread-crumb.component.html',
  styleUrls: ['./bread-crumb.component.scss']
})
export class BreadCrumbComponent implements OnInit {

  @Input()
  public list: BreadCrumbItem[];

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  actionItem(item: BreadCrumbItem){
    if(item.route && item.route != ""){
        this.router.navigateByUrl(item.route);
    }
  }
}
