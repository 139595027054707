<div class="modal-content">
    <div class="header__content__header">
        <div class="header__content__header__title">
            <span>Filtro</span>
            <div class="header__content__header__icon">
                <app-icon [icon]="'close'" (click)="close()"  [width]="24" [height]="24" title="Fechar"></app-icon>
            </div>
        </div>
    </div>
    <div class="modal-body">
        <form [formGroup]="form" class="modal-filter-primary">
            <div class="modal-filter-primary__row">
                <div class="modal-filter-primary__row__item">
                    <span class="modal-filter-primary__row__item__label"> Número da Viagem </span>
                    <app-input formControlName="numeroViagem" placeholder="Digite o número da viagem" separator=";"
                        [removeAllSpaces]="true"></app-input>
                </div>
                <div class="modal-filter-primary__row__item">
                    <span class="modal-filter-primary__row__item__label"> Placa </span>
                    <app-input formControlName="placa" placeholder="Digite a placa do veículo" separator=";"
                        [removeAllSpaces]="true"></app-input>
                </div>
            </div>

            <div class="modal-filter-primary__row">
                <div class="modal-filter-primary__row__item">
                    <span class="col-2"> Transportadora </span>
                    <app-select id="transportadora" formControlName="transportadora"
                        placeholder="Selecione a transportadora" selectedText="transportadora selecionada"
                        placeholderSearchOptions="Pesquise pela transportadora"
                        placeholderTotalSelected="Transportadora selecionada"
                        [options]="options.transportadoras"></app-select>
                </div>
                <div class="modal-filter-primary__row__item">
                    <span class="modal-filter-primary__row__item__label"> Regional </span>
                    <app-select id="regional" formControlName="regional" placeholder="Selecione a região"
                        selectedText="região selecionada" placeholderSearchOptions="Pesquise pela região"
                        placeholderTotalSelected="Região selecionada"
                        [options]="options.regionaiscalculadas"></app-select>
                </div>
            </div>

            <div class="modal-filter-primary__row">
                <div class="modal-filter-primary__row__item">
                    <span class="modal-filter-primary__row__item__label"> Data planejada do carregamento </span>
                    <app-date-range-picker formControlName="dataColeta" startDate="2021-08-17" endDate="2021-08-17">
                    </app-date-range-picker>
                </div>
                <div class="modal-filter-primary__row__item">
                    <span class="modal-filter-primary__row__item__label"> Data planejada da entrega </span>
                    <app-date-range-picker formControlName="dataEntrega" startDate="2021-08-17" endDate="2021-08-17">
                    </app-date-range-picker>
                </div>
            </div>

            <div class="modal-filter-primary__row">
                <div class="modal-filter-primary__row__item">
                    <span class="modal-filter-primary__row__item__label"> Remessa </span>
                    <app-input formControlName="remessa" placeholder="Digite o número da remessa" separator=";"
                        [removeAllSpaces]="true"></app-input>
                </div>
                <div class="modal-filter-primary__row__item">
                    <span class="modal-filter-primary__row__item__label"> OCR </span>
                    <app-input formControlName="ocr" placeholder="Digite número da OCR" separator=";"
                        [removeAllSpaces]="true"></app-input>
                </div>
            </div>

            <div class="modal-filter-primary__row">
                <div class="modal-filter-primary__row__item">
                    <span class="modal-filter-primary__row__item__label"> Origem (ID e Nome) </span>
                    <app-select id="origem" formControlName="origem" placeholder="Selecione a origem"
                        selectedText="origem selecionada" placeholderSearchOptions="Pesquise pela origem"
                        placeholderTotalSelected="Origem selecionada" [options]="options.origens"></app-select>
                </div>
                <div class="modal-filter-primary__row__item">
                    <span class="modal-filter-primary__row__item__label"> Destino (ID e Nome) </span>
                    <app-select id="destino" formControlName="destino" placeholder="Selecione o destino"
                        selectedText="destino selecionado" placeholderSearchOptions="Pesquise pelo destino"
                        placeholderTotalSelected="Destino selecionado" [options]="options.destinos"></app-select>
                </div>
            </div>

            <div class="modal-filter-primary__row">
                <div class="modal-filter-primary__row__item">
                    <span class="modal-filter-primary__row__item__label"> Status da viagem </span>
                    <app-select id="statusViagem" formControlName="statusViagem"
                        placeholder="Selecione status da viagem" selectedText="status viagem selecionado"
                        placeholderSearchOptions="Pesquise pelo status viagem"
                        placeholderTotalSelected="Status viagem selecionado"
                        [options]="options.statusViagemLista"></app-select>
                </div>
                <div class="modal-filter-primary__row__item">
                    <span class="modal-filter-primary__row__item__label"> Status do atendimento </span>
                    <app-select id="statusAtendimento" formControlName="statusAtendimento"
                        placeholder="Selecione status do atendimento" selectedText="status atendimento selecionado"
                        placeholderSearchOptions="Pesquise pelo status atendimento"
                        placeholderTotalSelected="Status atendimento selecionado"
                        [options]="options.statusAtendimentoLista"></app-select>
                </div>
            </div>            

            <div class="modal-filter-primary__row">
                <div class="modal-filter-primary__row__item">
                    <span class="modal-filter-primary__row__item__label"> Status do espelhamento </span>
                    <app-select id="statusEspelhamento" formControlName="statusEspelhamento"
                        placeholder="Selecione o status do espalhamento" selectedText="status espelhamento selecionado"
                        placeholderSearchOptions="Pesquise pelo status espalhamento"
                        placeholderTotalSelected="Status espalhamento selecionado"
                        [options]="options.statusEspelhamentoLista"></app-select>
                </div>
                <div class="modal-filter-primary__row__item">
                    <span class="modal-filter-primary__row__item__label"> Tipo de veículo </span>
                    <app-select id="tipoEquipamento" formControlName="tipoEquipamento"
                        placeholder="Selecione o tipo de equipamento" selectedText="Tipos de equipamentos selecionados"
                        placeholderSearchOptions="Pesquise pelos tipos de equipamentos"
                        placeholderTotalSelected="tipos de equipamentos selecionados"
                        [options]="options.tipoEquipamentoLista"></app-select>
                </div>
            </div>

            <div class="modal-filter-primary__row">
                <div class="modal-filter-primary__row__item">
                    <span class="modal-filter-primary__row__item__label"> Tipo de operação </span>
                    <app-select id="tipoOperacao" formControlName="tipoOperacao"
                        placeholder="Selecione o tipo de operação" selectedText="Tipos de operações selecionados"
                        placeholderSearchOptions="Pesquise pelos tipos operações"
                        placeholderTotalSelected="tipos de operações selecionados"
                        [options]="options.tipoOperacaoLista"></app-select>
                </div>
                <div class="modal-filter-primary__row__item">
                    <span class="monitoring-filter-primary__row__item__label">Tipo de frota</span>
                    <app-select id="tipoFrota" formControlName="tipoFrota" placeholder="Selecione o tipo de frota"
                        selectedText="tipos de frota selecionados" placeholderSearchOptions="Pesquise pelos tipos de frota"
                        placeholderTotalSelected="Tipos de frotas selecionados" [options]="options.tipoFrotaLista">
                    </app-select>
                </div>
            </div>

            <div class="modal-filter-primary__row">
                <div class="modal-filter-primary__row__item">
                    <span class="modal-filter-primary__row__item__label"> Tipo de projeto </span>
                    <app-select id="tipoProjeto" formControlName="tipoProjeto" placeholder="Selecione o tipo de projeto"
                        selectedText="Tipos de projetos selecionados"
                        placeholderSearchOptions="Pesquise pelos tipos projetos"
                        placeholderTotalSelected="tipos de projetos selecionados"
                        [options]="options.tipoProjetoLista"></app-select>                    
                </div>
                <div class="modal-filter-primary__row__item">
                    <span class="monitoring-filter-agro__row__item__label">Mercado</span>
                    <app-select id="mercado" formControlName="mercado" placeholder="Selecione o mercado"
                        selectedText="mercados selecionados" placeholderSearchOptions="Pesquise pelos mercados"
                        placeholderTotalSelected="Mercados selecionados" [options]="options.mercadoLista"></app-select>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-filter-primary__actions">
        <app-button [isDisabled]="form.invalid" text="Aplicar Filtro" (clicked)="apply()"></app-button>
    </div>
</div>