export class StepDetailCommonProgrammedTracking {
    #unloadingDate: Date;

    #unloadingFinishedDate: Date;

    #displacementTime: number;

    #displacementKm: number;

    #timeStopped: number;

    constructor({
        dataHoraInicio,
        dataHoraTermino,
        deslocamentoTempo,
        deslocamentoTotalKm,
        tempoParado
    }) {
        this.#unloadingDate = !dataHoraInicio ? null : new Date(dataHoraInicio);
        this.#unloadingFinishedDate = !dataHoraTermino ? null : new Date(dataHoraTermino);
        this.#displacementTime = deslocamentoTempo;
        this.#displacementKm = deslocamentoTotalKm;
        this.#timeStopped = tempoParado;
    }

    get unloadingDate(): Date {
        return this.#unloadingDate;
    }

    get unloadingFinishedDate(): Date {
        return this.#unloadingFinishedDate;
    }

    get displacementTime(): number {
        return this.#displacementTime;
    }

    get displacementKm(): number {
        return this.#displacementKm;
    }

    get timeStopped(): number {
        return this.#timeStopped;
    }
}
