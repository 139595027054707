import {
    MonitoramentoStatusAtendimentoEnum,
    MonitoramentoStatusEspelhamentoEnum,
    TipoFrotaEnum,
} from '../enums/filtro-monitoramento.enum';

export class RotaFilter {
    // to binding screen
    public numeroViagemString: string;
    public placaString: string;
    public pedidosString: string;
    public dataEntregaString: string;
    public dataColetaString: string;
    public dataAgendaString: string;
    public dataInicioPrevistoString: string;
    public dataTerminoPrevistoString: string;
    public dataInicioRealString: string;
    public dataTerminoRealString: string;
    public dataViagemString: string;
    public dataInicioRotaString: string;
    public remessaString: string;
    public codigoClienteString: string;
    public nomeClienteString: string;
    public clienteString: string;
    public ocrString: string;
    public transportadoras: any[];
    public regionaiscalculadas: any[];
    public unidades: any[];
    public empresas: string[];
    public origens: any[];
    public destinos: any[];
    public statusViagemLista: any[];
    public statusAtendimentoLista: any[];
    public tipoOperacaoLista: any[];
    public tipoOperacaoAgroLista: any[];
    public tipoProjetoLista: any[];
    public tipoEquipamentoLista: any[];
    public statusEspelhamentoLista: any[];
    public mercadoLista: any[];
    public tipoContratacaoLista: any[];
    public tipoVeiculoLista: any[];
    public tipoClienteLista: any[];
    public tipoFrotaLista: any[];

    // to request api
    public numeroViagem: string[];
    public placa: string[];
    public pedidos: string[];
    public transportadora: string[];
    public unidade: string[];
    public empresa: string[];

    public dataEntregaInicio: Date;
    public dataEntregaFim: Date;
    public dataColetaInicio: Date;
    public dataColetaFim: Date;
    public dataAgendaInicio: Date;
    public dataAgendaFim: Date;
    public dataInicioPrevistoInicio: Date;
    public dataInicioPrevistoFim: Date;
    public dataTerminoPrevistoInicio: Date;
    public dataTerminoPrevistoFim: Date;
    public dataInicioRealInicio: Date;
    public dataInicioRealFim: Date;
    public dataTerminoRealInicio: Date;
    public dataTerminoRealFim: Date;
    public dataViagemInicio: Date;
    public dataViagemFim: Date;
    public dataInicioRota: Date;
    public regionalcalculada: any[];
    public regional: any[];
    public remessa: string[];
    public codigoCliente: string[];
    public nomeCliente: string[];
    public cliente: string;
    public ocr: string[];
    public origem: string[];
    public destino: string[];
    public statusViagem: string[];
    public statusAtendimento: MonitoramentoStatusAtendimentoEnum[];
    public tipoFrota: TipoFrotaEnum[];
    public tipoOperacao: string[];
    public tipoProjeto: string[];
    public tipoEquipamento: string[];
    public statusEspelhamento: MonitoramentoStatusEspelhamentoEnum[];
    public mercado: string[];
    public tipoContratacao: string[];
    public tipoVeiculo: string[];
    public tipoCliente: string[];
    public logistica: string;
    public dtScheduledStart: Date;
    public dtScheduledEnd: Date;

    constructor() {
    }
}
