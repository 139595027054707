<span class="input">
  <input
    #inputElement
    [type]="type"
    autocomplete="off"
    [disabled]="isDisabled"
    [placeholder]="placeholder"
    [(ngModel)]="value"
    class="input__input"
    [ngClass]="{'invalid': invalid}"
    (keyup)="handleValue($event.target.value)"    
    (keypress)="onkeypress($event)"    
    [mask]="mask"
    [readonly]="readonly"
    [maxlength]="maxlength"
  />
</span>
<span @height-fade *ngIf="invalid" class="message-error">{{messageError}}</span>
