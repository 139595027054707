import { TOption } from "src/app/components/select/select.component";
import { LogisticTypeEnum } from "../enums/logistic-type.enum";
import { Filter } from "../filter.model";

export class SubzoneFilterAgro extends Filter {

    public logistica: string;
    public codigos: string[] = [];
    public descricao: string;
    public status: boolean = true;
    public tipoUnidades: number[] = [];
    public cidadesEstados: CityFilter[] = [];
    public outroEstado: string;
    public outraCidade: string;
    public cidades: TOption[];
    public estados: TOption[];

    constructor(logistica: LogisticTypeEnum) {
        super();
        this.logistica = logistica;
        this.pageSize = 10;
    }
}

export class CityFilter {
    public cidade: string;
    public estado: string;

    constructor(cidade: string, estado: string) {
        this.cidade = cidade;
        this.estado = estado;
    }
}

export class SubzoneFilterPrimary extends Filter {

    public logistica: string;
    public categoria: string;
    public nome: string;
    public tipoUnidade: string[] = [];
    public codigo: string;
    public tipoSaida: number[] = [];    

    constructor(logistica: LogisticTypeEnum) {
        super();
        this.logistica = logistica;
        this.pageSize = 10;
    }
}