import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tag-chips',
  templateUrl: './chips.component.html',
  styleUrls: ['./chips.component.scss']
})
export class TagChipsComponent implements OnInit {

  @Input() public title: string;

  @Input() public modifier = 'default';

  @Input() public hasMargin = true;

  @Input() public link: string;

  @Input() public icon: string;

  public marginModifier: string;

  public iconColor = '#5B5C60';

  ngOnInit(): void {
    if (!this.title) {
      console.error('Título para o componente "TagChips" é necessário');
      return;
    }

    if (this.hasMargin) {
      this.marginModifier = 'tag-chips--marginable';
    }

    if (this.icon) {
      if (this.modifier === 'danger') {
        this.iconColor = '#D22630';
      }

      if (this.modifier === 'warning') {
        this.iconColor = '#FF6600';
      }

      if (this.modifier === 'primary') {
        this.iconColor = '#003087';
      }

      if (this.modifier === 'ok') {
        this.iconColor = '#76BB79';
      }
    }
  }
}
