import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { SessionStorageService } from 'src/app/core/services/session-storage.service';
import { ModalEnum, ModalService, TModal } from '../../modal.service';

@Component({
  selector: 'app-modal-window-loads-related',
  templateUrl: './loads-related.component.html',
  styleUrls: ['./loads-related.component.scss']
})
export class ModalWindowLoadsRelatedComponent {

  @Input() modalId: string;

  @Input() loadNumber: string;

  @Input() loadNumberRelated: string[];

  constructor(
    private router: Router,
    private modalService: ModalService,
    private sessionStorageService: SessionStorageService
  ) { }

  public onModalOpen(): void {
    const modal: TModal = {
      name: ModalEnum.WINDOW_LOADS_RELATED,
      data: {
        loadNumber: this.loadNumber,
        loadNumberRelated: this.loadNumberRelated
      }
    };

    this.modalService.setModal(modal);
  }

  public onRedirect(loadNumber: string): void {
    this.sessionStorageService.setItem('tab', 0);
    this.router.navigateByUrl('/anomaly/rota/' + loadNumber);
    this.modalService.unsetModal(this.modalId);
  }
}
