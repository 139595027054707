import { NgModule } from '@angular/core';
import { CommonModule} from '@angular/common';
import { MinutePipe } from './minute.pipe';

@NgModule({
  declarations: [MinutePipe],
  imports: [CommonModule],
  exports: [MinutePipe]
})
export class MinuteModule { }
