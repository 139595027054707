export class ChatLoad {
    #loadNumber: string;

    #plate: string;

    #status: string;

    constructor({ numeroViagem, placa, statusViagem }) {
        this.#loadNumber = numeroViagem;
        this.#plate = placa;
        this.#status = statusViagem;
    }

    get loadNumber(): string {
        return this.#loadNumber;
    }

    get plate(): string {
        return this.#plate;
    }

    get status(): string {
        return this.#status;
    }

    static from(loads: any[]): ChatLoad[] {
        return loads.map((load: any) => new ChatLoad(load));
    }
}
