import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from 'src/app/core/services/modal';
import { IconModule } from 'src/app/components/shared/icon/icon-module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'src/app/components/button/button.module';
import { ModalFilterPrimary } from './modal-filter-primary.component';
import { InputModule } from 'src/app/components/input/input.module';
import { SelectModule } from 'src/app/components/select/select.module';
import { DateRangePickerModule } from 'src/app/components/date-range-picker/date-range-picker.module';

@NgModule({
    declarations: [ModalFilterPrimary],
    imports: [
        CommonModule,
        IconModule,
        ModalModule,
        FormsModule,
        ReactiveFormsModule,
        ButtonModule,
        InputModule,
        SelectModule,
        DateRangePickerModule                
    ],
})
export class ModalFilterPrimaryModule { }