import * as moment from "moment";
import { KilogramFormatPipe } from "src/app/pipes/kilogram/kilogram.pipe";
import { TimeFormatPipe } from "src/app/pipes/time-format/time.pipe";
import { Anomaly } from "../../anomaly.model";
import { PointV2 } from "../../point-v2.model";
import { SynopticMapThreePoint } from "../synoptic-map-three-point.model";
import { ISynopticMapModel } from "../synoptic-map.interface";
import { SynopticMap } from "../synoptic-map.model";
import { SynopticMapPointDistribution } from "./synoptic-map-point-distribution.model";

export class SynopticMapDistribution extends SynopticMap<SynopticMapPointDistribution>
    implements ISynopticMapModel<SynopticMapPointDistribution>
{

    kilogramPipe: KilogramFormatPipe;
    timeFormatPipe: TimeFormatPipe;


    constructor(route: any) {
        super(route);
        this.kilogramPipe = new KilogramFormatPipe();
        this.timeFormatPipe = new TimeFormatPipe();
    }

    getPoints(): any[] {
        return this.points;
    }

    setPoints(): void {
        let pointsCompleted = this.originalPoints.filter(
            (point: SynopticMapPointDistribution) =>
                point.completed && point.type !== 'inicio_rota' && point.type !== 'fim_rota'
        );
        let pointsNotCompleted = this.originalPoints.filter(
            (point: SynopticMapPointDistribution) =>
                !point.completed && point.type !== 'inicio_rota' && point.type !== 'fim_rota'
        );
        let pointStart = this.originalPoints.filter((point: SynopticMapPointDistribution) => point.type === 'inicio_rota')[0] || null;
        let pointEnd = this.originalPoints.filter((point: SynopticMapPointDistribution) => point.type === 'fim_rota')[0] || null;
        pointsCompleted = pointsCompleted.sort(
            (pointOne: SynopticMapPointDistribution, pointTwo: SynopticMapPointDistribution) => pointOne.dateFinished > pointTwo.dateFinished ? 1 : -1
        );
        this.points = pointsCompleted.concat(pointsNotCompleted);
        if (pointStart) this.points.unshift(pointStart);
        if (pointEnd) this.points.push(pointEnd);
        this.addExtraPointInTransit();
        this.setPointsDescending();
        this.addExtraPointAnomalies();        
    }

    getPointsDescending(): any[] {
        return this.pointsDescending;
    }

    setPointsDescending(): void {
        this.pointsDescending = [...this.points];
    }

    getThreePoints(): SynopticMapThreePoint[] {
        return this.threePoints;
    }
    

    getPointsMap(): PointV2[] {
        return this.pointsMap;
    }

    setPointsMap(pointId?: string): void {
        if (!this.points) {
            this.setPoints();
        }
        this.pointsMap = PointV2.from(
            this.points.map((point: SynopticMapPointDistribution) => {
                let timeForessen: string, timeAccomplished: string, time: string = null;
                if (point.totalTimeForessen && point.totalTimeForessen > 0) {
                    time = timeForessen = this.timeFormatPipe.transform(point.totalTimeForessen, 'minute');
                }
                if (point.totalTimeAccomplished && point.totalTimeAccomplished > 0) {
                    time = timeAccomplished = this.timeFormatPipe.transform(point.totalTimeAccomplished, 'minute');
                }
                if (timeAccomplished && timeForessen) time = `${timeForessen} - ${timeAccomplished}`;
                return {
                    title: point.title,
                    titleUrl: pointId === point.id
                        ? null
                        : point.urlPoint,
                    subtitle: point.subtitle,
                    isTransfer: point.isTransfer,   
                    date: point.date,
                    dateFinished: point.dateFinished,
                    time,
                    moreDetails: (point.weightForessen || point.weightAccomplished)
                        ? `${this.kilogramPipe.transform(point.weightForessen)} - ${this.kilogramPipe.transform(point.weightAccomplished)}`
                        : null,
                    status: point.status,
                    statusColor: (point.expectedUnloadingNoteDate <= point.unloadingNoteDate && point.completed) ? 'delay' : point.statusColor,
                    size: point.size,
                    color: this.pointOnTime(point) ? point.color : 'delay',
                    current: point.current,
                    anomaly: point.anomalyName,
                    anomalies: point.listAnomaliesName,
                    anomalyTotal: point.anomalies?.length || 0,
                    anomalyUrl: point.urlPointAnomaly,
                    showing: pointId !== null
                        ? pointId === point.id
                        : point.current,
                    type: point.type
                };
            })
        );
    }

    addExtraPointInTransit(): void {

        this.points = this.points.filter(point => point.type !== 'in_transit');
        if (this.points.findIndex(point => point?.status?.toLocaleLowerCase() === 'em andamento') > -1) return;
        for (let i = 0; i <= this.points.length; i++) {
            const point = this.points[i];
            const nextPoint = this.points[i + 1];            
            if (!!nextPoint && !nextPoint?.completed && !nextPoint?.current && point?.completed) {                
                this.points.splice(i + 1, 0, new SynopticMapPointDistribution({
                    id: null,
                    ordem: point.order + 1,
                    descricao: null,
                    concluido: false,
                    tipoParada: 'in_transit',
                    classificacao: null,
                    nome: nextPoint?.subtitle ? `Para ${nextPoint.subtitle}` : '',
                    dataHora: point.dateFinished,
                    dataHoraFinalizacao: null,
                    dataHoraInicio: null,
                    dataHoraFim: null,
                    codigoCliente: null,
                    codigoParada: null,
                    tipoPonto: null,
                    anomalias: null,
                    numeroViagem: this.loadNumber
                }));

                this.reorderPoints(this.points);

                break;
            }
        }
    }
    addExtraPointAnomalies(): void {
        const points = [];
        this.points.forEach((point, index) => {

            points.push(point);

            if (point.date === null || point.type === 'fim_rota' || point.type === 'in_transit' || !point.completed) {
                return;
            }

            const nextPoint = this.points[index + 1];
            const dateOne = point.date;
            const dateTwo = nextPoint?.date === null ? new Date() : nextPoint?.date;
            const anomalies = this.anomalies.filter((anomaly: Anomaly) => anomaly.creationAt >= dateOne && anomaly.creationAt < dateTwo);

            if (anomalies.length) {
                anomalies.forEach((anomaly: Anomaly) => points.push(this.prepareAnomalyPoint(anomaly)));
            }
        });

        this.points = points;
    }

    addAnomalySignalR(anomalySignalR: any): void {
        try {
            if (this.loadNumber !== anomalySignalR.rota.numeroViagem) {
                return;
            }

            const newAnomaly = new Anomaly({
                id: anomalySignalR.id,
                detalheDescricao: anomalySignalR.detalheDescricao,
                dtCreated: anomalySignalR.dtCreated,
                nome: anomalySignalR.nome,
                status: anomalySignalR.status,
                tipo: anomalySignalR.tipo,
                idParada: anomalySignalR.idParada,
                prioridade: anomalySignalR.prioridade,
                numeroViagem: anomalySignalR.rota.numeroViagem
            });

            const anomalyExistsIndex = this.points.findIndex(point => point.id === newAnomaly.id && point.type === 'anomaly');

            if (anomalyExistsIndex !== -1) {
                return;
            }

            const pointIndex = anomalySignalR.idParada !== null
                ? this.points.findIndex(point => point.id === anomalySignalR.idParada)
                : -1;

            /**
             * FLUXO 1
             * Inserir a anomalia que foi gerada dentro do raio da parada
             */
            if (pointIndex !== -1) {
                const pointFinded = this.points[pointIndex];
                const anomalyIndex = pointFinded.anomalies.findIndex(anomaly => anomaly.id === newAnomaly.id);

                if (anomalyIndex === -1) {
                    const anomalies = [...pointFinded.anomalies];
                    anomalies.push(newAnomaly);
                    pointFinded.anomalies = anomalies;
                } else {
                    pointFinded.anomalies[anomalyIndex] = newAnomaly;
                }

                this.setPointsMap();

                return;
            }

            /**
             * FLUXO 2:
             * Inserir anomalia que foi gerada fora do raio de uma parada
             */
            for (let index = 0; index < this.originalPoints.length; index++) {
                const originalPoint = this.originalPoints[index];

                if (originalPoint.type === 'fim_rota') {
                    continue;
                }

                const nextPoint = this.originalPoints[index + 1];

                if (nextPoint.date === null || originalPoint.date > newAnomaly.creationAt && nextPoint.date < newAnomaly.creationAt) {
                    const pointFindedIndex = this.points.findIndex(point => point.id === originalPoint.id);
                    this.points.splice((pointFindedIndex + 1), 0, this.prepareAnomalyPoint(newAnomaly));
                    this.setPointsMap();
                    return;
                }
            }

        } catch (e) {
            console.error('[ADD ANOMALY SIGNALR]', this.loadNumber, anomalySignalR, e);
        }
    }

    upsertPointSignalR(pointSignalR: any): void {
        try {
            if (this.loadNumber !== pointSignalR.NumeroViagem) {
                return;
            }

            const pointIndex = this.points.findIndex(point => point.id === pointSignalR.Id);

            if (pointIndex !== -1) {
                const pointFinded = this.points[pointIndex];

                pointFinded.completed = pointSignalR.Concluido;
                pointFinded.unloadingNoteDate = !pointSignalR.DataHora ? null : new Date(pointSignalR.DataHora);
                pointFinded.unloadingNoteDateFinished = !pointSignalR.DataHoraFinalizacao
                    ? null
                    : new Date(pointSignalR.DataHoraFinalizacao);

                this.addExtraPointInTransit();
                this.setPointsMap();

                return;
            }

            throw new Error('Inserção de novos pontos não implementado');

        } catch (e) {
            console.error('[UPSERT POINT SIGNALR]', this.loadNumber, pointSignalR, e);
        }
    }

    private pointOnTime(point: SynopticMapPointDistribution): boolean {
        if (point.type == 'in_transit') {
            return true;
        }
        if (point.expectedUnloadingNoteDate === null || point.unloadingNoteDate === null) {
            return true;
        }
        let unloadingNoteMotorist = moment(point.unloadingNoteDate);
        let expectedUnloadingNoteMotorist = moment(point.expectedUnloadingNoteDate);
        return unloadingNoteMotorist.isSameOrBefore(expectedUnloadingNoteMotorist);
    }

    private reorderPoints(points: SynopticMapPointDistribution[]): void {
        for (let i = 0; i < points.length; i++) {
            points[i].order = (i + 1);
        }
    }

    private prepareAnomalyPoint(anomaly: Anomaly): SynopticMapPointDistribution {
        return new SynopticMapPointDistribution({
            id: anomaly.id,
            ordem: null,
            descricao: null,
            concluido: false,
            tipoParada: 'anomaly',
            classificacao: null,
            nome: anomaly.name,
            dataHora: null,
            dataHoraFinalizacao: null,
            dataHoraInicio: anomaly.creationAt,
            dataHoraFim: null,
            codigoCliente: null,
            codigoParada: null,
            tipoPonto: null,
            anomalias: null,
            numeroViagem: this.loadNumber
        });
    }

    setThreePoints(): void {
        throw new Error("Method not implemented.");
    }
}