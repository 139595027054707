import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PerfilPermissaoService } from '../services/perfil-permissao.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class SubscriptionInterceptor implements HttpInterceptor {
    constructor(private perfilService: PerfilPermissaoService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


        if (request.url.includes("assets") || request.url.includes("microsoft")) {
            return next.handle(request);
        } else {

            const dupReq = request.clone({
                url: `${environment.express_url}/`,
                method: "POST",
                responseType: request.responseType,
                body: {
                    method: request.method,
                    url: request.params ? `${request.urlWithParams}` : request.url,
                    bodyObj: request.body,
                    logistic: this.perfilService.getCurrentLogisticaRole()?.logistica,
                    responseType: request.responseType
                }
            });

            return next.handle(dupReq);
        }

    }
}
