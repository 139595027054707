<form [formGroup]="filterForm" class="monitoring-filter-commodities">
  <div class="monitoring-filter-commodities__row">
    <div class="monitoring-filter-commodities__row__item">
      <span class="monitoring-filter-commodities__row__item__label"> Número da viagem </span>
      <app-input [autofocus]="true" formControlName="loadNumber"
        placeholder="Digite o(s) número(s) da(s) viagem(ns) (Agendamento)" separator=";" [removeAllSpaces]="true">
      </app-input>
    </div>

    <div class="monitoring-filter-commodities__row__item">
      <span class="monitoring-filter-commodities__row__item__label"> Pedido </span>
      <app-input formControlName="order" placeholder="Digite o(s) número(s) do(s) pedido(s)" separator=";"
        [removeAllSpaces]="true"></app-input>
    </div>
  </div>

  <div class="monitoring-filter-commodities__row">
    <div class="monitoring-filter-commodities__row__item">
      <span class="monitoring-filter-commodities__row__item__label"> Placa </span>
      <app-input formControlName="plate" placeholder="Digite a(s) placa(s) do(s) veículo(s)" separator=";"
        [removeAllSpaces]="true"></app-input>
    </div>

    <div class="monitoring-filter-commodities__row__item">
      <span class="monitoring-filter-commodities__row__item__label"> Transportadora </span>

      <app-select formControlName="carriers" placeholder="Selecione a transportadora"
        selectedText="transportadoras selecionadas" placeholderSearchOptions="Pesquise pela transportadora"
        placeholderTotalSelected="Transportadora(s) selecionadas" [options]="options.transportadora"></app-select>
    </div>
  </div>

  <div class="monitoring-filter-commodities__row">
    <div class="monitoring-filter-commodities__row__item">
      <span class="monitoring-filter-commodities__row__item__label"> Tipo de operação </span>

      <app-select formControlName="operations" placeholder="Selecione o tipo de operação"
        selectedText="tipos de operação selecionados" placeholderSearchOptions="Pesquise pelos tipos de operação"
        placeholderTotalSelected="Tipos de operação selecionados" [options]="options.tipooperacao"></app-select>
    </div>

    <div class="monitoring-filter-commodities__row__item">
      <span class="monitoring-filter-commodities__row__item__label"> Produto </span>

      <app-select formControlName="products" placeholder="Selecione o produto" selectedText="produtos selecionados"
        placeholderSearchOptions="Pesquise pelos produtos" placeholderTotalSelected="Produtos selecionados"
        [options]="options.produtos"></app-select>
    </div>
  </div>

  <div class="monitoring-filter-commodities__row">
    <div class="monitoring-filter-commodities__row__item">
      <span class="monitoring-filter-commodities__row__item__label"> Data de entrega </span>
      <app-date-range-picker formControlName="loadDate"></app-date-range-picker>
    </div>

    <div class="monitoring-filter-commodities__row__item">
      <span class="monitoring-filter-commodities__row__item__label"> Tipo agendamento </span>

      <app-select formControlName="scheduleType" placeholder="Selecione o tipo de agendamento"
        selectedText="tipos de agendamentos selecionados"
        placeholderSearchOptions="Pesquise pelos tipos de agendamentos"
        placeholderTotalSelected="Tipos de agendamentos selecionados" [options]="options.tipoagendamento"
        [isDisabled]="true"></app-select>
    </div>
  </div>

  <div class="monitoring-filter-commodities__row">
    <div class="monitoring-filter-commodities__row__item">
      <span class="monitoring-filter-commodities__row__item__label"> Origem (ID e Nome) </span>

      <app-select formControlName="origins" placeholder="Selecione origem" selectedText="origens selecionadas"
        placeholderSearchOptions="Pesquise pela origem" placeholderTotalSelected="Origens selecionadas"
        [options]="options.origens"></app-select>
    </div>

    <div class="monitoring-filter-commodities__row__item">
      <span class="monitoring-filter-commodities__row__item__label"> Destino (ID e Nome) </span>

      <app-select formControlName="destinations" placeholder="Selecione o destino" selectedText="destinos selecionados"
        placeholderSearchOptions="Pesquise pelo destino" placeholderTotalSelected="Destino(s) selecionado(s)"
        [options]="options.destinos"></app-select>
    </div>
  </div>

  <div class="monitoring-filter-commodities__row">
    <div class="monitoring-filter-commodities__row__item">
      <span class="monitoring-filter-commodities__row__item__label"> Status da viagem </span>

      <app-select formControlName="loadStatus" placeholder="Selecione o status da viagem"
        selectedText="Status de viagens selecionados" placeholderSearchOptions="Pesquise pelos status das viagens"
        placeholderTotalSelected="Status das viagens selecionados" [options]="options.statusviagem"></app-select>
    </div>

    <div class="monitoring-filter-commodities__row__item">
      <span class="monitoring-filter-commodities__row__item__label"> Status do atendimento </span>

      <app-select formControlName="attendanceStatus" placeholder="Selecione o status do atendimento"
        selectedText="Status dos atendimentos selecionados"
        placeholderSearchOptions="Pesquise pelos status dos atendimentos"
        placeholderTotalSelected="Status dos atendimentos selecionados" [options]="options.statusatendimento"
        [isDisabled]="true"></app-select>
    </div>
  </div>

  <div class="monitoring-filter-commodities__row">
    <div class="monitoring-filter-commodities__row__item">
      <span class="monitoring-filter-commodities__row__item__label"> Status do espelhamento </span>

      <app-select formControlName="mirroringStatus" placeholder="Selecione o status do espelhamento"
        selectedText="Status dos espelhamentos selecionados"
        placeholderSearchOptions="Pesquise pelos status dos espelhamentos"
        placeholderTotalSelected="Status dos espelhamentos selecionados" [options]="options.statusespelhamento">
      </app-select>
    </div>

    <div class="monitoring-filter-commodities__row__item">
      <span class="monitoring-filter-commodities__row__item__label"> Tipo de equipamento </span>

      <app-select formControlName="equipmentType" placeholder="Selecione o tipo do equipamento"
        selectedText="Status dos equipamentos selecionados"
        placeholderSearchOptions="Pesquise pelos status dos equipamentos"
        placeholderTotalSelected="Status dos equipamentos selecionados" [options]="options.tipoequipamento"
        [isDisabled]="true"></app-select>
    </div>
  </div>

  <div class="monitoring-filter-commodities__actions">
    <app-link *ngIf="!!filterCount" (handleClick)="onClearFilter()" [isBolder]="true">
      Limpar todos os filtros
    </app-link>

    <app-button [isDisabled]="!filterCount" (clicked)="savedFilter ? onCancel() : onCreateFilter()"
      [text]="savedFilter ? 'Cancelar' : 'Criar filtro'" [isOutlined]="true" [isMarginRight]="true"
      [isMarginLeft]="true"></app-button>

    <app-button *ngIf="(savedFilters$ | async)?.length" [isDisabled]="!filterCount"
      [text]="savedFilter ? 'Salvar' : 'Aplicar filtro'" (clicked)="savedFilter ? onSaveFilter() : onSubmit()">
    </app-button>
  </div>
</form>