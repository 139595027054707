import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AwesomeTooltipModule } from 'src/app/core/directives/tooltip/tooltip.module';
import { OpenTabDirectiveModule } from 'src/app/directives/open-tab.module';
import { DateTimeFormatModule } from 'src/app/pipes/date-time-format/date-time-format.module';
import { MaxNumberModule } from 'src/app/pipes/max-number/max-number.module';
import { MinuteModule } from 'src/app/pipes/minute/minute.module';
import { SynopticMapV2BoilerplateComponent } from './boilerplate/boilerplate.component';
import { SynopticMapV2Component } from './synoptic-map-v2.component';

@NgModule({
  declarations: [
    SynopticMapV2Component,
    SynopticMapV2BoilerplateComponent
  ],
  exports: [
    SynopticMapV2Component
  ],
  imports: [
    CommonModule,
    AwesomeTooltipModule,
    DateTimeFormatModule,
    MaxNumberModule,
    MinuteModule,
    OpenTabDirectiveModule
  ]
})
export class SynopticMapV2Module { }
