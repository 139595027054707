import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GmPolylineComponent } from './gm-polyline.component';

@NgModule({
    declarations: [GmPolylineComponent],
    exports: [GmPolylineComponent],
    imports: [
        CommonModule,
    ],    
}) export class GmPolylineModule { }
