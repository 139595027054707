<form (ngSubmit)="submitGridForm.emit(this.formArray)" class="approval-request-grid-form">
  <table class="approval-request-grid-form-table">
    <thead>
      <tr>
        <th *ngFor="let header of headers">
          {{ header.label }}
        </th>
      </tr>
    </thead>

    <tbody>
      <ng-container *ngFor="let formGroup of formArrayControlGroups; let index = index">
        <tr
          approval-request-grid-form-row
          [formGroup]="formGroup"
          (deleteRow)="formArray.removeAt(index)"
          (pasteValue)="setColumnValues(index, $event)"
          (replicateColumnValue)="replicateColumnValue(index, $event)"
        ></tr>
      </ng-container>
    </tbody>
  </table>

  <div class="approval-request-grid-form-buttons">
    <button
      type="button"
      (click)="addNewFormGroup(rowsToAdd || 1)"
      class="mat-raised-button approval-request-grid-form-add-row-button"
    >
      Inserir linha{{ rowsToAdd !== 1 ? 's' : '' }}:
      <input
        matInput
        type="number"
        [min]="1"
        [(ngModel)]="rowsToAdd"
        (click)="$event.stopPropagation()"
        (change)="handleRowsToAddChange()"
        class="approval-request-grid-form-add-row-button-input"
        [ngStyle]="{ width: 'calc(' + (rowsToAdd || 1).toString().length.toString() + 'ch + 20px)' }"
      />
    </button>

    <button type="submit" class="submit mat-raised-button" [disabled]="formArray.invalid">
      Enviar para aprovação
    </button>
  </div>
</form>
