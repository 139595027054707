import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Guid } from 'guid-typescript';
import { ErrorService } from '../services/error.service';

export class Filter { 

    public page: number;

    public pageSize: number;

    constructor() {
        this.page = 1;
        this.pageSize = 12;
    }
}