import { Component, Injectable, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Anomaly } from 'src/app/core/models';
import { Modulo } from 'src/app/core/models/perfil-permissao.model';
import { AnomalyService } from 'src/app/core/services';
import { AuthService } from 'src/app/core/services/auth.service';
import { MenuService } from 'src/app/core/services/menu.service';
import { PerfilPermissaoService } from 'src/app/core/services/perfil-permissao.service';
import { SessionStorageService } from 'src/app/core/services/session-storage.service';

@Injectable({ providedIn: 'root' })
export class BaseComponent {

  public user: any;

  public logistica: string;
  public permissoes: Modulo[];
  public perfilPermissaoModuloNome: string;
  private transportadoraRoles: string[];

  constructor(protected authService: AuthService,
    protected router: Router,
    protected menuService: MenuService,
    protected sessionStorageService: SessionStorageService,
    protected perfilPermissaoService: PerfilPermissaoService,
    perfilPermissaoModuloNome: string = null,
    backGroundColor: string = null) {
      this.menuService.setBackGroundColor(backGroundColor);
      this.perfilPermissaoModuloNome = perfilPermissaoModuloNome;
      this.transportadoraRoles = ["TransportadorPrimaria","TransportadorDistribuicao","TransportadoraAgro","TransportadoraCommodities"];
  }

  checkAuth(): void {
    this.authService.checkAuth().subscribe(isAuthenticated => {
      if (isAuthenticated) {
        this.authService.setLoginScreen(false);
        this.authService.userData.subscribe((user) => {
          this.user = user;
          var logisticaRole = this.perfilPermissaoService.getCurrentLogisticaRole();
          if(logisticaRole){
            this.permissoes = logisticaRole.permissoes;
            this.logistica = logisticaRole.logistica;
          }
        });

        this.authService.setDateLocale();
      } else {
        this.authService.setLoginScreen(true);
        this.router.navigateByUrl('/login');
      }
    });
  }

  public isReadOnly(): boolean {
    if(this.perfilPermissaoModuloNome == null)
      return false;
     return !this.permissoes || this.permissoes.find(p=> p.codigo == `${this.perfilPermissaoModuloNome}_manutencao`) == null;
  }

  public isTransportadora(): boolean {
    var logisticaRole = this.perfilPermissaoService.getCurrentLogisticaRole();
    if(!logisticaRole)
      return false;
    return this.transportadoraRoles.indexOf(logisticaRole.codigo) != -1;
  }
}
