import { Pipe, PipeTransform } from '@angular/core';
import { LogisticTypeEnum } from 'src/app/core/models/enums/logistic-type.enum';
import {
    TypeOperationAgroEnum,
    TypeOperationCommoditiesEnum,
    TypeOperation
} from 'src/app/core/models/enums/type-operation.enum';

@Pipe({
    name: 'typeOperation'
})
export class TypeOperationPipe implements PipeTransform {
    transform(value: number | string, logistic: string) {
        if (typeof value == 'string') {
            const valueInt: number = parseInt(value);
            if (isNaN(valueInt)) {
                return this.convertTypeOperationConst(value);
            }
            value = valueInt;
        }
        if (logistic == LogisticTypeEnum.AGRONOMY) {
            return this.convertTypeOperationAgroEnum(value);
        } else {
            return this.convertTypeOperationCommoditiesEnum(value);
        }

    }

    convertTypeOperationConst(value: string) {
        if (!value) return '';
        switch (value) {
            case TypeOperation.FRANGO:
                return TypeOperation.FRANGO;
            case TypeOperation.PERUS:
                return TypeOperation.PERUS;
            case TypeOperation.PINTOS_E_PERUZINHOS:
                return 'Pintos e peruzinhos';
            case TypeOperation.RACAO:
                return TypeOperation.RACAO;
            case TypeOperation.SUINOS:
                return TypeOperation.SUINOS;
            case TypeOperation.ANIMAIS_ABATE:
                return 'Animais para abate';
            case TypeOperation.OVOS_CARRINHOS:
                return 'Ovos e carrinhos';
            case TypeOperation.LEITAO:
                return 'Leitão';
            default:
                return value;
        }
    }

    convertTypeOperationAgroEnum(value: number): string {
        if (!value) return '';
        switch (value) {
            case TypeOperationAgroEnum.OVOS_CARRINHOS: 
                return 'Ovos e carrinhos';
            case TypeOperationAgroEnum.PINTOS_E_PERUZINHOS:
                return 'Pintos e peruzinhos';
            case TypeOperationAgroEnum.RACAO:
                return TypeOperation.RACAO;
            case TypeOperationAgroEnum.FRANGO:
                return TypeOperation.FRANGO;
            default:
                return value.toString();
        }
    }

    convertTypeOperationCommoditiesEnum(value: number) {
        if (!value) return '';
        switch (value) {
            case TypeOperationCommoditiesEnum.TRANSFERENCIA:
                return TypeOperation.TRANSFERENCIA;
            case TypeOperationCommoditiesEnum.COMPRA_FIXA:
                return TypeOperation.COMPRA_FIXA;
            default:
                return value.toString();
        }

    }
}