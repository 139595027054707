import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LogisticTypeEnum } from '../models/enums/logistic-type.enum';
import { PerfilPermissaoService } from '../services/perfil-permissao.service';
import { ApimConfig, ApimService } from '../services/apim.service';
import { AuthService } from '../services/auth.service';

@Injectable()
export class SubscriptionInterceptor implements HttpInterceptor {
    constructor(private perfilService: PerfilPermissaoService, private apimService: ApimService, private authService: AuthService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // let subscription: string = null;
        // const config: ApimConfig = this.apimService.config;

        if (request.url.toLowerCase().includes(environment.base_api_distribution_diaria)) {
            return from(this.handleAccess(request, next));
        } else if (request.url.toLowerCase().includes(environment.base_api_primary)) {
            return from(this.handleAccess(request, next));
        } else if (request.url.toLowerCase().includes(environment.base_api_distribution)) {
            return from(this.handleAccess(request, next));
        } else {
            return next.handle(request);
        }
        // console.warn(subscription)
        // const token = this.authService.token;

        // if (subscription) {
        //     const headers = {
        //         'X-Content-Type-Options': 'nosniff',
        //         'X-Frame-Options': 'DENY',
        //         'X-Xss-Protection': '1; mode=block',
        //         Authorization: `Bearer ${token}`
        //     };

        //     headers[config.label_authorization_key] = subscription;
        //     request = request.clone({
        //         setHeaders: headers
        //     });
        // }
        // return next.handle(request);


    }

    private async handleAccess(request: HttpRequest<any>, next: HttpHandler):
        Promise<HttpEvent<any>> {

        if (this.apimService.config.label_authorization_key == "") {
            await this.apimService.initConfig();
        }
        let subscription: string = null;
        const config: ApimConfig = this.apimService.config;

        if (request.url.toLowerCase().includes(environment.base_api_distribution_diaria)) {
            subscription = config.subscription_portal_distribution_diaria;
        } else if (request.url.toLowerCase().includes(environment.base_api_primary)) {
            subscription = config.subscription_portal_primary;
        } else if (request.url.toLowerCase().includes(environment.base_api_distribution)) {
            subscription = config.subscription_portal_distribution;
        }
        console.warn(this.apimService.config)
        const token = this.authService.token;

        const changedReq = request.clone({
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'X-Content-Type-Options': 'nosniff',
                'X-Frame-Options': 'DENY',
                'X-Xss-Protection': '1; mode=block',
                Authorization: `Bearer ${token}`,
                [config.label_authorization_key]: subscription,
            })

        });

        return next.handle(changedReq).toPromise();
    }
}
