import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { MessageComponent } from 'src/app/components/shared/message/message.component';

@Injectable({
    providedIn: 'root'
})
export class MessageService {
    horizontalPosition: MatSnackBarHorizontalPosition = 'right';
    verticalPosition: MatSnackBarVerticalPosition = 'top';

    constructor(private matSnackBar: MatSnackBar) { }

    success(
        message: string,
        icon: string = null,
        duration: number = 5000,
        button: any = null,
        isHtml: boolean = false,
        methodObservable: any = null,
        hideIcon: boolean = false,
    ): void {
        this.message("success", message, icon, duration, button, isHtml, hideIcon, methodObservable);
    }


    error(
        message: string,
        icon: string = null,
        duration: number = 5000,
        button: any = null,
        isHtml: boolean = false,
        methodObservable: any = null,
        hideIcon: boolean = false,
    ): void {
        this.message("error", message, icon, duration, button, isHtml, hideIcon, methodObservable,);
    }

    message(
        type: "success" | "error" | "caution",
        message: string,
        icon: string = null,
        duration: number = 5000,
        button: any = null,
        isHtml: boolean = false,
        hideIcon: boolean = false,
        methodObservable: any = null
    ): void {
        this.matSnackBar.openFromComponent(
            MessageComponent,
            {
                data: {
                    message,
                    isHtml,
                    type,
                    icon,
                    button,
                    hideIcon,
                    methodObservable
                },
                verticalPosition: this.verticalPosition,
                horizontalPosition: this.horizontalPosition,
                duration,
                panelClass: [type == "error" ? 'red-snackbar' : type == "success" ? 'green-snackbar' : 'yellow-snackbar']
            }
        );
    }
}
