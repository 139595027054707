import { Pagination } from '../pagination.model';

export class TableResult<T>
{
    public currentPage: number;
    public pageSize: number;
    public totalPages: number;
    public totalRegisters: number;
    public registers: Array<T>;

    public getPagination(): Pagination{
        return new Pagination(this.currentPage, this.pageSize, this.totalPages, this.totalRegisters);
    }

    constructor(tableResult: any){
        this.currentPage = tableResult.currentPage;
        this.pageSize = tableResult.pageSize;
        this.totalPages = tableResult.totalPages;
        this.totalRegisters = tableResult.totalRegisters;
        this.registers = tableResult.registers;
    }
}