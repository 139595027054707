import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { finalize } from 'rxjs/operators';
import { LoaderService } from 'src/app/components/shared/loader/loader.service';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
    requestCount = 0;

    constructor(public loaderService: LoaderService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.urlWithParams.match('loading=false')) {
            return next.handle(request);
        }

        this.requestCount++;

        this.loaderService.startLoading();
        return next
            .handle(request)
            .pipe(finalize(() => {
                this.requestCount--;

                if (this.requestCount === 0) {
                    this.loaderService.finishLoading();
                }
            }));
    }
}
