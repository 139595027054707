import { LogisticTypeEnum } from "./enums/logistic-type.enum";

export class PerfilPermissao {
    public logistica: string;
    public codigo: string;
    public nome: string;
    public permissoes: Modulo[];
    public visualizacao: string;

    constructor(logistica: string, codigo: string , nome: string, permissoes: Modulo[] = []) {
        this.logistica = logistica;
        this.codigo = codigo;
        this.nome = nome;
        this.permissoes = permissoes;
        this.visualizacao = this.getLogisticName(logistica);
    }

    private getLogisticName(logistic: string) {
        switch (logistic) {
          case LogisticTypeEnum.DISTRIBUITION:
            return 'Distribuição';
          case LogisticTypeEnum.PRIMARY:
            return 'Primária';
          case LogisticTypeEnum.AGRONOMY:
            return 'Agro';
          case LogisticTypeEnum.COMMODITIES:
            return 'Commodities';
          default:
            console.error(`Logística ${logistic} inexistente`);
            return '';
        }
    }
}

export class Modulo{
    public codigo: string;
    public nome: string;

    constructor(codigo: string, nome: string){
        this.codigo = codigo;
        this.nome = nome;
    }
}