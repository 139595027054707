import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Output
} from '@angular/core';
import { ModalService, TModal } from './modal.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalComponent implements AfterViewInit {

  @Output() public modalState = new EventEmitter<boolean>();

  public modals: TModal[] = [];

  public modalCurrent: TModal;

  constructor(private modalService: ModalService, private cdref: ChangeDetectorRef) { }

  ngAfterViewInit(): void {
    this.modalService.createModal().subscribe((modal: TModal) => {
      this.modals.push(modal);
      this.modalCurrent = modal;
      this.cdref.detectChanges();
    });

    this.modalService.removeModal().subscribe((uuid: string) => {
      this.modals = this.modals.filter((modal: TModal) => modal.uuid != uuid);
      this.modalCurrent = this.modals[this.modals.length -1];
      this.cdref.detectChanges();
    });
  }
}
