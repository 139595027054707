
<div class="save-filter">
    <app-modal-window [modalId]="modalId" title="Salvar filtro" subTitle="Crie um nome para seu filtro">
        <form [formGroup]="saveFormGroup">
            <div class="save-filter__row">
                <div class="save-filter__row__item">
                    <span class="save-filter__row__item__label"> Nome do filtro </span>
                    <app-input 
                        formControlName="name" 
                        placeholder="Nomeie o seu filtro" 
                        [maxlength]="40"
                        [messageError]="messageError"
                        [invalid]="submitted && saveFormGroup.get('name').invalid">
                    </app-input>
                </div>
            </div>

            <div class="save-filter__actions">
                <app-button (clicked)="handleBack()" text="Voltar" [isOutlined]="true" [isMarginRight]="true"></app-button>
                <app-button (clicked)="handleSaveFilter()" [isDisabled]="saveFormGroup.get('name').hasError('required')" text="Salvar"></app-button>
            </div>
        </form>
    </app-modal-window>
</div>
