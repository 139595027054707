import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DateRangePickerComponent } from './date-range-picker.component';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
  declarations: [
    DateRangePickerComponent
  ],
  exports: [
    DateRangePickerComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule
  ]
})
export class DateRangePickerModule { }
