import { Anomaly } from 'src/app/models/anomaly.model';
import { StepDetailDistributionClientCharge } from './charge.model';
import { StepDetailDistributionClientCommercial } from './commercial.model';
import { StepDetailDistributionClientInfo } from './info.model';
import { StepDetailDistributionClientTracking } from './tracking.model';

export class StepDetailDistributionClient {
    constructor(
        public anomalies: Anomaly[],
        public info: StepDetailDistributionClientInfo,
        public commercial: StepDetailDistributionClientCommercial,
        public tracking: StepDetailDistributionClientTracking,
        public charge: StepDetailDistributionClientCharge[]
    ) { }

    get title(): string {
        let titled = this.info.code;

        titled = !titled
            ? this.info.fullName
            : `${this.info.code} - ${this.info.fullName}`;

        return titled;
    }
}
