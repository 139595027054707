import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GmDirectionComponent } from './gm-direction.component';

@NgModule({
    declarations: [GmDirectionComponent],
    exports: [GmDirectionComponent],
    imports: [
        CommonModule,
    ],    
}) export class GmDirectionModule { }
