import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, Subject } from 'rxjs';
import { MenuItemOriginEnum } from 'src/app/enums/menu-item-origin.enum';
import { BreadCrumbItem } from '../models/bread-crumb-item.model';
import { MenuItem } from '../models/menu-item.model';
import { PerfilPermissao } from '../models/perfil-permissao.model';
import { AuthService } from './auth.service';
import { PerfilPermissaoService } from './perfil-permissao.service';

@Injectable({ providedIn: 'root' })
export class MenuService {

    public onChangeBackGroundColor: Subject<string>;
    public onChangeBreadCrumb: Subject<BreadCrumbItem[]>;
    public onChangeMenus : Subject<PerfilPermissao>;
    public onChangeActiveMenu : Subject<string> = new Subject();

    constructor(public authService: AuthService, private perfilPermissaoService: PerfilPermissaoService) {
        this.onChangeBreadCrumb = new Subject();
        this.onChangeBackGroundColor = new Subject();
        this.onChangeMenus = new Subject();
    }

    public setBreadCrumb(breadCrumb: BreadCrumbItem[]) {
        if (!breadCrumb) {
            return this.onChangeBreadCrumb.next(breadCrumb);
        }

        const role: PerfilPermissao = this.perfilPermissaoService.getCurrentLogisticaRole();

        const logistic = new BreadCrumbItem(role?.visualizacao, null);

        breadCrumb.unshift(logistic);

        this.onChangeBreadCrumb.next(breadCrumb);
    }

    public setBackGroundColor(backGroundColor: string){
        this.onChangeBackGroundColor.next(backGroundColor);
    }

    public setMenus(perfilPermissao: PerfilPermissao){
        this.onChangeMenus.next(perfilPermissao);
    }

    public setActiveMenu(origin: MenuItemOriginEnum): void {
        this.onChangeActiveMenu.next(origin);
    }
}
