<span [id]="id" class="select" [ngClass]="{'disabled': isDisabled, 'invalid': invalid}" (click)="toggle()" #selectTarget>
    <span *ngIf="selected && selected.length === 0">
      {{placeholder}}
    </span>

    <span *ngIf="selected && selected.length === 1" class="select__one-selected">
      {{selected[0].display}}
    </span>

    <span *ngIf="selected && selected.length > 1">
      {{selected.length}} {{selectedText}}
    </span>

    <span class="select__selection__icon">
      <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.02671 9.57756C7.83604 9.57756 7.65404 9.49556 7.52737 9.35356L0.111373 0.970894C0.052849 0.904678 0.0230256 0.817926 0.0284642 0.729721C0.0339027 0.641517 0.0741577 0.559085 0.140373 0.500561C0.206589 0.442036 0.293342 0.412213 0.381546 0.417652C0.469751 0.42309 0.552182 0.463345 0.610707 0.529561L8.02737 8.91156L15.4447 0.529561C15.5033 0.463385 15.5858 0.423138 15.674 0.41764C15.7622 0.412141 15.849 0.44184 15.9154 0.500227C15.9482 0.529243 15.9749 0.564436 15.9941 0.603796C16.0133 0.643156 16.0246 0.685912 16.0272 0.729621C16.0299 0.77333 16.0239 0.817135 16.0096 0.858534C15.9954 0.899933 15.9731 0.938113 15.944 0.970894L8.52671 9.35356C8.46383 9.42399 8.38679 9.48035 8.30062 9.51895C8.21446 9.55755 8.12112 9.57752 8.02671 9.57756Z" fill="#5B5C60"/>
      </svg>
    </span>
</span>

<div *popover="expand; target: selectTarget; closed: toggle" [ngClass]="placement == 'bottom' ? 'select__viewing-bottom' : 'select__viewing-top'">
  <header [ngClass]="placement == 'bottom' ? 'select__viewing-bottom__searcher' : 'select__viewing-top__searcher'">
    <div [ngClass]="placement == 'bottom' ? 'select__viewing-bottom__searcher__input' : 'select__viewing-top__searcher__input'">
      <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.5981 13C12.5629 13.0001 12.528 12.9931 12.4954 12.9795C12.4629 12.966 12.4333 12.9461 12.4083 12.9209L7.58139 8.04375C6.74924 8.79457 5.67241 9.20919 4.55677 9.20833C2.04411 9.20833 0 7.14296 0 4.60417C0 2.06538 2.04411 0 4.55677 0C7.06942 0 9.11354 2.06538 9.11354 4.60417C9.11444 5.73153 8.70387 6.81963 7.96041 7.66025L12.7874 12.5374C12.8249 12.5753 12.8505 12.6235 12.8609 12.6761C12.8713 12.7286 12.866 12.7831 12.8458 12.8326C12.8255 12.8822 12.7912 12.9245 12.7471 12.9543C12.703 12.9841 12.6512 13 12.5981 13ZM4.55677 0.541667C2.34003 0.541667 0.53609 2.36437 0.53609 4.60417C0.53609 6.84396 2.34003 8.66667 4.55677 8.66667C6.7735 8.66667 8.57745 6.84396 8.57745 4.60417C8.57745 2.36437 6.7735 0.541667 4.55677 0.541667Z" fill="#5B5C60"/>
      </svg>

      <input
        type="text"
        [ngClass]="placement == 'bottom' ? 'select__viewing-bottom__searcher__input__input' : 'select__viewing-top__searcher__input__input'"
        [placeholder]="placeholderSearchOptions"
        [(ngModel)]="inputSearchOptions"
        (ngModelChange)="handleSearch($event)"
        (keyup)="handleFilter()"
      />

      <svg *ngIf="!inputSearchOptions" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.708 13.9992C13.6697 13.9993 13.6317 13.9918 13.5963 13.9772C13.5609 13.9625 13.5286 13.9411 13.5015 13.914L6.99967 7.4116L0.497841 13.914C0.470761 13.9411 0.438605 13.9627 0.403209 13.9773C0.367813 13.992 0.32987 13.9996 0.291547 13.9996C0.253223 13.9997 0.21527 13.9921 0.179853 13.9775C0.144436 13.9629 0.11225 13.9414 0.0851323 13.9143C0.0580144 13.8872 0.0364957 13.8551 0.0218049 13.8197C0.00711407 13.7843 -0.000461125 13.7463 -0.000488208 13.708C-0.000515292 13.6697 0.00700627 13.6317 0.021647 13.5963C0.0362878 13.5609 0.057761 13.5287 0.0848407 13.5016L6.58667 6.99977L0.085424 0.497352C0.0306568 0.442663 -0.000141844 0.368456 -0.000196542 0.291059C-0.00025124 0.213661 0.0304425 0.139411 0.0851323 0.084644C0.139822 0.0298768 0.214028 -0.000921791 0.291426 -0.00097649C0.368824 -0.00103119 0.443073 0.0296625 0.497841 0.0843524L6.99967 6.58677L13.5021 0.0849357C13.5292 0.0578177 13.5613 0.036299 13.5967 0.0216082C13.6321 0.00691745 13.6701 -0.000657749 13.7084 -0.000684834C13.7467 -0.000711918 13.7847 0.00680964 13.8201 0.0214504C13.8555 0.0360911 13.8877 0.0575644 13.9148 0.084644C13.9419 0.111724 13.9634 0.143879 13.9781 0.179275C13.9928 0.214671 14.0004 0.252614 14.0004 0.290938C14.0004 0.329261 13.9929 0.367215 13.9783 0.402632C13.9636 0.438048 13.9422 0.470234 13.9151 0.497352L7.41209 6.99919L13.9139 13.501C13.9548 13.5418 13.9826 13.5937 13.994 13.6503C14.0053 13.7069 13.9995 13.7656 13.9775 13.8189C13.9554 13.8723 13.9181 13.9179 13.8701 13.95C13.8221 13.982 13.7657 13.9992 13.708 13.9992Z" fill="#5B5C60"/>
      </svg>

      <svg *ngIf="inputSearchOptions" (click)="handleClearInputSearchOptions()" style="cursor: pointer;" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.708 13.9992C13.6697 13.9993 13.6317 13.9918 13.5963 13.9772C13.5609 13.9625 13.5286 13.9411 13.5015 13.914L6.99967 7.4116L0.497841 13.914C0.470761 13.9411 0.438605 13.9627 0.403209 13.9773C0.367813 13.992 0.32987 13.9996 0.291547 13.9996C0.253223 13.9997 0.21527 13.9921 0.179853 13.9775C0.144436 13.9629 0.11225 13.9414 0.0851323 13.9143C0.0580144 13.8872 0.0364957 13.8551 0.0218049 13.8197C0.00711407 13.7843 -0.000461125 13.7463 -0.000488208 13.708C-0.000515292 13.6697 0.00700627 13.6317 0.021647 13.5963C0.0362878 13.5609 0.057761 13.5287 0.0848407 13.5016L6.58667 6.99977L0.085424 0.497352C0.0306568 0.442663 -0.000141844 0.368456 -0.000196542 0.291059C-0.00025124 0.213661 0.0304425 0.139411 0.0851323 0.084644C0.139822 0.0298768 0.214028 -0.000921791 0.291426 -0.00097649C0.368824 -0.00103119 0.443073 0.0296625 0.497841 0.0843524L6.99967 6.58677L13.5021 0.0849357C13.5292 0.0578177 13.5613 0.036299 13.5967 0.0216082C13.6321 0.00691745 13.6701 -0.000657749 13.7084 -0.000684834C13.7467 -0.000711918 13.7847 0.00680964 13.8201 0.0214504C13.8555 0.0360911 13.8877 0.0575644 13.9148 0.084644C13.9419 0.111724 13.9634 0.143879 13.9781 0.179275C13.9928 0.214671 14.0004 0.252614 14.0004 0.290938C14.0004 0.329261 13.9929 0.367215 13.9783 0.402632C13.9636 0.438048 13.9422 0.470234 13.9151 0.497352L7.41209 6.99919L13.9139 13.501C13.9548 13.5418 13.9826 13.5937 13.994 13.6503C14.0053 13.7069 13.9995 13.7656 13.9775 13.8189C13.9554 13.8723 13.9181 13.9179 13.8701 13.95C13.8221 13.982 13.7657 13.9992 13.708 13.9992Z" fill="#003087"/>
      </svg>
    </div>
  </header>

  <span *ngIf="selected && selected.length > 0" [ngClass]="placement == 'bottom' ? 'select__viewing-bottom__total' : 'select__viewing-top__total'">
    {{selected.length}} {{placeholderTotalSelected}}
  </span>

  <main [ngClass]="placement == 'bottom' ? 'select__viewing-bottom__options' : 'select__viewing-top__options'">    
    <span *ngIf="multipleSelect" [ngClass]="placement == 'bottom' ? 'select__viewing-bottom__options__item' : 'select__viewing-top__options__item'" (click)="markAll()">
      <input [ngClass]="placement == 'bottom' ? 'select__viewing-bottom__options__item__checkbox' : 'select__viewing-top__options__item__checkbox'" type="checkbox" [checked]="selected.length === options.length" />
      <span>Selecionar tudo</span>
    </span>
    <div *ngIf="options.length == 0">
      <p>Nenhum resultado encontrado</p>
    </div>
    <ng-container *ngIf="selectedFiltered.length === 0">
      <span *ngFor="let option of options" [ngClass]="placement == 'bottom' ? 'select__viewing-bottom__options__item' : 'select__viewing-top__options__item'" (click)="multipleSelect ? markOption(option) : markOnlyOneOption(option)">
        <input 
          [type]="multipleSelect ? 'checkbox' : 'radio'" 
          [ngClass]="placement == 'bottom' ? 'select__viewing-bottom__options__item__checkbox' : 'select__viewing-top__options__item__checkbox'" 
          [checked]="option.checked" />
        <span [outerHTML]="option.display"></span>
      </span>
    </ng-container>

    <ng-container *ngIf="selectedFiltered.length > 0">
      <span *ngFor="let option of selectedFiltered" [ngClass]="placement == 'bottom' ? 'select__viewing-bottom__options__item' : 'select__viewing-top__options__item'" (click)="multipleSelect ? markOption(option) : markOnlyOneOption(option)">
        <input 
          [type]="multipleSelect ? 'checkbox' : 'radio'" 
          [ngClass]="placement == 'bottom' ? 'select__viewing-bottom__options__item__checkbox' : 'select__viewing-top__options__item__checkbox'" 
          [checked]="option.checked" />
        <span [outerHTML]="option.display"></span>
      </span>

    </ng-container>
  </main>
</div>
