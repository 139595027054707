export enum ModalAnimation {
  SLIDE_UP = 'slideUp',
  FADE = 'fade',
}

export interface ModalOptions {

  /**
   * Modal open close animation
   */
  animation?: ModalAnimation;

  /**
   * Close when click outside of modal content
   * */
  backdropDismiss?: boolean;

  /**
   * Close when press ESC key
   */
  keyboardDismiss?: boolean;

  /**
   * Not allow scroll modal
   */
  noOverflow?: boolean;

  verticalAlign?: 'top' | 'center';

  openFrom?: { x?: number, y?: number , top?: number, left?: number };
}
