export class ChatMessageTypeTopic {
    #title: string;

    #origin: string;

    constructor({
        titulo,
        origem
    }) {
        this.#title = titulo;
        this.#origin = origem;
    }

    get title(): string {
        return this.#title;
    }

    get origin(): string {
        return this.#origin;
    }
}
