import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SelectComponent } from './select.component';
import { PopoverModule } from '../../core/directives/popover/popover.module';

@NgModule({
  declarations: [
    SelectComponent
  ],
  exports: [
    SelectComponent
  ],
    imports: [
      CommonModule,
      FormsModule,
      PopoverModule
    ]
})
export class SelectModule { }
