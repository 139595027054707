import { Component, EventEmitter, Input, OnInit, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'gm-circle',
  template: ''
})
export class GmCircleComponent implements OnInit, OnChanges, OnDestroy {

  @Input() map: google.maps.Map;

  @Input() latitude: number;

  @Input() longitude: number;

  @Input() radius: number = 0;

  @Input() strokeWeight: number = 2;

  @Input() strokeOpacity = 0.8;

  @Input() strokeColor = '#5f5d9c';

  @Input() fillColor: string = '#5f5d9c';

  @Input() fillOpacity: number = 0.35;

  @Input() draggable: boolean = false;

  @Input() editable: boolean = false;

  @Input() clickable: boolean = false;

  @Input() visible: boolean = true;

  @Output() onCircleComplete = new EventEmitter<google.maps.Circle>();

  circle: google.maps.Circle;

  ngOnInit(): void {
    if (!this.map) {
      console.error("You need pass 'map' to render 'circle'");
      return;
    };
    if (this.radius < 1) return;
    this.circle = new google.maps.Circle({
      strokeColor: this.strokeColor,
      strokeWeight: this.strokeWeight,
      strokeOpacity: this.strokeOpacity,
      fillColor: this.fillColor,
      fillOpacity: this.fillOpacity,
      radius: this.radius,
      center: { lat: this.latitude, lng: this.longitude },
      draggable: this.draggable,
      editable: this.editable,
      clickable: this.clickable,
      visible: this.visible
    });
    this.circle.setMap(this.map);
    this.onCircleComplete.emit(this.circle);
  }

  ngOnChanges(changes: SimpleChanges): void {
    try {
      if (changes && this.map && this.circle) {
        if (changes.radius.currentValue != changes.radius.previousValue) {
          this.circle.setRadius(changes.radius.currentValue);
        }
      }
    } catch (e) {
      console.error(["Error to render google maps circle", e]);
    }

  }

  ngOnDestroy(): void {
    if (this.circle) {
      this.circle.setMap(null)
    }
  }
}
