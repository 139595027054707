import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Guid } from 'guid-typescript';

export enum ModalEnum {
    DRAWER_ANOMALIES = 'drawer-anomalies',
    WINDOW_LOADS_RELATED = 'window-loads-related',
    WINDOW_SHIPPINGS = 'window-shippings',
    WINDOW_DEVOLUCAO = 'window-devolucao',
    WINDOW_PARADA_RAIO = 'window-parada-raio',
    WINDOW_ORDERS = 'window-orders',
    WINDOW_FINISH_LOAD = 'window-finish-load',
    WINDOW_FINISH_STEP = 'window-finish-step',
    WINDOW_MONITORING_FILTER = 'window-monitoring-filter',
    WINDOW_MONITORING_FILTER_SAVE = 'window-monitoring-filter-save',
    WINDOW_MONITORING_FILTER_MY = 'window-monitoring-filter-my-filters',
    WINDOW_MONITORING_FILTER_REMOVE = 'window-monitoring-filter-remove',    
}

export type TModal = {
    uuid?: string
    name: ModalEnum
    data?: any
    extraData?: any
};

@Injectable({
    providedIn: 'root'
})
export class ModalService {

    #createModal = new Subject<TModal>();

    #removeModal = new Subject<string>();

    public setModal(modal: TModal): string {
        if (!modal) {
            console.error('Necessário passar os dados do modal');
            return;
        }

        modal.uuid = Guid.create().toString();

        this.#createModal.next(modal);

        return modal.uuid.toString();
    }

    public unsetModal(uuid: string): void {
        if (!uuid) {
            console.error('Necessário passar identificador único do modal e gravar em seu componente requisitante');
            return;
        }

        this.#removeModal.next(uuid);
    }

    public createModal(): Observable<TModal> {
        return this.#createModal.asObservable();
    }

    public removeModal(): Observable<string> {
        return this.#removeModal.asObservable();
    }
}
