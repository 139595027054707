import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MessageService } from 'src/app/core/services';
import { FilterService } from 'src/app/core/services/filter.service';
import { UserFilterScreenMonitoring } from 'src/app/models/user-filter/screen/monitoring.model';
import { UserFilter} from 'src/app/models/user-filter/user-filter.model';
import { ModalEnum, ModalService, TModal } from '../../../modal.service';
import { ModalWindowMonitoringFilterService, TCurrentFilter } from '../monitoring-filter.service';
import * as moment from 'moment';
import { DateUTCPipe } from 'src/app/core/custom-pipes/date-utc.pipe';

interface IFilterForm {
  transportadora: string[];
  origens: string[];
  destinos: string[];
  tipoagendamento: string[];
  tipooperacao: string[];
  produtos: string[];
  statusviagem: string[];
  statusatendimento: string[];
  statusespelhamento: string[];
  tipoequipamento: string[];
}

@Component({
  selector: 'app-modal-window-monitoring-filter-commodities',
  templateUrl: './commodities.component.html',
  styleUrls: ['./commodities.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class ModalWindowMonitoringFilterCommoditiesComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  @Input() public modalId: string;

  @Input() public myFiltersModalId: string;

  @Input() public savedFilterId: string;

  @Input() public isFirstFilter = false;

  @Output() filterCountChange = new EventEmitter<number>();

  public filterForm: FormGroup = null;

  public options: IFilterForm = null;

  public uuidModalMonitoringSaveFilter: string;

  public currentFilter: TCurrentFilter;

  public savedFilter: UserFilter<UserFilterScreenMonitoring>;

  public savedFilters$ = this.modalWindowMonitoringFilterService.savedFilters$;

  public filterCount = 0;

  constructor(
    private filterService: FilterService,
    private cdref: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private modalWindowMonitoringFilterService: ModalWindowMonitoringFilterService,
    private modalService: ModalService,
    private messageService: MessageService,
    protected dateUTC: DateUTCPipe,
  ) { }

  public ngOnInit(): void {
    const filter: UserFilterScreenMonitoring = this.getFilterParameters();

    let loadDate = null;

    if (filter?.loadStartDate && filter?.loadEndDate) {
      loadDate = {
        startDate: filter?.loadStartDate ? this.dateUTC.transform(filter?.loadStartDate) : null,
        endDate: filter?.loadEndDate ? this.dateUTC.transform(filter?.loadEndDate) : null,
      };
    }

    this.filterForm = this.formBuilder.group({
      loadNumber: new FormControl(filter?.loadNumber),
      order: new FormControl(filter?.orders),
      plate: new FormControl(filter?.plate),
      carriers: new FormControl(),
      operations: new FormControl(),
      products: new FormControl(),
      loadDate: new FormControl(loadDate),
      scheduleType: new FormControl(),
      origins: new FormControl(),
      destinations: new FormControl(),
      loadStatus: new FormControl(),
      attendanceStatus: new FormControl(),
      equipmentType: new FormControl(),
      mirroringStatus: new FormControl()
    });

    this.options = {
      transportadora: [],
      destinos: [],
      origens: [],
      tipoagendamento: [],
      tipooperacao: [],
      produtos: [],
      statusatendimento: [],
      statusviagem: [],
      statusespelhamento: [],
      tipoequipamento: []
    };

    this.cdref.detectChanges();
  }

  public ngOnChanges(_: SimpleChanges): void {
    this.countFilters();
  }

  public ngAfterViewInit(): void {
    this.filterForm.valueChanges.subscribe(values => values && this.countFilters());
    this.getOptions();
  }

  public ngOnDestroy(): void {
    this.options = null;
    this.filterForm = null;
  }

  private getOptions(): void {
    const options = this.modalWindowMonitoringFilterService.getOptions();

    if (options) {
      this.options = this.prepareOptions(options);
      this.countFilters(false);
      this.cdref.detectChanges();
      return;
    }

    this.filterService.getSelectBoxOptions(
      [
        'transportadora',
        'destinos',
        'origens',
        'produtos',
        'tipoequipamento'
      ],
      'commodities'
    ).subscribe(data => {
      const fields = this.prepareOptions(data);

      this.options = fields;

      this.modalWindowMonitoringFilterService.setOptions(data);

      this.countFilters(false);

      this.cdref.detectChanges();
    });
  }

  public onSubmit(): void {
    const currentFilter: TCurrentFilter = {
      mode: 'filtered',
      filter: this.getFakeUserFilter()
    };

    this.modalWindowMonitoringFilterService.setCurrentFilter(currentFilter, 'commodities');
    this.modalService.unsetModal(this.modalId);
  }

  public onCreateFilter(): void {
    const modal: TModal = {
      name: ModalEnum.WINDOW_MONITORING_FILTER_SAVE,
      data: {
        uuidModalMonitoringFilter: this.modalId,
        filtersResult: this.getFakeUserFilter().parameters.serialize()
      }
    };

    this.uuidModalMonitoringSaveFilter = this.modalService.setModal(modal);
  }

  public onSaveFilter(): void {
    this.savedFilter.parameters = this.getParameters(false);
    this.savedFilter.default = true;

    const currentFilter: TCurrentFilter = {
      mode: 'saved',
      filter: this.savedFilter
    };

    const defaultSavedFilter = this.modalWindowMonitoringFilterService.getDefaultSavedFilter();

    if (defaultSavedFilter && defaultSavedFilter.id !== this.savedFilter.id) {
      defaultSavedFilter.default = false;
      this.modalWindowMonitoringFilterService.updateSavedFilter(defaultSavedFilter, true, 'commodities');
    }

    this.modalWindowMonitoringFilterService.updateSavedFilter(this.savedFilter, true, 'commodities');
    this.modalWindowMonitoringFilterService.setCurrentFilter(currentFilter, 'commodities');

    this.modalService.unsetModal(this.modalId);
    this.modalService.unsetModal(this.myFiltersModalId);
    this.messageService.success('Filtro salvo com sucesso');
  }

  public onClearFilter(): void {
    this.filterForm.reset();
    this.cdref.detectChanges();
  }

  public onCancel(): void {
    this.modalService.unsetModal(this.modalId);
  }

  private getFakeUserFilter(): UserFilter<UserFilterScreenMonitoring> {
    return new UserFilter<UserFilterScreenMonitoring>({
      id: null,
      idUsuario: null,
      nomeFiltro: null,
      tela: 'monitoramento-commodities',
      definicaoFiltro: this.getParameters(),
      filtroPadrao: false
    });
  }

  private getParameters(isStringify = true): any {
    const {
      loadNumber,
      plate,
      loadDate,
      carriers,
      origins,
      destinations,
      operations,
      equipmentType,
      products,
      order,
      scheduleType,
      loadStatus,
      mirroringStatus
    } = this.filterForm.controls;

    const params = {
      numeroViagem: loadNumber.value || [],
      pedidos: order.value || [],
      placa: plate.value || [],
      transportadora: carriers.value
        ? carriers.value.map(carrier => carrier.key.replace('transportadora;', ''))
        : null,
      tipoProcessoCommodities: operations.value
        ? operations.value.map(operation => operation.key)
        : null,
      produtos: products.value
        ? products.value.map(product => product.key.replace('produtos;', ''))
        : null,
      dataViagemInicio: loadDate.value?.startDate ? moment(loadDate.value?.startDate).format('YYYY-MM-DD') : null,
      dataViagemFim: loadDate.value?.endDate ? moment(loadDate.value?.endDate).format('YYYY-MM-DD') : null,
      tipoAgendamento: scheduleType.value
        ? scheduleType.value.map(operation => operation.key)
        : null,
      origem: origins.value ? origins.value.map(origin => origin.key.replace('origens;', '')) : null,
      destino: destinations.value ? destinations.value.map(destination => destination.key.replace('destinos;', '')) : null,
      statusViagem: loadStatus.value
        ? loadStatus.value.map(operation => operation.key)
        : null,
      statusEspelhamento: mirroringStatus.value
        ? mirroringStatus.value.map(operation => operation.key)
        : null,
      tipoEquipamento: equipmentType.value
        ? equipmentType.value.map(type => type.key.replace('tipoequipamento;', ''))
        : null
    };

    return isStringify
      ? JSON.stringify(params)
      : new UserFilterScreenMonitoring(params);
  }

  private getKeyCurrentFilterByKey(key: string): any {
    const filter = this.getFilterParameters();

    let options = [];

    switch (key) {
      case 'transportadora':
        options = filter?.carriers || [];
        break;
      case 'origens':
        options = filter?.origins || [];
        break;
      case 'destinos':
        options = filter?.destinations || [];
        break;
      case 'produtos':
        options = filter?.products || [];
        break;
      case 'tipoequipamento':
        options = filter?.equipmentType || [];
        break;
    }

    const filtersOptionKey = {};

    options.forEach(option => filtersOptionKey[`${key};${option}`] = null);

    return filtersOptionKey;
  }

  public prepareOptions(options: any): any {
    const fields = {
      transportadora: [],
      destinos: [],
      origens: [],
      produtos: [],
      tipoequipamento: [],
      tipooperacao: [],
      tipoagendamento: [],
      statusviagem: [],
      statusespelhamento: []
    };

    const selectedFields = {
      transportadora: [],
      destinos: [],
      origens: [],
      produtos: [],
      tipoequipamento: [],
      tipooperacao: [],
      tipoagendamento: [],
      statusviagem: [],
      statusespelhamento: []
    };

    Object.keys(options).forEach(field => {
      const keyValueFilterData = this.getKeyCurrentFilterByKey(field);
      fields[field] = options[field].map((option: any) => {
        const item = {
          key: option.id,
          display: option.valor,
          value: option.label,
          checked: keyValueFilterData ? option.id in keyValueFilterData : false
        };

        if (item.checked) {
          selectedFields[field].push(item);
        }

        return item;
      });
    });

    this.prepareFixedFields(fields);

    // campos dinâmicos que vem da API
    this.filterForm.controls.carriers.setValue(selectedFields.transportadora.filter(_ => _.checked));
    this.filterForm.controls.destinations.setValue(selectedFields.destinos.filter(_ => _.checked));
    this.filterForm.controls.origins.setValue(selectedFields.origens.filter(_ => _.checked));
    this.filterForm.controls.products.setValue(selectedFields.produtos.filter(_ => _.checked));



    // campos fixos
    this.filterForm.controls.operations.setValue(fields.tipooperacao.filter(_ => _.checked));
    this.filterForm.controls.scheduleType.setValue(fields.tipoagendamento.filter(_ => _.checked));
    this.filterForm.controls.loadStatus.setValue(fields.statusviagem.filter(_ => _.checked));
    this.filterForm.controls.mirroringStatus.setValue(fields.statusespelhamento.filter(_ => _.checked));

    return fields;
  }

  private prepareFixedFields(fields: any): void {
    const parameters = this.getFilterParameters();

    const loadStatus: any[] = parameters.loadStatus; // TODO: loadStatus deve ser um ENUM e não um texto, mudar assim que possível

    this.prepareOperationsType(fields, parameters.processTypeCommodities || []);
    this.prepareScheduleType(fields, parameters.scheduleType || []);
    this.prepareLoadStatus(fields, loadStatus || []);
    this.prepareMirroringStatus(fields, parameters.mirroringStatus || []);
  }

  private prepareOperationsType(fields: any, values: number[]): void {
    fields.tipooperacao = [
      { key: 1, display: 'Transferência', value: 'Transferência', checked: values.some(value => value === 1) },      
      { key: 2, display: 'Compra fixa', value: 'Compra fixa', checked: values.some(value => value === 2) },
      // TODO: Não será entregue no MVP 1
      //{ key: 2, display: 'Depósito', value: 'Depósito', checked: values.some(value => value === 2) }
    ];
  }

  private prepareScheduleType(fields: any, values: number[]): void {
    fields.tipoagendamento = [
      { key: 0, display: 'Carga', value: 'Carga', checked: values.some(value => value === 0) },
      { key: 1, display: 'Descarga', value: 'Descarga', checked: values.some(value => value === 1) }
    ];
  }

  private prepareLoadStatus(fields: any, values: string[]): void {
    fields.statusviagem = [
      { key: 'TRANSITO', display: 'Em trânsito', value: 'Em trânsito', checked: values.some(value => value === 'TRANSITO') },
      { key: 'INICIO_VIAGEM', display: 'Início de viagem', value: 'Início de viagem', checked: values.some(value => value === 'INICIO_VIAGEM') },
      { key: 'TERMINO_VIAGEM', display: 'Término da viagem', value: 'Término da viagem', checked: values.some(value => value === 'TERMINO_VIAGEM') },
      { key: 'FINALIZADO', display: 'Finalizada', value: 'Finalizada', checked: values.some(value => value === 'FINALIZADO') },
    ];
  }

  private prepareAttendanceStatus(fields: any, values: string[]): void {
    // TODO: Não será entregue no MVP 1 e ainda precisa de definição por parte da BRF
  }

  private prepareMirroringStatus(fields: any, values: number[]): void {
    fields.statusespelhamento = [
      { key: 0, display: 'Espelhado', value: 'Espelhado', checked: values.some(value => value === 0) },
      { key: 1, display: 'Não espelhado', value: 'Não espelhado', checked: values.some(value => value === 1) }
    ];
  }

  private getFilterParameters(): UserFilterScreenMonitoring {
    if (this.savedFilterId && !this.savedFilter) {
      this.savedFilter = this.modalWindowMonitoringFilterService.getSavedFilterById(this.savedFilterId);
    }

    if (!this.currentFilter) {
      this.currentFilter = this.modalWindowMonitoringFilterService.getCurrentFilter();
    }

    return this.savedFilterId
      ? this.savedFilter?.parameters
      : this.currentFilter?.filter?.parameters;
  }

  private countFilters(isDetectChanges: boolean = true): void {
    const count = this.modalWindowMonitoringFilterService.countFilters(this.filterForm);

    if (count === this.filterCount) {
      return;
    }

    this.filterCount = count;
    this.filterCountChange.emit(count);

    if (isDetectChanges) {
      this.cdref.detectChanges();
    }
  }
}
