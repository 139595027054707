import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TOption } from 'src/app/components/select/select.component';
import { MonitoramentoStatusAtendimentoEnum, MonitoramentoStatusEspelhamentoEnum, TipoFrotaEnum } from 'src/app/core/models/enums/filtro-monitoramento.enum';
import { LogisticTypeEnum } from 'src/app/core/models/enums/logistic-type.enum';
import { RoutePrimaryFilter } from 'src/app/core/models/filters/route-primary-filter.model';
import { FilterService } from 'src/app/core/services/filter.service';

import { IModal, ModalRef, MODAL_DATA, MODAL_REF } from 'src/app/core/services/modal';
import { SessionStorageService } from 'src/app/core/services/session-storage.service';

export type BasicModalInput = any;
export type BasicModalData = any;
export type BasicModalResult = any;

@Component({
  host: { class: 'noOutline modal-dialog' },
  templateUrl: './modal-filter-primary.component.html',
  styleUrls: ['./modal-filter-primary.component.scss']
})
export class ModalFilterPrimary implements OnInit, IModal<BasicModalInput, BasicModalResult> {

  public form: FormGroup;

  options: {
    transportadoras: TOption[],
    regionaiscalculadas: TOption[],
    origens: TOption[],
    destinos: TOption[],
    tipoOperacaoLista: TOption[],
    tipoOperacaoAgroLista: TOption[],
    tipoProjetoLista: TOption[],
    statusViagemLista: TOption[],
    tipoEquipamentoLista: TOption[],
    mercadoLista: TOption[],
    tipoContratacaoLista: TOption[],
    tipoClienteLista: TOption[],
    tipoVeiculoLista: TOption[],
    statusAtendimentoLista: TOption[],
    statusEspelhamentoLista: TOption[],
    tipoFrotaLista: TOption[]
  } = {
      transportadoras: [],
      regionaiscalculadas: [],
      origens: [],
      destinos: [],
      tipoOperacaoLista: [],
      tipoOperacaoAgroLista: [],
      tipoProjetoLista: [],
      tipoEquipamentoLista: [],
      mercadoLista: [],
      tipoContratacaoLista: [],
      tipoClienteLista: [],
      tipoVeiculoLista: [],
      statusEspelhamentoLista: [
        {
          key: MonitoramentoStatusEspelhamentoEnum.Espelhado,
          display: "Espelhado",
          value: 0,
          checked: false
        },
        {
          key: MonitoramentoStatusEspelhamentoEnum.NaoEspelhado,
          display: "Não espelhado",
          value: 1,
          checked: false
        },
      ],
      statusViagemLista: [
        {
          key: "VIAGEM_PROGRAMADA",
          display: "Viagem Programada",
          checked: false,
          value: "VIAGEM_PROGRAMADA"
        },
        {
          key: "TRANSITO_CARREGAMENTO",
          display: "Trânsito Carregamento",
          checked: false,
          value: "TRANSITO_CARREGAMENTO"
        },
        {
          key: "CARREGAMENTO",
          display: "Em Carregamento",
          checked: false,
          value: "CARREGAMENTO"
        },
        {
          key: "TRANSITO_DESCARGA",
          display: "Trânsito Descarga",
          checked: false,
          value: "TRANSITO_DESCARGA"
        },
        {
          key: "DESCARGA",
          display: "Em Descarga",
          checked: false,
          value: "DESCARGA"
        },
        {
          key: "NF",
          display: "Troca de NF",
          checked: false,
          value: "NF"
        },
        {
          key: "MONITORAMENTO_CONTINUO",
          display: "Monitoramento Contínuo",
          checked: false,
          value: "MONITORAMENTO_CONTINUO"
        },
        {
          key: "VIAGEM_CANCELADA",
          display: "Cancelado",
          checked: false,
          value: "VIAGEM_CANCELADA"
        },
        {
          key: "FINALIZADO",
          display: "Finalizado",
          checked: false,
          value: "FINALIZADO"
        }
      ],
      statusAtendimentoLista: [
        {
          key: MonitoramentoStatusAtendimentoEnum.NaoAtende,
          display: "Não atende",
          value: 0,
          checked: false,
        },
        {
          key: MonitoramentoStatusAtendimentoEnum.Atende,
          display: "Atende",
          value: 1,
          checked: false,
        },
        {
          key: MonitoramentoStatusAtendimentoEnum.Risco,
          display: "Risco",
          value: 2,
          checked: false,
        },
      ],
      tipoFrotaLista: [
        {
          key: TipoFrotaEnum.FrotaFixaBRF,
          display: "Frota fixa BRF",
          value: TipoFrotaEnum.FrotaFixaBRF,
          checked: false
        },
        {
          key: TipoFrotaEnum.FrotaComun,
          display: "Frota comum",
          value: TipoFrotaEnum.FrotaComun,
          checked: false
        }
      ]
    }

  constructor(
    @Inject(MODAL_REF) public modalRef: ModalRef<BasicModalResult, BasicModalData>,
    @Inject(MODAL_DATA) public data: BasicModalInput,
    private filterService: FilterService,
    private sessionStorageService: SessionStorageService
  ) { }

  ngOnInit() {
    this.form = new FormGroup({
      numeroViagem: new FormControl(),
      placa: new FormControl(),
      transportadora: new FormControl(),
      regional: new FormControl(),
      dataColeta: new FormControl(),
      dataEntrega: new FormControl(),
      remessa: new FormControl(),
      ocr: new FormControl(),
      origem: new FormControl(),
      destino: new FormControl(),
      statusViagem: new FormControl(),
      statusAtendimento: new FormControl(),
      statusEspelhamento: new FormControl(),
      tipoEquipamento: new FormControl(),
      tipoOperacao: new FormControl(),
      tipoProjeto: new FormControl(),
      tipoFrota: new FormControl(),
      mercado: new FormControl()
    });
    const campos = [
      "regionalcalculada",
      "transportadora",
      "tipoparada",
      "tipooperacao",
      "tipoprojeto",
      "tipoequipamento",
      "mercado",
      "origem",
      "unidadecomcodigo",
    ];
    this.filterService.getSelectBoxOptions(campos, LogisticTypeEnum.PRIMARY).subscribe(result => {
      this.options.transportadoras = result.transportadora.map(item => {
        const data = item.label.split('-');
        return {
          key: data[0].trim(),
          display: item.label,
          value: data[0].trim(),
          checked: false
        }
      })
      result.unidadecomcodigo.map(item => {
        const data = item.label.split('-');
        const code = data[0].trim();
        const display = data[1].trim();
        this.options.origens.push(
          {
            key: item.id,
            display: `${code} - ${display}`,
            value: item.valor,
            checked: false
          }
        );
        this.options.destinos.push(
          {
            key: item.id,
            display: `${code} - ${display}`,
            value: item.valor,
            checked: false
          }
        );
      })
      this.options.regionaiscalculadas = result.regionalcalculada.map(item => {
        return {
          key: item.id,
          value: item.valor,
          display: item.label,
          checked: false
        }
      })
      this.options.mercadoLista = result.mercado.map(item => {
        return {
          key: item.id,
          value: item.valor,
          display: item.label,
          checked: false
        }
      })
      this.options.tipoProjetoLista = result.tipoprojeto.map(item => {
        return {
          key: item.id,
          value: item.valor,
          display: item.label,
          checked: false
        }
      })
      this.options.tipoOperacaoLista = result.tipooperacao.map(item => {
        return {
          key: item.id,
          value: item.valor,
          display: item.label,
          checked: false
        }
      })
      this.options.tipoEquipamentoLista = result.tipoequipamento.map(item => {
        return {
          key: item.id,
          value: item.valor,
          display: item.label,
          checked: false
        }
      })
      this.options.statusViagemLista = this.options.statusViagemLista.map(item => {
        return {
          ...item,
          checked: false
        }
      })
      this.options.statusAtendimentoLista = this.options.statusAtendimentoLista.map(item => {
        return {
          ...item,
          checked: false
        }
      })
      this.options.statusEspelhamentoLista = this.options.statusEspelhamentoLista.map(item => {
        return {
          ...item,
          checked: false
        }
      })
      this.options.tipoFrotaLista = this.options.tipoFrotaLista.map(item => {
        return {
          ...item,
          checked: false
        }
      })
    });
  }

  apply() {
    const filterPrimary = new RoutePrimaryFilter(this.form.value);
    const item = filterPrimary.toJson();    
    this.sessionStorageService.setItem(`objectFilter-monitoramento-primaria`, item);    
    this.modalRef.close(true);
  }

  close = () => this.modalRef.close(false);

}