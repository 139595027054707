import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogFiltroComponent } from '../pages/daily/dialog-filtro/dialog-filtro.component';
import { DialogMeusFiltrosComponent } from '../pages/daily/dialog-meus-filtros/dialog-meus-filtros.component';
import { DialogDataSaveFilter } from '../pages/daily/dialog-salvar-filtro/dialog-salvar-filtro.component';

@Injectable()
export class DialogFiltroService {

  constructor(public dialog: MatDialog) { }

  openDialogFiltro(data = null): MatDialogRef<any> {
    return this.dialog.open(DialogFiltroComponent, { width: '800px', height: 'auto', data });
  }

  openDialogListFiltros(): MatDialogRef<any> {
    return this.dialog.open(DialogMeusFiltrosComponent, { width: '800px', height: 'auto' });

  }

  openDialogCreateFilter(objectFilter: object) {
    this.dialog.open(DialogDataSaveFilter, {
      width: '800px',
      height: 'auto',
      // data: {
      //   screen: "governanca-diaria",
      //   objectFilter
      // },
    });
  }

}
