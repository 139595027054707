import { Injectable } from '@angular/core';
import { LogisticTypeEnum } from '../models/enums/logistic-type.enum';
import { Modulo, PerfilPermissao } from '../models/perfil-permissao.model';
import { LocalStorageService } from './local-storage.service';
import { SessionStorageService } from './session-storage.service';

@Injectable({ providedIn: 'root' })
export class PerfilPermissaoService {

    public logisticasRoles: Array<PerfilPermissao[]> = [];
    public modulos: Modulo[] = [];
    constructor(private sessionStorageService: SessionStorageService, private localStorageStorageService: LocalStorageService) {

        this.modulos["alertas"] = new Modulo("alertas", "Alertas");
        this.modulos["dashboard"] = new Modulo("dashboard", "Dashboard");
        this.modulos["monitoramento"] = new Modulo("monitoramento", "Monitoramento");
        this.modulos["anomalia_consulta"] = new Modulo("anomalia_consulta", "Anomalias visualizacao");
        this.modulos["anomalia_manutencao"] = new Modulo("anomalia_manutencao", "Anomalias tratativa");
        this.modulos["disponibilidade"] = new Modulo("disponibilidade", "Disponibilidade");
        this.modulos["painel_aeroporto"] = new Modulo("painel_aeroporto", "Painel de Aeroporto");
        this.modulos["consulta_canhoto"] = new Modulo("consulta_canhoto", "Consulta de Canhotos");
        this.modulos["viagens"] = new Modulo("viagens", "Viagens");
        this.modulos["relatorios"] = new Modulo("relatorios", "Relatorios");
        this.modulos["cadastro_consulta"] = new Modulo("cadastro_consulta", "Cadastro visualização");
        this.modulos["cadastro_manutencao"] = new Modulo("cadastro_manutencao", "Cadastro manutenção");
        this.modulos["subareas"] = new Modulo("subareas", "Cadastro de subáreas")
        this.modulos["retroalimentacao_consulta"] = new Modulo("retroalimentacao_consulta", "Retroalimentação visualização");
        this.modulos["retroalimentacao_manutencao"] = new Modulo("retroalimentacao_manutencao", "Retroalimentação tratativa");
        this.modulos["parametrizacao"] = new Modulo("parametrizacao", "Parametrização");
        this.modulos["transportadoras"] = new Modulo("transportadoras", "Transportadoras")
        this.modulos["desvios"] = new Modulo("desvios", "Desvios")
        this.modulos["diarias"] = new Modulo("diarias", "Diarias");

        this.logisticasRoles["primaria"] = [];
        this.logisticasRoles["primaria"].push(new PerfilPermissao("primaria", "MonitorPrimaria", "Monitor", [
            this.modulos["alertas"],
            this.modulos["dashboard"],
            this.modulos["monitoramento"],
            this.modulos["anomalia_consulta"],
            this.modulos["anomalia_manutencao"],
            this.modulos["disponibilidade"],
            this.modulos["painel_aeroporto"],
            this.modulos["viagens"],
            this.modulos["relatorios"],
            this.modulos["cadastro_consulta"],
            this.modulos["cadastro_manutencao"],
            this.modulos["subareas"],
            this.modulos["transportadoras"],
            this.modulos['desvios'],
            this.modulos['diarias']
        ]));
        this.logisticasRoles["primaria"].push(new PerfilPermissao("primaria", "AnalistaVD", "Analista Venda Direta", [
            this.modulos["alertas"],
            this.modulos["dashboard"],
            this.modulos["monitoramento"],
            this.modulos["anomalia_consulta"],
            this.modulos["anomalia_manutencao"],
            this.modulos["disponibilidade"],
            this.modulos["painel_aeroporto"],
            this.modulos["viagens"],
            this.modulos["relatorios"],
            this.modulos["cadastro_consulta"],
            this.modulos["cadastro_manutencao"],
            this.modulos['desvios']
        ]));
        this.logisticasRoles["primaria"].push(new PerfilPermissao("primaria", "GestorPrimaria", "Gestor", [
            this.modulos["dashboard"],
            this.modulos["monitoramento"],
            this.modulos["anomalia_consulta"],
            this.modulos["disponibilidade"],
            this.modulos["painel_aeroporto"],
            this.modulos["viagens"],
            this.modulos["relatorios"],
            this.modulos["cadastro_consulta"],
            this.modulos['desvios']
        ]));
        this.logisticasRoles["primaria"].push(new PerfilPermissao("primaria", "AnalistaProjetos", "Analista Projetos", [
            this.modulos["alertas"],
            this.modulos["dashboard"],
            this.modulos["monitoramento"],
            this.modulos["anomalia_consulta"],
            this.modulos["anomalia_manutencao"],
            this.modulos["disponibilidade"],
            this.modulos["painel_aeroporto"],
            this.modulos["viagens"],
            this.modulos["relatorios"],
            this.modulos["cadastro_consulta"],
            this.modulos["cadastro_manutencao"],
            this.modulos['desvios']
        ]));
        this.logisticasRoles["primaria"].push(new PerfilPermissao("primaria", "SomenteLeituraBRFPrimaria", "Somente leitura BRF", [
            this.modulos["dashboard"],
            this.modulos["monitoramento"],
            this.modulos["anomalia_consulta"],
            this.modulos["disponibilidade"],
            this.modulos["painel_aeroporto"],
            this.modulos["viagens"],
            this.modulos["relatorios"],
            this.modulos["cadastro_consulta"],
            this.modulos['desvios'],
            this.modulos['diarias']
        ]));
        this.logisticasRoles["primaria"].push(new PerfilPermissao("primaria", "TransportadorPrimaria", "Transportador", [
            this.modulos["monitoramento"],
            this.modulos["anomalia_consulta"],
            this.modulos["viagens"],
            this.modulos['desvios']
        ]));

        this.logisticasRoles["primaria"].push(new PerfilPermissao("primaria", "TransportadoraPrimaria", "Transportadora", [
            this.modulos["monitoramento"],
            this.modulos["anomalia_consulta"],
            this.modulos["viagens"],
            this.modulos['desvios']
        ]));

        this.logisticasRoles["primaria"].push(new PerfilPermissao("primaria", "AdminPrimaria", "Admin", [
            this.modulos["alertas"],
            this.modulos["dashboard"],
            this.modulos["monitoramento"],
            this.modulos["anomalia_consulta"],
            this.modulos["anomalia_manutencao"],
            this.modulos["disponibilidade"],
            this.modulos["painel_aeroporto"],
            this.modulos["viagens"],
            this.modulos["relatorios"],
            this.modulos["cadastro_consulta"],
            this.modulos["cadastro_manutencao"],
            this.modulos['desvios'],
            this.modulos['diarias']
        ]));

        this.logisticasRoles["distribuicao"] = [];
        this.logisticasRoles["distribuicao"].push(new PerfilPermissao("distribuicao", "MonitorDistribuicao", "Monitor", [
            this.modulos["alertas"],
            this.modulos["dashboard"],
            this.modulos["monitoramento"],
            this.modulos["anomalia_consulta"],
            this.modulos["anomalia_manutencao"],
            this.modulos["relatorios"],
            this.modulos["retroalimentacao_consulta"],
            this.modulos["retroalimentacao_manutencao"],
            this.modulos["cadastro_consulta"],
            this.modulos["cadastro_manutencao"],
            this.modulos["consulta_canhoto"],
            this.modulos['diarias'],
        ]));
        this.logisticasRoles["distribuicao"].push(new PerfilPermissao("distribuicao", "RiscoDistribuicao", "Risco", [
            this.modulos["alertas"],
            this.modulos["dashboard"],
            this.modulos["monitoramento"],
            this.modulos["anomalia_consulta"],
            this.modulos["anomalia_manutencao"],
            this.modulos["relatorios"],
            this.modulos["consulta_canhoto"],
            this.modulos["retroalimentacao_consulta"],
            this.modulos["retroalimentacao_manutencao"],
            this.modulos["cadastro_consulta"],
            this.modulos["cadastro_manutencao"],
            this.modulos['diarias']
        ]));
        this.logisticasRoles["distribuicao"].push(new PerfilPermissao("distribuicao", "SegurancaDistribuicao", "Seguranca", [
            this.modulos["alertas"],
            this.modulos["dashboard"],
            this.modulos["monitoramento"],
            this.modulos["anomalia_consulta"],
            this.modulos["anomalia_manutencao"],
            this.modulos["relatorios"],
            this.modulos["retroalimentacao_consulta"],
            this.modulos["retroalimentacao_manutencao"],
            this.modulos["cadastro_consulta"],
            this.modulos["cadastro_manutencao"],
            this.modulos['diarias']
        ]));
        this.logisticasRoles["distribuicao"].push(new PerfilPermissao("distribuicao", "GestorDistribuicao", "Gestor", [
            this.modulos["alertas"],
            this.modulos["dashboard"],
            this.modulos["monitoramento"],
            this.modulos["anomalia_consulta"],
            this.modulos["anomalia_manutencao"],
            this.modulos["relatorios"],
            this.modulos["consulta_canhoto"],
            this.modulos["retroalimentacao_consulta"],
            this.modulos["retroalimentacao_manutencao"],
            this.modulos["cadastro_consulta"],
            this.modulos["cadastro_manutencao"],
            this.modulos["transportadoras"],
            this.modulos['diarias']
        ]));
        this.logisticasRoles["distribuicao"].push(new PerfilPermissao("distribuicao", "TransporteDistribuicao", "Transporte", [
            this.modulos["dashboard"],
            this.modulos["monitoramento"],
            this.modulos["anomalia_consulta"],
            this.modulos["anomalia_manutencao"],
            this.modulos["relatorios"],
            this.modulos["consulta_canhoto"],
            this.modulos["retroalimentacao_consulta"],
            this.modulos["cadastro_consulta"],
            this.modulos['diarias']
        ]));
        this.logisticasRoles["distribuicao"].push(new PerfilPermissao("distribuicao", "RoteirizadorDistribuicao", "Roteirizador", [
            this.modulos["dashboard"],
            this.modulos["monitoramento"],
            this.modulos["anomalia_consulta"],
            this.modulos["relatorios"],
            this.modulos["retroalimentacao_consulta"],
            this.modulos["retroalimentacao_manutencao"],
            this.modulos["cadastro_consulta"],
            this.modulos['diarias']
        ]));
        this.logisticasRoles["distribuicao"].push(new PerfilPermissao("distribuicao", "QualidadeDistribuicao", "Qualidade", [
            this.modulos["dashboard"],
            this.modulos["monitoramento"],
            this.modulos["anomalia_consulta"],
            this.modulos["relatorios"],
            this.modulos["retroalimentacao_consulta"],
            this.modulos["cadastro_consulta"],
            this.modulos['diarias']
        ]));
        this.logisticasRoles["distribuicao"].push(new PerfilPermissao("distribuicao", "CorporativoDistribuicao", "Corporativo", [
            this.modulos["dashboard"],
            this.modulos["monitoramento"],
            this.modulos["anomalia_consulta"],
            this.modulos["relatorios"],
            this.modulos["consulta_canhoto"],
            this.modulos["retroalimentacao_consulta"],
            this.modulos["cadastro_consulta"],
            this.modulos["cadastro_manutencao"],
            this.modulos['diarias']
        ]));

        this.logisticasRoles["distribuicao"].push(new PerfilPermissao("distribuicao", "ComercialDistribuicao", "Comercial", [
            this.modulos["dashboard"],
            this.modulos["monitoramento"],
            this.modulos["anomalia_consulta"],
            this.modulos["relatorios"],
            this.modulos["retroalimentacao_consulta"],
            this.modulos["cadastro_consulta"],
            this.modulos['diarias']

        ]));
        this.logisticasRoles["distribuicao"].push(new PerfilPermissao("distribuicao", "AuditoriaDistribuicao", "Auditoria", [
            this.modulos["dashboard"],
            this.modulos["monitoramento"],
            this.modulos["anomalia_consulta"],
            this.modulos["relatorios"],
            this.modulos["consulta_canhoto"],
            this.modulos["retroalimentacao_consulta"],
            this.modulos["cadastro_consulta"],
            this.modulos['diarias']
        ]));
        this.logisticasRoles["distribuicao"].push(new PerfilPermissao("distribuicao", "ControladoriaDistribuicao", "Controladoria", [
            this.modulos["dashboard"],
            this.modulos["monitoramento"],
            this.modulos["anomalia_consulta"],
            this.modulos["relatorios"],
            this.modulos["consulta_canhoto"],
            this.modulos["retroalimentacao_consulta"],
            this.modulos["cadastro_consulta"],
            this.modulos['diarias']
        ]));
        this.logisticasRoles["distribuicao"].push(new PerfilPermissao("distribuicao", "TransportadorDistribuicao", "Transportador", [
            this.modulos["dashboard"],
            this.modulos["monitoramento"],
            this.modulos["anomalia_consulta"],
            this.modulos["relatorios"],
            this.modulos["consulta_canhoto"],
            this.modulos["retroalimentacao_consulta"],

        ]));
        this.logisticasRoles["distribuicao"].push(new PerfilPermissao("distribuicao", "AdminDistribuicao", "Admin", [
            this.modulos["alertas"],
            this.modulos["dashboard"],
            this.modulos["monitoramento"],
            this.modulos["anomalia_consulta"],
            this.modulos["anomalia_manutencao"],
            this.modulos["relatorios"],
            this.modulos["consulta_canhoto"],
            this.modulos["retroalimentacao_consulta"],
            this.modulos["retroalimentacao_manutencao"],
            this.modulos["cadastro_consulta"],
            this.modulos["cadastro_manutencao"],
            this.modulos["parametrizacao"],
            this.modulos["transportadoras"],
            this.modulos['diarias']
        ]));


        this.logisticasRoles["primaria"].push(new PerfilPermissao("primaria", "GestaoDiariasLeitura", "Gestão Diárias - Leitura", [
            this.modulos['diarias']
        ]));


        this.logisticasRoles["primaria"].push(new PerfilPermissao("primaria", "GestaoDiariasEdicao", "Gestão Diárias - Edição", [
            this.modulos['diarias']
        ]));


        this.logisticasRoles["primaria"].push(new PerfilPermissao("distribuicao", "TransportadoraDistribuicao", "Transportadora", [
            this.modulos["monitoramento"],
            this.modulos["anomalia_consulta"],
            this.modulos["viagens"],
            //this.modulos["relatorios"]
        ]));



        this.logisticasRoles["agro"] = [];

        this.logisticasRoles["agro"].push(new PerfilPermissao("agro", "MonitorAgro", "Monitor", [
            this.modulos["alertas"],
            this.modulos["dashboard"],
            this.modulos["monitoramento"],
            this.modulos["anomalia_consulta"],
            this.modulos["anomalia_manutencao"],
            this.modulos["relatorios"],
            this.modulos["disponibilidade"],
            this.modulos["subareas"]
        ]));

        this.logisticasRoles["agro"].push(new PerfilPermissao("agro", "AdminAgro", "Admin", [
            this.modulos["alertas"],
            this.modulos["dashboard"],
            this.modulos["monitoramento"],
            this.modulos["anomalia_consulta"],
            this.modulos["anomalia_manutencao"],
            this.modulos["relatorios"],
            this.modulos["disponibilidade"],
            this.modulos["subareas"]
        ]));

        // NÃO TERÁ ACESSO NESSE MOMENTO!
        // this.logisticasRoles["agro"].push(new PerfilPermissao("agro", "TransportadoraAgro", "Transportadora", [
        //     this.modulos["monitoramento"],
        //     this.modulos["anomalia_consulta"],
        //     this.modulos["viagens"],
        //     this.modulos["relatorios"]
        // ]));

        this.logisticasRoles["agro"].push(new PerfilPermissao("agro", "TransportadorAgro", "Transportador", [
            this.modulos["monitoramento"],
            this.modulos["anomalia_consulta"],
            this.modulos["viagens"],
            this.modulos["relatorios"],
            this.modulos["disponibilidade"],
            this.modulos["subareas"]
        ]));

        this.logisticasRoles["agro"].push(new PerfilPermissao("agro", "ProgramadorAgro", "Programador", [
            this.modulos["alertas"],
            this.modulos["dashboard"],
            this.modulos["monitoramento"],
            this.modulos["anomalia_consulta"],
            this.modulos["anomalia_manutencao"],
            this.modulos["relatorios"],
            this.modulos["disponibilidade"],
            this.modulos["subareas"]
        ]));

        this.logisticasRoles["agro"].push(new PerfilPermissao("agro", "GestorAgro", "Gestor", [
            this.modulos["alertas"],
            this.modulos["dashboard"],
            this.modulos["monitoramento"],
            this.modulos["anomalia_consulta"],
            this.modulos["anomalia_manutencao"],
            this.modulos["relatorios"],
            this.modulos["disponibilidade"],
            this.modulos["subareas"]
        ]));

        this.logisticasRoles["agro"].push(new PerfilPermissao("agro", "SegurancaAgro", "Segurança", [
            this.modulos["monitoramento"],
            this.modulos["anomalia_consulta"],
            this.modulos["viagens"],
            this.modulos["relatorios"],
            this.modulos["disponibilidade"],
            this.modulos["subareas"]
        ]));

        this.logisticasRoles["agro"].push(new PerfilPermissao("agro", "SegurancaAgro", "Segurança", [
            this.modulos["monitoramento"],
            this.modulos["anomalia_consulta"],
            this.modulos["viagens"],
            this.modulos["relatorios"],
            this.modulos["disponibilidade"],
            this.modulos["subareas"]
        ]));

        this.logisticasRoles["agro"].push(new PerfilPermissao("agro", "MotoristaAgro", "Motorista", [
            this.modulos["monitoramento"],
            this.modulos["anomalia_consulta"],
            this.modulos["viagens"],
            this.modulos["relatorios"],
            this.modulos["disponibilidade"],
            this.modulos["subareas"]
        ]));

        this.logisticasRoles["commodities"] = [];
        this.logisticasRoles["commodities"].push(new PerfilPermissao("commodities", "MonitorCommodities", "Monitor", [
            this.modulos["alertas"],
            this.modulos["dashboard"],
            this.modulos["monitoramento"],
            this.modulos["anomalia_consulta"],
            // this.modulos["anomalia_manutencao"],
            // this.modulos["relatorios"],
        ]));
        this.logisticasRoles["commodities"].push(new PerfilPermissao("commodities", "AdminCommodities", "Admin", [
            this.modulos["alertas"],
            this.modulos["dashboard"],
            this.modulos["monitoramento"],
            this.modulos["anomalia_consulta"],
            // this.modulos["anomalia_manutencao"],
            // this.modulos["relatorios"],
        ]));

        this.logisticasRoles["primaria"].push(new PerfilPermissao("commodities", "TransportadoraCommodities", "Transportadora", [
            this.modulos["monitoramento"],
            // this.modulos["anomalia_consulta"],
            this.modulos["viagens"],
            //this.modulos["relatorios"]
        ]));
    }

    public podeAlterarAnomalias() {
        let currentRole = this.getCurrentLogisticaRole();
        if (currentRole) {

            let roles = ['RiscoDistribuicao', 'SegurancaDistribuicao', 'MonitorDistribuicao', 'GestorDistribuicao', 'TransporteDistribuicao', 'AdminDistribuicao',
                'MonitorPrimaria', 'AnalistaVD', 'AnalistaProjetos', 'AdminPrimaria'];
            return roles.includes(currentRole.codigo);
        }

        return false;
    }

    public getCurrentLogisticaRole(): PerfilPermissao {
        return this.localStorageStorageService.getItem("CURRENT_ROLE") as PerfilPermissao;
    }

    public setCurrentLogisticaRole(role: string): void {
        return this.localStorageStorageService.setItem("CURRENT_ROLE", this.getLogisticaRole(role));
    }

    public getLogisticaRole(codigoRole: string): PerfilPermissao {
        var result: PerfilPermissao = null;
        if (this.logisticasRoles) {
            for (var logistica in this.logisticasRoles) {
                var logisticasRole = this.logisticasRoles[logistica].find(role => role.codigo == codigoRole);
                if (logisticasRole) {
                    result = logisticasRole;
                    break;
                }
            }
        }
        return result;
    }

    public getLogisticaRoles(codigoRoles: string[]): PerfilPermissao[] {
        var result: PerfilPermissao[] = [];
        codigoRoles.forEach(p => {
            for (var logistica in this.logisticasRoles) {
                var logisticasRole = this.logisticasRoles[logistica].find(role => role.codigo == p);
                if (logisticasRole) {
                    result.push(logisticasRole);
                }
            }
        });
        this.localStorageStorageService.setItem("roles", result);
        return result;
    }


    /** 
     * Implemented for a while only to users logged in logistic Primária      
     * */
    public isAdmin(): boolean {
        const perfilPermissao = this.localStorageStorageService.getItem("CURRENT_ROLE") as PerfilPermissao;
        switch (perfilPermissao.logistica) {
            case LogisticTypeEnum.PRIMARY:
                return perfilPermissao.codigo == "MonitorPrimaria" || perfilPermissao.codigo == "AdminPrimaria";
            case LogisticTypeEnum.DISTRIBUITION:
                return perfilPermissao.codigo == "MonitorDistribuicao" ||
                    perfilPermissao.codigo == "AdminDistribuicao" ||
                    perfilPermissao.codigo == "GestorDistribuicao"
            default:
                return true;
        }
    }
}
