import { Injectable } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { MessageService } from 'src/app/core/services/message.service';
import { PerfilPermissaoService } from 'src/app/core/services/perfil-permissao.service';
import { ChatBusinessService } from 'src/app/components/chat/chat-business.service';
import { ChatHttpService } from 'src/app/components/chat/chat-http.service';
import { ChatMessageTypeFile } from 'src/app/components/chat/chat-message-type-file.model';
import { ChatMessageTypeMessage } from 'src/app/components/chat/chat-message-type-message.model';
import { ChatMessage, TMessage } from 'src/app/components/chat/chat-message.model';
import { ChatObservableService } from 'src/app/components/chat/chat-observable.service';
import { IPooling } from './pooling.interface';

@Injectable({
  providedIn: 'root'
})
export class ChatPoolingService implements IPooling {

  public subscription: Subscription = null;

  public dueTime = 1000; // 16 segundos

  public scheduler = 60000; // 1 minuto

  #userName: string;

  #logistic: string;

  #profilePermission = [
    'MonitorDistribuicao'
  ];

  #logisticPermission = [
    'distribuicao'
  ];

  constructor(
    private chatService: ChatHttpService,
    private chatBusinessService: ChatBusinessService,
    private chatObservableService: ChatObservableService,
    private perfilPermissaoService: PerfilPermissaoService,
    private messageService: MessageService
  ) { }

  public subscribe(): void {

    if (!this.chatBusinessService.hasSubcriptions()) {
      console.warn('[POOLING.CHAT.SUBSCRIBE] monitoring boards first');
      return;
    }

    if (this.subscription && this.#userName && this.#logistic) {
      return;
    }

    const role = this.perfilPermissaoService.getCurrentLogisticaRole();

    if (!role) {
      return;
    }

    if (!this.#logisticPermission.includes(role.logistica)
      || !this.#profilePermission.includes(role.codigo)
    ) {
      console.warn(`[POOLING.CHAT.SUBSCRIBE] logistic "${role.logistica}" and code "${role.codigo}" permission denied`);
      console.warn('[POOLING.CHAT.SUBSCRIBE] logistic granted', this.#logisticPermission);
      console.warn('[POOLING.CHAT.SUBSCRIBE] profile granted', this.#profilePermission);

      return;
    }

    const user = this.chatBusinessService.getCurrentUser();

    if (!user) {
      console.error('[POOLING.CHAT.SUBSCRIBE] user not found, it was not possible subscriber');
      return;
    }

    this.#userName = user.preferred_username;

    this.#logistic = role.logistica;

    this.subscription = timer(this.dueTime, this.scheduler).subscribe(() => this.run());
  }

  public unsubscribe(): void {
    console.log('[POOLING.CHAT.UNSUBSCRIBE]');

    this.subscription.unsubscribe();
  }

  public run(): void {
    console.log(`[POOLING.CHAT.RUN] milliseconds - first executed: ${this.dueTime} scheduler execution: ${this.scheduler}`);

    this.chatService
      .getNewMessagesMonitor(this.#logistic, this.#userName)
      .subscribe((chat: ChatMessage) => {
        if (!chat) {
          return;
        }

        const firstMessage = chat.messages[0];

        const message: ChatMessageTypeFile | ChatMessageTypeMessage = firstMessage?.file || firstMessage?.message;

        if (!message) {
          console.error('[POOLING.CHAT.RUN] Não foi possível resgatar a mensagem, está vazio!');
          return;
        }

        const button = {
          nome: 'Responder',
          navigateUrl: `chat/viagem/${message.loadNumber}/placa/${message.plate}`
        };

        let text = '';

        if (message instanceof ChatMessageTypeFile) {
          text = `<strong>${message.loadNumber}</strong>: Novo arquivo`;
        } else {
          text = `<strong>${message.loadNumber}</strong>: ${message.message}`;
        }

        const icon = 'chat/toast.svg';

        this.messageService.success(
          text,
          icon,
          15000,
          button,
          true,
          () => this.chatObservableService.setMarkAllRead([message.id])
        );

        chat.messages.forEach((chatMessage: TMessage) => {
          const messageOrFile = chatMessage?.file || chatMessage?.message;

          this.chatObservableService.sendMessage(messageOrFile);
        });
      });
  }
}
