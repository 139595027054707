import { Address } from 'src/app/models/address.model';

export class StepDetailDistributionClientInfo {
    constructor(
        public code: string,
        public documentNumber: string,
        public fullName: string,
        public address: Address,
        public attendanceInitial: Date,
        public attendanceFinal: Date,
        public contactName: string,
        public contactPhone: string,
    ) { }
}
