import { BrowserModule } from '@angular/platform-browser';
import { NgModule, DEFAULT_CURRENCY_CODE, LOCALE_ID, CUSTOM_ELEMENTS_SCHEMA, inject } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtInterceptor } from './core/interceptors/jwt.interceptor';

import { ErrorService } from './core/services/error.service';
import { FormsModule } from '@angular/forms';
import { AuthModule, LogLevel, StsConfigHttpLoader, StsConfigLoader } from 'angular-auth-oidc-client';
import { ForbiddenComponent } from './components/auth/forbidden/forbidden.component';
import { UnauthorizedComponent } from './components/auth/unauthorized/unauthorized.component';
import { MatCardModule } from '@angular/material/card';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { SharedModule } from './components/shared/shared.module';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS, HttpBackend } from '@angular/common/http';
import { AuthService } from './core/services/auth.service';
import { DEFAULT_TIMEOUT, ErrorInterceptor, LoadingInterceptor, TimeoutInterceptor } from './core/interceptors';
import { LayoutModule } from './components/layout/layout.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import localePT from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
registerLocaleData(localePT);

import { NgxMaskModule, IConfig } from 'ngx-mask';
import { DateUTCPipe } from './core/custom-pipes/date-utc.pipe';
import { UriInterceptor } from './core/interceptors/uri.interceptor';
import { ReturnComponent } from './components/auth/return/return.component';
import { MonitoramentoService } from './core/services/monitoramento.service';
import { SubscriptionInterceptor } from './core/interceptors/subscription.interceptor';
import { DailyModule } from './pages/daily/daily.module';
import { map } from 'rxjs/operators';
import { CustomStorage } from './core/services/custom-storage';
import { environment } from 'src/environments/environment';

const maskConfig: Partial<IConfig> = {
    validation: false,
};


export const httpLoaderFactory = (handler: HttpBackend,) => {
    const httpClient: HttpClient = new HttpClient(handler)
    const config$ = httpClient
        .get<any>(`${environment.express_url}/microsoft`)
        .pipe(
            map((customConfig: any) => {
                return {
                    authority: customConfig.config.authority,
                    authWellknownEndpointUrl: customConfig.config.authWellknownEndpointUrl,
                    eagerLoadAuthWellKnownEndpoints: true,
                    redirectUrl: `${window.location.origin}/return`,
                    postLogoutRedirectUri: `${window.location.origin}/return`,
                    clientId: customConfig.config.clientId,
                    scope: customConfig.config.scope,
                    responseType: 'code',
                    useRefreshToken: true,
                    maxIdTokenIatOffsetAllowedInSeconds: 600,
                    autoUserInfo: false,
                    logLevel: LogLevel.Debug,
                    startCheckSession: true,
                    storage: new CustomStorage(),
                    secureRoutes: ['https://graph.microsoft.com/', 'https://graph.windows.net/'],


                    // silentRenewUrl: customConfig.config.redirect_url + '/silent-renew.html',
                    // postLoginRoute: customConfig.config.startup_route,
                    // forbiddenRoute: customConfig.config.forbidden_route,
                    // unauthorizedRoute: customConfig.config.unauthorized_route,

                    // autoUserInfo: false,
                };
            })
        )
        .toPromise();

    return new StsConfigHttpLoader(config$);
};

@NgModule({
    declarations: [
        AppComponent,
        ForbiddenComponent,
        UnauthorizedComponent,
        DateUTCPipe,
        ReturnComponent
    ],
    imports: [
        AuthModule.forRoot({
            loader: {
                provide: StsConfigLoader,
                useFactory: httpLoaderFactory,
                deps: [HttpBackend],
            }
        }),
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        DailyModule,
        FormsModule,
        FlexLayoutModule,
        LayoutModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatSidenavModule,
        MatSnackBarModule,
        MatCardModule,
        SharedModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        NgxMaskModule.forRoot(maskConfig)
    ],
    providers: [
        MonitoramentoService,
        AuthService,
        DateUTCPipe,
        ErrorService,
        // { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        // { provide: HTTP_INTERCEPTORS, useClass: UriInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: SubscriptionInterceptor, multi: true },
        { provide: DEFAULT_TIMEOUT, useValue: 300000 },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
        { provide: LOCALE_ID, useValue: 'pt' },
        { provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL' }
        // AuthorizationGuard,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent]
})
export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
