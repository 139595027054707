import {OverlayModule} from '@angular/cdk/overlay';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PopoverDirective} from './popover.directive';
import { PopoverOptionsDirective } from './popover-options.directive';

@NgModule({
  declarations: [
    PopoverDirective,
    PopoverOptionsDirective
  ],
  imports: [
    CommonModule,
    OverlayModule
  ],
  exports: [
    PopoverDirective,
    PopoverOptionsDirective
  ]
})
export class PopoverModule { }
