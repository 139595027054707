<ng-container *ngIf="link">
    <span class="tag-chips tag-chips--{{modifier}} tag-chips--clickable {{marginModifier}}" [routerLink]="link">
        <span *ngIf="icon" class="tag-chips__icon">
            <app-icon [icon]="icon" height="11px" width="11px" [color]="iconColor"></app-icon>
        </span>

        {{title}}
    </span>
</ng-container>

<ng-container *ngIf="!link">
    <span class="tag-chips tag-chips--{{modifier}} {{marginModifier}}">
        <span *ngIf="icon" class="tag-chips__icon">
            <app-icon [icon]="icon" height="11px" width="11px" [color]="iconColor"></app-icon>
        </span>

        {{title}}
    </span>
</ng-container>
