import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalService } from '../modal.service';

@Component({
  selector: 'app-modal-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss']
})
export class ModalDrawerComponent implements OnInit, OnDestroy {

  @Input() public modalId: string;

  @Input() public disposition: string;

  public animationFade = 'fade-in';

  public animationSides = 'right-to-left';

  constructor(private modalService: ModalService) { }

  ngOnInit(): void {
    if (!this.disposition) {
      this.disposition = 'right';
    }
  }

  ngOnDestroy(): void {
    this.animationFade = 'fade-in';
    this.animationSides = 'right-to-left';
  }

  public setAnimations(): void {
    if (!this.disposition) {
      this.disposition = 'right';
    }
  }

  public closeModal(event: any): void {
    this.animationFade = 'fade-out';
    this.animationSides = 'left-to-right';
    this.modalService.unsetModal(this.modalId);
  }
}
