import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DiariaDetail } from '../../../models/diarias/diaria-detail.interface';

interface CreateManualApprovalFormGroupOptions {
  initialValues?: DiariaDetail;
  withTravelNumberAndPlate?: boolean;
}

export const createManualApprovalFormGroup = ({ initialValues, withTravelNumberAndPlate }: CreateManualApprovalFormGroupOptions = {}) => {
  return new FormGroup({
    areaResponsavel: new FormControl(initialValues?.areaResponsavel ?? '', Validators.required),
    clienteResponsavel: new FormControl(initialValues?.clienteResponavel ?? ''),
    justificativa: new FormControl(initialValues?.justificativa ?? '', Validators.required),
    motivo: new FormControl(initialValues?.motivo ?? '', Validators.required),
    subMotivo: new FormControl(initialValues?.subMotivo ?? '', Validators.required),
    ...(withTravelNumberAndPlate ? {
      numeroViagem: new FormControl(initialValues?.numeroViagem ?? '', Validators.required),
      placa: new FormControl(initialValues?.placa ?? ''),
    } : {}),
  });
};
