import { NgModule } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { MenuComponent } from './menu/menu.component';
import { UserProfileComponent } from './header/user-profile/user-profile.component';
import { SearchComponent } from './header/search/search.component';
import { MatIconModule } from '@angular/material/icon';
import { MatCommonModule } from '@angular/material/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { ChatLinkModule } from 'src/app/components/chat/link/link.module';
import { MatDividerModule } from '@angular/material/divider';
import { OpenTabDirectiveModule } from 'src/app/directives/open-tab.module';
import { IconModule } from '../shared/icon/icon-module';
import { ModalFilterPrimaryModule } from '../modal/window/modal-filter-primary/modal-filter-primary.module';
import { AwesomeTooltipModule } from 'src/app/core/directives/tooltip/tooltip.module';

@NgModule({
  declarations: [
    HeaderComponent,
    MenuComponent,
    UserProfileComponent,
    SearchComponent
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatButtonModule,
    MatCheckboxModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    FormsModule,
    MatIconModule,
    MatCardModule,
    MatExpansionModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatCommonModule,
    MatInputModule,
    MatMenuModule,
    TranslateModule,
    MatDividerModule,
    ChatLinkModule,
    OpenTabDirectiveModule,
    IconModule,
    ModalFilterPrimaryModule,
    AwesomeTooltipModule
  ],

  exports: [
    HeaderComponent,
    MenuComponent
  ],
  providers: [],
  bootstrap: []
})
export class LayoutModule { }
