import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, EventEmitter,
  Input,
  OnDestroy,
  OnInit, Output,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { FilterService } from 'src/app/core/services/filter.service';
import { UserFilterScreenMonitoring } from 'src/app/models/user-filter/screen/monitoring.model';
import { UserFilter } from 'src/app/models/user-filter/user-filter.model';
import { ModalEnum, ModalService, TModal } from '../../../modal.service';
import { ModalWindowMonitoringFilterService, TCurrentFilter } from '../monitoring-filter.service';
import * as moment from 'moment';
import { DateUTCPipe } from 'src/app/core/custom-pipes/date-utc.pipe';
import { MonitoringFilter } from '../monitoring-filter';
import { TypeOperationPipe } from 'src/app/pipes/type-operation/type-operation.pipe';
import { LogisticTypeEnum } from 'src/app/core/models/enums/logistic-type.enum';

interface IFilterForm {
  transportadora: string[];
  unidadecomcodigoagro: string[];
  unidadecomcodigoorigemagro: string[];
  unidadecomcodigodestinoagro: string[];
  tipooperacaoagro: string[];
}

@Component({
  selector: 'app-modal-window-monitoring-filter-agro',
  templateUrl: './agro.component.html',
  styleUrls: ['./agro.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TypeOperationPipe]
})
export class ModalWindowMonitoringFilterAgroComponent extends MonitoringFilter implements OnInit, AfterViewInit, OnDestroy {

  @Input() public modalId: string;

  @Input() public myFiltersModalId: string;

  @Input() public savedFilterId: string;

  @Input() public isFirstFilter = false;

  @Output() filterCountChange = new EventEmitter<number>();

  public filterForm: FormGroup = null;

  public options: IFilterForm = null;

  public uuidModalMonitoringSaveFilter: string;

  public currentFilter: TCurrentFilter;

  public savedFilter: UserFilter<UserFilterScreenMonitoring>;

  savedFilters$ = this.modalWindowMonitoringFilterService.savedFilters$;

  filterCount = 0;

  constructor(
    private filterService: FilterService,
    private cdref: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private modalService: ModalService,
    private typeOperationPipe: TypeOperationPipe,
    protected dateUTC: DateUTCPipe,
    modalWindowMonitoringFilterService: ModalWindowMonitoringFilterService,
  ) {
    super(modalWindowMonitoringFilterService);
  }

  public ngOnInit(): void {
    const filter = this.getFilter(this.savedFilterId);

    let loadDate = null;

    if (filter) {
      loadDate = {
        startDate: filter.loadStartDate ? this.dateUTC.transform(filter?.loadStartDate) : null,
        endDate: filter.loadEndDate ? this.dateUTC.transform(filter?.loadEndDate) : null
      };
    }

    this.filterForm = this.formBuilder.group({
      loadNumber: new FormControl(filter?.loadNumber),
      plate: new FormControl(filter?.plate),
      carriers: new FormControl(),
      loadDate: new FormControl(loadDate),
      units: new FormControl(),
      origins: new FormControl(),
      destinations: new FormControl(),
      operations: new FormControl()
    });

    this.filterForm.valueChanges.subscribe(values => {
      if (values) {
        const count = Object.keys(this.filterForm.value)
          .filter(key => {
            const isArray = Array.isArray(this.filterForm.value[key]);
            if (!isArray && !!this.filterForm.value[key]) {
              return true;
            }
            if (isArray && this.filterForm.value[key].filter(item => !!item).length) {
              return true;
            }
          }).length;
        this.filterCount = count;
        this.filterCountChange.emit(count);
        this.cdref.detectChanges();
      }
    });

    this.options = {
      transportadora: [],
      unidadecomcodigoagro: [],
      unidadecomcodigodestinoagro: [],
      unidadecomcodigoorigemagro: [],
      tipooperacaoagro: []
    };

    this.cdref.detectChanges();
  }

  public ngAfterViewInit(): void {
    this.getOptions();
  }

  public ngOnDestroy(): void {
    this.options = null;
    this.filterForm = null;
  }

  private getOptions(): void {
    const options = this.modalWindowMonitoringFilterService.getOptions();

    if (options) {
      this.options = this.prepareOptions(options);
      this.cdref.detectChanges();
      return;
    }

    this.filterService.getSelectBoxOptions(
      [
        'transportadora',
        'unidadecomcodigoagro',
        'unidadecomcodigoorigemagro',
        'unidadecomcodigodestinoagro',
        'tipooperacaoagro'
      ],
      'agro'
    )
      .subscribe(data => {
        const fields = this.prepareOptions(data);

        this.options = fields;

        this.modalWindowMonitoringFilterService.setOptions(data);

        this.cdref.detectChanges();
      });
  }

  public onSubmit(): void {
    const currentFilter: TCurrentFilter = {
      mode: 'filtered',
      filter: this.getFakeUserFilter('monitoramento-agro', this.getParameters())
    };

    this.modalWindowMonitoringFilterService.setCurrentFilter(currentFilter);
    this.modalService.unsetModal(this.modalId);
  }

  public onCreateFilter(): void {
    const modal: TModal = {
      name: ModalEnum.WINDOW_MONITORING_FILTER_SAVE,
      data: {
        uuidModalMonitoringFilter: this.modalId,
        filtersResult: this.getFakeUserFilter(
          'monitoramento-agro',
          this.getParameters()
        ).parameters.serialize()
      }
    };

    this.uuidModalMonitoringSaveFilter = this.modalService.setModal(modal);
  }

  public onSaveFilter(): void {
    this.savedFilter.parameters = this.getParameters(false);
    this.savedFilter.default = true;

    const currentFilter: TCurrentFilter = {
      mode: 'saved',
      filter: this.savedFilter
    };

    const defaultSavedFilter = this.modalWindowMonitoringFilterService.getDefaultSavedFilter();

    if (defaultSavedFilter && defaultSavedFilter.id !== this.savedFilter.id) {
      defaultSavedFilter.default = false;
      this.modalWindowMonitoringFilterService.updateSavedFilter(defaultSavedFilter);
    }

    this.modalWindowMonitoringFilterService.updateSavedFilter(this.savedFilter);
    this.modalWindowMonitoringFilterService.setCurrentFilter(currentFilter);

    this.modalService.unsetModal(this.modalId);
    this.modalService.unsetModal(this.myFiltersModalId);
  }

  public onClearFilter(): void {
    this.filterForm.reset();
    this.cdref.detectChanges();
  }

  public onCancel(): void {
    this.modalService.unsetModal(this.modalId);
  }

  private getParameters(isStringify = true): any {
    const {
      loadNumber,
      plate,
      loadDate,
      carriers,
      units,
      origins,
      destinations,
      operations
    } = this.filterForm.controls;

    const params = {
      numeroViagem: loadNumber.value || [],
      placa: plate.value || [],
      transportadora: carriers.value ? carriers.value.map(carrier => carrier.key.replace('transportadora;', '')) : null,
      dataViagemInicio: loadDate.value?.startDate ? moment(loadDate.value?.startDate).format('YYYY-MM-DD') : null,
      dataViagemFim: loadDate.value?.endDate ? moment(loadDate.value?.endDate).format('YYYY-MM-DD') : null,
      unidade: units.value ? units.value.map(unit => unit.key.replace('unidadecomcodigoagro;', '')) : null,
      origem: origins.value ? origins.value.map(origin => origin.key.replace('unidadecomcodigoorigemagro;', '')) : null,
      destino: destinations.value
        ? destinations.value.map(destination => destination.key.replace('unidadecomcodigodestinoagro;', ''))
        : null,
      operacao: operations.value
        ? operations.value.map(operation => operation.key.replace('tipooperacaoagro;', ''))
        : null
    };

    return isStringify
      ? JSON.stringify(params)
      : new UserFilterScreenMonitoring(params);
  }

  private getKeyCurrentFilterByKey(key: string): any {
    if (!this.currentFilter || (this.currentFilter.mode === 'saved' && !this.savedFilterId)) {
      return;
    }

    let options = [];

    switch (key) {
      case 'transportadora':
        options = this.currentFilter.filter.parameters.carriers || [];
        break;
      case 'unidadecomcodigoagro':
        options = this.currentFilter.filter.parameters.units || [];
        break;
      case 'unidadecomcodigoorigemagro':
        options = this.currentFilter.filter.parameters.origins || [];
        break;
      case 'unidadecomcodigodestinoagro':
        options = this.currentFilter.filter.parameters.destinations || [];
        break;
      case 'tipooperacaoagro':
        options = this.currentFilter.filter.parameters.operations || [];
        break;
    }

    const filtersOptionKey = {};

    options.forEach(option => filtersOptionKey[`${key};${option}`] = null);

    return filtersOptionKey;
  }

  public prepareOptions(options: any): any {
    const fields = {
      transportadora: [],
      unidadecomcodigoagro: [],
      unidadecomcodigoorigemagro: [],
      unidadecomcodigodestinoagro: [],
      tipooperacaoagro: []
    };

    const selectedFields = {
      transportadora: [],
      unidadecomcodigoagro: [],
      unidadecomcodigoorigemagro: [],
      unidadecomcodigodestinoagro: [],
      tipooperacaoagro: []
    };

    Object.keys(options).forEach(field => {
      const keyValueFilterData = this.getKeyCurrentFilterByKey(field);
      fields[field] = options[field].map((option: any) => {
        const item = {
          key: option.id,
          display: field == 'tipooperacaoagro' ? this.typeOperationPipe.transform(option.label, LogisticTypeEnum.AGRONOMY) : option.label,
          value: field == 'tipooperacaoagro' ? this.typeOperationPipe.transform(option.label, LogisticTypeEnum.AGRONOMY) : option.label,
          checked: keyValueFilterData ? option.id in keyValueFilterData : false
        };

        if (item.checked) {
          selectedFields[field].push(item);
        }

        return item;
      });
    });

    this.filterForm.controls.carriers.setValue(selectedFields.transportadora);
    this.filterForm.controls.units.setValue(selectedFields.unidadecomcodigoagro);
    this.filterForm.controls.origins.setValue(selectedFields.unidadecomcodigoorigemagro);
    this.filterForm.controls.destinations.setValue(selectedFields.unidadecomcodigodestinoagro);
    this.filterForm.controls.operations.setValue(selectedFields.tipooperacaoagro);

    return fields;
  }
}
