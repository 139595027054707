import {NgModule} from '@angular/core';
import {GoogleMapComponent} from './google-map.component';
import {CommonModule} from '@angular/common';
import { GmMapModule } from './gm-map/gm-map.module';
import { GmMarkerModule } from './gm-marker/gm-marker.module';
import { GmCircleModule } from './gm-circle/gm-circle.module';
import { GmPolygonModule } from './gm-polygon/gm-polygon.module';
import { GmPolylineModule } from './gm-polyline/gm-polyline.module';
import { GmDirectionModule } from './gm-direction/gm-direction.module';

@NgModule({
  declarations: [GoogleMapComponent],
  exports: [GoogleMapComponent],
  imports: [
    CommonModule,
    GmCircleModule,
    GmPolylineModule,
    GmMarkerModule,
    GmMapModule,        
    GmPolygonModule,
    GmDirectionModule
  ]
}) export class GoogleMapModule { }
