import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MyFilter } from 'src/app/core/models/my-filter.model';
import { DiariaService } from 'src/app/core/services/diaria.service';
import { GDFilterService } from 'src/app/core/services/gd-filter.service';
import { Diarias } from 'src/app/models/diarias.model';

export class DialogDataDeleteFilter {
  name: string;
  id: string;
  screen: string;
}

@Component({
  selector: 'app-dialog-deletar-filtro',
  templateUrl: './dialog-deletar-filtro.component.html',
  styleUrls: ['./dialog-deletar-filtro.component.scss'],
})
export class DialogDeletarFiltroComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogDataDeleteFilter,
    private dialog: MatDialog,
    private gdFilterService: GDFilterService,
    private diariaService: DiariaService,
  ) { }

  nameFilter = '';

  ngOnInit(): void {
    this.nameFilter = this.data.name;
  }

  close(): void {
    this.dialog.closeAll();
  }

  async deleteFilter(): Promise<void> {
    await this.gdFilterService.deleteFilter(this.data.id, JSON.parse(localStorage.getItem('currentUser')).email);
    void this.loadFilters();
  }

  async loadFilters(): Promise<void> {
    const myFiltersResponse = await this.diariaService.getMyFilters(JSON.parse(localStorage.getItem('currentUser')).email);
    const myFilters: MyFilter[] = myFiltersResponse.map((element: any) => new MyFilter(element));
    const [defaultFilter] = myFilters.filter((element) => element.filtroPadrao);
    this.gdFilterService.myFilters = myFilters;
    this.gdFilterService.myFilterSelected = defaultFilter ?? null;

    const diarias = await this.diariaService.getAllDiarias(defaultFilter ? JSON.parse(defaultFilter.definicaoFiltro) : undefined);
    this.diariaService.listaDiarias = diarias.map((element: any) => new Diarias(element));
    this.diariaService.listaDiariasCompleta = diarias.map((element: any) => new Diarias(element));
    this.dialog.closeAll();
  }
}
