import { Anomaly } from 'src/app/models/anomaly.model';
import { StepDetailCommonProgrammedTracking } from './tracking.model';

export class StepDetailCommonProgrammed {
    #anomalies: Anomaly[];

    #tracking: StepDetailCommonProgrammedTracking;

    constructor({ anomalias, tracking }) {
        this.#anomalies = Anomaly.from(anomalias);
        this.#tracking = new StepDetailCommonProgrammedTracking(tracking);
    }

    get anomalies(): Anomaly[] {
        return this.#anomalies;
    }

    get tracking(): StepDetailCommonProgrammedTracking {
        return this.#tracking;
    }
}
