import { ProductStatusDistributionEnum } from '../enums/distribution/product-status.enum';
import { DateFormatPipe } from '../pipes/date.pipe';
import { KilogramFormatPipe } from '../pipes/kilogram/kilogram.pipe';
import { MoneyFormatPipe } from '../pipes/money.pipe';

export class Product {
    #code: string;

    #description: string;

    #dueDate: Date;

    #type: string;

    #weight: number;

    #quantityReturned: number;

    #quantity: number;

    #unit: string;

    #price: number;

    #status: string;

    constructor({
        codigo,
        descricao,
        vencimentoData,
        tipo,
        peso,
        quantidadeDevolvida,
        quantidade,
        unidadeMedida,
        valor,
        status
    }) {
        this.#code = codigo;
        this.#description = descricao;
        this.#dueDate = !vencimentoData ? null : new Date(vencimentoData);
        this.#type = tipo;
        this.#weight = peso;
        this.#quantityReturned = quantidadeDevolvida;
        this.#quantity = quantidade;
        this.#unit = unidadeMedida;
        this.#price = valor;
        this.#status = status;
    }

    get code(): string {
        return this.#code;
    }

    get description(): string {
        return this.#description;
    }

    get dueDate(): Date {
        return this.#dueDate;
    }

    get type(): string {
        return this.#type;
    }

    get weight(): number {
        return this.#weight;
    }

    get quantityReturned(): number {
        return this.#quantityReturned;
    }

    get quantity(): number {
        return this.#quantity;
    }

    get unit(): string {
        return this.#unit;
    }

    get price(): number {
        return this.#price;
    }

    get status(): string {
        return this.#status;
    }

    /**
     * Format for table
     */
    get weightReturned(): number {
        if (!this.quantityReturned || !this.weight) {
            return 0;
        }

        const total = this.quantityReturned * this.weight;
        return total;
    }

    get dueDateFormatted(): string {
        const pipe = new DateFormatPipe();

        return pipe.transform(this.dueDate);
    }

    get quantityBoxFormatted(): string {
        return `${this.quantity ?? 0} ${this.unit}`;
    }

    get priceFormatted(): string {
        const pipe = new MoneyFormatPipe();

        const formatted = pipe.transform(this.price);

        return formatted.replace('R$', '').trim();
    }

    get weightFormatted(): string {
        const pipe = new KilogramFormatPipe();

        const formatted = pipe.transform(this.weight);


        return formatted != undefined ? formatted.replace('kg', '').trim() : "";
    }

    get weightReturnedFormatted(): string {
        const pipe = new KilogramFormatPipe();

        const formatted = pipe.transform(this.weightReturned);

        return formatted.replace('kg', '').trim();
    }

    get statusFormatted(): string {
        return `(${this.status})`;
    }

    get hasDevolution(): boolean {
        return this.status === ProductStatusDistributionEnum.DevolutionTotal ||
            this.status === ProductStatusDistributionEnum.DevolutionPartial;
    }

    /** Serve apenas para plotar em vermelho dentro do componente app-grid */
    get hasAnomaly(): boolean {
        return this.hasDevolution;
    }

    /**
     * Instância de um lote de produtos
     *
     * @param products any[]
     * @returns Products[]
     */
    static from(products: any[]): Product[] {
        return products.map((product) => new Product(product));
    }
}
