import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseService } from './base.service';
import { MessageService } from './message.service';
import { PerfilPermissaoService } from './perfil-permissao.service';
import { StopMonitoringContinuosRequest } from '../models/stop-monitoring-continuos.model';
import { StartMonitoringContinuosRequest } from '../models/start-monitoring-continuos.model';

@Injectable()
export class MonitoringContinuosService extends BaseService<any> {
    constructor(_http: HttpClient, messageService: MessageService, perfilPermissaoService: PerfilPermissaoService) {
        super(_http, 'monitoramentocontinuo', messageService, perfilPermissaoService);
    }

    startMonitoring(startMonitoring: StartMonitoringContinuosRequest[]): Observable<any[]> {
        return this.http.post(`${this.url}/ativar-monitoramento`, startMonitoring)
            .pipe(map((response: any) => response as any[]));
    }

    stopMonitoring(stopMonitoring: StopMonitoringContinuosRequest): Observable<any[]> {
        return this.http.post(`${this.url}/parar-monitoramento`, stopMonitoring)
            .pipe(map((response: any) => response as any[]));
    }
}
