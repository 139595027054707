export enum MenuItemOriginEnum {
    Home = 'home',
    Dashboard = 'dashboard',
    Monitoring = 'monitoring',
    Anomaly = 'anomaly',
    Availability = 'availability',
    Airport = 'aiport-panel',
    Report = 'report',
    Register = 'register',
    DeliveryReceipt = 'consultaCanhoto',
    Feedback = 'retroalimentacao',
    Subzones = 'subzones',
    ParametersDistribution = 'parameters-distribution',
    Carrying = 'transportadoras',
    Deviations = 'desvios',
    Daily = 'diarias'
}
