interface IRequestViagemFilter {
  usuario: string;
  logistica: string;
  relatorio: ReportEnum;
  filtros: FiltrosViagem;
}

export class RequestViagem implements IRequestViagemFilter {
  public usuario: string;
  public logistica: string;
  public relatorio: ReportEnum;
  public filtros: FiltrosViagem;
}

export interface Relatorio {
  nome: string;
  processado: string;
  acao: string;
}

export interface RangeDataProgramada {
  dataInicial: Date;
  dataFinal: Date;
}

export interface FiltrosViagem {
  empresa?: Array<{ value: string; display: string }> | string;
  placas?: string;
  numeroViagens?: string;
  dataProgramadaCarregamento?: RangeDataProgramada;
  tipoRelatorio: any;
}

export interface ReportItem {
  relatorio?: string;
  nome?: string;
  usuario?: string;
  tamanho?: string;
  dataProcessamento?: Date;
  status?: string;
  filtros?: FiltrosViagem;
  urlArquivo?: string;
}

export enum ReportEnum {
  travels = 0,
  vehicles = 1,
  track = 2,
  process = 3,
  anomalies = 4,
  bucket = 5,
  devolution = 6,
  team_efficiency = 7,
  ticket_management = 8,
  travel = 9,
  customers = 10,
  products = 11,
  stops = 12,
  devolution_detailed = 13,
  track_pbi = 14
}

export enum ReportTravelEnum {
  travel_consolidated = 9,
  customers = 10,
  products = 11,
  stops = 12
}

export enum ReportDevolutionEnum {
  consolidated = 6,
  detailed = 13,
}

export interface ReportInfo {
  label: string;
  type: string;
  enum: ReportEnum;
  url: string;
}

export const ReportInformation: { [s: string]: ReportInfo } = {
  travels: { label: 'Viagens', type: 'primaria_viagens', enum: ReportEnum.travels, url: 'travels' },
  vehicles: { label: 'Veículos', type: 'primaria_veiculos', enum: ReportEnum.vehicles, url: 'vehicles' },
  deviations: { label: 'Desvios', type: 'primaria_desvios', enum: ReportEnum.track_pbi, url: 'deviations' },
  track: { label: 'Rastro', type: 'primaria_rastro', enum: ReportEnum.track, url: 'track' },
  'track-pbi': { label: 'Rastro - PBI', type: 'primaria_rastro_pbi', enum: ReportEnum.track_pbi, url: 'track-pbi' },
  process: { label: 'Processos', type: 'primaria_processo', enum: ReportEnum.process, url: 'process' },
  anomalies: { label: 'Anomalias', type: 'primaria_anomalias', enum: ReportEnum.anomalies, url: 'anomalies' },
  bucket: { label: 'Baldeio', type: 'distribuicao_baldeio', enum: ReportEnum.bucket, url: 'bucket' },
  devolution: { label: 'Devolução', type: 'distribuicao_consolidado_devolucao', enum: ReportEnum.devolution, url: 'devolution?reportType=devolution' },
  'team-efficiency': { label: 'Eficiência da equipe', type: 'distribuicao_eficiencia', enum: ReportEnum.team_efficiency, url: 'team-efficiency' },
  'ticket-management': { label: 'Gestão de canhotos', type: 'distribuicao_canhotos', enum: ReportEnum.ticket_management, url: 'ticket-management' },
  travel: { label: 'Viagem', type: 'distribuicao_viagem', enum: ReportEnum.travel, url: 'travel?reportType=travel' },
  customers: { label: 'Viagem', type: 'distribuicao_cliente', enum: ReportEnum.customers, url: 'travel?reportType=customers' },
  products: { label: 'Viagem', type: 'distribuicao_produto', enum: ReportEnum.products, url: 'travel?reportType=products' },
  stops: { label: 'Viagem', type: 'distribuicao_parada', enum: ReportEnum.stops, url: 'travel?reportType=stops' },
  'devolution-detailed': { label: 'Devolução', type: 'distribuicao_detalhado_devolucao', enum: ReportEnum.devolution_detailed, url: 'devolution?reportType=devolution-detailed' },
};
