import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { OidcSecurityService, UserDataResult } from 'angular-auth-oidc-client';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { MessageService } from './message.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { LogisticTypeEnum } from '../models/enums/logistic-type.enum';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthService {

  private readonly currentUserSubject: BehaviorSubject<any>;
  private readonly tokenSubject: BehaviorSubject<string>;

  private isLoginScreen: Subject<boolean>;
  currentUser: Observable<any>;
  tokenStorage: Observable<string>;
  user: any;

  constructor(private oidcSecurityService: OidcSecurityService,
    private translate: TranslateService,
    private dateAdapter: DateAdapter<any>,
    private readonly http: HttpClient,
    protected router: Router,
    protected messageService: MessageService) {
    this.isLoginScreen = new Subject();
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));

    try {
      this.tokenSubject = new BehaviorSubject<string>(JSON.parse(localStorage.getItem('token')));
    } catch (error) {
      console.log(error)
    }

  }

  public checkAuth(): Observable<any> {
    if (localStorage.getItem('token')) {
      var subject = Observable.create(observer => {
        observer.next(true);
        observer.complete();
      });
      return subject;
    }
    return this.oidcSecurityService.checkAuth();
  }

  public login(): void {
    return this.oidcSecurityService.authorize();
  }

  public logout(): void {
    if (localStorage.getItem('token')) {
      localStorage.clear();
    } else {
      this.oidcSecurityService.logoff();
    }
  }

  public get token(): string {
    if (localStorage.getItem('token')) {
      return this.tokenSubject.getValue();
    }
    return this.oidcSecurityService.getAccessToken();
  }

  public get userData(): Observable<any> {
    if (localStorage.getItem('token')) {
      return this.currentUserSubject;
    }
    return this.oidcSecurityService.userData$
      .pipe(map((data: UserDataResult) => data.userData));
  }

  public get isAuthenticated(): Observable<any> {
    return this.oidcSecurityService.isAuthenticated$;
  }

  public get onLoginScreen(): Observable<any> {
    return this.isLoginScreen.asObservable();
  }

  public setLoginScreen(isLoginScreen: boolean) {
    if (isLoginScreen) {
      localStorage.clear();
    }
    this.isLoginScreen.next(isLoginScreen);
  }

  public setDateLocale() {
    this.translate.setDefaultLang('pt');
    moment.locale('pt');
    this.dateAdapter.setLocale('pt');
  }

  public async loginTransportadora(email: String, password: String, logistic: String) {
    const api = logistic === 'Primária' ? environment.base_api_primary : environment.base_api_distribution;
    try {
      const resp: any = await this.http.post<any>(api + '/AutenticacaoTransportadora/login', {
        email: email,
        senha: password
      }, logistic === 'Primária' ? this.headerPrimary() : this.headerDistribution()).toPromise();

      if (resp) {
        const auth = resp;
        localStorage.setItem('token', JSON.stringify(auth.token));
        this.tokenSubject.next(auth.token);
        if (auth?.role?.toLowerCase() === LogisticTypeEnum.DISTRIBUITION) {
          auth['roles'] = ["TransportadoraDistribuicao"];
        }
        if (auth?.role?.toLowerCase() === LogisticTypeEnum.PRIMARY) {
          auth['roles'] = ["TransportadoraPrimaria"];
        }
        // Alteração solicitado para testes de automatização do front
        if (auth?.role?.toLowerCase() === 'automacaoqas') {
          auth['roles'] = [
            "MonitorDistribuicao",
            "MonitorPrimaria",
            "AdminAgro",
            "AdminCommodities",
            "AdminDistribuicao",
            "SomenteLeituraBRFPrimaria"
          ];
        }
        this.user = auth;
        this.user.token = '';
        localStorage.setItem('currentUser', JSON.stringify(this.user))
        this.currentUserSubject.next(this.user);
        return this.user;
      }
      return resp as any;
    } catch (error) {
      catchError(this.handleError);
    }
  }

  headerDistribution(): any {

    const headers = {
      'Content-Type': 'application/json',
      'X-Content-Type-Options': 'nosniff',
      'X-Frame-Options': 'DENY',
      'X-Xss-Protection': '1; mode=block',
    };


    const httpOptions = {
      headers: new HttpHeaders(headers)
    };

    return httpOptions;
  }

  headerPrimary(): any {

    const headers = {
      'Content-Type': 'application/json',
      'X-Content-Type-Options': 'nosniff',
      'X-Frame-Options': 'DENY',
      'X-Xss-Protection': '1; mode=block'
    };


    const httpOptions = {
      headers: new HttpHeaders(headers)
    };
    return httpOptions;
  }

  handleError(error: any): Observable<any> {
    console.log(error);
    return throwError(error);
  }
}
