import { NgModule } from '@angular/core';
import { MatNativeDateModule } from '@angular/material/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { GridComponent } from './grid/grid.component';
import { BreadCrumbComponent } from './bread-crumb/bread-crumb.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { TruncatePipe } from 'src/app/core/pipes/truncate.pipe';
import { TimePipe } from 'src/app/core/pipes/time.pipe';
import { MessageComponent } from './message/message.component';
import { MatSelectModule } from '@angular/material/select';
import { PaginationComponent } from './pagination/pagination.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FilterComponent } from './filter/filter.component';
import { FilterSelectedComponent } from './filter/filter-selected/filter-selected.component';
import { MyFiltersComponent } from './filter/my-filters/my-filters.component';
import { FilterSaveComponent } from './filter/filter-save/filter-save.component';
import { FilterDeleteComponent } from './filter/filter-delete/filter-delete.component';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { UsuarioFiltrosService } from 'src/app/core/services/usuario-filtros.service';
import { TextBoxComponent } from './input/text-box/text-box.component';
import { ComboBoxAutocompleteComponent } from './select/combo-box-autocomplete/combo-box-autocomplete.component';
import { MatIconModule } from '@angular/material/icon';
import { CopyPasteMultipleValue } from 'src/app/core/directives/copy-paste-multiple-value';
import { MatTooltipModule } from '@angular/material/tooltip';
import { GoogleChartsModule } from 'angular-google-charts';
import { DateTimeFormatPipe } from 'src/app/core/pipes/date-time-format.pipe';
import { LoaderComponent } from './loader/loader.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { DialogInfoComponent } from './dialog-info/dialog-info.component';
import { PanelAccordionComponent } from '../panel/accordion/accordion.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { KilometerFormatPipe } from 'src/app/pipes/kilometer.pipe';
import { KilogramFormatPipe } from 'src/app/pipes/kilogram/kilogram.pipe';
import { MoneyFormatPipe } from 'src/app/pipes/money.pipe';
import { ModalDrawerComponent } from '../modal/drawer/drawer.component';
import { ModalComponent } from '../modal/modal.component';
import { ModalDrawerAnomaliesComponent } from '../modal/drawer/anomalies/anomalies.component';
import { TagChipsComponent } from '../tag/chips/chips.component';
import { PanelComponent } from '../panel/panel.component';
import { PanelHeaderComponent } from '../panel/header/header.component';
import { AnomalyStatusPipe } from 'src/app/pipes/anomaly-status.pipe';
import { PriorityPipe } from 'src/app/pipes/priority.pipe';
import { LoadStatusPipe } from 'src/app/pipes/load-status.pipe';
import { DateFormatPipe } from 'src/app/pipes/date.pipe';
import { GridFooterComponent } from './grid/footer/footer.component';
import { ModalWindowComponent } from '../modal/window/window.component';
import { ModalWindowLoadsRelatedComponent } from '../modal/window/loads-related/loads-related.component';
import { ModalWindowShippingsComponent } from '../modal/window/shippings/shippings.component';
import { ModalDevolucaoComponent } from '../modal/window/modal-devolucao/modal-devolucao.component';
import { ModalWindowOrdersComponent } from '../modal/window/orders/orders.component';
import { GridOptionsComponent } from './grid/grid-options/grid-options.component';
import { BallTabsComponent } from './ball-tabs/ball-tabs.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ModalWindowFinishLoadComponent } from '../modal/window/finish-load/finish-load.component';
import { NumberFormatPipe } from 'src/app/pipes/number.pipe';
import { YesNoPipe } from 'src/app/pipes/yesno.pipe';
import { ModalWindowMonitoringFilterComponent } from '../modal/window/monitoring-filter/monitoring-filter.component';
import { ModalWindowMonitoringFilterAgroComponent } from '../modal/window/monitoring-filter/agro/agro.component';
import { LinkModule } from '../link/link.module';
import { SelectModule } from '../select/select.module';
import { InputModule } from '../input/input.module';
import { DateRangePickerModule } from '../date-range-picker/date-range-picker.module';
import { ModalWindowMonitoringFilterSaveFilterComponent } from '../modal/window/monitoring-filter/save-filter/save-filter.component';
import { ModalWindowMonitoringFilterMyFiltersComponent } from '../modal/window/monitoring-filter/my-filters/my-filters.component';
import { ModalWindowFinishStepComponent } from '../modal/window/finish-step/finish-step.component';
import { ModalWindowMonitoringFilterDeleteFilterComponent } from '../modal/window/monitoring-filter/delete-filter/delete-filter.component';
import { ButtonModule } from '../button/button.module';
import { InputFilter } from 'src/app/core/directives/input-filter';
import { ModalWindowMonitoringFilterCommoditiesComponent } from '../modal/window/monitoring-filter/commodities/commodities.component';
import { IconModule } from './icon/icon-module';
import { NotFoundModule } from './not-found/not-found.module';
import { ModalParadaRaioComponent } from '../modal/window/modal-parada-raio/modal-parada-raio.component';
import { ModalFilterSubzonesAgroComponent } from '../modal/window/monitoring-filter/modal-filter-subzones-agro/modal-filter-subzones-agro.component';
import { ModalFilterSubzonesPrimaryComponent } from '../modal/window/monitoring-filter/modal-filter-subzones-primary/modal-filter-subzones-primary.component';
import { RetroAlimentacaoModalComponent } from './retro-alimentacao-modal/retro-alimentacao-modal.component';
import { GoogleMapModule } from './google-map/google-map.module';
import { ModalFinishLoadModule } from '../modal/window/modal-finish-load/modal-finish-load.module';
import { TimeFormatModule } from 'src/app/pipes/time-format/time-format.module';
import { MonitoringContinuosService } from 'src/app/core/services/monitoring-contiuos.service';
import { SynopticMapV2Module } from '../synoptic-map-v2/synoptic-map-v2.module';
import { MatTableModule } from '@angular/material/table';
import { KilogramFormatModule } from 'src/app/pipes/kilogram/kilogram.module';

const maskConfig: Partial<IConfig> = {
    validation: false,
};
@NgModule({
    exports: [
        BreadCrumbComponent,
        GridComponent,
        FilterComponent,
        TextBoxComponent,
        ComboBoxAutocompleteComponent,
        TruncatePipe,
        TimePipe,
        CommonModule,
        CopyPasteMultipleValue,
        PaginationComponent,
        DateTimeFormatPipe,
        LoaderComponent,
        PanelAccordionComponent,
        PanelComponent,
        PanelHeaderComponent,
        PanelAccordionComponent,
        KilometerFormatPipe,
        // KilogramFormatPipe,
        KilogramFormatModule,
        MoneyFormatPipe,
        ModalComponent,
        ModalDrawerComponent,
        ModalDrawerAnomaliesComponent,
        TagChipsComponent,
        AnomalyStatusPipe,
        PriorityPipe,
        LoadStatusPipe,
        DateFormatPipe,
        GridFooterComponent,
        ModalWindowComponent,
        ModalWindowLoadsRelatedComponent,
        ModalWindowShippingsComponent,
        ModalDevolucaoComponent,
        ModalParadaRaioComponent,
        ModalWindowOrdersComponent,
        BallTabsComponent,
        ModalWindowFinishLoadComponent,
        ModalWindowFinishStepComponent,
        NumberFormatPipe,
        YesNoPipe,
        ModalWindowMonitoringFilterComponent,
        ModalWindowMonitoringFilterAgroComponent,
        ModalWindowMonitoringFilterSaveFilterComponent,
        ModalWindowMonitoringFilterDeleteFilterComponent,
        InputFilter,
        ModalWindowMonitoringFilterCommoditiesComponent,
        ModalFilterSubzonesAgroComponent,
        ModalFilterSubzonesPrimaryComponent,
        MatTableModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        InputModule,
        MatDatepickerModule,
        MatProgressSpinnerModule,
        MatButtonModule,
        MatIconModule,
    ],

    imports: [
        CommonModule,
        FormsModule,
        FlexLayoutModule,
        TranslateModule,
        MatSelectModule,
        MatMenuModule,
        MatButtonModule,
        MatCheckboxModule,
        MatIconModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatRadioModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        MatExpansionModule,
        MatAutocompleteModule,
        GoogleChartsModule.forRoot(),
        LinkModule,
        SelectModule,
        InputModule,
        DateRangePickerModule,
        ReactiveFormsModule,
        NgxMaskModule.forRoot(maskConfig),
        ButtonModule,
        IconModule,
        NotFoundModule,
        GoogleMapModule,
        ModalFinishLoadModule,
        KilogramFormatModule,
        TimeFormatModule,
        SynopticMapV2Module,
    ],
    entryComponents: [
        FilterSelectedComponent
    ],

    providers: [
        UsuarioFiltrosService,
        MonitoringContinuosService
    ],
    declarations: [
        BreadCrumbComponent,
        GridComponent,
        TruncatePipe,
        TimePipe,
        MessageComponent,
        PaginationComponent,
        FilterComponent,
        FilterSelectedComponent,
        MyFiltersComponent,
        FilterSaveComponent,
        FilterDeleteComponent,
        TextBoxComponent,
        ComboBoxAutocompleteComponent,
        CopyPasteMultipleValue,
        DateTimeFormatPipe,
        LoaderComponent,
        DialogInfoComponent,
        PanelAccordionComponent,
        KilometerFormatPipe,
        // KilogramFormatPipe,
        MoneyFormatPipe,
        ModalComponent,
        ModalDrawerComponent,
        ModalDrawerAnomaliesComponent,
        TagChipsComponent,
        PanelComponent,
        PanelHeaderComponent,
        AnomalyStatusPipe,
        PriorityPipe,
        LoadStatusPipe,
        DateFormatPipe,
        GridFooterComponent,
        ModalWindowComponent,
        ModalWindowLoadsRelatedComponent,
        ModalWindowShippingsComponent,
        ModalDevolucaoComponent,
        ModalParadaRaioComponent,
        ModalWindowOrdersComponent,
        GridOptionsComponent,
        BallTabsComponent,
        ModalWindowFinishLoadComponent,
        ModalWindowFinishStepComponent,
        NumberFormatPipe,
        YesNoPipe,
        ModalWindowMonitoringFilterComponent,
        ModalWindowMonitoringFilterAgroComponent,
        ModalWindowMonitoringFilterSaveFilterComponent,
        ModalWindowMonitoringFilterMyFiltersComponent,
        ModalWindowMonitoringFilterDeleteFilterComponent,
        InputFilter,
        ModalWindowMonitoringFilterCommoditiesComponent,
        ModalFilterSubzonesAgroComponent,
        ModalFilterSubzonesPrimaryComponent,
        RetroAlimentacaoModalComponent

    ]
})
export class SharedModule { }
