import {OnDestroy} from '@angular/core';


export type shouldCloseOnBackdropFn = () => Promise<boolean>;

export class ShouldModalClose {
  private queue: shouldCloseOnBackdropFn[] = [];

  constructor() {
  }

  intercept(bind: OnDestroy, fn: shouldCloseOnBackdropFn): () => void {
    this.queue.push(fn);
    const remove = () => this.queue.splice(this.queue.indexOf(fn), 1);
    const _destroy = bind.ngOnDestroy;
    bind.ngOnDestroy = (...args) => {
      remove();
      _destroy.apply(bind, ...args);
    };
    return remove.bind(this);
  }

  async should(): Promise<boolean> {

    for (const fn of this.queue) {
      if (!(await fn())) {
        return false;
      }
    }

    return true;
  }
}
