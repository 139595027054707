import {
  ChangeDetectionStrategy,
  Component,
  Input,
} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-chat-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatLinkComponent {

  @Input() public loadNumber: string = null;

  @Input() public plate: string = null;

  @Input() public totalUnreadMessage = 0;

  @Input() public floatingButton = false;

  @Input() public isMobile = false;

  constructor(private router: Router) { }

  public handleChat(): void {
    if (this.loadNumber && this.plate) {
      this.router.navigateByUrl(`/chat/viagem/${this.loadNumber}/placa/${this.plate}`);
      return;
    }

    this.router.navigateByUrl('/chat');
  }
}
