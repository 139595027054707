import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ForbiddenComponent } from './components/auth/forbidden/forbidden.component';
import { ReturnComponent } from './components/auth/return/return.component';
import { UnauthorizedComponent } from './components/auth/unauthorized/unauthorized.component';
import { ModalModule } from './core/services/modal';
import { CanActivateProfileAdmin } from './guards/can-activate-profile';

const routes: Routes = [
    { path: '', redirectTo: '/login', pathMatch: 'full' },
    {
        path: 'login',
        pathMatch: 'full',
        loadChildren: () => import('./components/login/login.module').then(m => m.LoginModule)
    },
    {
        path: 'forgot-password',
        pathMatch: 'full',
        loadChildren: () => import('./components/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
    },
    {
        path: 'new-password',
        pathMatch: 'full',
        loadChildren: () => import('./components/new-password/new-password.module').then(m => m.NewPasswordModule)
    },
    {
        path: 'logistic',
        pathMatch: 'full',
        loadChildren: () => import('./components/logistic/logistic.module').then(m => m.LogisticModule)
    },
    {
        path: 'home',
        pathMatch: 'full',
        loadChildren: () => import('./components/home/home.module').then(m => m.HomeModule)
    },
    {
        path: 'anomaly',
        loadChildren: () => import('./components/anomaly/anomaly.module').then(m => m.AnomalyModule)
    },
    {
        path: 'monitoring',
        loadChildren: () => import('./components/monitoring/monitoring.module').then(m => m.MonitoringModule)
    },
    {
        path: 'availability',
        loadChildren: () => import('./components/availability/availability.module').then(m => m.AvailabilityModule)
    },
    {
        path: 'consultaCanhoto',
        loadChildren: () => import('./components/consultaCanhoto/consultaCanhoto.module').then(m => m.ConsultaCanhotoModule)
    },
    {
        path: 'airport-panel',
        loadChildren: () => import('./components/airport-panel/airport-panel.module').then(m => m.AirportPanelModule)
    },
    {
        path: 'baldeio/:id',
        loadChildren: () => import('./components/baldeio/baldeio.module').then(m => m.BaldeioModule)
    },
    {
        path: 'chat',
        loadChildren: () => import('./pages/chat/chat.module').then(m => m.ChatMessageModule)
    },
    {
        path: 'load/:loadNumber',
        loadChildren: () => import('./pages/load/load.module').then(m => m.LoadModule)
    },
    {
        path: 'load/:loadNumber/:tab',
        loadChildren: () => import('./pages/load/load.module').then(m => m.LoadModule)
    },
    {
        path: 'load/:loadNumber/:tab/:id',
        loadChildren: () => import('./pages/load/load.module').then(m => m.LoadModule)
    },
    {
        path: 'monitoring-v2',
        loadChildren: () => import('./pages/monitoring-v2/monitoring-v2.module').then(m => m.MonitoringV2Module)
    },
    {
        path: 'anomaly-v2',
        loadChildren: () => import('./pages/anomaly/anomaly.module').then(m => m.AnomalyModule)
    },
    {
        path: 'reports',
        loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsModule)
    },
    {
        path: 'reports/:reportName',
        loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsModule)
    },
    {
        canActivate: [CanActivateProfileAdmin],
        path: 'subzones',
        loadChildren: () => import('./pages/subzones/subzones.module').then(m => m.SubzonesModule)
    },
    {
        path: 'retroalimentacao',
        loadChildren: () => import('./components/retro-alimentacao/retro-alimentacao.module').then(m => m.RetroAlimentacaoModule)
    },
    {
        path: 'retroalimentacao/:tipos-devolucao',
        loadChildren: () => import('./components/retro-alimentacao/retro-alimentacao.module').then(m => m.RetroAlimentacaoModule)
    },
    {
        canActivate: [CanActivateProfileAdmin],
        path: 'parameters-distribution',
        loadChildren: () => import('./pages/parameters/parameters-distribution/parameters-distribution.routing.module')
            .then(m => m.ParametersDistributionRoutingModule)
    },
    {
        canActivate: [CanActivateProfileAdmin],
        path: 'parameters-distribution/:parameterName',
        loadChildren: () => import('./pages/parameters/parameters-distribution/parameters-distribution.module')
            .then(m => m.ParametersDistributionModule)
    },
    {
        path: 'diarias',
        loadChildren: () => import('./pages/daily/daily.module').then(m => m.DailyModule)
    },
    {
        path: 'entregas',
        loadChildren: () => import('./pages/deliveries/deliveries.module').then(m => m.DeliveriesModule)
    },
    {
        path: 'gestao-de-viagens-e-entregas',
        loadChildren: () => import('./pages/deliveries-manager/deliveries-manager-page.module').then(m => m.DeliveriesManagerPageModule)
    },
    {
        canActivate: [CanActivateProfileAdmin],
        path: 'carriers',
        loadChildren: () => import('./pages/carrying/carrying.module').then(m => m.CarryingModule)
    },
    { path: 'return', component: ReturnComponent },
    { path: 'forbidden', component: ForbiddenComponent },
    { path: 'unauthorized', component: UnauthorizedComponent },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
        ModalModule
    ],
    providers: [CanActivateProfileAdmin],
    exports: [RouterModule]
})
export class AppRoutingModule { }
