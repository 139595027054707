import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateUTC'
})
export class DateUTCPipe implements PipeTransform {
  transform(value: Date, ...args: unknown[]): Date {
    if (value != null) {
      let dtMoment: moment.Moment = moment(value);
      let date = dtMoment.utc().toDate();
      return date;
    } else { return new Date() }
  }
}
