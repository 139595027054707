
<app-modal-window [modalId]="modalId" title="Excluir filtro">
    <div class="delete-filter">
        <span class="delete-filter__text">
            Tem certeza que deseja excluir o filtro “<strong>{{savedFilter.name}}</strong>”? Esta ação não pode ser desfeita.
        </span>    

        <div class="delete-filter__actions">
            <app-button (clicked)="handleBack()" text="Cancelar" [isOutlined]="true" [isMarginRight]="true"></app-button>
            <app-button (clicked)="handleDeleteFilter()" text="Excluir"></app-button>
        </div>
    </div>
</app-modal-window>
