export class Coordinate {

    private lat: number;
    private lng: number;
    private latitude: number;
    private longitude: number;

    constructor(lat: any, lng: any) {
        this.lat = Coordinate.convertLatLng(lat);
        this.lng = Coordinate.convertLatLng(lng);
        this.latitude = Coordinate.convertLatLng(lat);
        this.longitude = Coordinate.convertLatLng(lng);
    }

    static convertLatLng(latLng: number | string): number {
        return (typeof latLng == 'number')
            ? Math.trunc(latLng * 1000000) / 1000000
            : parseFloat(parseFloat(latLng).toFixed(6));
    }

    static latLngFormat(latLng: number, dashed = '-') {
        if (!latLng) {
            return dashed;
        }
        let str = latLng.toString();
        if (str.includes('-') && !str.includes('.')) {
            str = `${str.slice(0, 3)}.${str.slice(3)}`
        }
        if (!str.includes('-') && !str.includes('.')) {
            str = `${str.slice(0, 2)}.${str.slice(2)}`
        }
        if (str.length < 10) {
            return str.padEnd(10, '0');
        }
        return str;
    }

    toString() {
        return `${this.getLatitudeFormat()}@${this.getLongitudeFormat()}`
    }

    toJson() {
        return { lat: this.lat, lng: this.lng };
    }

    toJsonPost() {
        return { latitude: this.lat, longitude: this.lng };
    }

    getLatitude(): number {
        return this.lat;
    }

    getLongitude(): number {
        return this.lng;
    }

    getLatitudeFormat(dashed = '-'): string {
        return Coordinate.latLngFormat(this.lat, dashed);
    }

    getLongitudeFormat(dashed = '-'): string {
        return Coordinate.latLngFormat(this.lng, dashed);
    }
}