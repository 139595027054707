import { Component, Input, OnInit } from '@angular/core'; 
import { ModalEnum, ModalService, TModal } from '../../modal.service';

@Component({
  selector: 'app-modal-parada-raio',
  templateUrl: './modal-parada-raio.component.html',
  styleUrls: ['./modal-parada-raio.component.scss']
})
export class ModalParadaRaioComponent implements OnInit {

  @Input() modalId: string;

  @Input() paradas: any[];

  @Input() paradaRaioIndex = 0;


  constructor(private modalService: ModalService) { }

  public ngOnInit(): void {
    if (!this.paradas) { 
      return;
    }

  }

  public onModalOpen(): void {
    if (this.paradas.length <= 1) {
      return;
    }

    const modal: TModal = {
      name: ModalEnum.WINDOW_PARADA_RAIO,
      data: { paradas: this.paradas }
    };

    this.modalService.setModal(modal);
  }
}
