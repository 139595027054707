import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateformat'
})
export class DateFormatPipe implements PipeTransform {
  transform(value: any): string {
    if (!value) {
      return;
    }

    if (!(value instanceof Date)) {
      value = new Date(value);
    }

    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    };

    const formatter = new Intl.DateTimeFormat('pt-BR', options);

    return formatter.format(value);
  }
}
