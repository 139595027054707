<div class="modal-window-shippings">
    <span *ngIf="!modalId && shippings[0]" (click)="onModalOpen()" class="modal-window-shippings__visualization">
        <span *ngIf="shippings[0].code" class="modal-window-shippings__visualization">
            {{shippings[0].code }}
            {{shippings[0].date | datetimeformat:false}}
        </span>

        <span *ngIf="shippings[0].numero" class="modal-window-shippings__visualization">
            {{shippings[0].numero }}
            {{shippings[0].data | datetimeformat:false}}
        </span>

        <strong *ngIf="shippings && shippings.length > 1" class="modal-window-shippings__link">+{{shippings.length -
            1}}</strong>
    </span>

    <div *ngIf="modalId && shippings">
        <app-modal-window [modalId]="modalId" title="Remessas">
            <div class="modal-window-shippings__content">

                <span *ngFor="let shipping of shippings" class="modal-window-shippings__text">
                    <div *ngIf="shipping.code">
                        {{shipping.code }}
                        {{shipping.date | datetimeformat:false}}
                    </div>

                    <div *ngIf="shipping.numero">
                        {{shipping.numero }}
                        {{shipping.data | datetimeformat:false}}
                    </div>

                </span>

            </div>

            <span class="modal-window-shippings__total">
                Total de remessas: {{shippings.length}}
            </span>
        </app-modal-window>
    </div>
</div>