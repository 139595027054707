import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss']
})
export class LinkComponent implements OnInit {

  @Output() public handleClick = new EventEmitter<boolean>();

  @Input() public url: string;

  @Input() public isBolder = false;

  @Input() public title = '';

  @Input() public text = '';

  public modifierBolder = '';

  constructor(private router: Router) { }

  public ngOnInit(): void {
    if (this.isBolder) {
      this.modifierBolder = 'link--is-bolder';
    }
  }

  public handleRedirect(): void {
    if (!this.url && !this.handleClick) {
      return;
    }

    this.url
      ? this.router.navigateByUrl(this.url)
      : this.handleClick.emit(true);
  }
}
