export class ChatMessageTypeMessageReply {
    #message: string;

    #responsible: string;

    constructor({ texto, usuario }) {
        this.#message = texto;
        this.#responsible = usuario;
    }

    get message(): string {
        return this.#message;
    }

    get responsible(): string {
        return this.#responsible;
    }
}
