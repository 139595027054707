import { TipoInicio } from './tipoInicioEnum';

export class StepDetailDistributionStart {
    private planningDate: Date;

    private finishedDate: Date;

    private tipoInicio: TipoInicio;

    private tipoInicioViagem: string;

    private exitCDDate: Date;

    constructor({ dataHoraInicioPlanejada, dataHoraInicioRealizada, tipoInicio, tipoInicioViagem, dataHoraSaidaRaioCD }) {
        this.planningDate = !dataHoraInicioPlanejada ? null : new Date(dataHoraInicioPlanejada);
        this.finishedDate = !dataHoraInicioRealizada ? null : new Date(dataHoraInicioRealizada);
        this.exitCDDate = !dataHoraSaidaRaioCD ? null : new Date(dataHoraSaidaRaioCD);
        this.tipoInicio = tipoInicio;
        this.tipoInicioViagem = tipoInicioViagem;
    }

    get PlanningDate(): Date {
        return this.planningDate;
    }

    get FinishedDate(): Date {
        return this.finishedDate;
    }

    get TipoInicio(): TipoInicio {
        return this.tipoInicio;
    }

    get TipoInicioViagem(): string {
        return this.tipoInicioViagem;
    }

    get ExitCDDate(): Date {
        return this.exitCDDate;
    }
}
