import { Guid } from 'guid-typescript';

export class BreadCrumbItem { 
    public id: Guid;
    public title: string;
    public route: string;
    public translate: boolean;
    public symbol: string;

    constructor( title: string = null, route: string = null, translate =  true, symbol = '/') {
        this.id = Guid.create();
        this.title = title;
        this.route = route;
        this.translate = translate;
        this.symbol = symbol;
    }
}