import { Component, Input, OnInit } from '@angular/core';
import { RastroViagem } from 'src/app/core/models/rastro-viagem.model';
import { DiariaDetail } from 'src/app/models/diarias/diaria-detail.interface';

@Component({
  selector: 'app-diaria-details-info',
  templateUrl: './diaria-details-info.component.html',
  styleUrls: ['./diaria-details-info.component.scss']
})
export class DiariaDetailsInfoComponent implements OnInit {

  @Input() details: DiariaDetail;
  googleMapPoints: Array<RastroViagem> | undefined = undefined;

  ngOnInit(): void {
    this.setGoogleMapPoints();
  }

  private setGoogleMapPoints = (): void => {
    if (!this.details.localAnomalia) {
      return;
    }

    this.googleMapPoints = [{
      tipoObj: 4,
      latitude: this.details.localAnomalia.latitude,
      longitude: this.details.localAnomalia.longitude,
      parada: { nome: this.details.localAnomalia.endereco },
    }];
  }
}
