import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent {

  public message: string;

  public icon: string;

  public type: "success" | "error" | "caution" = "success";

  public button: any;

  public isHtml: boolean;

  public methodObservable: any = null;

  public hideIcon: boolean = false;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    protected router: Router,
    public snackBar: MatSnackBar
  ) {
    this.message = data.message;
    // this.icon = data.icon;
    this.type = data.type;
    this.button = data.button;
    this.isHtml = data.isHtml;
    this.methodObservable = data.methodObservable;
    this.hideIcon = data.hideIcon;
  }

  goTo(url: string): void {
    if (this.methodObservable) {
      this.methodObservable();
    }

    this.router.navigateByUrl(url);
  }

  close(): void {
    this.snackBar.dismiss();
  }
}
