import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GmMarkerComponent } from './gm-marker.component';

@NgModule({
    declarations: [GmMarkerComponent],
    exports: [GmMarkerComponent],
    imports: [
        CommonModule,
    ],    
}) export class GmMarkerModule { }
