import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private isLoadingSubject: Subject<boolean>;
  public isLoading: Observable<boolean>;

  constructor() {
    this.isLoadingSubject = new Subject();
    this.isLoading = this.isLoadingSubject.asObservable();
  }

  public startLoading() {
    this.isLoadingSubject.next(true);
  }

  public finishLoading() {
    this.isLoadingSubject.next(false);
  }
}
