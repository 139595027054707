import {Overlay} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {ApplicationRef, ComponentFactory, ComponentFactoryResolver, Injectable, Injector, Type} from '@angular/core';
import {ModalContainerComponent} from './modal-container/modal-container.component';
import {ModalAnimation, ModalOptions} from './modal-options';
import {ModalRef} from './modal-ref.model';
import {MODAL_COMPONENT, MODAL_COMPONENT_INJECTOR, MODAL_DATA, MODAL_REF} from './modal-tokens';
import {IModal} from './modal.interface';

export interface ModalCreateOptions<C extends IModal<C['data'], C['$r']>> {
  data?: C['data'];
  options?: ModalOptions;
}

@Injectable({providedIn: 'root'})
export class ModalService {

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
    private overlay: Overlay,
  ) {
    this.modalContainerFactory = this.componentFactoryResolver.resolveComponentFactory(ModalContainerComponent);
  }

  private modalContainerFactory: ComponentFactory<ModalContainerComponent>;


  create<C extends IModal<C['data'], C['$r']>>(
    component: Type<C>,
    {data, options = {}}: ModalCreateOptions<C> = {}
  ): ModalRef<C['$r']> {

    const opts: ModalOptions = {
      backdropDismiss: true,
      keyboardDismiss: true,
      animation: ModalAnimation.SLIDE_UP,
      verticalAlign: 'center',
      ...(options || {})
    };

    const open = options.openFrom;
    if (open?.x && open?.y) {
      opts.openFrom = { top: open.y, left: open.x };
    } else {
      opts.openFrom = { left: window.innerWidth / 2, top: window.innerHeight / 2 };
    }

    const modalRef = new ModalRef<C['$r']>(opts);

    const componentInjector = Injector.create({
      providers: [
        { provide: MODAL_DATA, useValue: data },
        { provide: MODAL_REF, useValue: modalRef },
      ],
      parent: this.injector
    });

    const containerInjector = Injector.create({
      providers: [
        { provide: MODAL_REF, useValue: modalRef },
        { provide: MODAL_COMPONENT, useValue: component },
        { provide: MODAL_COMPONENT_INJECTOR, useValue: componentInjector },
      ],
      parent: this.injector
    });

    const overlayRef = this.overlay.create({
      // disposeOnNavigation: true
    });

    modalRef.container = overlayRef.attach(
      new ComponentPortal(ModalContainerComponent, null, containerInjector)
    );

    modalRef.container.onDestroy(_ => overlayRef.detach());

    return modalRef;
  }
}
