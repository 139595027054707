import { TOption } from 'src/app/components/select/select.component';
import { LogisticTypeEnum } from 'src/app/core/models/enums/logistic-type.enum';
import { CityFilter, SubzoneFilterAgro, SubzoneFilterPrimary } from 'src/app/core/models/filters/subzone-filter';
import { ISerialize } from '../../serialize.interface';

export class UserFilterScreenMonitoring implements ISerialize {
    #loadNumber: string[];

    #plate: string[];

    #carriers: string[];

    #loadStartDate: Date;

    #loadEndDate: Date;

    #units: string[];

    #origins: string[];

    #destinations: string[];

    #operations: number[];

    #products: string[];

    #equipmentType: string[];

    #orders: string[];

    #loadStatus: number[];

    #scheduleType: number[];

    #mirroringStatus: number[];

    #processTypeCommodities: number[];

    #unitCode: string[];

    #code: string;

    #description: string;

    #unitTypes: number[];

    #unitType: string[];

    #status: boolean;

    #cities: TOption[];

    #states: TOption[];

    #anotherCity: string;

    #anotherState: string;

    #citiesAndStates: CityFilter[];

    #category: string;

    #name: string;

    #exitType: number[];

    #deductibleStay: string;

    constructor({
        numeroViagem = null,
        placa = null,
        transportadora = null,
        dataViagemInicio = null,
        dataViagemFim = null,
        unidade = null,
        origem = null,
        destino = null,
        operacao = null,
        produtos = null,
        pedidos = null,
        tipoAgendamento = null,
        statusViagem = null,
        statusEspelhamento = null,
        tipoEquipamento = null,
        tipoProcessoCommodities = null,
        descricao = null,
        codigos = null,
        codigo = null,
        status = null,
        tipoUnidades = [],
        tipoUnidade = [],
        cidadesEstados = [],
        cidades = [],
        estados = [],
        outraCidade = null,
        outroEstado = null,
        categoria = null,
        nome = null,
        tipoSaida = []
    }) {        
        this.#loadNumber = numeroViagem;
        this.#plate = placa;
        this.#carriers = transportadora;
        this.#loadStartDate = dataViagemInicio;
        this.#loadEndDate = dataViagemFim;
        this.#units = unidade;
        this.#origins = origem;
        this.#destinations = destino;
        this.#operations = operacao;
        this.#products = produtos;
        this.#equipmentType = tipoEquipamento;
        this.#orders = pedidos;
        this.#loadStatus = statusViagem;
        this.#scheduleType = tipoAgendamento;
        this.#mirroringStatus = statusEspelhamento;
        this.#processTypeCommodities = tipoProcessoCommodities;
        this.#description = descricao;
        this.#unitCode = codigos;
        this.#code = codigo;
        this.#status = status;
        this.#unitTypes = tipoUnidades;
        this.#unitType = tipoUnidade;
        this.#citiesAndStates = cidadesEstados;
        this.#cities = cidades;
        this.#states = estados;
        this.#anotherCity = outraCidade;
        this.#anotherState = outroEstado;
        this.#category = categoria;
        this.#name = nome;
        this.#exitType = tipoSaida;        
    }

    get loadNumber(): string[] {
        return this.#loadNumber;
    }

    get plate(): string[] {
        return this.#plate;
    }

    get carriers(): string[] {
        return this.#carriers;
    }

    get loadStartDate(): Date {
        return this.#loadStartDate;
    }

    get loadEndDate(): Date {
        return this.#loadEndDate;
    }

    get units(): string[] {
        return this.#units;
    }

    get origins(): string[] {
        return this.#origins;
    }

    get destinations(): string[] {
        return this.#destinations;
    }

    get operations(): number[] {
        return this.#operations;
    }

    get products(): string[] {
        return this.#products;
    }

    get equipmentType(): string[] {
        return this.#equipmentType;
    }

    get orders(): string[] {
        return this.#orders;
    }

    get loadStatus(): number[] {
        return this.#loadStatus;
    }

    get scheduleType(): number[] {
        return this.#scheduleType;
    }

    get unitCode(): string[] {
        return this.#unitCode;
    }

    get code(): string {
        return this.#code;
    }

    get description(): string {
        return this.#description;
    }

    get unitTypes(): number[] {
        return this.#unitTypes;
    }

    get unitType(): string[] {
        return this.#unitType;
    }

    get status(): boolean {
        return this.#status;
    }

    get cities(): TOption[] {
        return this.#cities;
    }

    get states(): TOption[] {
        return this.#states;
    }

    get anotherCity(): string {
        return this.#anotherCity;
    }

    get anotherState(): string {
        return this.#anotherState;
    }

    get citiesAndStates(): CityFilter[] {
        return this.#citiesAndStates;
    }

    get mirroringStatus(): number[] {
        return this.#mirroringStatus;
    }

    get processTypeCommodities(): number[] {
        return this.#processTypeCommodities
    }

    get category() : string {
        return this.#category;
    }

    get name(): string {
        return this.#name;
    }

    get exitType(): number[] {
        return this.#exitType;
    }    

    serialize(): any {
        const params = {
            numeroViagem: this.#loadNumber,
            placa: this.#plate,
            transportadora: this.#carriers,
            dataViagemInicio: this.#loadStartDate instanceof Date ? this.#loadStartDate.toISOString() : this.#loadStartDate,
            dataViagemFim: this.#loadEndDate instanceof Date ? this.#loadEndDate.toISOString() : this.#loadEndDate,
            unidade: this.#units,
            origem: this.#origins,
            destino: this.#destinations,
            operacao: this.#operations,
            produtos: this.#products,
            tipoEquipamento: this.#equipmentType,
            pedidos: this.#orders,
            statusViagem: this.#loadStatus,
            statusEspelhamento: this.#mirroringStatus,
            tipoAgendamento: this.#scheduleType,
            tipoProcessoCommodities: this.#processTypeCommodities,
            descricao: this.#description,
            codigos: this.#unitCode,
            codigo: this.#code,
            status: this.#status,
            tipoUnidades: this.#unitTypes,
            tipoUnidade: this.#unitType,
            cidades: this.#cities,
            estados: this.#states,
            outraCidade: this.#anotherCity,
            outroEstado: this.#anotherState,
            cidadesEstados: this.#citiesAndStates,
            tipoSaida: this.#exitType            
        };

        const preparedParams = {};

        Object.keys(params).forEach(paramKey => {
            const value = params[paramKey];
            if (typeof value == 'boolean') {
                preparedParams[paramKey] = value;
            }
            if (Array.isArray(value) && value.length) {                
                preparedParams[paramKey] = value;
            } else if (typeof value === 'string' && !!value) {
                preparedParams[paramKey] = value;
            }
        });

        return preparedParams;
    }

    getSubzoneFilterAgro(): SubzoneFilterAgro {
        const filter = new SubzoneFilterAgro(LogisticTypeEnum.AGRONOMY);
        filter.codigos = this.unitCode || [];
        filter.descricao = this.description;
        filter.status = this.status;
        filter.tipoUnidades = this.unitTypes;
        filter.outraCidade = this.anotherCity;
        filter.outroEstado = this.anotherState;
        filter.cidades = this.cities;
        filter.estados = this.states;
        if (filter.outraCidade && filter.outroEstado) {
            filter.cidadesEstados = [new CityFilter(filter.outraCidade, filter.outroEstado)];
        } else {
            filter.cidadesEstados = this.citiesAndStates;
        }

        return filter;
    }

    getSubzoneFilterPrimary() : SubzoneFilterPrimary {
        const filter = new SubzoneFilterPrimary(LogisticTypeEnum.PRIMARY);
        filter.categoria = this.category;
        filter.nome = this.name;
        filter.tipoUnidade = this.unitType;
        filter.codigo = this.code;
        filter.tipoSaida = this.exitType;        
        return filter;
    }
}
