import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DailyComponent } from './daily.component';
import { TabsModule } from 'src/app/components/tabs/tabs.module';
import { GoogleMapModule } from 'src/app/components/shared/google-map/google-map.module';
import { DailyRoutingModule } from './daily-routing.module';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { DialogFiltroComponent } from './dialog-filtro/dialog-filtro.component';
import { SinalizarDiariaComponent } from './sinalizar-diaria/sinalizar-diaria.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DialogFiltroService } from 'src/app/services/dialog-filtro.service';
import { AnomalyService } from 'src/app/core/services';
import { DetalhesComponent } from './detalhes/detalhes.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DiariaDetailsInfoComponent } from './components/diaria-details-info/diaria-details-info.component';
import { ApprovalRequestFormComponent } from './components/approval-request-form/approval-request-form.component';
import { ApprovalRequestGridFormComponent } from './components/approval-request-grid-form/approval-request-grid-form.component';
import { ApprovalRequestGridFormRowComponent } from './components/approval-request-grid-form/approval-request-grid-form-row/approval-request-grid-form-row.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { ButtonModule } from 'src/app/components/button/button.module';
import { LinkModule } from 'src/app/components/link/link.module';
import { DialogMeusFiltrosComponent } from './dialog-meus-filtros/dialog-meus-filtros.component';
import { DialogSalvarFiltroComponent } from './dialog-salvar-filtro/dialog-salvar-filtro.component';
import { DiariaTreatmentComponent } from './components/diaria-treatment/diaria-treatment.component';
import { IconModule } from 'src/app/components/shared/icon/icon-module';
import { InputFormFieldModule } from 'src/app/components/input-form-field/input-form-field.module';
import { SelectFormFieldModule } from 'src/app/components/select-form-field/select-form-field.module';
import { MatRadioModule } from '@angular/material/radio';
import { DialogDeletarFiltroComponent } from './dialog-deletar-filtro/dialog-deletar-filtro.component';
import { SelectModule } from 'src/app/components/select/select.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  declarations: [
    DailyComponent,
    DialogFiltroComponent,
    DetalhesComponent,
    SinalizarDiariaComponent,
    DiariaDetailsInfoComponent,
    ApprovalRequestFormComponent,
    ApprovalRequestGridFormComponent,
    ApprovalRequestGridFormRowComponent,
    DialogMeusFiltrosComponent,
    DialogSalvarFiltroComponent,
    DialogDeletarFiltroComponent,
    DiariaTreatmentComponent,
  ],
  imports: [
    CommonModule,
    DailyRoutingModule,
    FormsModule,
    MatCardModule,
    MatTabsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressBarModule,
    ButtonModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatTooltipModule,
    ReactiveFormsModule,
    TabsModule,
    GoogleMapModule,
    ReactiveFormsModule,
    TranslateModule,
    MatIconModule,
    LinkModule,
    IconModule,
    MatRadioModule,
    SelectModule,
    MatButtonModule,
    InputFormFieldModule,
    SelectFormFieldModule,
    MatCheckboxModule,
  ],
  providers: [
    AnomalyService,
    DialogFiltroService,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { hideRequiredMarker: 'hideRequire' },
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [DailyComponent],
})
export class DailyModule { }
