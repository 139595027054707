<mat-form-field appearance="fill">
    <mat-datepicker-toggle [for]="picker"></mat-datepicker-toggle>

    <mat-date-range-input
      [rangePicker]="picker"
      [formGroup]="dateGroup"
      [disabled]="isDisabled"
      [min]="min"
      [max]="max">
      <input readonly matStartDate formControlName="startDate" [placeholder]="placeholderStartDate"
             (ngModelChange)="handleChange()" (click)="picker.open()">
      <input readonly matEndDate formControlName="endDate" [placeholder]="placeholderEndDate"
             (ngModelChange)="handleChange()" (click)="picker.open()">
    </mat-date-range-input>

    <mat-datepicker-toggle *ngIf="dateGroup.controls.startDate.value || dateGroup.controls.endDate.value" (click)="reset()">
       <mat-icon matDatepickerToggleIcon>
              close
       </mat-icon>
     </mat-datepicker-toggle>

    <mat-date-range-picker #picker></mat-date-range-picker>
</mat-form-field>
