<button
    [title]="text"
    class="
        button 
        {{modifierColor}}
        {{modifierSize}}
        {{modifierCircle}}
        {{modifierAnimation}}
        {{modifierFont}}
        {{modifierDisabled}}
        {{modifierAnimation}}
        {{modifierMarginLeft}}
        {{modifierMarginRight}}
        {{modifierPadding}}
    "
    (click)="onClick()"
    [type]="type"
>
    {{text}}
</button>
