<div class="filter-table-daily" *ngIf="!loading">
  <div class="filter-table-daily__title">
    <h1>Filtros</h1>
    <button class="close-button" (click)="close()">
      <mat-icon matSuffix>close</mat-icon>
    </button>
  </div>
  <form [formGroup]="form" class="filter-table-daily__content">
    <app-input-form-field
      textListSeparator=";"
      formControlName="numeroViagem"
      [label]="'DAILY.TRAVEL' | translate"
      [placeholder]="'DAILY.INSERT_NUMBER_TRAVEL' | translate"
    ></app-input-form-field>

    <app-input-form-field
      textListSeparator=";"
      formControlName="placa"
      [label]="'DAILY.PLATE' | translate"
      [placeholder]="'DAILY.INSERT_PLATE' | translate"
    ></app-input-form-field>

    <app-input-form-field
      formControlName="tempoEmDiaria"
      [label]="'DAILY.TIME_IN_DAILY' | translate"
      [placeholder]="'DAILY.TIME_IN_DAILY' | translate"
    ></app-input-form-field>

    <app-input-form-field
      type="number"
      formControlName="pesoMinimo"
      [label]="'DAILY.WEIGHT_MIN' | translate"
      [placeholder]="'DAILY.INSERT_WEIGHT_IN_KG' | translate"
    ></app-input-form-field>

    <app-input-form-field
      type="number"
      formControlName="pesoMaximo"
      [label]="'DAILY.WEIGHT_MAX' | translate"
      [placeholder]="'DAILY.INSERT_WEIGHT_IN_KG' | translate"
    ></app-input-form-field>

    <app-select-form-field
      formControlName="cliente"
      [label]="'DAILY.CLIENT' | translate"
      [searchPlaceholder]="'FILTER.SEARCH_FOR_CLIENT_NAME' | translate"
      [options]="clientsOptions"
    ></app-select-form-field>

    <app-select-form-field
      formControlName="motivo"
      [label]="'DAILY.REASON' | translate"
      [options]="reasonsOptions"
    ></app-select-form-field>

    <app-select-form-field
      formControlName="status"
      [label]="'DAILY.STATUS' | translate"
      [options]="statusesOptions"
    ></app-select-form-field>

    <app-select-form-field
      formControlName="regiao"
      [label]="'DAILY.REGION' | translate"
      [options]="regionOptions"
    ></app-select-form-field>

    <app-select-form-field
      *ngIf="displayIndustryFilter"
      formControlName="unidadeCalculada"
      [label]="'DAILY.INDUSTRY' | translate"
      [options]="industriesOptions"
    ></app-select-form-field>

    <app-select-form-field
      formControlName="areaResponsavel"
      [label]="'DAILY.RESPONSIBLE_AREA' | translate"
      [options]="responsibleAreaOptions"
    ></app-select-form-field>

  </form>
  <div class="filter-table-daily__footer">
    <app-link *ngIf="haveValue" (handleClick)="clearFilter()" [isBolder]="true">
      {{ 'FILTER.CLEAR_FILTER' | translate }}
    </app-link>

    <app-button
      *ngIf="!useMyFilter"
      text="{{'FILTER.CREATE_FILTER' | translate}}"
      (clicked)="createFilter()"
      [isDisabled]="!haveValue"
      [isOutlined]="true"
      [isMarginLeft]="true"
      [isMarginRight]="true"
    ></app-button>

    <app-button
      *ngIf="useMyFilter"
      text="{{'FILTER.CANCEL' | translate}}"
      (clicked)="close()"
      [isOutlined]="true"
      [isMarginRight]="true"
      [isMarginLeft]="true"
    ></app-button>

    <app-button
      *ngIf="typeAction != 'edit'"
      text="{{'FILTER.APPLY_FILTER' | translate}}"
      (clicked)="applyFilter()"
    ></app-button>

    <app-button
      *ngIf="typeAction == 'edit'"
      text="{{'FILTER.EDIT_FILTER' | translate}}"
      (clicked)="editFilter()"
      [isDisabled]="!haveValue"
    ></app-button>
  </div>
</div>
