import { ProductStatusDistributionEnum } from 'src/app/enums/distribution/product-status.enum';
import { Product } from 'src/app/models/product.model';
import { Shipping } from 'src/app/models/shipping.model';

export class StepDetailDistributionClientCharge {
    constructor(
        public loadNumber: string,
        public invoiceNumber: string,
        public serie: string,
        public emissionDate: Date,
        public shippings: Shipping[],
        public orders: string[],
        public originCenter: string,
        public grossWeight: number,
        public netWeight: number,
        public invoicePrice: number,
        public valorItensNotaFiscal: number,
        public volume: number,
        public conditionPayment: string,
        public products: Product[]
    ) { }

    get fullName(): string {
        return `Nota Fiscal: ${this.invoiceNumber}`;
    }

    get fullNameWithLoad(): string {
        if (!this.loadNumber) {
            console.error('Não possui número da viagem para exibir o texto');
            return '---';
        }

        return `Viagem: ${this.loadNumber} - Nota Fiscal: ${this.invoiceNumber}`;
    }

    get volumeFormatted(): string {
        return this.volume.toString().replace('.', ',');
    }

    get totalDevolutions(): number {
        return this.products.filter((product: Product) => {
            return product.status === ProductStatusDistributionEnum.DevolutionTotal ||
                product.status === ProductStatusDistributionEnum.DevolutionPartial;
        }).length;
    }

    get hasDevolution(): boolean {
        return this.products.filter((product: Product) => {
            return product.status === ProductStatusDistributionEnum.DevolutionTotal ||
                product.status === ProductStatusDistributionEnum.DevolutionPartial;
        }).length > 0;
    }

    get priority(): string {
        const shipping = this.shippings.length > 0
            ? this.shippings[0]
            : null;

        if (!shipping) {
            return;
        }

        return shipping.type;
    }
}
