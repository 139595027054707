import {NgModule} from '@angular/core';
import {NotFoundComponent} from './not-found.component';
import {CommonModule} from '@angular/common';
import {ButtonModule} from '../../button/button.module';


@NgModule({
  declarations: [NotFoundComponent],
  exports: [NotFoundComponent],
  imports: [
    CommonModule,
    ButtonModule
  ]
}) export class NotFoundModule { }
