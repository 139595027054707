import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {

    constructor() {

    }

    public getItem<T>(key: string): T {
        const value = localStorage.getItem(key);
        return JSON.parse(value) as T;
    }

    public setItem<T>(key: string, value: T): void {
        localStorage.setItem(key, JSON.stringify(value));
    }

    public removeItem<T>(key: string): void {
        localStorage.removeItem(key);
    }

    public clearAll(): void {
        localStorage.clear();
    }
}
