<div [ngStyle]="{'display': isLoginScreenCompleted ? 'block' : 'none'}">

<div *ngIf="!isLoginScreen && !checkUrlPrintPreview()" class="app-container">
  <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
  <app-header [(toggle)]="toggle" [showMenu]="!isLogisticScreen"></app-header>
    <mat-sidenav-container class="sidenav-container" autosize
                           [style.marginTop.px]="0">
      <mat-sidenav *ngIf="!isLogisticScreen" #snav [mode]="mobileQuery.matches ? 'over' : 'side'" [opened]="false" [position]="'start'" [hasBackdrop]="false">
        <app-menu (itemSelected)="setBreadCrumb($event.breadCrumb)"></app-menu>
      </mat-sidenav>
      <mat-sidenav-content  (scroll)="onElementScroll($event)">
        <div id="content-container" class="screen-container" fxLayout="column" [ngStyle]="{'background-color': backGroundColor ? backGroundColor : '#fff'}">
          <app-bread-crumb [list]="breadCrumb"></app-bread-crumb>
          <router-outlet></router-outlet>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
</div>

  <router-outlet *ngIf="isLoginScreen || checkUrlPrintPreview()"></router-outlet>

  <app-modal></app-modal>
</div>

<app-loader></app-loader>
