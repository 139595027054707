import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { createManualApprovalFormGroup } from 'src/app/pages/daily/utils/create-manual-approval-form-group';
import { DiariaManualApprovalRequest } from 'src/app/models/diarias/diaria-approval.interface';

@Component({
  selector: 'app-approval-request-grid-form',
  templateUrl: './approval-request-grid-form.component.html',
  styleUrls: ['./approval-request-grid-form.component.scss']
})
export class ApprovalRequestGridFormComponent implements OnInit {
  rowsToAdd: number | null = 5;
  formArray: FormArray = new FormArray([]);
  @Output() submitGridForm = new EventEmitter<FormArray>();

  headers: Array<{ key: keyof DiariaManualApprovalRequest, label: string }> = [
    { key: 'numeroViagem', label: 'Numero da Viagem' },
    { key: 'placa', label: 'Placa' },
    { key: 'areaResponsavel', label: 'Area Responsável' },
    { key: 'motivo', label: 'Motivo' },
    { key: 'subMotivo', label: 'Submotivo' },
    { key: 'clienteResponsavel', label: 'Cliente Responsável' },
    { key: 'justificativa', label: 'Justificativa' },
  ];

  constructor() {
  }

  ngOnInit(): void {
    this.addNewFormGroup(2);
  }

  get formArrayControlGroups(): FormGroup[] {
    return this.formArray.controls as FormGroup[];
  }

  handleRowsToAddChange(): void {
    if (!this.rowsToAdd || typeof this.rowsToAdd !== 'number' || this.rowsToAdd < 1) {
      this.rowsToAdd = 1;
    }
  }

  addNewFormGroup(count: number = 1): void {
    const array = new Array(count < 1 ? 1 : count);
    const formGroups = array.fill(1).map(() => createManualApprovalFormGroup({ withTravelNumberAndPlate: true }));
    formGroups.forEach(formGroup => this.formArray.push(formGroup));
  }

  replicateColumnValue(startRowIndex: number, params: { formControlName: string, value: string }): void {
    const formGroups = this.formArrayControlGroups.slice(startRowIndex + 1);
    formGroups.forEach(formGroup => formGroup.get(params.formControlName).setValue(params.value));
  }

  setColumnValues(startRowIndex: number, params: { formControlName: string, values: string[] }): void {
    const existingRowsCount = this.formArray.length - startRowIndex;
    const missingRowsCount = params.values.length - existingRowsCount;
    if (missingRowsCount > 0) {
      this.addNewFormGroup(missingRowsCount);
    }
    params.values.forEach((value, index) => {
      const formGroup = this.formArrayControlGroups[startRowIndex + index];
      formGroup?.get(params.formControlName).setValue(value);
    });
  }
}
