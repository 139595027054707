import { Component, Input, OnInit } from '@angular/core'; 
import { ModalEnum, ModalService, TModal } from '../../modal.service';

@Component({
  selector: 'app-modal-devolucao',
  templateUrl: './modal-devolucao.component.html',
  styleUrls: ['./modal-devolucao.component.scss']
})
export class ModalDevolucaoComponent implements OnInit {

  @Input() modalId: string;

  @Input() devolucoes: any[];

  @Input() devolucaoIndex = 0;

  public devolucaoView: any;

  constructor(private modalService: ModalService) { }

  public ngOnInit(): void {
    if (!this.devolucoes) { 
      return;
    }
  }

  public onModalOpen(): void {
    if (this.devolucoes.length <= 1) {
      return;
    }

    const modal: TModal = {
      name: ModalEnum.WINDOW_DEVOLUCAO,
      data: { devolucoes: this.devolucoes }
    };

    this.modalService.setModal(modal);
  }

  public retornarTipoDevolucao(devolucao): string{
    if(devolucao.tratativas && devolucao.tratativas.length > 0){
      if(devolucao.tratativas[devolucao.tratativas.length -1].acaoMotorista == 2)
      {
        return "(R)"
      }
    }

    if(devolucao.tipo == 2){
      return "(DT)"
    }

    if(devolucao.tipo == 3){
      return "(DP)"
    }
    return "";
  }
}
