import { Component, Input, OnInit } from '@angular/core';
import { Shipping } from 'src/app/models/shipping.model';
import { ModalEnum, ModalService, TModal } from '../../modal.service';

@Component({
  selector: 'app-modal-window-shippings',
  templateUrl: './shippings.component.html',
  styleUrls: ['./shippings.component.scss']
})
export class ModalWindowShippingsComponent implements OnInit {

  @Input() modalId: string;

  @Input() shippings: Shipping[];

  @Input() shippingIndex = 0;


  constructor(private modalService: ModalService) { }

  public ngOnInit(): void {
    if (!this.shippings) {
      console.error('Não foram enviados remessas');
      return;
    }

  }

  public onModalOpen(): void {
    if (this.shippings.length <= 1) {
      return;
    }

    const modal: TModal = {
      name: ModalEnum.WINDOW_SHIPPINGS,
      data: { shippings: this.shippings }
    };

    this.modalService.setModal(modal);
  }
}
