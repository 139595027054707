<div [class.green-snackbar]="type == 'success'" [class.red-snackbar]="type == 'error'"
    [class.yellow-snackbar]="type == 'caution'" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="flex-start center">
    <svg fxFlex="20px" *ngIf="type != 'error' && !icon && !hideIcon" width="16" height="16" viewBox="0 0 16 16"
        fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.33348 11.3322C7.25795 11.332 7.18468 11.3064 7.12548 11.2595L3.79215 8.59284C3.72849 8.53597 3.68902 8.45688 3.68184 8.37181C3.67467 8.28675 3.70035 8.20216 3.75359 8.13544C3.80684 8.06871 3.88361 8.0249 3.96815 8.01301C4.05268 8.00112 4.13856 8.02206 4.20815 8.07151L7.27082 10.5215L11.7321 4.46751C11.7582 4.43227 11.7908 4.4025 11.8283 4.37989C11.8659 4.35729 11.9074 4.34229 11.9507 4.33576C11.9941 4.32923 12.0382 4.33129 12.0807 4.34183C12.1232 4.35237 12.1632 4.37117 12.1985 4.39717C12.2337 4.42318 12.2635 4.45586 12.2861 4.49337C12.3087 4.53088 12.3237 4.57247 12.3302 4.61577C12.3368 4.65908 12.3347 4.70324 12.3242 4.74575C12.3136 4.78826 12.2948 4.82827 12.2688 4.86351L7.60215 11.1968C7.57115 11.2389 7.53066 11.2731 7.48399 11.2966C7.43731 11.3201 7.38575 11.3323 7.33348 11.3322Z"
            fill="white" />
        <path
            d="M8 15.999C3.58867 15.999 0 12.4104 0 7.99902C0 3.58769 3.58867 -0.000976562 8 -0.000976562C12.4113 -0.000976562 16 3.58769 16 7.99902C16 12.4104 12.4113 15.999 8 15.999ZM8 0.66569C3.95667 0.66569 0.666667 3.95569 0.666667 7.99902C0.666667 12.0424 3.95667 15.3324 8 15.3324C12.0433 15.3324 15.3333 12.0424 15.3333 7.99902C15.3333 3.95569 12.0433 0.66569 8 0.66569Z"
            fill="white" />
    </svg>
    <svg fxFlex="20px" *ngIf="type == 'error' && !icon && !hideIcon" width="16" height="16" viewBox="0 0 16 16"
        fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M8.87105 1.31552C8.38961 0.449839 7.61059 0.450898 7.12974 1.31552L0.204546 13.7678C-0.276894 14.6335 0.120689 15.3333 1.09126 15.3333H14.9095C15.8771 15.3333 16.2771 14.6324 15.7962 13.7678L8.87105 1.31552ZM8 10.6667C7.55092 10.6667 7.17605 10.324 7.13577 9.87675L6.78623 5.99462C6.72201 5.2813 7.2838 4.66666 8 4.66666C8.7162 4.66666 9.27799 5.2813 9.21377 5.99462L8.86423 9.87675C8.82395 10.324 8.44908 10.6667 8 10.6667ZM6.66667 12.6667C6.66667 13.4045 7.25641 14 8 14C8.74359 14 9.33333 13.4045 9.33333 12.6667C9.33333 11.9288 8.74359 11.3333 8 11.3333C7.25641 11.3333 6.66667 11.9288 6.66667 12.6667Z"
            fill="white" />
    </svg>
    <img fxFlex="20px" width="16" *ngIf="icon" height="16" [src]="'../../../../assets/icons/' + icon" />
    <span *ngIf="!isHtml" fxFlex style="flex-wrap: wrap; overflow-wrap:break-word;">
        {{ message }}
    </span>
    <div *ngIf="isHtml" fxFlex style="flex-wrap: wrap; overflow-wrap:break-word;" [innerHTML]="message"></div>
    <button fxFlex="130px" *ngIf="button" class="button-snack" mat-stroked-button
        (click)="goTo(button.navigateUrl)">{{button.nome}}</button>
    <div (click)="close()" fxFlex="20px" fxLayout="row" fxLayoutAlign="flex-end">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.6669 15.9992C15.623 15.9993 15.5797 15.9907 15.5392 15.974C15.4987 15.9573 15.4619 15.9328 15.4309 15.9019L8.00019 8.47054L0.569519 15.9019C0.538571 15.9329 0.501821 15.9575 0.461369 15.9743C0.420916 15.991 0.377553 15.9997 0.333755 15.9997C0.289956 15.9998 0.246581 15.9912 0.206104 15.9744C0.165628 15.9577 0.128844 15.9332 0.0978521 15.9022C0.0668602 15.8713 0.0422674 15.8345 0.0254779 15.7941C0.0086884 15.7536 3.10362e-05 15.7102 8.32946e-08 15.6664C-3.08696e-05 15.6226 0.0085652 15.5793 0.0252975 15.5388C0.0420298 15.4983 0.0665706 15.4615 0.0975188 15.4305L7.52819 7.99988L0.0981854 0.568542C0.0355944 0.50604 0.000395929 0.421233 0.000333417 0.332778C0.000270905 0.244323 0.0353494 0.159467 0.0978521 0.0968756C0.160355 0.0342845 0.245162 -0.000913967 0.333616 -0.000976479C0.422071 -0.00103899 0.506928 0.0340395 0.569519 0.0965422L8.00019 7.52788L15.4315 0.0972089C15.4625 0.0662169 15.4992 0.0416241 15.5397 0.0248346C15.5801 0.00804516 15.6235 -0.000612204 15.6673 -0.000643158C15.7111 -0.000674112 15.7545 0.00792195 15.7949 0.0246542C15.8354 0.0413865 15.8722 0.0659274 15.9032 0.0968755C15.9342 0.127824 15.9588 0.164573 15.9756 0.205026C15.9924 0.245478 16.001 0.288842 16.001 0.33264C16.0011 0.376438 15.9925 0.419814 15.9757 0.46029C15.959 0.500766 15.9345 0.53755 15.9035 0.568542L8.47152 7.99921L15.9022 15.4299C15.9489 15.4765 15.9807 15.5358 15.9936 15.6005C16.0066 15.6652 16 15.7323 15.9748 15.7932C15.9496 15.8542 15.9069 15.9063 15.8521 15.9429C15.7973 15.9796 15.7328 15.9992 15.6669 15.9992Z"
                fill="white" />
        </svg>
    </div>
</div>