import { Shipping } from "../../shipping.model";

export class StepDetailPrimaryInTransitUnloading {
    #planningArrivalAt: Date;

    #forecastArrivalAt: Date;

    #kilemeterForecastArrival: number;

    #cetAt: Date;

    #cetNumber: number;

    #gradeUnloadingAt: Date;

    #shippings: Shipping[];

    #finishedAt: Date;

    #scheduledAt: Date;

    #password: string;

    #coordenateLat: number;

    #coordenateLng: number;

    #description: string;

    #dataHoraApontamentoSistema: Date;
    #motivoFinalizacao: string;
    #usuario: string;
    #status: string;


    constructor({
        dataHoraPlanejadaChegada,
        dataHoraPrevistoChegadaEta,
        kmPrevistoChegada,
        dataHoraCet,
        numeroCet,
        gradeDescarga,
        remessas,
        dataHoraFinalizacao,
        dataHoraAgenda,
        senha,
        coordenadaVeiculoLatitude,
        coordenadaVeiculoLongitude,
        descricaoProximaEtapa,
        dataHoraApontamentoSistema,
        motivoFinalizacao,
        usuario,
        status
    }) {
        this.#planningArrivalAt = !dataHoraPlanejadaChegada
            ? null 
            : new Date(dataHoraPlanejadaChegada);
        this.#forecastArrivalAt = !dataHoraPrevistoChegadaEta
            ? null 
            : new Date(dataHoraPrevistoChegadaEta);
        this.#kilemeterForecastArrival = kmPrevistoChegada;
        this.#cetAt = dataHoraCet;
        this.#cetNumber = numeroCet;
        this.#gradeUnloadingAt = !gradeDescarga
            ? null
            : new Date(gradeDescarga);
        this.#shippings = !remessas ? null : Shipping.from(remessas);
        this.#finishedAt = dataHoraFinalizacao;
        this.#scheduledAt = dataHoraAgenda;
        this.#password = senha;
        this.#coordenateLat = coordenadaVeiculoLatitude;
        this.#coordenateLng = coordenadaVeiculoLongitude;
        this.#description = descricaoProximaEtapa;

        this.#dataHoraApontamentoSistema = dataHoraApontamentoSistema;
        this.#motivoFinalizacao = motivoFinalizacao;
        this.#usuario = usuario;
        this.#status = status;
    }

    get planningArrivalAt(): Date {
        return this.#planningArrivalAt;
    }

    get forecastArrivalAt(): Date {
        return this.#forecastArrivalAt;
    }

    get kilemeterForecastArrival(): number {
        return this.#kilemeterForecastArrival;
    }

    get cetAt(): Date {
        return this.#cetAt;
    }

    get cetNumber(): number {
        return this.#cetNumber;
    }

    get gradeUnloadingAt(): Date {
        return this.#gradeUnloadingAt;
    }

    get shippings(): Shipping[] {
        return this.#shippings;
    }

    get finishedAt(): Date {
        return this.#finishedAt;
    }

    get scheduledAt(): Date {
        return this.#scheduledAt;
    }

    get password(): string {
        return this.#password;
    }

    get coordenateLat(): number {
        return this.#coordenateLat;
    }

    get coordenateLng(): number {
        return this.#coordenateLng;
    }

    get description(): string {
        return this.#description;
    }

    get dataHoraApontamentoSistema(): Date {
        return this.#dataHoraApontamentoSistema;
    }

    get motivoFinalizacao(): string {
        return this.#motivoFinalizacao;
    }

    get usuario(): string {
        return this.#usuario;
    }

    get status(): string {
        return this.#status;
    }
}
