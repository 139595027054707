import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-panel-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class PanelHeaderComponent implements OnInit {

  @Output() public redirectUrl = new EventEmitter<boolean>();
  
  @Input() public title: string;
  
  @Input() public height = '72px';

  @Input() public link: string;

  @Input() public isHovered: boolean = true;

  @Input() public isClickable: boolean = true;

  @Input() public isDot: boolean = false;

  public hoverStyled = '';

  public clickStyled = '';

  public dotStyled = '';

  constructor(private router: Router) { }

  ngOnInit(): void {
    if (this.isHovered) {
      this.hoverStyled = 'panel-header--is-hovered';
    }

    if (this.isClickable) {
      this.clickStyled = 'panel-header--is-clickable';
    }
    
    if (this.isDot) {
      this.dotStyled = 'panel-header__title--is-dot';
    }
  }

  onRedirectionUrl() {
    if (this.link && this.isClickable) {
      this.router.navigateByUrl(this.link);
      this.redirectUrl.emit(true);
    }
  }
}
