
<div class="modal-window-orders">
    <span *ngIf="!modalId && orders[0]" (click)="onModalOpen()" class="modal-window-orders__visualization">
        <span class="modal-window-orders__visualization">
            {{orders[0]}}
        </span>

        <strong *ngIf="orders && orders.length > 1" class="modal-window-orders__link">+{{ orders.length - 1 }}</strong>
    </span>

    <div *ngIf="modalId && orders">
        <app-modal-window [modalId]="modalId" title="Pedidos">
            <div class="modal-window-orders__content">
                <span 
                    *ngFor="let order of orders" 
                    class="modal-window-orders__text"
                >
                    {{order}}
                </span>
            </div>

            <span class="modal-window-orders__total">
                Total de pedidos: {{orders.length}}
            </span>
        </app-modal-window>
    </div>
</div>
