import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GmCircleComponent } from './gm-circle.component';

@NgModule({
    declarations: [GmCircleComponent],
    exports: [GmCircleComponent],
    imports: [
        CommonModule,
    ],    
}) export class GmCircleModule { }
