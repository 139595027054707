import { NgModule } from '@angular/core';
import { CommonModule} from '@angular/common';
import { KilogramFormatPipe } from './kilogram.pipe';


@NgModule({
  declarations: [KilogramFormatPipe],
  imports: [CommonModule],
  exports: [KilogramFormatPipe]
})
export class KilogramFormatModule { }
