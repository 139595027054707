import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SessionStorageService {

    public getItem<T>(key: string): T {
        try {
            const value = sessionStorage.getItem(key);
            return JSON.parse(value) as T;
        } catch (e) {
            console.error(`[SessionStorageService.getItem] Não possível carregar a chave ${key}`, e);
            return null;
        }
    }

    public setItem<T>(key: string, value: T): void {
        try {
            sessionStorage.setItem(key, JSON.stringify(value));
        } catch (e) {
            console.error('[SessionStorageService.setItem] Não possível setar um novo item', e);
        }
    }

    public removeItem<T>(key: string): void {
        sessionStorage.removeItem(key);
    }

    public clearAll(): void {
        sessionStorage.clear();
    }
}
