import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TOption } from 'src/app/components/select/select.component';
import { TypesSubzoneOption } from 'src/app/core/models/subzone.model';
import { FilterService } from 'src/app/core/services/filter.service';
import { UserFilterScreenMonitoring } from 'src/app/models/user-filter/screen/monitoring.model';
import { UserFilter } from 'src/app/models/user-filter/user-filter.model';
import { ModalEnum, ModalService, TModal } from '../../../modal.service';
import { MonitoringFilter } from '../monitoring-filter';
import { ModalWindowMonitoringFilterService, TCurrentFilter } from '../monitoring-filter.service';

@Component({
  selector: 'app-modal-window-filter-subzones-primary',
  templateUrl: './modal-filter-subzones-primary.component.html',
  styleUrls: ['./modal-filter-subzones-primary.component.scss']
})
export class ModalFilterSubzonesPrimaryComponent extends MonitoringFilter implements OnInit {

  @Input() public modalId: string;

  @Input() public myFiltersModalId: string;

  @Input() public savedFilterId: string;

  @Input() public isFirstFilter = false;

  @Output() filterCountChange = new EventEmitter<number>();

  public filterForm: FormGroup = null;

  public uuidModalMonitoringSaveFilter: string;

  public currentFilter: TCurrentFilter;

  public savedFilter: UserFilter<UserFilterScreenMonitoring>;

  public savedFilters$ = this.modalWindowMonitoringFilterService.savedFilters$;

  public filterCount = 0;

  public tipoParadaOptions: TOption[];

  public tipoSaidaOptions: TOption[];

  constructor(
    private formBuilder: FormBuilder,
    private cdref: ChangeDetectorRef,
    private modalService: ModalService,
    private filterService: FilterService,
    modalWindowMonitoringFilterService: ModalWindowMonitoringFilterService,
  ) {
    super(modalWindowMonitoringFilterService);
  }

  ngOnInit(): void {
    const filter: UserFilterScreenMonitoring = this.getFilter(this.savedFilterId);

    this.filterForm = this.formBuilder.group({
      categoria: new FormControl(filter?.category || ''),
      nome: new FormControl(filter?.name || ''),
      tipoUnidade: new FormControl(''),
      codigo: new FormControl(filter?.code || ''),
      tipoSaida: new FormControl(''),
    });

    this.getOptions(filter);

    const count = Object.values(this.filterForm.getRawValue()).
      filter((value: any) => {
        const isArray = Array.isArray(value);
        if (!isArray && !!value) {
          return true;
        }
        if (isArray && value.filter(item => !!item).length) {
          return true;
        }
      }).length;
    this.filterCount = count;
    this.filterCountChange.emit(this.filterCount);
    this.filterForm.valueChanges.subscribe(values => {
      if (values) {
        const count = Object.keys(this.filterForm.value)
          .filter(key => {
            const isArray = Array.isArray(this.filterForm.value[key]);
            if (!isArray && !!this.filterForm.value[key]) {
              return true;
            }
            if (isArray && this.filterForm.value[key].filter(item => !!item).length) {
              return true;
            }
          }).length;
        this.filterCount = count;
        this.filterCountChange.emit(count);
        this.cdref.detectChanges();
      }
    });
    this.getOptions(filter);
    this.cdref.detectChanges();
  }

  getOptions(filter: UserFilterScreenMonitoring) {
    this.filterService.getSelectBoxOptions(['tipoparada'], 'primaria').subscribe(data => {
      this.tipoParadaOptions = data.tipoparada.map(item => {
        return {
          key: item.id,
          display: item.label,
          value: item.label,
          checked: !!filter ? filter.unitType.findIndex(value => value === item.label) >= 0 : false
        }
      });
      this.filterForm.get('tipoUnidade').setValue(this.tipoParadaOptions.filter(item => item.checked));

      this.tipoSaidaOptions = TypesSubzoneOption.getExitType().map(item => {
        return { 
          ...item, 
          checked: !!filter ? filter.exitType.findIndex(value => value === item.key) >= 0 : false }
      });
      this.filterForm.get('tipoSaida').setValue(this.tipoSaidaOptions.filter(item => item.checked));
    })
  }

  public onCreateFilter() {
    const modal: TModal = {
      name: ModalEnum.WINDOW_MONITORING_FILTER_SAVE,
      data: {
        uuidModalMonitoringFilter: this.modalId,
        filtersResult: this.getFakeUserFilter(
          'monitoramento-primaria-subzones',
          this.getParameters()
        ).parameters.getSubzoneFilterPrimary(),
        logistic: 'primaria-subzones'
      }
    };
    this.uuidModalMonitoringSaveFilter = this.modalService.setModal(modal);
  }

  public onCancel = () => this.modalService.unsetModal(this.modalId);

  public onSubmit(): void {
    const currentFilter: TCurrentFilter = {
      mode: 'filtered',
      filter: this.getFakeUserFilter('monitoramento-primaria-subzones', this.getParameters()),
    };

    this.modalWindowMonitoringFilterService.setCurrentFilter(currentFilter);
    this.modalService.unsetModal(this.modalId);
  }

  public onSaveFilter() {
    this.savedFilter.parameters = this.getParameters(false);
    this.savedFilter.default = true;

    const currentFilter: TCurrentFilter = {
      mode: 'saved',
      filter: this.savedFilter
    };

    const defaultSavedFilter = this.modalWindowMonitoringFilterService.getDefaultSavedFilter();

    if (defaultSavedFilter && defaultSavedFilter.id !== this.savedFilter.id) {
      defaultSavedFilter.default = false;
      this.modalWindowMonitoringFilterService.updateSavedFilter(defaultSavedFilter);
    }

    this.modalWindowMonitoringFilterService.updateSavedFilter(this.savedFilter, true, 'primaria-subzones');
    this.modalWindowMonitoringFilterService.setCurrentFilter(currentFilter, 'primaria-subzones');

    this.modalService.unsetModal(this.modalId);
    this.modalService.unsetModal(this.myFiltersModalId);
  }

  public onClearFilter(): void {
    this.filterForm.reset();
    this.cdref.detectChanges();
  }

  private getParameters(isStringify = true): any {
    var params: any = {};
    Object.keys(this.filterForm.getRawValue()).map((property) => {
      if (Array.isArray(this.filterForm.getRawValue()[property]) && property != 'codigos') {
        if (property == 'tipoSaida') {
          params[property] = this.filterForm.getRawValue()[property].map((typeUnit: TOption) => typeUnit.key);
          return;
        }
        if (property == 'tipoUnidade') {
          params[property] = this.filterForm.getRawValue()[property].map((typeUnit: TOption) => typeUnit.value);
          return;
        }
        params[property] = this.filterForm.getRawValue()[property];
      } else {
        params[property] = this.filterForm.getRawValue()[property]
      }
    });
    return isStringify ? JSON.stringify(params) : new UserFilterScreenMonitoring(params);
  }


}
