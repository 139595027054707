import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DiariaTreatment } from 'src/app/models/diarias/diaria-detail.interface';

@Component({
  selector: 'app-diaria-treatment',
  templateUrl: './diaria-treatment.component.html',
  styleUrls: ['./diaria-treatment.component.scss']
})
export class DiariaTreatmentComponent implements OnInit {

  @Input() treatment: DiariaTreatment;
  @Input() showAnalyzeButton = false;
  @Output() analyzeButtonClick: EventEmitter<void> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  handleAnalyzeButtonClick(): void {
    this.analyzeButtonClick.emit();
  }
}
