import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'gm-map',
  templateUrl: './gm-map.component.html',
})
export class GmMapComponent implements OnChanges {

  @Input() latitude: number;

  @Input() longitude: number;

  @Input() zoom: number;

  @Input() fullscreenControl: boolean = true;

  @Input() enableDrawing: boolean = false;

  @Input() width: string = '100%';

  @Input() height: string = '300px';

  @Output() onMapReady = new EventEmitter<google.maps.Map>();

  @Output() onCircleComplete = new EventEmitter<google.maps.Circle>();

  @Output() onPolygonComplete = new EventEmitter<google.maps.Polygon>();

  @Output() onChangeZoom = new EventEmitter<number>();

  public map: google.maps.Map = null;

  private drawingManager: google.maps.drawing.DrawingManager;

  private listeners: google.maps.MapsEventListener[] = [];

  constructor() { }

  mapReady(event: google.maps.Map) {
    this.map = event;
    const mapOptions: google.maps.MapOptions = {
      center: new google.maps.LatLng(this.latitude, this.longitude),
      zoom: this.zoom,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      fullscreenControl: this.fullscreenControl,
      mapTypeControl: true
    }
    this.map.setOptions(mapOptions);
    if (this.enableDrawing) {
      this.drawing();
    }
    this.listeners.push(google.maps.event.addListener(this.map, 'zoom_changed', () => {      
      this.onChangeZoom.emit(this.map.getZoom());
    }))
    this.onMapReady.emit(this.map);
  }

  ngOnChanges(changes: SimpleChanges): void {
    try {
      if (changes && this.map) {
        if (
          changes.latitude?.currentValue !== changes.latitude?.previousValue ||
          changes.longitude?.currentValue !== changes.longitude?.previousValue ||
          changes.zoom?.currentValue !== changes.zoom?.previousValue
        ) {
          this.map.setOptions({
            center: new google.maps.LatLng(this.latitude, this.longitude),
            zoom: this.zoom,
            mapTypeControl: true,
            scaleControl: true,
          });
        }
        if (changes.enableDrawing?.currentValue !== changes.enableDrawing?.previousValue) {
          if (this.enableDrawing) {
            this.drawing();
          } else {
            this.drawingManager.setOptions({
              drawingControl: false,
              drawingMode: null
            });
          }
        }
      }
    } catch (e) {
      console.error(['Error to update map', e]);
    }
  }

  private drawing() {
    const circleOptions: google.maps.CircleOptions = {
      draggable: true,
      editable: true,
      clickable: true,
      fillColor: '#5f5d9c',
      fillOpacity: 0.35,
      strokeColor: '#5f5d9c',
      strokeOpacity: 0.8,
      strokeWeight: 2
    };
    const polygonOptions: google.maps.PolygonOptions = {
      draggable: true,
      editable: true,
      clickable: true,
      fillColor: '#5f5d9c',
      fillOpacity: 0.35,
      strokeColor: '#5f5d9c',
      strokeOpacity: 0.8,
      strokeWeight: 2
    }
    this.drawingManager = new google.maps.drawing.DrawingManager({
      drawingControl: true,
      drawingControlOptions: {
        drawingModes: [
          google.maps.drawing.OverlayType.CIRCLE,
          google.maps.drawing.OverlayType.POLYGON,
        ],
      },
      circleOptions,
      polygonOptions
    });
    this.drawingManager.setMap(this.map);
    this.listeners.push(google.maps.event.addListener(
      this.drawingManager,
      'overlaycomplete',
      (event: any) => {
        if (event.type === google.maps.drawing.OverlayType.POLYGON) this.onPolygonComplete.emit(event.overlay);
        if (event.type === google.maps.drawing.OverlayType.CIRCLE) this.onCircleComplete.emit(event.overlay);
      }
    ));
  }

  ngOnDestroy(): void {
    this.listeners.map((listener) => listener.remove());
  }

}
