import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalEnum, ModalService } from '../../../modal.service';
import { ModalWindowMonitoringFilterService, TCurrentFilter } from '../monitoring-filter.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserFilter } from 'src/app/models/user-filter/user-filter.model';
import { UserFilterScreenMonitoring } from 'src/app/models/user-filter/screen/monitoring.model';
import { PerfilPermissaoService } from 'src/app/core/services/perfil-permissao.service';
import { LogisticTypeEnum } from 'src/app/core/models/enums/logistic-type.enum';

@Component({
  selector: 'app-modal-window-monitoring-filter-my-filters',
  templateUrl: './my-filters.component.html',
  styleUrls: ['./my-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalWindowMonitoringFilterMyFiltersComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() modalId: string;  

  @Input() logistic: string;

  public myFilters: UserFilter<UserFilterScreenMonitoring>[] = [];

  public selectedFilter: UserFilter<UserFilterScreenMonitoring>;

  private savedFilters$ = this.modalWindowMonitoringFilterService.savedFilters$;

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private modalService: ModalService,
    private modalWindowMonitoringFilterService: ModalWindowMonitoringFilterService,
    private cdref: ChangeDetectorRef,
    private perfilPermissaoService: PerfilPermissaoService
  ) { }

  public ngOnInit(): void {
    this.logistic = this.logistic || this.perfilPermissaoService.getCurrentLogisticaRole()?.logistica;
  }

  public ngAfterViewInit(): void {
    this.myFilters = this.modalWindowMonitoringFilterService.getSavedFilters();

    if (this.myFilters) {
      this.selectedFilter = this.myFilters.find(myFilter => myFilter.default);
      this.cdref.detectChanges();
    }

    this.savedFilters$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(myFilters => {
        this.myFilters = myFilters;
        this.selectedFilter = myFilters.find(myFilter => myFilter.default);
        this.cdref.detectChanges();
      });
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public handleEdit(filter: UserFilter<UserFilterScreenMonitoring>): void {
    const modal = {
      name: ModalEnum.WINDOW_MONITORING_FILTER,
      data: {
        savedFilterId: filter.id,
        logistic: this.logistic,
        myFiltersModalId: this.modalId
      }
    };

    this.modalService.setModal(modal);
  }

  public handleDelete(filter: UserFilter<UserFilterScreenMonitoring>): void {
    const modal = {
      name: ModalEnum.WINDOW_MONITORING_FILTER_REMOVE,
      data: {
        id: filter.id,
        logistic: this.logistic
      }
    };

    this.modalService.setModal(modal);
  }

  public handleApplyFilter(): void {
    var currentFilter = this.myFilters.find(myFilter => myFilter.default);
    if (this.myFilters.length === 1) {
      currentFilter = this.myFilters[0];
    }

    currentFilter.default = false;

    this.selectedFilter.default = true;

    const newCurrentFilter = {
      mode: 'saved',
      filter: this.selectedFilter
    };
    
    const logistic: string = this.logistic ? this.logistic : LogisticTypeEnum.AGRONOMY;

    this.modalWindowMonitoringFilterService.setCurrentFilter(newCurrentFilter, logistic);

    this.modalWindowMonitoringFilterService.updateSavedFilter(currentFilter, true, logistic);

    this.modalWindowMonitoringFilterService.updateSavedFilter(this.selectedFilter, true, logistic);

    this.modalService.unsetModal(this.modalId);
  }

  public handleChangeMyFilter(selectedFilter: UserFilter<UserFilterScreenMonitoring>): void {
    this.selectedFilter = selectedFilter;
    this.cdref.detectChanges();
  }
}
