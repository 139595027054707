import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';

@Component({
  selector: 'gm-polyline',
  template: ''
})
export class GmPolylineComponent implements OnChanges, OnDestroy {

  @Input() map: google.maps.Map;

  @Input() path: { latitude: number, longitude: number }[] = [];

  @Input() strokeColor: string = '#03a9f4';

  @Input() strokeWeight: number = 4;

  @Input() showArrowDirection: boolean = false;

  @Input() strokeColorArrow: string = '#478d32';

  @Input() visible: boolean = true;

  polyline: google.maps.Polyline;

  ngOnChanges(changes: SimpleChanges): void {
    try {
      if (!changes) return;
      if (!this.map) {
        console.error("You need pass 'map' to render 'Polyline'");
        return;
      };
      const lineSymbol: google.maps.Symbol = {
        path: google.maps.SymbolPath.FORWARD_OPEN_ARROW,
        strokeColor: this.strokeColorArrow,
        scale: 2.5,
        strokeWeight: 2
      };      
      const path: any = this.path.map((latLng) => new google.maps.LatLng(latLng.latitude, latLng.longitude));             
      const polylineOptions: google.maps.PolylineOptions = {
        path,
        strokeColor: this.strokeColor,
        strokeWeight: this.strokeWeight,        
        visible: this.visible,
        icons: this.showArrowDirection ? [
          {
            icon: lineSymbol,
            repeat: '200px',
            offset: '100%',
          }
        ] : null
      }
      if (!changes.visible?.isFirstChange()) {
        this.polyline.setOptions(polylineOptions);
      } else {        
        this.polyline = new google.maps.Polyline(polylineOptions);        
      }
      this.polyline.setMap(this.map);      
    } catch (e) {
      console.error(["Error to render google maps polyline", e]);
    }
  }

  ngOnDestroy(): void {
    if (this.polyline) {
      this.polyline.setMap(null);
    }
  }
}
