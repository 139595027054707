export class StepDetailDistributionFinishTrackingFinished {
    #finishedManually: boolean;

    #finishedDate: Date;

    #noteSystemDate: Date;

    #responbibleUser: string;

    #reason: string;

    constructor({
        finalizadoManualmente,
        dataFinalizacao,
        dataApontamentoSistema,
        responsavel,
        motivo
    }) {
        this.#finishedManually = finalizadoManualmente;
        this.#finishedDate = !dataFinalizacao ? null : new Date(dataFinalizacao);
        this.#noteSystemDate = !dataApontamentoSistema ? null : new Date(dataApontamentoSistema);
        this.#responbibleUser = responsavel;
        this.#reason = motivo;
    }

    get finishedManually(): boolean {
        return this.#finishedManually;
    }

    get finishedDate(): Date {
        return this.#finishedDate;
    }

    get noteSystemDate(): Date {
        return this.#noteSystemDate;
    }

    get responbibleUser(): string {
        return this.#responbibleUser;
    }

    get reason(): string {
        return this.#reason;
    }
}
