<app-modal-window [modalId]="modalId" titleModal="Meus filtros" subTitle="Selecione um dos filtros abaixo">
    <div class="my-filters">
        <span class="my-filters__item" *ngFor="let filter of myFilters">
            <span [title]="filter.name" class="my-filters__item__selection" (click)="handleChangeMyFilter(filter)">
                <input type="radio" name="filter" [checked]="filter.id === selectedFilter?.id" />
                {{filter.name}}
            </span>

            <span class="my-filters__item__actions">
                <app-icon (click)="handleEdit(filter)" matTooltip="Editar" icon="pencil-edit" [width]="24" [height]="24"
                    style="height:16px; cursor: pointer;"></app-icon>
                
                <app-icon *ngIf="myFilters.length > 1" style="cursor: pointer;" (click)="handleDelete(filter)"
                    matTooltip="Excluir" icon="trash" [width]="24" [height]="24" style="height:16px; cursor: pointer;">
                </app-icon>
            </span>
        </span>

        <div class="my-filters__actions">
            <app-button text="Aplicar filtro" (clicked)="handleApplyFilter()"></app-button>
        </div>
    </div>
</app-modal-window>