import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';

@Component({
    selector: 'gm-polygon',
    template: ''
})
export class GmPolygonComponent implements OnChanges, OnDestroy {

    @Input() map: google.maps.Map;

    @Input() paths: google.maps.LatLng[] = [];

    @Input() strokeWeight: number = 2;

    @Input() strokeOpacity = 0.8;

    @Input() strokeColor = '#5f5d9c';

    @Input() fillColor: string = '#5f5d9c';

    @Input() fillOpacity: number = 0.35;

    @Input() draggable: boolean = false;

    @Input() editable: boolean = false;

    @Input() clickable: boolean = false;

    polygon: google.maps.Polygon;

    @Output() onPolygonComplete = new EventEmitter<google.maps.Polygon>();

    ngOnChanges(changes: SimpleChanges): void {
        try {
            if (!changes) return;
            if (!this.map) {
                console.error("You need pass 'map' to render 'polygon'");
                return;
            };            
            if (!this.paths) return;
            if (this.paths.length < 0) return;
            this.polygon = new google.maps.Polygon({
                paths: this.paths,
                strokeColor: this.strokeColor,
                strokeWeight: this.strokeWeight,
                strokeOpacity: this.strokeOpacity,
                fillColor: this.fillColor,
                fillOpacity: this.fillOpacity,
                draggable: this.draggable,
                editable: this.editable,
                clickable: this.clickable,
            });
            this.polygon.setMap(this.map);
            this.onPolygonComplete.emit(this.polygon);
        } catch (e) {
            console.error(["Error to render google maps polygon", e]);
        }
    }

    ngOnDestroy(): void {
        if (this.polygon) {
            this.polygon.setMap(null);
        }
    }
}