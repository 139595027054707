<ng-container [formGroup]="formGroup">
  <mat-form-field appearance="outline">
    <mat-label>Área Responsável</mat-label>
    <mat-select formControlName="areaResponsavel" [disabled]="isLoading">
      <mat-option *ngFor="let option of responsibleAreaOptions" [value]="option.value">
        {{option.label}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field
    appearance="outline"
    [floatLabel]="isAreaSelected ? 'auto' : 'always'"
    [hintLabel]="formGroup.get('motivo').enabled ? 'Escolha um motivo para a diária' : ''"
  >
    <mat-label>Motivo</mat-label>
    <mat-select
      formControlName="motivo"
      [disabled]="isLoading || !isAreaSelected"
      [placeholder]="isAreaSelected ? '' : 'Selecione uma área'"
      (openedChange)="$event ? reasonSearchInput.focus() : reasonFormControl.setValue('')"
    >
      <input
        matInput
        #reasonSearchInput
        class="select-search-input"
        [formControl]="reasonFormControl"
        placeholder="Digite para buscar..."
      />
      <mat-option *ngFor="let option of reasonsOptions | async" [value]="option.value">
        {{ option.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline" [floatLabel]="isReasonSelected ? 'auto' : 'always'">
    <mat-label>Sub motivo</mat-label>
    <mat-select
      formControlName="subMotivo"
      [disabled]="isLoading || !isReasonSelected"
      [placeholder]="isReasonSelected ? '' : 'Selecione um motivo'"
      (openedChange)="$event ? subReasonSearchInput.focus() : subReasonFormControl.setValue('')"
    >
      <input
        matInput
        #subReasonSearchInput
        class="select-search-input"
        [formControl]="subReasonFormControl"
        placeholder="Digite para buscar..."
      />
      <mat-option *ngFor="let option of subReasonsOptions | async" [value]="option.value">
        {{ option.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Cliente Responsável</mat-label>
    <mat-select
      formControlName="clienteResponsavel"
      [disabled]="isLoading"
      (openedChange)="$event ? responsibleClientSearchInput.focus() : responsibleClientFormControl.setValue('')"
    >
      <input
        matInput
        #responsibleClientSearchInput
        class="select-search-input"
        [formControl]="responsibleClientFormControl"
        placeholder="Digite para buscar..."
      />
      <mat-option *ngIf="!responsibleClientSearchInput.value" value="">Nenhum cliente responsável</mat-option>
      <mat-option *ngFor="let option of responsibleClientsOptions | async" [value]="option.value">
        {{ option.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline" floatLabel="always" style="grid-column: span 2">
    <mat-label>Justificativa</mat-label>
    <textarea
      matInput
      rows="5"
      name="justificativa"
      formControlName="justificativa"
      placeholder="Escreva uma justificativa para ser enviada para aprovação"
    ></textarea>
  </mat-form-field>
</ng-container>
