export class StepDetailDistributionClientCommercial {
    constructor(
        public sellerName: string,
        public sellerPhone: string,
        public supervisorName: string,
        public supervisorPhone: string,
        public managerName: string,
        public managerPhone: string
    ) { }
}
