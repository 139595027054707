import { HttpClient } from '@angular/common/http';
import { Injectable, } from '@angular/core';
import { Observable, } from 'rxjs';
import { map } from 'rxjs/operators';
import { TFilterResult } from '../components/modal/window/monitoring-filter/monitoring-filter.service';
import { MessageService } from '../core/services';
import { BaseService } from '../core/services/base.service';
import { PerfilPermissaoService } from '../core/services/perfil-permissao.service';
import { UserFilterScreenMonitoring } from '../models/user-filter/screen/monitoring.model';
import { UserFilter } from '../models/user-filter/user-filter.model';

@Injectable({
  providedIn: 'root'
})
export class RouteService extends BaseService<any> {

  constructor(
    _http: HttpClient,
    messageService: MessageService,
    perfilPermissaoService: PerfilPermissaoService
  ) {
    super(_http, 'monitoramento', messageService, perfilPermissaoService);
  }

  /**
   * Busca viagens baseado no filtro
   *
   * @param filter TFilterResult
   * @returns any[]
   */
  public getMonitoringMonitor(logistic: string, filter: UserFilter<UserFilterScreenMonitoring>): Observable<any[]> {
    const serialized = { ...{ logistica: logistic }, ...filter.serialize().definicaoFiltro };
    return this.http
      .post(this.url, serialized)
      .pipe(map((response: any) => {
        try {
          return response;
        } catch (e: any) {
          console.error(
            '[RouteService.getMonitoring] Erro ao compilar os dados do endpoint de monitoramento',
            e.message,
            response
          );
          return null;
        }
      }));
  }

  /**
   * Busca viagens de uma transportadora
   *
   * @param filter TFilterResult
   * @returns any[]
   */
  public getMonitoringCarrier(filter: TFilterResult): Observable<any[]> {
    return this.http
      .post(`${this.url}/transportadora`, filter)
      .pipe(map((response: any) => {
        try {
          return response;
        } catch (e: any) {
          console.error(
            '[RouteService.getMonitoringCarrier] Erro ao compilar os dados do endpoint de monitoramento',
            e.message,
            response
          );
          return null;
        }
      }));
  }
}
