<div class="modal-save">
    <div class="modal-save__title">
        <div>
            <h1>{{ 'FILTER.SAVE_FILTER' | translate }}</h1>
            <p>{{ 'FILTER.CREATE_NAME_FOR_YOUR_FILTER' | translate }}</p>
        </div>

        <button class="close-button" (click)="close()">
            <mat-icon matSuffix>close</mat-icon>
        </button>
    </div>
    <div class="modal-save__content">
        <form [formGroup]="form">
            <mat-form-field appearance="outline" class="filter-table-primaria__content__row__col-6">
                <mat-label>{{ 'FILTER.NAME_FILTER' | translate }}</mat-label>
                <input matInput type="text" formControlName="nomeFiltro" placeholder="Nomeie o seu filtro" />
            </mat-form-field>
        </form>
    </div>
    <div class="modal-save__footer">

        <app-button (clicked)="close()" text="{{'FILTER.CANCEL' | translate}}" [isOutlined]="true"
            [isMarginRight]="true" [isMarginLeft]="true"></app-button>

        <app-button text="{{'FILTER.SAVE_FILTER' | translate}}" [isDisabled]="!haveValue"
            (clicked)="create()"></app-button>
    </div>
</div>