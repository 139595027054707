
<div class="modal-window-devolucao">
    <span *ngIf="!modalId && devolucoes[0]" (click)="onModalOpen()" class="modal-window-devolucao__visualization">
        <span class="modal-window-devolucao__visualization"> 
            {{devolucoes[0].dtCreated | datetimeformat:false}} - {{retornarTipoDevolucao(devolucoes[0])}}
        </span>

        <strong *ngIf="devolucoes && devolucoes.length > 1" class="modal-window-devolucao__link"> + {{devolucoes.length - 1}} </strong>
    </span>
    
    <div *ngIf="modalId && devolucoes">
        <app-modal-window [modalId]="modalId" title="Devoluções">
   
            <div class="modal-window-devolucao__content">
                <span 
                    *ngFor="let devolucao of devolucoes" 
                    class="modal-window-devolucao__text" > 
                    {{devolucao.dtCreated | datetimeformat:false}} - {{retornarTipoDevolucao(devolucao)}}
                </span>
            </div>

            <span class="modal-window-devolucao__total">
                Total de Devoluções: {{devolucoes.length}}   
            </span>
        </app-modal-window>
    </div>
</div>
