import { Coordinate } from "src/app/models/google-maps/coordinate.model";

export type TipoEquipamento = 'CAVALO' | 'CARRETA';

export class RastroViagem {   

    public latitude: number;

    public longitude: number;

    public latitudeRealizada?: number;

    public longitudeRealizada?: number;

    public parada?: any;

    public velocidade?: any;

    public temperatura?: any;

    public anomalia?: any;

    public dataHora?: Date;

    public tipoObj?: number;

    public radius?: number;

    public pointsPolygon?: Coordinate[];

    public estaParado?: boolean;

    public description?: string;

    public tipoEquipamento?: TipoEquipamento;

    public detalhes?: any;

    constructor({
        latitude,
        longitude,
        latitudeRealizada = null,
        longitudeRealizada = null,
        parada = null,
        anomalia = null,
        dataHora = null,
        tipoObj = 4,
        radius = 0,
        pointsPolygon = [],
        estaParado = false,
        descricao = null,
        tipoEquipamento = null
    }) {
        const coordinate = new Coordinate(latitude, longitude);
        const coordinateRealized = new Coordinate(latitudeRealizada, longitudeRealizada);
        this.latitude = coordinate.getLatitude()
        this.longitude = coordinate.getLongitude();
        this.latitudeRealizada = coordinateRealized.getLatitude();
        this.longitudeRealizada = coordinateRealized.getLongitude();
        this.parada = parada;
        this.anomalia = anomalia;
        this.dataHora = dataHora;
        this.tipoObj = tipoObj;
        this.radius = radius;
        this.pointsPolygon = pointsPolygon;
        this.estaParado = estaParado;
        this.description = descricao;
        this.tipoEquipamento = tipoEquipamento
    }

}
