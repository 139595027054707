
export const environment = {
  production: true,

  base_api: "https://apim-qas.brf.cloud/portal-d/api",
  base_signal_r_hub: "https://apim-qas.brf.cloud/hub-d/api",

  base_api_primary: "https://apim-qas.brf.cloud/portal-p/api",
  base_signal_r_hub_primary: "https://apim-qas.brf.cloud/hub-p/api",
  base_api_distribution: "https://apim-qas.brf.cloud/portal-d/api",
  base_api_distribution_diaria: "https://apim-qas.brf.cloud/portal-gd/api",
  base_signal_r_hub_distribution: "https://brf-fnc-onelog-proc-hub-qas.azurewebsites.net/api",
  base_api_agro_commodities: "https://apim-qas.brf.cloud/portal-ac/api",
  base_signal_r_hub_agro_commodities: "https://apim-qas.brf.cloud/portal-ac/api",

  express_url: "https://ygg-qas.brf.cloud/onelog/server"
};
