import { Coordinate } from "./google-maps/coordinate.model";

export class Address {
    #street: string;

    #complement: string;

    #number: string;

    #neighborhood: string;

    #city: string;

    #state: string;

    #country: string;

    #lat: string;

    #lng: string;

    #latNumber: number;

    #lngNumber: number;

    #federativeUnit: string;

    #zipCode: string;

    constructor({
        rua,
        complemento,
        numero,
        bairro,
        cidade,
        estado,
        pais,
        longitude,
        latitude,
        uf,
        cep
    }) {
        this.#street = rua;
        this.#complement = complemento;
        this.#number = numero;
        this.#neighborhood = bairro;
        this.#city = cidade;
        this.#state = estado;
        this.#country = pais;
        const coordinate = new Coordinate(latitude, longitude);
        this.#lat = coordinate.getLatitudeFormat();
        this.#lng = coordinate.getLongitudeFormat();
        this.#latNumber = coordinate.getLatitude();
        this.#lngNumber = coordinate.getLongitude();
        this.#federativeUnit = uf;
        this.#zipCode = cep;
    }

    get street(): string {
        return this.#street || '';
    }

    get complement(): string {
        return this.#complement || '';
    }

    get number(): string {
        return this.#number || '';
    }

    get neighborhood(): string {
        return this.#neighborhood || '';
    }

    get city(): string {
        return this.#city || '';
    }

    get state(): string {
        return this.#state || '';
    }

    get country(): string {
        return this.#country || '';
    }

    get lat(): string {
        return this.#lat || '';
    }

    get lng(): string {
        return this.#lng || '';
    }

    get latNumber(): number {
        return this.#latNumber;
    }

    get lngNumber(): number {
        return this.#lngNumber;
    }

    get federativeUnit(): string {
        return this.#federativeUnit || '';
    }

    get zipCode(): string {
        return this.#zipCode || '';
    }

    get fullAddress(): string {
      let fullAddress = '';
      fullAddress += this.street ? `${this.street}, ` : '';
      fullAddress += this.number ? `${this.number}, ` : '';
      fullAddress += this.neighborhood ? `${this.neighborhood}, ` : '';
      fullAddress += this.city ? `${this.city}, ` : '';
      fullAddress += this.city ? `${this.federativeUnit}, ` : '';
      fullAddress += this.zipCode ? this.zipCode : '';
      return fullAddress;
    }

    static from(addresses: any[]): Address[] {
        return addresses.map((address) => new Address(address));
    }
}
