<mat-form-field [appearance]="appearance" style="width: 100%">
  <mat-label>{{ label }}</mat-label>

  <input
    #inputElement
    matInput
    [type]="type"
    [value]="value"
    [disabled]="disabled"
    [placeholder]="placeholder"
    (input)="handleInputChange(inputElement.value)"
    (blur)="propagateTouched()"
    (paste)="formatPastedValue($event, inputElement)"
  />

  <button
    *ngIf="!!value"
    matSuffix
    mat-icon-button
    aria-label="Clear"
    (click)="handleInputChange('')"
  >
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>
