import { Injectable } from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoadingService {

    private loading: Subject<boolean>;

    constructor() {
        this.loading= new Subject();
    }

    public get onLoading(): Observable<boolean> {
        return this.loading.asObservable();
    }

    public setLoading(loading: boolean){
        this.loading.next(loading);
    }
}
