import {OverlayModule} from '@angular/cdk/overlay';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ModalContainerComponent} from './modal-container/modal-container.component';
import {ModalService} from './modal.service';

@NgModule({
  declarations: [
    ModalContainerComponent,
  ],
  imports: [
    CommonModule,
    OverlayModule,
  ],
  providers: [
    ModalService,
  ],
  entryComponents: [
    ModalContainerComponent
  ],
  exports: [
    OverlayModule,
  ]
})
export class ModalModule {
}
