import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";

@Component({
    selector: 'gm-direction',
    template: ''
})
export class GmDirectionComponent implements OnChanges {

    @Input() map: google.maps.Map;

    @Input() directionsList: { origin: google.maps.LatLng, destination: google.maps.LatLng }[] = [];

    @Input() waypoints: any[] = [];

    @Input() routeColor: string = "#808080";

    @Input() showArrowDirection: boolean = true;

    @Input() strokeColorArrow: string = "#808080";

    @Input() strokeWeight: number = 4;

    @Input() visible: boolean = true;

    directionsRenderer: google.maps.DirectionsRenderer[] = [];

    delayFactor = 0;

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes) return;
        if (!this.map) {
            console.error("You need pass 'map' to render 'Direction'");
            return;
        };
        try {
            const lineSymbol = {
                path: google.maps.SymbolPath.FORWARD_OPEN_ARROW,
                strokeColor: this.strokeColorArrow
            };
            const options: google.maps.DirectionsRendererOptions = {
                suppressMarkers: true,
                preserveViewport: true,
                polylineOptions: {
                    strokeColor: this.routeColor,
                    strokeWeight: this.strokeWeight,
                    visible: this.visible,
                    icons: this.showArrowDirection ? [{
                        icon: lineSymbol,
                        repeat: '200px',
                        offset: '100%'
                    }] : null
                }
            }
            if (!changes.visible?.isFirstChange()) {
                this.directionsRenderer.map((rendered: google.maps.DirectionsRenderer) => {
                    rendered.setOptions(options)
                    rendered.setMap(this.map);
                })
            } else {
                this.directionsList.forEach((direction: { origin: google.maps.LatLng, destination: google.maps.LatLng }) => {
                    this.delayFactor++;
                    const directionsService = new google.maps.DirectionsService();
                    const directionsRenderer = new google.maps.DirectionsRenderer(options);
                    directionsRenderer.setMap(this.map);

                    this.delayFactor++;
                    const request: google.maps.DirectionsRequest = {
                        origin: direction.origin,
                        destination: direction.destination,
                        waypoints: this.waypoints,
                        travelMode: google.maps.TravelMode.DRIVING,
                    }
                    setTimeout(() => {
                        this.generateDirections(directionsRenderer, request, directionsService);
                    }, this.delayFactor * 500);
                    directionsRenderer.setMap(this.map);
                    this.directionsRenderer.push(directionsRenderer);
                });
            }

        } catch (e) {
            console.error(["Error to render google maps directions", e]);
        }

    }

    generateDirections(
        directionsRenderer: google.maps.DirectionsRenderer,
        request: google.maps.DirectionsRequest,
        directionsService: google.maps.DirectionsService
    ) {
        directionsService.route(request, (response: google.maps.DirectionsResult, status: google.maps.DirectionsStatus) => {
            if (status === google.maps.DirectionsStatus.OK) {
                directionsRenderer.setDirections(response);
            } else if (status === google.maps.DirectionsStatus.OVER_QUERY_LIMIT) {
                setTimeout(() => {
                    this.generateDirections(directionsRenderer, request, directionsService);
                }, this.delayFactor * 1000);
            }
        });
    }

}