export class Shipping {
    #code: string;

    #priority: string;

    #date: Date;

    #type: string;

    constructor({ numero, prioritaria, data, tipoRemessa }) {
        this.#code = numero;
        this.#priority = prioritaria,
        this.#date = !data ? null : new Date(data);
        this.#type = tipoRemessa;
    }

    get code(): string {
        return this.#code;
    }

    get priority(): string {
        return this.#priority;
    }

    get date(): Date {
        return this.#date;
    }

    get type(): string {
        return this.#type;
    }

    static from(shippings: any[]): Shipping[] {
        return shippings.map((shipping) => new Shipping(shipping));
    }
}
