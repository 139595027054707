export class ChatLoadConversationHistoric {
    #loadNumber: string;

    #plate: string;

    #lastMessage: string;

    #quantityMessageUnread: number;

    #lastMessageAt: Date;

    #chatIdsUnreaded: string[];

    #travelStatus: string;

    constructor({
        numeroViagem,
        placa,
        texto,
        numMensagemNaoLidas,
        dataUltimaMensagem,
        idsNaoLidos,
        status
    }) {
        this.#loadNumber = numeroViagem;
        this.#plate = placa;
        this.#lastMessage = texto;
        this.#quantityMessageUnread = numMensagemNaoLidas;
        this.#lastMessageAt = !dataUltimaMensagem ? null : new Date(dataUltimaMensagem);
        this.#chatIdsUnreaded = idsNaoLidos;
        this.#travelStatus = status;
    }

    get loadNumber(): string {
        return this.#loadNumber;
    }

    get plate(): string {
        return this.#plate;
    }

    get lastMessage(): string {
        return this.#lastMessage;
    }

    set lastMessage(message: string) {
        this.#lastMessage = message;
    }

    get quantityMessageUnread(): number {
        return this.#quantityMessageUnread;
    }

    set quantityMessageUnread(quantity: number) {
        this.#quantityMessageUnread = quantity;
    }

    get lastMessageAt(): Date {
        return this.#lastMessageAt;
    }

    set lastMessageAt(date: Date) {
        this.#lastMessageAt = date;
    }

    get fullTitle(): string {
        return `${this.#loadNumber} - ${this.#plate}`;
    }

    get chatIdsUnreaded(): string[] {
        return this.#chatIdsUnreaded;
    }

    set chatIdsUnreaded(ids: string[]) {
        this.#chatIdsUnreaded = ids;
    }

    get travelStatus(): string {
        return this.#travelStatus;
    }

    static from(viagens: any[]): ChatLoadConversationHistoric[] {
        return viagens.map((viagem: any) => new ChatLoadConversationHistoric(viagem));
    }
}
