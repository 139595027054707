import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnInit {

  @Input()
  width: number = 16;
  @Input()
  height: number = 16;

  @Input()
  opacity: number = 1;

  @Input()
  icon: string = "";

  @Input()
  tooltip: string = "teste";

  @Input()
  viewBox: string = null;

  _color: string = "#5B5C60";
  @Input()
  set color(value: string) {
    if (!value) this._color = "#5B5C60";
    else if (value == "primary") this._color = "#002465";
    else if (value == "primary-400") this._color = "#005AFE";
    else if (value == "success") this._color = "#76BB79";
    else if (value == "danger") this._color = "#D22630";
    else if (value == "warning") this._color = "#F6BF00";
    else if (value == "default") this._color = "#5B5C60";
    else if (value == "light") this._color = "#B8BAC2";
    else if (value == "primary-blue") this._color = "#003087";
    else this._color = value;
  }

  get color(): string {
    return this._color;
  }

  constructor() { }

  ngOnInit(): void {
  }
}
