import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
  })
export class FilterJsonService {

    constructor(private http: HttpClient){}

    getCategorias(): Observable<any>{
        return this.http.get<any[]>('./assets/json/categorias.json');
    }

    getMotivos(): Observable<any>{
        return this.http.get<any[]>('./assets/json/motivos.json');
    }

    getStatusDevolucao(): Observable<any>{
        return this.http.get<any[]>('./assets/json/status-devolucao.json');
    }

    getAcoesMotorista() : Observable<any>{
        return this.http.get<any[]>('./assets/json/acoes-motorista.json');
    }

    getPaises() : Observable<any>{
        return this.http.get<any[]>('./assets/json/paises.json');
    }

    getEstatosCidades() : Observable<any>{
        return this.http.get<any[]>('./assets/json/cidades.json');
    }

    getTiposUnidades() : Observable<any>{
        return this.http.get<any[]>('./assets/json/tipos-unidades.json');
    }

    getBlackListSeller(): Observable<{name: string, id: string}[]> {
        return this.http.get<{name: string, id: string}[]>('./assets/json/blacklist-seller.json');
    }
}
