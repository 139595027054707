import { Component, Input } from '@angular/core';
import { SessionStorageService } from 'src/app/core/services/session-storage.service';
import { Anomaly } from 'src/app/models/anomaly.model';
import { ModalService } from '../../modal.service';

@Component({
  selector: 'app-modal-drawer-anomalies',
  templateUrl: './anomalies.component.html',
  styleUrls: ['./anomalies.component.scss']
})
export class ModalDrawerAnomaliesComponent {

  @Input() modalId: string;

  @Input() anomalies: Anomaly[];

  @Input() logistic: string;

  constructor(private modalService: ModalService, private sessionStorageService: SessionStorageService) { }

  public onRedirectionUrl(): void {
    // TODO: Quando for migrado a página inteira e separado por responsabilidades não haverá necessidade desse controle
    this.sessionStorageService.setItem('tab', 1);

    this.modalService.unsetModal(this.modalId);
  }
}
