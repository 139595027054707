import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ISerialize } from '../models/serialize.interface';
import { UserFilter } from '../models/user-filter/user-filter.model';
import { UsuarioFiltro } from '../core/models/usuario-filtro.model';
import { BaseService } from '../core/services/base.service';
import { MessageService } from '../core/services';
import { PerfilPermissaoService } from '../core/services/perfil-permissao.service';

@Injectable({
  providedIn: 'root'
})
export class UserFiltersService extends BaseService<any> {

  constructor(
    _http: HttpClient,
    messageService: MessageService,
    perfilPermissaoService: PerfilPermissaoService
  ) { 
    super(_http, 'usuariofiltros', messageService, perfilPermissaoService);
  }
  


  public getMyFilters<T extends ISerialize>(login: string, screen: string): Promise<UserFilter<T>[]> {
    return this.http
      .get(`${this.url}/usuarios/${login}/telas/${screen}`)
      .toPromise().then((response: any) => {
        return UserFilter.from(response);
      });
      
  }


  public getMyFiltersNoSerialized(login: string, screen: string): Observable<UsuarioFiltro[]> {
    console.log('getMyFiltersNoSerialized')
    return this.http
      .get(`${this.url}/usuarios/${login}/telas/${screen}`)
      .pipe(map((response: any) => response));
  }

  public createFilter<T extends ISerialize>(filter: UserFilter<T>): Observable<boolean> {
    const serialized = filter.serialize();
    serialized.definicaoFiltro = JSON.stringify(serialized.definicaoFiltro);

    return this.http
      .post(this.url, serialized)
      .pipe(map((_) => true));
  }

  public createFilterNoSerialized(filter: Partial<UsuarioFiltro>): Observable<boolean> {
    return this.http
      .post(this.url, filter)
      .pipe(map((_) => true));
  }

  public updateFilter<T extends ISerialize>(filter: UserFilter<T>): Observable<boolean> {
    const serialized = filter.serialize();
    serialized.definicaoFiltro = JSON.stringify(serialized.definicaoFiltro);

    return this.http
      .put(this.url, serialized)
      .pipe(map((_) => true));
  }

  public updateFilterNoSerialized(filter: UsuarioFiltro): Observable<boolean> {
    return this.http
      .put(this.url, filter)
      .pipe(map((_) => true));
  }

  public removeFilter<T extends ISerialize>(filter: UserFilter<T>): Observable<boolean> {
    return this.http
      .delete(`${this.url}/filtros/${filter.id}/usuarios/${filter.login}`)
      .pipe(map(_ => true));
  }

  public removeFilterNoSerialized(filter: UsuarioFiltro): Observable<boolean> {
    return this.http
      .delete(`${this.url}/filtros/${filter.id}/usuarios/${filter.idUsuario}`)
      .pipe(map(_ => true));
  }
}
