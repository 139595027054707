import { Anomaly } from "../../anomaly.model";
import { PointV2 } from "../../point-v2.model";
import { SynopticMapThreePoint } from "../synoptic-map-three-point.model";
import { ISynopticMapModel } from "../synoptic-map.interface";
import { SynopticMap } from "../synoptic-map.model";
import { SynopticMapPointPrimary } from "./synoptic-map-point-primary.model";

export class SynopticMapPrimary extends SynopticMap<SynopticMapPointPrimary>
    implements ISynopticMapModel<SynopticMapPointPrimary> {

    constructor(route: any) {
        super(route);
    }

    getPoints(): any[] {
        return this.points;
    }

    setPoints(): void {
        this.points = [...this.originalPoints]        
        this.points = this.preparePoints(this.points);
        this.setPointsDescending();        
        this.setThreePoints();
        this.addExtraPointAnomalies();
    }

    getPointsDescending(): any[] {
        return this.pointsDescending;
    }

    setPointsDescending(): void {
        this.pointsDescending = [...this.points];
    }

    getThreePoints(): SynopticMapThreePoint[] {
        return this.threePoints;
    }

    setThreePoints(): void {
        try {
            if (!this.pointsDescending) {
                this.setPoints();
            }

            if (this.threePoints.length > 0) {
                return;
            }

            // Condição para quando o último ponto já foi finalizado
            const lastPoint = this.points[this.points.length - 1];
            if (lastPoint?.completed || this.loadStatus?.toLocaleLowerCase() === 'cancelado') {
                lastPoint.threeCurrent = this.loadStatus?.toLocaleLowerCase() !== 'cancelado';
                this.addLastThreePoints(
                    this.points[this.points.length - 3],
                    this.points[this.points.length - 2],
                    lastPoint,
                );
                return;
            }

            const inTransitPointIndex = this.pointsDescending.findIndex((point: SynopticMapPointPrimary) => point.current);

            if (inTransitPointIndex === -1) {
                this.addLastThreePoints(
                    this.points[0],
                    this.points[1],
                    this.points[2],
                );
                return;
            }


            if (inTransitPointIndex !== -1) {
                const inTransitPoint = this.pointsDescending[inTransitPointIndex];

                inTransitPoint.threeCurrent = true;

                this.addLastThreePoints(
                    this.pointsDescending[inTransitPointIndex - 1],
                    inTransitPoint,
                    this.pointsDescending[inTransitPointIndex + 1]
                );

                return;
            }

            for (let i = 0; i < this.pointsDescending.length; i++) {
                const point = this.pointsDescending[i];

                if (point.unloadingNoteDate !== null && point.unloadingNoteDateFinished !== null) {
                    this.addPointsFromUnloadDateFinished(point, i);
                    return;
                } else if (point.unloadingNoteDate !== null && point.unloadingNoteDateFinished === null) {
                    this.addPointsFromUnloadDateWithoutFinished(point, i);
                    return;
                }
            }

            const pointOne = this.pointsDescending[this.pointsDescending.length - 1];
            const pointTwo = this.pointsDescending[this.pointsDescending.length - 2];
            const pointThree = this.pointsDescending[this.pointsDescending.length - 3];

            this.addLastThreePoints(pointOne, pointTwo, pointThree);

        } catch (e) {
            console.error('Preparação da coluna Mapa sinótico falhou', e);
        }
    }

    getPointsMap(): PointV2[] {
        return this.pointsMap;
    }

    setPointsMap(pointId?: string): void {
        if (!this.points) {
            this.setPoints();
        }
        this.pointsMap = PointV2.from(
            this.points.map((point: SynopticMapPointPrimary) => {
                let color = point.color;
                if (point.type === 'fim_rota') {
                    color = this.loadStatus === 'finalizadoManual' || this.loadStatus === 'finalizadoForcado' ? 'delay' : point.color;
                }
                return {
                    title: point.title,
                    titleUrl: pointId === point.id
                        ? null
                        : point.urlPoint,
                    subtitle: point.subtitle,
                    date: point.date,
                    dateFinished: point.dateFinished,
                    size: point.size,
                    color: this.loadStatus?.toLocaleLowerCase() === 'cancelado' ? 'default' : color,
                    current: point.current,
                    anomaly: point.anomalyName,
                    anomalyTotal: point.anomalies?.length || 0,
                    anomalyUrl: point.anomalyUrl,
                    showing: pointId !== null
                        ? pointId === point.id
                        : point.current,
                    type: point.type
                };
            })
        );
    }
    addExtraPointInTransit(): void {
        if (this.loadStatus !== 'monitorado') {
            return;
        }

        this.points = this.points.filter(point => point.type !== 'in_transit');

        for (let i = 0; i <= this.points.length; i++) {
            const point = this.points[i];
            const nextPoint = this.points[i + 1];

            if (nextPoint &&
                nextPoint.unloadingNoteDate === null &&
                nextPoint.unloadingNoteDateFinished === null &&
                point &&
                point.unloadingNoteDate !== null &&
                point.unloadingNoteDateFinished !== null
            ) {
                this.points.splice(i + 1, 0, new SynopticMapPointPrimary({
                    id: null,
                    ordem: point.order + 1,
                    descricao: null,
                    concluido: false,
                    tipoParada: 'in_transit',
                    classificacao: null,
                    nome: null,
                    dataHora: null,
                    dataHoraFinalizacao: null,
                    dataHoraInicio: null,
                    dataHoraFim: null,
                    codigoCliente: null,
                    codigoParada: null,
                    tipoPonto: null,
                    anomalias: null,
                    numeroViagem: this.loadNumber
                }));
                break;
            }
        }
    }
    addExtraPointAnomalies(): void {
        const points = [];
        let anomaliesFilter = [];
        let anomalies = this.anomalies;
        this.points.forEach((point, index) => {

            points.push(point);

            if (!point.completed || point.date === null || point.type === 'fim_rota' || point.type === 'in_transit') {
                return;
            }

            const nextPoint = this.points[index + 1];
            const dateOne = point.date;
            const dateTwo = nextPoint?.date === null ? new Date() : nextPoint?.date;
            anomaliesFilter = anomalies.filter((anomaly: Anomaly) =>
                anomaly.creationAt >= dateOne && anomaly.creationAt < dateTwo && anomaly.type != '50'
            );

            if (anomaliesFilter.length) {
                anomaliesFilter.forEach((anomaly: Anomaly) => {
                    points.push(this.prepareAnomalyPoint(anomaly))
                    const index = anomalies.findIndex(_ => _.id === anomaly.id);
                    if (index > -1) anomalies.splice(index, 1);
                });
            }
        });
        // Caso a anomalia não esteja no intervalo de tempo entre as etapas, colocar a anomalia apos primeira etapa da viagem
        const pointsAnomalies = []
        if (anomalies.length > 0) {
            anomalies.forEach((anomaly: Anomaly) => {
                if (anomaly.type != '50') {
                    pointsAnomalies.push(this.prepareAnomalyPoint(anomaly));

                }
            })
            points.splice(1, 0, ...pointsAnomalies);
        }
        this.points = points;
    }

    addAnomalySignalR(anomalySignalR: any): void {
        try {
            if (this.loadNumber !== anomalySignalR.rota.numeroViagem) {
                return;
            }

            const newAnomaly = new Anomaly({
                id: anomalySignalR.id,
                detalheDescricao: anomalySignalR.detalheDescricao,
                dtCreated: anomalySignalR.dtCreated,
                nome: anomalySignalR.nome,
                status: anomalySignalR.status,
                tipo: anomalySignalR.tipo,
                idParada: anomalySignalR.idParada,
                prioridade: anomalySignalR.prioridade,
                numeroViagem: anomalySignalR.rota.numeroViagem
            });

            const anomalyExistsIndex = this.points.findIndex(point => point.id === newAnomaly.id && point.type === 'anomaly');

            if (anomalyExistsIndex !== -1) {
                return;
            }

            const pointIndex = anomalySignalR.idParada !== null
                ? this.points.findIndex(point => point.id === anomalySignalR.idParada)
                : -1;

            /**
             * FLUXO 1
             * Inserir a anomalia que foi gerada dentro do raio da parada
             */
            if (pointIndex !== -1) {
                const pointFinded = this.points[pointIndex];
                const anomalyIndex = pointFinded.anomalies.findIndex(anomaly => anomaly.id === newAnomaly.id);

                if (anomalyIndex === -1) {
                    const anomalies = [...pointFinded.anomalies];
                    anomalies.push(newAnomaly);
                    pointFinded.anomalies = anomalies;
                } else {
                    pointFinded.anomalies[anomalyIndex] = newAnomaly;
                }

                this.setPointsMap();

                return;
            }

            /**
             * FLUXO 2:
             * Inserir anomalia que foi gerada fora do raio de uma parada
             */
            for (let index = 0; index < this.originalPoints.length; index++) {
                const originalPoint = this.originalPoints[index];

                if (originalPoint.type === 'fim_rota') {
                    continue;
                }

                const nextPoint = this.originalPoints[index + 1];

                if (nextPoint.date === null || originalPoint.date > newAnomaly.creationAt && nextPoint.date < newAnomaly.creationAt) {
                    const pointFindedIndex = this.points.findIndex(point => point.id === originalPoint.id);
                    this.points.splice((pointFindedIndex + 1), 0, this.prepareAnomalyPoint(newAnomaly));
                    this.setPointsMap();
                    return;
                }
            }

        } catch (e) {
            console.error('[ADD ANOMALY SIGNALR]', this.loadNumber, anomalySignalR, e);
        }
    }

    upsertPointSignalR(pointSignalR: any): void {
        try {
            if (this.loadNumber !== pointSignalR.NumeroViagem) {
                return;
            }

            const pointIndex = this.points.findIndex(point => point.id === pointSignalR.Id);

            if (pointIndex !== -1) {
                const pointFinded = this.points[pointIndex];

                pointFinded.completed = pointSignalR.Concluido;
                pointFinded.unloadingNoteDate = !pointSignalR.DataHora ? null : new Date(pointSignalR.DataHora);
                pointFinded.unloadingNoteDateFinished = !pointSignalR.DataHoraFinalizacao
                    ? null
                    : new Date(pointSignalR.DataHoraFinalizacao);

                this.addExtraPointInTransit();
                this.setPointsMap();

                return;
            }

            throw new Error('Inserção de novos pontos não implementado');

        } catch (e) {
            console.error('[UPSERT POINT SIGNALR]', this.loadNumber, pointSignalR, e);
        }
    }

    private preparePoints(points) {
        let pointsExpected = [];
        let pointUnexpected = [];        

        // Remove a etapa de fim de rota da lista para ordernar as demais paradas
        const indexPointFinished = points.findIndex((point: SynopticMapPointPrimary) => point.type == 'fim_rota');
        let pointFinished = null;
        if (indexPointFinished > -1) {
            pointFinished = points[indexPointFinished];        
            points.splice(indexPointFinished, 1);
        }        
        
        points.forEach(point => point.order > 0 ? pointsExpected.push(point) : pointUnexpected.push(point));                
        
        pointUnexpected = pointUnexpected.sort((a, b) => a.date - b.date);

        let pointsOrdered = pointsExpected.sort((a, b) => a.order - b.order);

        if (pointsExpected.length == 0) {
            pointsOrdered = pointUnexpected;
            if (pointFinished) pointsOrdered.push(pointFinished);
            return pointsOrdered;
        }
        pointUnexpected.forEach((point: SynopticMapPointPrimary) => {
            let pointInserted = false;
            pointsOrdered.forEach((pointExpected: SynopticMapPointPrimary, index) => {                                
                if (index > 2 && point.date > pointsExpected[index - 1].date && point.date < pointExpected.date && !pointInserted) {
                    pointsOrdered.splice(index, 0, point);
                    pointInserted = true;
                }
                if (index > 2 && point.date < pointExpected.date && !pointExpected.completed && !pointInserted) {
                    pointsOrdered.splice(index, 0, point);
                    pointInserted = true;
                }
            })            
            if (!pointInserted) pointsOrdered.push(point);
        })
        // Adiciona etapa de fim de rota no final da timeline
        if (pointFinished) pointsOrdered.push(pointFinished);
        return pointsOrdered;
    }

    private prepareAnomalyPoint(anomaly: Anomaly): SynopticMapPointPrimary {
        return new SynopticMapPointPrimary({
            id: anomaly.id,
            ordem: null,
            descricao: null,
            concluido: false,
            tipoParada: 'anomaly',
            classificacao: null,
            nome: anomaly.name,
            dataHora: anomaly.creationAt,
            dataHoraFinalizacao: null,
            dataHoraInicio: null,
            dataHoraFim: null,
            codigoCliente: null,
            codigoParada: null,
            tipoPonto: null,
            anomalias: null,
            numeroViagem: this.loadNumber
        });
    }

    /**
     * Adiciona os pontos que estão em andamento
     *
     * @param point1 any
     * @param point2 any
     * @param point3 any
     */
    private addLastThreePoints(
        point1: SynopticMapPointPrimary,
        point2: SynopticMapPointPrimary,
        point3: SynopticMapPointPrimary
    ): void {
        if (this.threePoints.length > 0) {
            return;
        }        
        if (this.loadStatus === 'finalizadoManual' || this.loadStatus === 'finalizadoForcado' || point3?.color == 'delay') {
            if (point1) point1.threeCurrent = false;
            if (point2) point2.threeCurrent = false;
            if (point3) point3.threeFinalizedForced = true;
        } else if (this.loadStatus === 'finalizado') {
            if (point1) point1.threeCurrent = false;
            if (point2) point2.threeCurrent = false;
            if (point3) point3.threeFinalizedSuccess = true;
        }
        if (point1) this.threePoints.push(new SynopticMapThreePoint(point1))
        if (point2) this.threePoints.push(new SynopticMapThreePoint(point2))
        if (point3) this.threePoints.push(new SynopticMapThreePoint(point3))
    }

    /**
     * Adiciona os ícones do mapa sinótico a partir do ponto de descarregamento que possue data de finalização
     *
     * @param pointIterate any
     * @param pointsOrdenateDescending any
     *
     * @return void
     */
    private addPointsFromUnloadDateFinished(pointIterate: SynopticMapPointPrimary, index: number): void {
        let pointOne = null;
        let pointTwo = null;
        let pointThree = null;

        if (pointIterate.type === 'fim_rota') {
            pointIterate.threeCurrent = true;

            pointOne = this.pointsDescending[index + 1];
            pointTwo = this.pointsDescending[index + 2];

            this.addLastThreePoints(pointOne, pointTwo, pointIterate);
            return;
        }

        if (pointIterate.type === 'inicio_rota') {
            pointIterate.threeCurrent = true;

            pointTwo = this.pointsDescending[index - 1];
            pointThree = this.pointsDescending[index - 2];

            this.addLastThreePoints(pointIterate, pointTwo, pointThree);
            return;
        }

        pointOne = this.pointsDescending[index - 1];
        pointThree = this.pointsDescending[index - 2];

        pointIterate.threeCurrent = true;

        this.addLastThreePoints(pointOne, pointIterate, pointThree);
    }

    /**
     * Adiciona os ícones do mapa sinótico a partir de uma parada que está em andamento
     *
     * @param pointIterate any
     * @param pointsOrdenateDescending any
     * @param index number
     *
     * @return void
     */
    private addPointsFromUnloadDateWithoutFinished(pointIterate: SynopticMapPointPrimary, index: number): void {
        let pointOne = null;
        let pointTwo = null;
        let pointThree = null;

        if (pointIterate.type === 'inicio_rota') {
            pointIterate.threeCurrent = true;

            pointTwo = this.pointsDescending[index - 1];
            pointThree = this.pointsDescending[index - 2];

            this.addLastThreePoints(pointIterate, pointTwo, pointThree);
            return;
        }

        if (pointIterate.type === 'fim_rota') {
            pointIterate.threeCurrent = true;

            pointTwo = this.pointsDescending[index + 1];
            pointThree = this.pointsDescending[index + 2];

            this.addLastThreePoints(pointThree, pointTwo, pointIterate);
            return;
        }

        pointIterate.threeCurrent = true;

        pointThree = this.pointsDescending[index - 1];
        pointOne = this.pointsDescending[index + 1];

        this.addLastThreePoints(pointOne, pointIterate, pointThree);
    }
}