import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from "@angular/core";
import { IconsPrimary } from "src/app/core/models/enums/icons-primary";


@Component({
    selector: 'gm-marker',
    template: ''
})
export class GmMarkerComponent implements OnChanges, OnDestroy {

    @Input() map: google.maps.Map;

    @Input() latitude: number;

    @Input() longitude: number;

    @Input() icon: string = IconsPrimary.ICON_CURRENT_POINT;

    @Input() title: string;

    public marker: google.maps.Marker;

    public listener: google.maps.MapsEventListener;

    ngOnChanges(changes: SimpleChanges): void {
        try {
            if (!changes) return;
            if (!this.map) {
                console.error("You need pass 'map' to render 'Marker'");
                return;
            };
            if ((!changes.latitude || !changes.longitude) && (!this.latitude || !this.longitude)) {
                console.error("You need pass 'latitude' and 'longitude to render 'Marker'");
                return;
            }
            if ((changes.latitude == null || changes.longitude == null) || (this.latitude == null || this.longitude == null)) {
                console.error("'latitude' or 'longitude is null");
                return;
            }
            if (!changes?.latitude?.isFirstChange() || !changes?.longitude?.isFirstChange()) {
                this.marker.setOptions({ position: { lat: this.latitude, lng: this.longitude }, icon: this.icon });
                return;
            }
            const Marker = google.maps.Marker;
            const infowindow = new google.maps.InfoWindow({
                content: `<strong> ${this.title || ''} </strong>`,
            });
            this.marker = new Marker({
                position: { lat: this.latitude, lng: this.longitude },
                icon: this.icon,
                map: this.map
            })
            this.listenerInfoWindow(infowindow);

        } catch (e) {
            console.error(["Error to render google maps marker", e]);
        }
    }

    ngOnDestroy(): void {
        if (this.marker) {
            this.listener.remove();
            this.marker.setMap(null);
        }
    }

    private listenerInfoWindow = (infowindow: any) =>
        this.listener = this.marker.addListener("click", () => {            
            infowindow.open({
                anchor: this.marker,
                map: this.map,
                shouldFocus: false,
            });
        });
}
