export function SerializeClassUtil(instanceClass: any): any {
  try {
    return instanceClass.serialize();
  } catch (e) {
    console.error('[SerializeClassUtil] Não foi possível serializar a classe', e);
  }
}

export function SerializeArrayClassUtil(arrayOfInstanceClass: any[]): any[] {
  const list = [];

  arrayOfInstanceClass.forEach(instanceClass => {
    const serializedItem = SerializeClassUtil(instanceClass);

    if (serializedItem) {
      list.push(serializedItem);
    }
  });

  return list;
}
