import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Anomaly } from '../models';
import { AnomalyNegociation } from '../models/anomaly-negociation.model';
import { AnomalyFilter } from '../models/filters/anomaly-filter';
import { TableResult } from '../models/table/table-result';
import { BaseService } from './base.service';
import * as moment from 'moment';
import { UsuarioFiltro } from '../models/usuario-filtro.model';
import { MessageService } from './message.service';
import { PerfilPermissaoService } from './perfil-permissao.service';

@Injectable()
export class UsuarioFiltrosService extends BaseService<UsuarioFiltro> {

    private primariaFilter: BehaviorSubject<Observable<any>> = new BehaviorSubject<Observable<any>>(null);

    private distribuicaoFilter: BehaviorSubject<Observable<any>> = new BehaviorSubject<Observable<any>>(null);

    private agroFilter: BehaviorSubject<Observable<any>> = new BehaviorSubject<Observable<any>>(null);

    private commoditiesFilter: BehaviorSubject<Observable<any>> = new BehaviorSubject<Observable<any>>(null);

    private usarPadrao: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    updateObsByTela(tela: string, data: any): void {
       var object = {
        'monitoramento-primaria': () => this.setPrimariaFilter = data,
        'monitoramento-distribuicao': () => this.setDistribuicaoFilter = data,
        'monitoramento-agro': () => this.setAgroFilter = data,
        'monitoramento-commodities': () => this.setCommoditiesFilter = data,
        };
        console.log(tela);
        return object[tela]();
    }

    getObsByTela(tela: string): Observable<any> {
        var object = {
            'monitoramento-primaria': this.getPrimariaFilter,
            'monitoramento-distribuicao': this.getDistribuicaoFilter,
            'monitoramento-agro': this.getAgroFilter,
            'monitoramento-commodities': this.getCommoditiesFilter,
            };
                
        return object[tela];
    }

    // Usar filtro padrão
    public get usarPadraoObs(): Observable<boolean> {
        return this.usarPadrao.asObservable();
    }

    public set setUsarPadrao(data: boolean) {
        this.usarPadrao.next(data);
    }

    // Primaria
    public get getPrimariaFilter(): Observable<any> {
        return this.primariaFilter.asObservable();
    }

    public set setPrimariaFilter(data: any) {
        this.primariaFilter.next(data);
    }

    //Distribuicao
    public get getDistribuicaoFilter(): Observable<any> {
        return this.distribuicaoFilter.asObservable();
    }

    public set setDistribuicaoFilter(data: any) {
        this.distribuicaoFilter.next(data);
    }

    // Agro
    public get getAgroFilter() {
        return this.agroFilter.asObservable();
    }
    public set setAgroFilter(data: any) {
        this.agroFilter.next(data);
    }

    // Commodities
    public get getCommoditiesFilter() {
        return this.commoditiesFilter.asObservable();
    }
    public set setCommoditiesFilter(data: any) {
        this.commoditiesFilter.next(data);
    }



    constructor(_http: HttpClient, messageService: MessageService, perfilPermissaoService: PerfilPermissaoService) {
        super(_http, 'usuariofiltros', messageService, perfilPermissaoService);
    }

    getByUsuario(usuarioId: string): Observable<UsuarioFiltro[]> {
        console.log('getByUsuario')
        return this.http.get(this.url + `/usuarios/${usuarioId}`, { headers: this.header() })
            .pipe(map((response: any) => response as UsuarioFiltro[]));
    }

    getByUsuarioTela(usuarioId: string, tela: string): Promise<UsuarioFiltro[]> {
        console.log('getByUsuarioTela')
        return this.http.get(this.url + `/usuarios/${usuarioId}/telas/${tela}`, { headers: this.header() })
        .toPromise().then((response: any) => {
            //this.updateObsByTela(tela, response);
            return response as UsuarioFiltro[];
        });
    }

    deleteFiltro(id: string, usuarioId: string): Observable<{}> {
        return this.http.delete(this.url + `/filtros/${id}/usuarios/${usuarioId}`, this.header())
            .pipe(catchError(null));
    }
}
