<div *ngIf="showMenu" style="display: flex; align-items: center;">
  <span *ngIf="currentLogistic?.logistica === 'distribuicao'">
    <app-chat-link openTab url="/chat"></app-chat-link>
  </span>
  <span *ngIf="showIconFilter" style="padding: 32px 12px 0 8px;">
    <app-icon [icon]="'filter'" [awesomeTooltip]="'Filtrar Viagens Tela de Monitoramento'" [color]="'#FFFFFF'"
      [width]="52" [height]="82" viewBox="0 0 32 32" (click)="openModalFilter()" style="cursor: pointer;"></app-icon>
  </span>
  <button class="user-profile" *ngIf="user" mat-button>
    <div fxLayout="row" fxLayoutAlign="left center" [matMenuTriggerFor]="menuProfile">
      <div class="profile-avatar">
        <span> {{getIniciais(user.name)}} </span>
      </div>

      <span class="user-name">{{user.name}}</span>
      <img src="../../../../../assets/icons/arrow-down.svg" />
    </div>
  </button>
</div>

<mat-menu #menuProfile="matMenu">

  <!-- <button (click)="changeLang('en')" mat-menu-item>
      <mat-icon class="english">flag</mat-icon>
      <span>EN - {{'LANG.ENGLISH' | translate}}</span>
      <mat-icon *ngIf="currentLang == 'en'" class="done">done</mat-icon>
    </button>
    <button (click)="changeLang('es')" mat-menu-item>
      <mat-icon class="spanish">flag</mat-icon>
      <span>ES - {{'LANG.SPANISH' | translate}}</span>
      <mat-icon *ngIf="currentLang == 'es'" class="done">done</mat-icon>
    </button>
    <button (click)="changeLang('pt')" mat-menu-item>
      <mat-icon class="portuguese">flag</mat-icon>
      <span>PT - {{'LANG.PORTUGUESE' | translate}}</span>
      <mat-icon *ngIf="currentLang == 'pt'" class="done">done</mat-icon>
    </button> -->

  <div class="mat-menu-content" *ngIf="user && showMenu">
    <div *ngIf="primariaRoles && primariaRoles.length > 0" class="logistica"
      [ngClass]="{'selected': currentLogistica == 'primaria'}">Primária</div>
    <div mat-menu-item class="role" *ngFor="let logisticaRole of primariaRoles"
      (click)="changeLogistica(logisticaRole.codigo)">
      <mat-icon *ngIf="currentRole == logisticaRole.codigo" class="done">label</mat-icon>
      <span [ngClass]="{'selected': currentRole == logisticaRole.codigo}">{{logisticaRole.nome}}</span>
    </div>

    <div *ngIf="distribuicaoRoles && distribuicaoRoles.length > 0" class="logistica top"
      [ngClass]="{'selected': currentLogistica == 'distribuicao'}">Distribuição</div>
    <div mat-menu-item class="role" *ngFor="let logisticaRole of distribuicaoRoles"
      (click)="changeLogistica(logisticaRole.codigo)">
      <mat-icon *ngIf="currentRole == logisticaRole.codigo" class="done">label</mat-icon>
      <span [ngClass]="{'selected': currentRole == logisticaRole.codigo}">{{logisticaRole.nome}}</span>
    </div>

    <div *ngIf="agroRoles && agroRoles.length > 0" class="logistica top"
      [ngClass]="{'selected': currentLogistica == 'agro'}">Agro</div>
    <div mat-menu-item class="role" *ngFor="let logisticaRole of agroRoles"
      (click)="changeLogistica(logisticaRole.codigo)">
      <mat-icon *ngIf="currentRole == logisticaRole.codigo" class="done">label</mat-icon>
      <span [ngClass]="{'selected': currentRole == logisticaRole.codigo}">{{logisticaRole.nome}}</span>
    </div>
    <div mat-menu-item class="role" (click)="changeLogistica(logisticaRole.codigo)">
      <mat-icon class="done">label</mat-icon>
      <span routerLink="/detalheViagem" style='cursor: pointer'>Detalhe da viagem</span>
    </div>
    <div *ngIf="commoditiesRoles && commoditiesRoles.length > 0" class="logistica top"
      [ngClass]="{'selected': currentLogistica == 'commodities'}">Commodities</div>
    <div mat-menu-item class="role" *ngFor="let logisticaRole of commoditiesRoles"
      (click)="changeLogistica(logisticaRole.codigo)">
      <mat-icon *ngIf="currentRole == logisticaRole.codigo" class="done">label</mat-icon>
      <span [ngClass]="{'selected': currentRole == logisticaRole.codigo}">{{logisticaRole.nome}}</span>
    </div>
  </div>
  <button (click)="logout()" mat-menu-item>
    <img class="icon-profile" width="16px" height="16px" mat-icon-button matPrefix
      src="../../../../../assets/icons/logout.svg">
    <span>{{'MENU.LOGOUT' | translate}}</span>
  </button>
</mat-menu>