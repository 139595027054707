<ng-container *ngIf="modalCurrent" [ngSwitch]="modalCurrent.name">

    <!-- Modal Drawer de Anomalias -->
    <app-modal-drawer-anomalies
        *ngSwitchCase="'drawer-anomalies'"
        [modalId]="modalCurrent.uuid"
        [anomalies]="modalCurrent.data.anomalies"
        [logistic]="modalCurrent.data.logistic"
    ></app-modal-drawer-anomalies>

    <!-- Modal Window de Viagens Relacionadas -->
    <app-modal-window-loads-related
        *ngSwitchCase="'window-loads-related'"
        [modalId]="modalCurrent.uuid"
        [loadNumber]="modalCurrent.data.loadNumber"
        [loadNumberRelated]="modalCurrent.data.loadNumberRelated"
    ></app-modal-window-loads-related>

    <!-- Modal Window de Listagem de Pedidos -->
    <app-modal-window-orders
        *ngSwitchCase="'window-orders'"
        [modalId]="modalCurrent.uuid"
        [orders]="modalCurrent.data.orders"
    ></app-modal-window-orders>

    <!-- Modal Window de Listagem de Remessas -->
    <app-modal-window-shippings
        *ngSwitchCase="'window-shippings'"
        [modalId]="modalCurrent.uuid"
        [shippings]="modalCurrent.data.shippings"
    ></app-modal-window-shippings>


    <app-modal-devolucao
    *ngSwitchCase="'window-devolucao'"
    [modalId]="modalCurrent.uuid"
    [devolucoes]="modalCurrent.data.devolucoes"
    ></app-modal-devolucao>


    <app-modal-parada-raio
    *ngSwitchCase="'window-parada-raio'"
    [modalId]="modalCurrent.uuid"
    [paradas]="modalCurrent.data.paradas"
    ></app-modal-parada-raio>

    <!-- Modal Window Monitoramento Filtro -->
    <app-modal-window-monitoring-filter
        *ngSwitchCase="'window-monitoring-filter'"
        [modalId]="modalCurrent.uuid"
        [logistic]="modalCurrent.data.logistic"
        [savedFilterId]="modalCurrent.data?.savedFilterId"
        [myFiltersModalId]="modalCurrent.data?.myFiltersModalId"
    ></app-modal-window-monitoring-filter>

    <!-- Modal Window Monitoramento Filtro Salvar -->
    <app-modal-window-monitoring-filter-save-filter
        *ngSwitchCase="'window-monitoring-filter-save'"
        [modalId]="modalCurrent.uuid"
        [uuidModalMonitoringFilter]="modalCurrent.data.uuidModalMonitoringFilter"
        [filtersResult]="modalCurrent.data.filtersResult"
        [logistic]="modalCurrent?.data?.logistic"
    ></app-modal-window-monitoring-filter-save-filter>

    <!-- Modal Window Monitoramento Filtro Meus Filtros -->
    <app-modal-window-monitoring-filter-my-filters
        *ngSwitchCase="'window-monitoring-filter-my-filters'"
        [modalId]="modalCurrent.uuid"
        [logistic]="modalCurrent?.data?.logistic"
    ></app-modal-window-monitoring-filter-my-filters>

    <!-- Modal Window Monitoramento Filtro Deletar -->
    <app-modal-window-monitoring-filter-delete-filter
        *ngSwitchCase="'window-monitoring-filter-remove'"
        [modalId]="modalCurrent.uuid"
        [savedFilterId]="modalCurrent.data.id"
        [logistic]="modalCurrent.data.logistic"
    ></app-modal-window-monitoring-filter-delete-filter>
</ng-container>
