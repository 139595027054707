export class Anomaly {
    #id: string;

    #name: string;

    #priority: number;

    #status: number;

    #detail: string;

    #creationAt: Date;

    #pointId: string;

    #loadNumber: string;

    #type: string;

    constructor({
        id = null,
        nome = null,
        prioridade = null,
        status = null,
        dataCriacao = null,
        detalheDescricao = null,
        idParada = null,
        priority = null,
        dtCreated = null,
        numeroViagem = null,
        tipo = null
    }) {
        const creationAt = dataCriacao || dtCreated;

        this.#id = id;
        this.#name = nome,
        this.#priority = prioridade || priority;
        this.#status = status;
        this.#creationAt = !creationAt ? null : new Date(creationAt);
        this.#detail = detalheDescricao;
        this.#pointId = idParada;
        this.#loadNumber = numeroViagem;
        this.#type = tipo;
    }

    get id(): string {
        return this.#id;
    }

    get name(): string {
        return this.#name;
    }

    get priority(): number {
        return this.#priority;
    }

    get status(): number {
        return this.#status;
    }

    get creationAt(): Date {
        return this.#creationAt;
    }

    get detail(): string {
        return this.#detail;
    }

    get pointId(): string {
        return this.#pointId;
    }

    get loadNumber(): string {
        return this.#loadNumber;
    }

    get type(): string {
        return this.#type;
    }

    get isDone(): boolean {
        return this.status === 3;
    }

    /**
     * Converte uma listagem de anomalias em instância da classe
     *
     * @param anomalies any[]
     * @returns Anomaly[]
     */
    static from(anomalies: any[]): Anomaly[] {
        return anomalies.map((anomaly: any) => new Anomaly(anomaly));
    }
}
