
export class ChatMessageOrigin {
    #type: string;

    #key: string;

    #description: string;

    constructor({ tipo, chave = null, descricao = null }) {
        this.#type = tipo;
        this.#key = chave;
        this.#description = descricao;
    }

    get type(): string {
        return this.#type;
    }

    get description(): string {
        return this.#description;
    }

    get key(): string {
        return this.#key;
    }

    serialize(): any {
        return {
            tipo: this.#type,
            descricao: this.#description,
            chave: this.#key
        };
    }
}
