import { Guid } from 'guid-typescript';
import { MenuItemOriginEnum } from 'src/app/enums/menu-item-origin.enum';
import { BreadCrumbItem } from './bread-crumb-item.model';

export class MenuItem { 
    public id: Guid;
    public origin: string;
    public icon: string;
    public title: string;
    public disabled: boolean;
    public selected: boolean;
    public toggle: boolean;
    public route: string;
    public action: any;
    public breadCrumb: BreadCrumbItem[];
    public children: MenuItemChild[];
    public routeChildren : string[];

    constructor(
        title: string = null,
        origin: MenuItemOriginEnum = null,
        route: string = null, 
        icon: string = null,
        disabled: boolean = false,
        selected: boolean = false,
        children: MenuItemChild[] = [], 
        action: any = null,
        toggle: boolean = false,
        breadCrumb: BreadCrumbItem[] = [],
        routeChildren : string []= []
    ) {
        this.id = Guid.create();
        this.title = title;
        this.origin = origin;
        this.route = route;
        this.icon = icon;
        this.disabled = disabled;
        this.selected = selected;
        this.children = children;
        this.action = action;
        this.toggle = toggle;
        this.breadCrumb = breadCrumb;
        this.routeChildren = routeChildren;
    }
}

export class MenuItemChild { 
    public id: Guid;
    public title: string;
    public selected: boolean;
    public route: string;
    public routeChildren: string[];
    public breadCrumb: BreadCrumbItem[];
    public isRender ;

    constructor( title: string = null, route: string = null, selected: boolean = false,
         breadCrumb: BreadCrumbItem[] = [], routeChildren: string[] = null, 
        isRender : boolean= true) {
        this.id = Guid.create();
        this.title = title;
        this.route = route;
        this.selected = selected;
        this.breadCrumb = breadCrumb;
        this.routeChildren = routeChildren;
    }
}