import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OpenTabDirective } from './open-tab.directive';
@NgModule({
  declarations: [OpenTabDirective],
  exports: [OpenTabDirective],
  imports: [
    CommonModule
  ]
})
export class OpenTabDirectiveModule { }
