import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { ModalService } from '../modal.service';

@Component({
  selector: 'app-modal-window',
  templateUrl: './window.component.html',
  styleUrls: ['./window.component.scss']
})
export class ModalWindowComponent implements OnInit {

  @Input() public modalId: string;

  @Input() public title: string;

  @Input() public titleModal: string;

  @Input() public subTitle: string;

  @Input() public extraClassContent: string;

  @Input() public closeIcon = true;

  @Input() public isCentered = true;

  @Input() public isCloseable = true;

  @Output() public closeModalOutput = new EventEmitter<boolean>();

  public centeredModifier = 'modal-window--is-centered';

  private mouseMove = false;

  @HostListener('click', ['$event'])
  onClick(e): void {
    if (!this.mouseMove) {
      e.stopPropagation();
      this.closeModal(e);
    }
  }

  @HostListener('mousemove', ['$event'])
  onMouseMove(_): void {
    this.mouseMove = true;    
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown(_): void {
    this.mouseMove = false;
  }

  constructor(private modalService: ModalService) { }

  public ngOnInit(): void {
    if (!this.isCentered) {
      this.centeredModifier = '';
    }
  }

  public closeModal($event: any): void {
    $event.stopPropagation();

    this.closeModalOutput.emit(true);
    this.modalService.unsetModal(this.modalId);
  }
}
