import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { TRangePickerDate } from 'src/app/components/date-range-picker/date-range-picker.component';
import { TOption } from 'src/app/components/select/select.component';
import { AuthService } from 'src/app/core/services/auth.service';
import { PerfilPermissaoService } from 'src/app/core/services/perfil-permissao.service';
import { SessionStorageService } from 'src/app/core/services/session-storage.service';
import { UserFilterScreenMonitoring } from 'src/app/models/user-filter/screen/monitoring.model';
import { UserFilter } from 'src/app/models/user-filter/user-filter.model';
import { MonitoringV2Service } from 'src/app/pages/monitoring-v2/monitoring-v2.service';
import { UserFiltersService } from 'src/app/services/user-filters.service';
import { SerializeArrayClassUtil, SerializeClassUtil } from 'src/app/utils/serialize-class.util';

export type TMode = {
  apply?: boolean
  create?: string
};

export type TFilterResult = {
  logistic: string
  loadNumber?: string[]
  plate?: string[]
  loadDate?: TRangePickerDate
  carriers?: TOption[]
  units?: TOption[]
  origins?: TOption[]
  destinations?: TOption[]
  operations?: TOption[]
  products?: TOption[]
  equipmentType?: TOption[]
};

export type TCurrentFilter = {
  mode: string
  filter: UserFilter<UserFilterScreenMonitoring>
};

@Injectable({
  providedIn: 'root'
})
export class ModalWindowMonitoringFilterService {

  private currentFilterSubject = new BehaviorSubject<TCurrentFilter>(null);

  public readonly currentFilter$ = this.currentFilterSubject.asObservable().pipe(filter(data => !!data));

  private savedFiltersSubject = new BehaviorSubject<UserFilter<UserFilterScreenMonitoring>[]>(null);

  public readonly savedFilters$ = this.savedFiltersSubject.asObservable().pipe(filter(data => !!data));

  private optionsSubject = new BehaviorSubject<any>(null);

  public readonly options$ = this.optionsSubject.asObservable().pipe(filter(data => !!data));

  constructor(
    private sessionStorageService: SessionStorageService,
    private userFilterService: UserFiltersService,
    private authService: AuthService,
    private perfilPermissaoService: PerfilPermissaoService,
    private monitoringV2Service: MonitoringV2Service
  ) { }

  /**
   * Inicializa os filtros salvos
   *
   * @returns void
   */
  public initSavedFilters(logistic: string = 'agro'): void {    
    const filters = this.getSavedFilters();

    // var filters = null;    

    if (!filters?.length) {
      try {
        let storageFilters = this.sessionStorageService.getItem<UserFilter<UserFilterScreenMonitoring>[]>(
          `monitoring-my-saved-filters-${logistic}`
        );

        if (storageFilters !== null) {
          storageFilters = UserFilter.from<UserFilterScreenMonitoring>(storageFilters);
          this.savedFiltersSubject.next(storageFilters);
          this.initCurrentFilter(logistic);
          return;
        }

        this.authService.userData.subscribe((user) => {
          const role = this.perfilPermissaoService.getCurrentLogisticaRole();

          const logistic1 = logistic == 'agro-subzones' || 'primaria-subzones'  ? logistic : role.logistica;

          this.userFilterService
            .getMyFilters<UserFilterScreenMonitoring>(user.preferred_username, `monitoramento-${logistic1}`)
            .then((savedFilters: UserFilter<UserFilterScreenMonitoring>[]) => {
              const storageSavedFilters: any[] = SerializeArrayClassUtil(savedFilters);

              storageSavedFilters.map((savedFilter: any) => {
                savedFilter.definicaoFiltro = JSON.stringify(savedFilter.definicaoFiltro);
                return savedFilter;
              });

              this.sessionStorageService.setItem(`monitoring-my-saved-filters-${logistic1}`, storageSavedFilters);
              this.savedFiltersSubject.next(savedFilters);
              this.initCurrentFilter(logistic1);
            });
        });
      } catch (e) {
        console.error('Não foi possível inicializar os filtros salvos', e);
      }
    }
  }

  /**
   * Inicializa um filtro atual
   *
   * @returns void
   */
  private initCurrentFilter(logistic: string = 'agro'): void {
    try {
      let currentFilter: TCurrentFilter = null;

      const storageCurrentFilter = this.sessionStorageService.getItem<any>(`monitoring-current-filter-${logistic}`);

      if (storageCurrentFilter) {
        storageCurrentFilter.filter = new UserFilter<UserFilterScreenMonitoring>(storageCurrentFilter.filter);
        currentFilter = storageCurrentFilter;
      } else {
        const savedFilter = this.getDefaultSavedFilter();

        if (!savedFilter) {
          return;
        }

        const serializedFilter = SerializeClassUtil(savedFilter);
        serializedFilter.definicaoFiltro = JSON.stringify(serializedFilter.definicaoFiltro);

        currentFilter = { mode: 'saved', filter: savedFilter };
        const currentFilterSave = { mode: 'saved', filter: serializedFilter };

        this.sessionStorageService.setItem<any>(`monitoring-current-filter-${logistic}`, currentFilterSave);
      }

      this.currentFilterSubject.next(currentFilter);

    } catch (e) {
      console.error('Não foi possível inicializar o current filter', e);
    }
  }

  public getOptions(): any {
    return this.optionsSubject.getValue();
  }

  public setOptions(options: any): void {
    this.optionsSubject.next(options);
  }

  public unsetOptions(): void {
    this.optionsSubject.complete();
    this.optionsSubject.next(null);
  }

  public getCurrentFilter(): TCurrentFilter {
    return this.currentFilterSubject.getValue();
  }

  /**
   * Busca os filtros do último next atualizado
   *
   * @param name string
   * @returns UserFilter<UserFilterScreenMonitoring>
   */
  public getSavedFilterByName(name: string): UserFilter<UserFilterScreenMonitoring> {
    const savedFilters = this.getSavedFilters();

    if (!savedFilters) {
      return null;
    }

    return savedFilters.find((savedFilter: UserFilter<UserFilterScreenMonitoring>) => savedFilter.name === name);
  }

  /**
   * Busca os filtros do último next atualizado
   *
   * @param id string
   * @returns UserFilter<UserFilterScreenMonitoring>
   */
  public getSavedFilterById(id: string): UserFilter<UserFilterScreenMonitoring> {
    const savedFilters = this.getSavedFilters();

    if (!savedFilters) {
      return null;
    }

    return savedFilters.find((savedFilter: UserFilter<UserFilterScreenMonitoring>) => savedFilter.id === id);
  }

  /**
   * Busca o filtro padrão atual
   *
   * @returns UserFilter<UserFilterScreenMonitoring>
   */
  public getDefaultSavedFilter(): UserFilter<UserFilterScreenMonitoring> {
    const savedFilters = this.getSavedFilters();

    if (!savedFilters) {
      return null;
    }

    return savedFilters.find((savedFilter: UserFilter<UserFilterScreenMonitoring>) => savedFilter.default);
  }

  /**
   * Busca os filtros do último next atualizado
   *
   * @returns UserFilter<UserFilterScreenMonitoring>[]
   */
  public getSavedFilters(): UserFilter<UserFilterScreenMonitoring>[] {
    return this.savedFiltersSubject.getValue() || [];
  }

  /**
   * Criar um novo filtro
   *
   * @param savedFilter UserFilter<UserFilterScreenMonitoring>
   */
  public createSavedFilter(savedFilter: UserFilter<UserFilterScreenMonitoring>, isNext = true, logistic: string = 'agro'): void {
    const [filters, currentFilter] = this.setRemoveAllDefaultFilter();

    this.userFilterService.createFilter<UserFilterScreenMonitoring>(savedFilter).subscribe(_ => {
      filters.push(savedFilter);

      if (currentFilter) {
        currentFilter.default = false;

        this.updateSavedFilter(currentFilter, false);
      }

      if (isNext) {
        const serializeList = SerializeArrayClassUtil(filters);

        serializeList.map(serializeItem => {
          serializeItem.definicaoFiltro = JSON.stringify(serializeItem.definicaoFiltro);
          return serializeItem;
        });

        this.sessionStorageService.setItem(`monitoring-my-saved-filters-${logistic}`, serializeList);

        this.savedFiltersSubject.next(filters);
      }
    });
  }

  /**
   * Atualiza um filtro existente
   *
   * @param savedFilter UserFilter<UserFilterScreenMonitoring>
   */
  public updateSavedFilter(savedFilter: UserFilter<UserFilterScreenMonitoring>, isNext = true, logistic: string = 'agro'): void {
    this.userFilterService.updateFilter<UserFilterScreenMonitoring>(savedFilter).subscribe(_ => {
      const newFilters = this.getSavedFilters().map((myFilter: UserFilter<UserFilterScreenMonitoring>) => {
        if (myFilter.id === savedFilter.id) {
          myFilter = savedFilter;
        }

        return myFilter;
      });

      if (isNext) {
        const serializeList = SerializeArrayClassUtil(newFilters);

        serializeList.map(serializeItem => {
          serializeItem.definicaoFiltro = JSON.stringify(serializeItem.definicaoFiltro);
          return serializeItem;
        });

        this.sessionStorageService.setItem(`monitoring-my-saved-filters-${logistic}`, serializeList);

        this.savedFiltersSubject.next(newFilters);
      }
    });
  }

  /**
   * Deleta um filtro e define um novo filtro como padrão
   *
   * @param savedFilter UserFilter<UserFilterScreenMonitoring>
   */
  public deleteSavedFilter(savedFilter: UserFilter<UserFilterScreenMonitoring>, logistic: string = 'agro'): void {
    this.userFilterService.removeFilter<UserFilterScreenMonitoring>(savedFilter).subscribe(_ => {
      const newFilters = this.getSavedFilters().filter((myFilter: UserFilter<UserFilterScreenMonitoring>) =>
        myFilter.id !== savedFilter.id
      );

      if (savedFilter.default) {
        const lastSavedFilterIndex = newFilters.length - 1;

        if (lastSavedFilterIndex !== -1) {
          newFilters[lastSavedFilterIndex].default = true;

          this.updateSavedFilter(newFilters[lastSavedFilterIndex], false, logistic);
        }

        this.monitoringV2Service.clearRoutes();
        this.sessionStorageService.removeItem<any>(`monitoring-current-filter-${logistic}`);
        this.currentFilterSubject.next({ mode: 'saved', filter: newFilters[lastSavedFilterIndex] });
      }

      if (newFilters.length) {
        const serializeList = SerializeArrayClassUtil(newFilters);

        serializeList.map(serializeItem => {
          serializeItem.definicaoFiltro = JSON.stringify(serializeItem.definicaoFiltro);
          return serializeItem;
        });

        this.sessionStorageService.setItem(`monitoring-my-saved-filters-${logistic}`, serializeList);
      }

      this.savedFiltersSubject.next(newFilters);
    });
  }

  /**
   * Seta o último filtro como padrão baseado na listagem de filtros
   *
   * @param savedFilters UserFilter<UserFilterScreenMonitoring>[]
   */
  public setDefaultByLastFilter(savedFilters: UserFilter<UserFilterScreenMonitoring>[]): void {
    const newFilters = savedFilters.map((myFilter: UserFilter<UserFilterScreenMonitoring>, index: number) => {
      if (index === (newFilters.length - 1)) {
        myFilter.default = true;
      }

      return myFilter;
    });

    const lastSavedFilter = newFilters[newFilters.length - 1];

    this.updateSavedFilter(lastSavedFilter);

    const currentFilter: TCurrentFilter = {
      mode: 'saved',
      filter: lastSavedFilter
    };

    this.currentFilterSubject.next(currentFilter);
  }

  /**
   * Define um novo filtro
   *
   * @param currentFilter TCurrentFilter
   */
  public setCurrentFilter(currentFilter: TCurrentFilter, logistic: string = 'agro'): void {
    try {
      const filterSerialized: any = currentFilter.filter.serialize();

      filterSerialized.definicaoFiltro = JSON.stringify(filterSerialized.definicaoFiltro);

      const serializedCurrentFilter = { mode: currentFilter.mode, filter: filterSerialized };

      this.sessionStorageService.setItem<any>(`monitoring-current-filter-${logistic}`, serializedCurrentFilter);

    } catch (e) {
      console.error('Não foi possível salvar no session storage', e);
    }

    this.currentFilterSubject.next(currentFilter);
  }

  /**
   * Seta um novo filtro como padrão
   *
   * @param savedFilter UserFilter<UserFilterScreenMonitoring>
   */
  public setDefaultFilter(savedFilter: UserFilter<UserFilterScreenMonitoring>): void {
    savedFilter.default = true;

    this.updateSavedFilter(savedFilter);

    const currentFilter: TCurrentFilter = {
      mode: 'saved',
      filter: savedFilter
    };

    this.currentFilterSubject.next(currentFilter);
  }

  /**
   * Remove o filtro padrão atual
   *
   * @returns [UserFilter<UserFilterScreenMonitoring>[], UserFilter<UserFilterScreenMonitoring>]
   */
  public setRemoveAllDefaultFilter(): [UserFilter<UserFilterScreenMonitoring>[], UserFilter<UserFilterScreenMonitoring>] {
    const defaultCurrentFilter = this.getDefaultSavedFilter();

    const filters = this.getSavedFilters().map((savedFilter: UserFilter<UserFilterScreenMonitoring>) => {
      savedFilter.default = false;
      return savedFilter;
    });

    return [filters, defaultCurrentFilter];
  }

  /**
   * Contabiliza os filtros que possuem valor
   *
   * @param form FormGroup
   * @returns number
   */
  public countFilters(form: FormGroup): number {
    if (form === null) {
      return 0;
    }

    const keys = Object.keys(form.value);

    return keys.reduce((totalFilters: any, field: any) => {
      const value = form.value[field];
      return totalFilters + Number(this.checkValue(value));
    }, 0);
  }

  /**
   * Valida se existe valor preenchido ou não
   *
   * @param value any
   * @returns boolean
   */
  public checkValue(value: any): boolean {
    if (Array.isArray(value) && !value.length) {
      return false;
    }

    if (value === undefined || value === null || value === '') {
      return false;
    }

    return true;
  }
}
