import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, Event } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {

  @Input() toggle: boolean = false;
  @Input() showMenu: boolean = false;
  @Output() toggleChange = new EventEmitter<boolean>();

  constructor(private router: Router) { 
  }

  ngOnInit(): void {
  }

  change(){
    this.toggle = !this.toggle;
    this.toggleChange.emit(this.toggle);
  }

}
