import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { PerfilPermissaoService } from "../core/services/perfil-permissao.service";


@Injectable()
export class CanActivateProfileAdmin implements CanActivate {
  constructor(
    private perfilPermissaoService: PerfilPermissaoService, 
    protected router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot    
  ): Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree {
    const roles = this.perfilPermissaoService.getCurrentLogisticaRole()
    if (this.perfilPermissaoService.isAdmin()) {
        return true;
    }    
    this.router.navigate(['/monitoring/list'])
    return false;
  }
}