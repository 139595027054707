import { APP_INITIALIZER, inject, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GmMapComponent } from './gm-map.component';
import { AgmCoreModule, LAZY_MAPS_API_CONFIG, LazyMapsAPILoaderConfigLiteral } from '@agm/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
export function agmConfigFactory(handler: HttpBackend, config: LazyMapsAPILoaderConfigLiteral) {
    const httpClient: HttpClient = new HttpClient(handler)
    return () => httpClient.get<any>(`${environment.express_url}/google`).pipe(
        map(response => {
            config.apiKey = response.googleMapsApiKey;
            return response;
        })
    ).toPromise();
}
@NgModule({
    declarations: [GmMapComponent],
    exports: [GmMapComponent],
    imports: [
        CommonModule,
        AgmCoreModule.forRoot({
            apiKey: 'initial'
        }),
    ],
    providers: [{
        provide: APP_INITIALIZER,
        useFactory: agmConfigFactory,
        deps: [HttpBackend, LAZY_MAPS_API_CONFIG],
        multi: true
    }],
})
export class GmMapModule { }
