import { IFrameService } from 'angular-auth-oidc-client/lib/iframe/existing-iframe.service';

export class Pagination
{
    public currentPage: number;
    public pageSize: number;
    public totalPages: number;
    public totalRegisters: number;

    public currentPageStart: number;
    public currentPageEnd: number;
    public pages: any[];
    public maxPagesBehind: number = 2;

    constructor(currentPage: number, pageSize: number, totalPages: number, totalRegisters: number){
        this.currentPage = currentPage;
        this.pageSize = pageSize;
        this.totalPages = totalPages;
        this.totalRegisters = totalRegisters;

        this.currentPageStart =   ((this.currentPage - 1) * this.pageSize) + 1;

        if(this.currentPageStart == 0)
            this.currentPageStart = 1;
        
        if(this.totalRegisters == 0)
            this.currentPageStart = 0;

        this.currentPageEnd = this.currentPageStart + this.pageSize - 1;
        if( this.currentPageEnd > this.totalRegisters)
            this.currentPageEnd = this.totalRegisters;

        this.pages = [];

        let pagesMaxLeft = this.maxPagesBehind;
        let pagesMaxRight = this.maxPagesBehind;

        let pagesLeft = 0;
        let pagesRight = 0;

        for(var i = this.currentPage - 1; i >= 1; i--) {
            this.pages.push({page: i, actived: false, left: true});
            pagesLeft++;
            if(pagesLeft == pagesMaxLeft)
                break;
        }

        if(pagesLeft < pagesMaxLeft){
            pagesMaxRight = pagesMaxRight + (pagesMaxLeft - pagesLeft);
        }
        
        this.pages.push({page: this.currentPage, actived: true});

        for(var i = this.currentPage + 1; i <= this.totalPages; i++) {
            this.pages.push({page: i, actived: false, right: true});
            pagesRight++;
            if(pagesRight == pagesMaxRight)
                break;
        }

        if(pagesRight < this.maxPagesBehind){
            pagesMaxLeft = this.maxPagesBehind - pagesRight;
        }

        let lefts =this.pages.filter(p=> p.left);

        if(pagesLeft < pagesMaxLeft){
            if(lefts != null && lefts.length > 0){
                const firstLeft = this.pages.filter(p=> p.left).sort((a, b)=> a.page - b.page)[0];
                for(var i = (firstLeft.page as number) - 1; i >= 1; i--) {
                    this.pages.push({page: i, actived: false, left: true});
                    pagesLeft++;
                    if(pagesLeft == pagesMaxLeft)
                        break;
                }
            } 
        }

        lefts = this.pages.filter(p=> p.left).sort((a, b)=> a.page - b.page);
        const rights = this.pages.filter(p=> p.right).sort((a, b)=>   a.page - b.page);
        const current = this.pages.find(p=> p.actived);
        if(this.pages.length > this.maxPagesBehind * 2){
            if(rights.length > 1){
                rights.splice(rights.length-1, 1);
            }else if(lefts.length > 1){
                lefts.splice(0, 1);
            }
        }

        this.pages = [];

        if(lefts != null && lefts.length > 0){
            lefts.forEach(p=> this.pages.push(p));
        }

        this.pages.push(current);

        if(rights != null && rights.length > 0){
            rights.forEach(p=> this.pages.push(p));
        }
    }
}