import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GmPolygonComponent } from './gm-polygon.component';

@NgModule({
    declarations: [GmPolygonComponent],
    exports: [GmPolygonComponent],
    imports: [
        CommonModule,
    ],
}) export class GmPolygonModule { }
