import { Injectable } from '@angular/core';
import { timingSafeEqual } from 'crypto';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { PerfilPermissaoService } from 'src/app/core/services/perfil-permissao.service';
import { UserFilterScreenMonitoring } from 'src/app/models/user-filter/screen/monitoring.model';
import { UserFilter } from 'src/app/models/user-filter/user-filter.model';
import { RouteService } from 'src/app/services/route.service';
import { LogisticTypeEnum } from '../../core/models/enums/logistic-type.enum';

@Injectable({
  providedIn: 'root'
})
export class MonitoringV2Service {

  private routesSubject = new BehaviorSubject<any[]>(null);

  public readonly monitoring$ = this.routesSubject.asObservable().pipe(filter(data => !!data));

  logistic = LogisticTypeEnum;

  constructor(
    private routeService: RouteService,
    private perfilPermissaoService: PerfilPermissaoService
  ) { }

  public getRoutes(): any[] {
    return this.routesSubject.getValue();
  }

  public setRoutes(filters: UserFilter<UserFilterScreenMonitoring>): void {
    const logistic = this.perfilPermissaoService.getCurrentLogisticaRole();

    this.routeService.getMonitoringMonitor(logistic.logistica, filters)
      .subscribe((data: any) => {
        // TODO: Ajustar API para retornar apenas em formato de ArrayList
        switch (logistic.logistica) {
          case this.logistic.PRIMARY:
            break;
          case this.logistic.DISTRIBUITION:
            break;
          case this.logistic.COMMODITIES:
            this.routesSubject.next(data.rotasMonitoramentoCommodities);
            break;
          case this.logistic.AGRONOMY:
            this.routesSubject.next(data.rotasMonitoramentoAgro);
            break;
        }
      });
  }

  public clearRoutes(): void {
    this.routesSubject.next([]);
  }

  public getTotalRoutes(): number {
    const totalRoutes = this.getRoutes();
    return !totalRoutes ? 0 : totalRoutes.length;
  }
}
